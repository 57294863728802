import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sortBy, isEmpty, uniqBy } from 'lodash';
import { ChildrenPlacementType } from 'basicprimitives';
import { GenericInput, FamilyChartGroup, ModalWithItem } from '../../common';
import RouteNames from '../../../containers/App/RouteNames';
import ModalProvisioningData from '../../common/ModalProvisioningData';

const convertData = ({
  customerInfo,
  dataSubsciption,
  dataService,
  offersData,
  t,
  setSubscriptionItemSelect,
  setServiceUnitSelect,
  setPriceUnitSelect,
  setItemSelect,
  modeGetSubscription,
  modeGetServiceUnit,
}) => {
  const parentItem = {
    id: customerInfo.id,
    parent: null,
    title: t('label.account'),
    templateName: 'smallSize',
    className: customerInfo.status ? customerInfo.status.toLowerCase() : '',
    description: (
      <div className="col-md-12 row">
        <div className="title-description">{`${t('label.id')}:`}</div>
        <div className="description-content">{customerInfo.id}</div>
      </div>
    ),
  };
  const data = [parentItem];

  if (dataSubsciption && dataSubsciption.length) {
    dataSubsciption.forEach(val => {
      const slt = t ? t('selections:subscriptionCategory')().find(i => i.value === val.category) : '';

      const item = {
        id: val.id,
        parent: customerInfo.id,
        title: t('label.subscription'),
        templateName: 'normalSize',
        description: (
          <div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.id')}:`}</div>
              <div className="description-content">{val.id}</div>
            </div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.type')}:`}</div>
              <div className="description-content">{slt ? slt.label : ''}</div>
            </div>
          </div>
        ),
        className: val.status ? val.status.toLowerCase() : '',
        onClickBox: modeGetSubscription
          ? () => {
              setSubscriptionItemSelect(val);
              if (val.status && val.status !== 'CLOSED') setItemSelect(val);
            }
          : null,
      };
      data.push(item);
    });
  }
  if (dataService && dataService.length) {
    dataService.forEach(val => {
      const slt = t ? t('selections:serviceType')().find(i => i.value === val.type) : '';
      const itemSelect =
        dataSubsciption && dataSubsciption.length ? dataSubsciption.find(sb => sb.id === val.subscriptionId) : null;
      const item = {
        id: val.id,
        parent: itemSelect ? itemSelect.id : null,
        title: t('label.serviceUnits'),
        className: val.status ? val.status.toLowerCase() : '',
        templateName: 'normalSize',
        description: (
          <div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.id')}:`}</div>
              <div className="description-content">{val.id}</div>
            </div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.type')}:`}</div>
              <div className="description-content">{slt ? slt.label : ''}</div>
            </div>
          </div>
        ),
        childrenPlacementType:
          offersData && offersData.length > 2 ? ChildrenPlacementType.Vertical : ChildrenPlacementType.Horizontal,
        onClickBox: modeGetServiceUnit ? () => setServiceUnitSelect(val) : null,
      };
      data.push(item);
    });
  }
  if (offersData && offersData.length) {
    offersData.forEach(val => {
      const itemSelect = dataService && dataService.length ? dataService.find(sb => sb.id === val.serviceUnitId) : null;
      const item = {
        id: val.id,
        parent: itemSelect ? itemSelect.id : null,
        title: val.offerType === 'PRICE_OFFER' ? t('label.priceUnit') : t('label.discountUnit'),
        description: (
          <div>
            <div className="col-md-12 row">
              <div className="title-description">{`${t('label.id')}:`}</div>
              <div className="description-content">{val.id}</div>
            </div>
            {val.offerName && val.offerType === 'PRICE_OFFER' && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.name')}:`}</div>
                <div className="description-content">{val.offerName}</div>
              </div>
            )}
            {val.offerName && val.offerType === 'DISCOUNT_OFFER' && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.name')}:`}</div>
                <div className="description-content">{val.offerName}</div>
              </div>
            )}
            {val.packageName && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.packageName')}:`}</div>
                <div className="description-content">{val.packageName}</div>
              </div>
            )}
            {val.bundleName && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.bundleName')}:`}</div>
                <div className="description-content">{val.bundleName}</div>
              </div>
            )}
            {!val.bundleName && !val.packageName && (
              <div className="col-md-12 row">
                <div className="title-description">{`${t('label.ala-Carte')}:`}</div>
                <div className="description-content">{t('label.yes')}</div>
              </div>
            )}
          </div>
        ),
        onClickBox: () => setPriceUnitSelect(val),
        className: val.status ? val.status.toLowerCase() : '',
      };
      data.push(item);
    });
  }
  return data;
};

const ServicesForm = ({
  id,
  dataFieldSubscription,
  dataSubsciption,
  dataFieldService,
  dataService,
  isDisableSubmit,
  getSubscription,
  getServiceUnitAc,
  getFlexAttributesMappingConfig,
  customerInfo,
  searchAccountAssets,
  offersData,
  columnsPriceUnit,
  modeGetSubscription,
  modeGetServiceUnit,
}) => {
  const { t } = useTranslation('common');
  const [isOpenModalProvisioningData, setIsOpenModalProvisioningData] = useState(false);
  const [itemProvisioningDataSelect, setItemProvisioningDataSelect] = useState(null);
  const [optionsSelectAttr, setOptionsSelectAttr] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);
  const [subscriptionItemSelect, setSubscriptionItemSelect] = useState(null);
  const [serviceUnitSelect, setServiceUnitSelect] = useState(null);
  const [priceUnitSelect, setPriceUnitSelect] = useState(null);
  const [isDefaultDisabledCreateButton, setIsDefaultDisabledCreateButton] = useState(true);

  let modalTitle = null;
  const onViewProvisioning = item => {
    setIsOpenModalProvisioningData(true);
    setItemProvisioningDataSelect(item);
    doGetFlexAttributesMappingConfig();
  };
  const onToggle = () => {
    if (subscriptionItemSelect) setSubscriptionItemSelect(null);
    if (serviceUnitSelect) setServiceUnitSelect(null);
    if (priceUnitSelect) setPriceUnitSelect(null);
    if (itemSelect) setItemSelect(null);
  };

  if (subscriptionItemSelect) modalTitle = t('label.subscription');
  if (serviceUnitSelect) modalTitle = t('label.serviceUnits');
  if (priceUnitSelect && priceUnitSelect.offerType === 'PRICE_OFFER') modalTitle = t('label.priceUnit');
  if (priceUnitSelect && priceUnitSelect.offerType === 'DISCOUNT_OFFER') modalTitle = t('label.discountUnit');

  const newColumnsService = [
    ...dataFieldService,
    // {
    //   name: 'status',
    //   label: 'common:label.viewProvisioningData',
    //   render: (colName, item) => {
    //     return (
    //       <button
    //         type="button"
    //         className="btn btn-outline-success btn-sm"
    //         onClick={() => {
    //           onViewProvisioning(item);
    //         }}
    //       >
    //         {t('label.view')}
    //       </button>
    //     );
    //   },
    // },
  ];

  const newOptionAttr = [];
  optionsSelectAttr.forEach(val => {
    if (!newOptionAttr.find(i => i.value === val.value)) {
      newOptionAttr.push(val);
    }
  });

  const allStatuSubScription =
    dataSubsciption && dataSubsciption.length ? dataSubsciption.map(item => item.status) : [];
  const isCreateBtn =
    isEmpty(dataSubsciption) ||
    (allStatuSubScription.indexOf('CLOSED') > -1 &&
      allStatuSubScription.indexOf('ACTIVE') === -1 &&
      allStatuSubScription.indexOf('SUSPENDED') === -1);

  const isResumeBtn = itemSelect && itemSelect.status === 'SUSPENDED' && itemSelect.status !== 'ACTIVE';
  const isOrtherBtn = itemSelect && itemSelect.status === 'ACTIVE';

  const newColumnsSubscription = [
    // {
    //   name: 'select',
    //   label: 'label.select',
    //   render: (colName, item) => (
    //     <GenericInput
    //       type="checkbox"
    //       name={item.id}
    //       checked={itemSelect && item.id === itemSelect.id}
    //       value={itemSelect && item.id === itemSelect.id}
    //       disabled={item.status && item.status === 'CLOSED'}
    //       onChange={() => {
    //         if (itemSelect && item.id === itemSelect.id) {
    //           setItemSelect(null);
    //         } else setItemSelect(item);
    //       }}
    //     />
    //   ),
    // },
    ...dataFieldSubscription,
  ];

  const doGetFlexAttributesMappingConfig = () => {
    if (!optionsSelectAttr || !optionsSelectAttr.length)
      getFlexAttributesMappingConfig({ objectType: 'SERVICE' }, ({ success, data }) => {
        if (success) {
          const optionsList =
            data && data.types && data.types.length
              ? data.types.map(val => ({ value: val.provAttributeType, label: val.provAttributeType }))
              : [];
          setOptionsSelectAttr(optionsList ? uniqBy(sortBy(optionsList, ['label']), 'label') : []);
        }
      });
  };

  const doSearchAccountAssets = useCallback(() => {
    searchAccountAssets({
      page: 1,
      size: 500,
      filter: { accountId: id },
    });
  }, [id, searchAccountAssets]);

  useEffect(() => {
    getSubscription(id, () => {
      setIsDefaultDisabledCreateButton(false);
    });
    getServiceUnitAc(id);
  }, [getServiceUnitAc, getSubscription, id]);

  useEffect(() => {
    doSearchAccountAssets();
  }, [doSearchAccountAssets]);

  return (
    <div className="col-md-12 mb-30">
      <FamilyChartGroup
        wrapperClass="border-top"
        items={convertData({
          customerInfo,
          dataSubsciption,
          dataService,
          offersData,
          t,
          setSubscriptionItemSelect,
          setServiceUnitSelect,
          setPriceUnitSelect,
          setItemSelect,
          modeGetSubscription,
          modeGetServiceUnit,
        })}
      />
      <ModalWithItem
        modalTitle={modalTitle}
        onToggle={onToggle}
        isOpen={subscriptionItemSelect || serviceUnitSelect || priceUnitSelect}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        {subscriptionItemSelect && modeGetSubscription !== 0 && (
          <div className="col-md-12">
            {/* <DataTable columns={newColumnsSubscription} data={subscriptionItemSelect ? [subscriptionItemSelect] : []} /> */}
            <div className="col-md-12 row">
              {newColumnsSubscription.map(val => (
                <GenericInput
                  {...val}
                  readOnly
                  disabled
                  value={subscriptionItemSelect ? subscriptionItemSelect[val.name] : null}
                />
              ))}
            </div>
            {!isDisableSubmit && (
              <div className="form-group col-md-12 action-buttons btn-subsciption embrix-disabled-link">
                <Link
                  to={{
                    pathname: RouteNames.newOrder.path,
                    state: {
                      accountId: id,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                      accountCategory: customerInfo?.accountCategory || null,
                      billingProfiles: customerInfo?.billingProfiles ? customerInfo?.billingProfiles[0] : null,
                    },
                  }}
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isCreateBtn || isDefaultDisabledCreateButton}
                >
                  {t('label.create')}
                </Link>
                <Link
                  to={{
                    pathname: RouteNames.modifyOrder.path,
                    state: {
                      accountId: id,
                      data: itemSelect,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                    },
                  }}
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isOrtherBtn}
                >
                  {t('label.modify')}
                </Link>
                <Link
                  to={{
                    pathname: RouteNames.cancelOrder.path,
                    state: {
                      data: itemSelect,
                      accountId: id,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                    },
                  }}
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isOrtherBtn}
                >
                  {t('label.cancel')}
                </Link>
                <Link
                  to={{
                    pathname: RouteNames.suspendOrder.path,
                    state: {
                      data: itemSelect,
                      accountId: id,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                    },
                  }}
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isOrtherBtn}
                >
                  {t('label.suspend')}
                </Link>
                <Link
                  to={{
                    pathname: RouteNames.renewOrder.path,
                    state: {
                      data: itemSelect,
                      accountId: id,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                    },
                  }}
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isOrtherBtn}
                >
                  {t('label.renew')}
                </Link>
                <Link
                  className="button button-border x-small float-right"
                  disabled={isDisableSubmit || !isResumeBtn}
                  to={{
                    pathname: RouteNames.resumeOrder.path,
                    state: {
                      data: itemSelect,
                      accountId: id,
                      backLink: RouteNames.prettifyPath(RouteNames.subscriptionView.path, id),
                    },
                  }}
                >
                  {t('label.resume')}
                </Link>
                <button type="button" className="button button-border x-small float-right" disabled>
                  {t('label.upgrade')}
                </button>
                <button type="button" className="button button-border x-small  float-right" disabled>
                  {t('label.downgrade')}
                </button>
                <span className="float-right text-order-group">
                  {t('label.placeOrder')}
:
                </span>
              </div>
            )}
          </div>
        )}
        {serviceUnitSelect && (
          <div className="col-md-12 row">
            {newColumnsService.map(val => (
              <GenericInput {...val} readOnly disabled value={serviceUnitSelect ? serviceUnitSelect[val.name] : null} />
            ))}
            <button
              type="button"
              className="button x-small btn-sm mt-4 mb-2"
              onClick={() => {
                onViewProvisioning(serviceUnitSelect);
              }}
            >
              {t('label.viewProvisioningData')}
            </button>
          </div>
        )}
        {priceUnitSelect && (
          <div className="col-md-12 row">
            {columnsPriceUnit.map(val => (
              <GenericInput {...val} readOnly disabled value={priceUnitSelect ? priceUnitSelect[val.name] : null} />
            ))}
          </div>
        )}
        <div className={`col-md-12 ${subscriptionItemSelect ? 'mt-5' : 'mt-2'}`}>
          <button type="button" onClick={onToggle} className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
        </div>
      </ModalWithItem>
      <ModalProvisioningData
        isOpen={isOpenModalProvisioningData}
        optionsSelectAttr={newOptionAttr}
        onCancel={() => {
          setIsOpenModalProvisioningData(false);
          setItemProvisioningDataSelect(null);
        }}
        defaultSearchParams={{
          serviceType: itemProvisioningDataSelect ? itemProvisioningDataSelect.type : null,
          accountId: id,
        }}
      />
    </div>
  );
};

ServicesForm.propTypes = {
  id: PropTypes.string,
  dataFieldSubscription: PropTypes.arrayOf(PropTypes.any),
  dataSubsciption: PropTypes.arrayOf(PropTypes.any),
  isDisableSubmit: PropTypes.bool,
  dataFieldService: PropTypes.arrayOf(PropTypes.any),
  dataService: PropTypes.arrayOf(PropTypes.any),
};

ServicesForm.defaultProps = {
  id: '',
  dataFieldSubscription: [],
  dataSubsciption: [],
  isDisableSubmit: false,
  dataFieldService: [],
  dataService: [],
};

export default ServicesForm;
