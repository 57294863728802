import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../../components/common';
import PricesForm from './PricesForm';
import PricesFormRecurringTiered from './PricesFormRecurringTiered';
import ModalRateUnit from '../../../../components/common/ModalRateUnit';
import ModalTimeUnit from '../../../../components/common/ModalTimeUnit';
import ModalHolidayUnit from '../../../../components/common/ModalHolidayUnit';
import ModalSelectItem from '../../../../components/common/ModalSelectItem';

const initAtrr = data => {
  let defaultConfig = [];
  if (data && data.length) {
    defaultConfig = data.map(val => {
      const atrr = val.attribute;
      const indxDot = atrr.indexOf('.');
      if (indxDot) {
        return { selectRecord: atrr.substring(0, indxDot), selectField: atrr.substring(indxDot + 1, atrr.length) };
      }
      return {};
    });
  }
  return defaultConfig;
};

class PricingForm extends Component {
  state = {
    dataConfigAttributeBase: [],
    isOpenModalItem: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.pricing === props.pricing) return null;
    return {
      pricing: props.pricing || {},
      dataConfigAttributeBase: state.dataConfigAttributeBase.length
        ? state.dataConfigAttributeBase
        : initAtrr(props.pricing.usageAttributePricingData),
    };
  }

  onChangePricing = () => {
    const { pricing } = this.state;
    const { onChangePricing, index } = this.props;
    onChangePricing(index, pricing);
  };

  handleChangePrice = (idx, data) => {
    const { pricing } = this.state;
    pricing.prices[idx] = data;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  handleChangeTier = (idx, data) => {
    const { pricing } = this.state;
    pricing.tiers[idx] = data;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  handleRemovePricing = e => {
    e.preventDefault();
    const { onRemovePricing, index, pricing } = this.props;
    if (onRemovePricing) {
      onRemovePricing(index, pricing);
    }
  };

  onChangePricingProps = ({ name, value }) => {
    const { pricing } = this.state;
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    pricing[name] = newValue;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  handleRemovePrice = (priceIdx, price) => {
    const { pricing } = this.state;
    if (pricing.prices && pricing.prices[priceIdx] >= 0) {
      pricing.prices.splice(priceIdx, 1);
    }
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onChangeSubInputRecurringTirred = ({ name, value }) => {
    const { pricing } = this.state;
    pricing.tierPricing[0][name] = value;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onChangeSubInputUsageAttributeBase = ({ name, value, idxPrice }) => {
    const { dataConfigAttributeBase, pricing } = this.state;
    const newPricing = pricing;
    const newDataConfig = dataConfigAttributeBase;
    if (!newDataConfig[idxPrice]) newDataConfig[idxPrice] = {};
    newDataConfig[idxPrice][name] = value;
    const newUsageAttributePricingData = cloneDeep(newPricing.usageAttributePricingData);
    if (newDataConfig[idxPrice] && newDataConfig[idxPrice].selectRecord && newDataConfig[idxPrice].selectField) {
      newUsageAttributePricingData[
        idxPrice
      ].attribute = `${newDataConfig[idxPrice].selectRecord}.${newDataConfig[idxPrice].selectField}`;
    } else {
      newUsageAttributePricingData[idxPrice].attribute = '';
    }
    newPricing.usageAttributePricingData = newUsageAttributePricingData;
    this.setState({ dataConfigAttributeBase: newDataConfig, pricing: newPricing }, () => this.onChangePricing());
  };

  onChangeAttributeBaseProps = ({ name, value, idxPrice }) => {
    const { pricing } = this.state;
    pricing.usageAttributePricingData[idxPrice][name] = value;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onChangeTierRecurring = (idx, data) => {
    const { pricing } = this.state;
    pricing.tierPricing[0].tiers[idx] = data;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onChangeRecurringPriceStructure = ({ data, idx, index }) => {
    const { pricing } = this.state;
    pricing.tierPricing[0].tiers[idx] = data;
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onCopyOldAttributeBase = () => {
    const { pricing } = this.state;
    const { creatingMode } = this.props;
    const lastIdxItem = pricing.usageAttributePricingData.length;
    if (lastIdxItem) {
      // const newItem = pricing.usageAttributePricingData[lastIdxItem - 1];
      const newItem = cloneDeep(pricing.usageAttributePricingData[0]);
      newItem.value = 'NONE';
      if (!creatingMode) {
        newItem.index++;
      }
      pricing.usageAttributePricingData.push(cloneDeep(newItem));
    }
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onHandleRemoveField = ({ idxItem }) => {
    const { pricing } = this.state;
    const { creatingMode } = this.props;
    if (creatingMode) {
      pricing.usageAttributePricingData.splice(idxItem, 1);
    } else {
      pricing.usageAttributePricingData[idxItem] = { index: pricing.usageAttributePricingData[idxItem].index };
    }
    this.setState({ pricing }, () => this.onChangePricing());
  };

  onOpenModal = ({ name }) => {
    this.setState({ isOpenModalItem: name });
  };

  onSelectItem = (row, val) => {
    const { isOpenModalItem } = this.state;
    this.onChangePricingProps({ name: isOpenModalItem, value: row });
    this.setState({ isOpenModalItem: '' });
  };

  onRenderSubinputAttribute = ({
    subInputUsageAtributeBase,
    dataConfigAttributeBase,
    pricing,
    usageContainerRecordList,
    index,
    isComplexUsage,
  }) => {
    return (
      subInputUsageAtributeBase &&
      subInputUsageAtributeBase.length > 0 &&
      pricing.usageAttributePricingData &&
      pricing.usageAttributePricingData
        .filter(val => size(val) > 1)
        .map((val, idxPrice) => {
          const itemDefaultConfig = dataConfigAttributeBase[idxPrice];
          const itemRecordSelect = itemDefaultConfig
            ? usageContainerRecordList.filter(val => val.recordType === itemDefaultConfig.selectRecord)
            : [];
          let optionFieldType = [];
          if (itemRecordSelect && itemRecordSelect.length)
            optionFieldType = itemRecordSelect[0].attributes.map(item => ({
              label: item.attributeName,
              value: item.attributeName,
            }));
          return (
            <div className="row col-md-12 pb-2" key={`idx-${idxPrice}`}>
              {/* <div className="card-header col-md-12">
                  {`${t('label.index').toUpperCase()}#${idxPrice + 1}`}
      
                </div> */}
              <div className="col-md-12 row p-2">
                <div className="row col-md-11 p-0">
                  {subInputUsageAtributeBase.map((input, idx) => {
                    return (
                      <GenericInput
                        key={`pricing-${input.name}-${idx}`}
                        disabled={input.disabled}
                        {...input}
                        value={
                          ['selectRecord', 'selectField'].indexOf(input.name) > -1
                            ? itemDefaultConfig
                              ? itemDefaultConfig[input.name]
                              : ''
                            : val[input.name]
                        }
                        options={input.name === 'selectField' ? optionFieldType : input.options}
                        onChange={
                          ['selectRecord', 'selectField'].indexOf(input.name) > -1
                            ? ({ name, value }) => this.onChangeSubInputUsageAttributeBase({ name, value, idxPrice })
                            : ({ name, value }) => this.onChangeAttributeBaseProps({ name, value, idxPrice })
                        }
                      />
                    );
                  })}
                </div>
                <div className="col-md-1 m-auto">
                  {pricing.usageAttributePricingData.length > 1 && index === 0 && (
                    <button
                      type="button"
                      className="btn-delete mt-3 float-left pl-4"
                      onClick={() => this.onHandleRemoveField({ idxItem: idxPrice })}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })
    );
  };

  render() {
    const {
      index,
      pricingInputs,
      pricingRemoveable,
      priceRemovable,
      creatingMode,
      isComplexUsage,
      subInputRecurringTirred,
      checkModel,
      usageContainerRecordList,
      indexShowing,
      onToggleShowIndex,
      inputFieldsUsageComplex,
      t,
      ...props
    } = this.props;
    const { pricing, dataConfigAttributeBase, isOpenModalItem } = this.state;
    const optionRecordType = usageContainerRecordList.map(val => ({ label: val.recordType, value: val.recordType }));
    const subInputUsageAtributeBase =
      props.pricingModel === 'USAGE_ATTRIBUTE_BASED' || props.pricingModel === 'COMPLEX'
        ? [
            {
              label: 'label.selectRecord',
              wrapperClass: 'col-md-3',
              name: 'selectRecord',
              // required: true,
              type: 'select',
              options: optionRecordType,
              disabled: index > 0 && !isComplexUsage,
              menuPortalTarget: true,
            },
            {
              label: 'label.selectField',
              wrapperClass: 'col-md-3',
              name: 'selectField',
              // required: true,
              type: 'select',
              options: [],
              disabled: index > 0 && !isComplexUsage,
              menuPortalTarget: true,
            },
            {
              label: 'label.field',
              wrapperClass: 'col-md-3',
              name: 'attribute',
              required: true,
              readOnly: true,
              disabled: index > 0 && !isComplexUsage,
            },
            {
              label: 'label.value',
              wrapperClass: 'col-md-3',
              name: 'value',
              required: true,
              // disabled: index > 0,
            },
          ]
        : [];

    if (isComplexUsage && size(pricing) < 11) return '';
    return (
      <div className={props.pricingModel !== 'FLAT' ? 'card pricing-panel' : ''}>
        {props.pricingModel !== 'FLAT' && (
          <button
            type="button"
            className={`card-header text-left no-border btn-collapsible-index ${
              indexShowing === index + 1 ? 'active-main' : ''
            }`}
            onClick={() => onToggleShowIndex(index)}
          >
            {`${t('label.index').toUpperCase()}#${index + 1}`}
            {indexShowing === index + 1 ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
            {(pricingRemoveable || isComplexUsage) && (
              <button type="button" className="btn-rm-card mr-5" onClick={this.handleRemovePricing}>
                <i className="fa fa-trash" />
              </button>
            )}
          </button>
        )}
        <div className={`card-body prc-card  ${indexShowing === index + 1 ? 'show-content' : 'hidden-content'}`}>
          <div className="form-row mb-4">
            {subInputRecurringTirred &&
              subInputRecurringTirred.map((input, idx) => {
                return (
                  <GenericInput
                    key={`pricing-${input.name}-${idx}`}
                    {...input}
                    value={(pricing && pricing.tierPricing && pricing.tierPricing[0][input.name]) || ''}
                    onChange={this.onChangeSubInputRecurringTirred}
                    disabled={
                      (!creatingMode && (input.name === 'unit' || input.name === 'frequency')) || input.disabled
                    }
                  />
                );
              })}
            {inputFieldsUsageComplex &&
              inputFieldsUsageComplex.map((val, index) => {
                if (!val.title && !val.isNoTitle) return '';
                return (
                  <div className="col-md-12 row" key={`key-${index}`}>
                    {val.title && <h5 className="card-title title-form">{t(val.title)}</h5>}
                    <div className="col-md-12 row p-0 m-0">
                      {isComplexUsage && val.isRenderSubField && (
                        <div className="col-md-12">
                          {this.onRenderSubinputAttribute({
                            isComplexUsage,
                            subInputUsageAtributeBase,
                            dataConfigAttributeBase,
                            pricing,
                            usageContainerRecordList,
                            index,
                          })}
                          <div className="col-md-12 pt-3 pb-3 text-center">
                            <button
                              type="button"
                              className="button button-border gray x-small"
                              onClick={this.onCopyOldAttributeBase}
                              disabled={index > 0}
                            >
                              {`+ ${t('label.addAnotherField')}`}
                            </button>
                          </div>
                        </div>
                      )}
                      {val.listField &&
                        val.listField.length > 0 &&
                        val.listField.map((input, idx) => {
                          return (
                            <GenericInput
                              key={`pricing-${input.name}-${idx}`}
                              {...input}
                              value={pricing[input.name]}
                              onChange={this.onChangePricingProps}
                              disabled={
                                (!creatingMode && (input.name === 'unit' || input.name === 'frequency')) ||
                                input.disabled
                                // (isComplexUsage && index > 0)
                              }
                              onClick={input.fa ? () => this.onOpenModal({ name: input.name }) : () => {}}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            {pricingInputs &&
              pricingInputs.map((input, idx) => {
                if (input.subType === 'boolean') {
                  return (
                    <GenericInput
                      key={input.name}
                      value={
                        (pricing[input.name] && pricing[input.name] && pricing[input.name] === 'true') ||
                        pricing[input.name] === true
                          ? 'TRUE'
                          : pricing[input.name] === undefined
                          ? null
                          : 'FALSE'
                      }
                      label={t(input.label)}
                      wrapperClass="col-md-3"
                      {...input}
                      onChange={this.onChangePricingProps}
                    />
                  );
                }
                return (
                  <GenericInput
                    key={`pricing-${input.name}-${idx}`}
                    {...input}
                    value={pricing[input.name]}
                    disabled={
                      (!creatingMode && (input.name === 'unit' || input.name === 'frequency')) || input.disabled
                      // (isComplexUsage && index > 0)
                    }
                    onClick={input.fa ? () => this.onOpenModal({ name: input.name }) : () => {}}
                    onChange={this.onChangePricingProps}
                  />
                );
              })}
            {!isComplexUsage &&
              this.onRenderSubinputAttribute({
                isComplexUsage,
                subInputUsageAtributeBase,
                dataConfigAttributeBase,
                pricing,
                usageContainerRecordList,
                index,
              })}

            {checkModel === 'USAGE_ATTRIBUTE_BASED' && (
              <div className="col-md-12 pt-3 pb-3 text-center">
                <button
                  type="button"
                  className="button button-border gray x-small"
                  onClick={this.onCopyOldAttributeBase}
                  disabled={index > 0}
                >
                  {`+ ${t('label.addAnotherField')}`}
                </button>
              </div>
            )}
          </div>
          {checkModel !== 'RECURRING_TIERED' && (
            <PricesForm
              item={pricing}
              index={index}
              onChangePrice={this.handleChangePrice}
              onChangeTier={this.handleChangeTier}
              onRemoveItem={this.handleRemovePrice}
              priceRemovable={priceRemovable}
              creatingMode={creatingMode}
              isComplexUsage={isComplexUsage}
              readOnlyCurrency={checkModel === 'USAGE_ATTRIBUTE_BASED' && index > 0}
              checkModel={checkModel}
              {...props}
            />
          )}
          {checkModel === 'RECURRING_TIERED' && (
            <PricesFormRecurringTiered
              item={pricing.tierPricing}
              index={index}
              onChangeRecurringPriceStructure={this.onChangeRecurringPriceStructure}
              onChangeTier={this.onChangeTierRecurring}
              onRemoveItem={this.handleRemovePrice}
              priceRemovable={priceRemovable}
              creatingMode={creatingMode}
              isComplexUsage={isComplexUsage}
              {...props}
            />
          )}
        </div>
        <ModalRateUnit
          isOpen={isOpenModalItem === 'rateUnit'}
          onSelect={this.onSelectItem}
          selectedParentId={pricing.rateUnit || ''}
          onCancel={() => {
            this.setState({ isOpenModalItem: '' });
          }}
        />
        <ModalTimeUnit
          isOpen={isOpenModalItem === 'timeUnit'}
          onSelect={this.onSelectItem}
          selectedParentId={pricing.timeUnit || ''}
          onCancel={() => {
            this.setState({ isOpenModalItem: '' });
          }}
          fieldName="timeUnit"
        />
        <ModalHolidayUnit
          isOpen={isOpenModalItem === 'holidayName'}
          onSelect={this.onSelectItem}
          selectedParentId={pricing.holidayName || ''}
          onCancel={() => {
            this.setState({ isOpenModalItem: '' });
          }}
        />
        <ModalSelectItem
          isOpen={isOpenModalItem === 'itemId'}
          onSelect={this.onSelectItem}
          onCancel={() => {
            this.setState({ isOpenModalItem: '' });
          }}
          // defaultSearchParams={{ type: 'RECEIVABLE' }}
        />
      </div>
    );
  }
}

PricingForm.propTypes = {
  onChangePricing: PropTypes.func.isRequired,
  pricing: PropTypes.object,
  priceRemovable: PropTypes.bool,
  pricingRemoveable: PropTypes.bool,
  creatingMode: PropTypes.bool,
};

PricingForm.defaultProps = {
  priceRemovable: true,
  pricingRemoveable: true,
  creatingMode: true,
};
export default withTranslation('common')(PricingForm);
