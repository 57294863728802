import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

let uri = process?.env?.REACT_APP_JOB_SCHEDULE_PROXY_URL || 'https://service-proxy.develop.embrix.org/graphql';

if (window._env_ && window._env_.REACT_APP_JOB_SCHEDULE_PROXY_URL) {
  uri = window._env_.REACT_APP_JOB_SCHEDULE_PROXY_URL;
}

const jobSchedule = new ApolloClient({
  link: new HttpLink({
    uri,
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

export default jobSchedule;
