import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { withTranslation } from 'react-i18next';
import { GenericInput } from '../../../common';

const HandleTable = SortableHandle(() => (
  <button type="button" className="sortable-handle-icon no-border">
    <i className="fa fa-bars" />
  </button>
));
const SortableItemTable = SortableElement(({ data, onRemove, sortingItemKey, onChange }) => {
  return (
    <tr className={`job-config-row ${sortingItemKey === data ? 'dragging' : ''}`} style={{ zIndex: 99999999 }}>
      <td>
        <HandleTable />
      </td>
      <td className="">
        <GenericInput
          value={data.moduleName || null}
          wrapperClass="col-md-12 p-0"
          type="select"
          name="moduleName"
          tOptions="selections:moduleName"
          onChange={({ name, value }) => onChange({ name, value, index: data.index })}
          menuPortalTarget
          required
        />
      </td>
      <td style={{ minWidth: 150 }}>
        <GenericInput
          value={data.active === 'true' || data.active === true ? 'TRUE' : 'FALSE'}
          type="select"
          tOptions="selections:selectBool"
          wrapperClass="col-md-12 p-0"
          name="active"
          onChange={({ name, value }) => onChange({ name, value, index: data.index })}
          menuPortalTarget
        />
      </td>
      <td className="">
        <GenericInput
          value={data.logContainer === 'true' || data.logContainer === true ? 'TRUE' : 'FALSE'}
          type="select"
          tOptions="selections:selectBool"
          name="logContainer"
          wrapperClass="col-md-12 p-0"
          onChange={({ name, value }) => onChange({ name, value, index: data.index })}
          menuPortalTarget
          required
        />
      </td>
      <td className="actions">
        <button type="button" className="ico-remove no-border" onClick={() => onRemove({ index: data.index })}>
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
});

const SortableListTable = SortableContainer(({ items, ...props }) => {
  return (
    <tbody>
      {items.map((data, index) => (
        <SortableItemTable key={`item-${index}`} index={index} data={data} {...props} />
      ))}
    </tbody>
  );
});
class TableChildren extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  onAddNewModule = () => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let indexNewItem = 0;
    if (newData.length) {
      newData.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    const newDataItem = {
      index: indexNewItem + 1,
      moduleName: null,
      active: 'true',
      logContainer: 'true',
    };

    newData.push(newDataItem);
    this.setState({ data: newData }, () => this.updateValueParentComponent());
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    let newData = cloneDeep(data);
    const tempOldIdx = newData[oldIndex].index;
    newData[oldIndex].index = newData[newIndex].index;
    newData[newIndex].index = tempOldIdx;
    newData = arrayMove(newData, oldIndex, newIndex);
    this.setState({ data: newData }, () => this.updateValueParentComponent());
  };

  onHandleChagneTable = ({ name, value, index }) => {
    const { data } = this.state;
    const indexChagne = data.findIndex(item => item.index === index);
    if (indexChagne !== -1) {
      if (value === 'TRUE' || value === 'FALSE') {
        data[indexChagne][name] = value === 'TRUE' ? 'true' : 'false';
      } else data[indexChagne][name] = value;
    }
    this.setState({ ...data }, () => this.updateValueParentComponent());
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    let newData = cloneDeep(data);
    newData = newData.filter(item => item.index !== index);
    newData.push({ index });
    this.setState({ data: newData }, () => this.updateValueParentComponent());
  };

  updateValueParentComponent = () => {
    const { data } = this.state;
    const { onUpdateValue } = this.props;
    onUpdateValue(data);
  };

  render() {
    const { data } = this.state;
    const { t } = this.props;
    const newDataRemoveIndex = data && data.length ? data.filter(item => size(item) > 1) : [];
    return (
      <div className="col-md-12 mt-2 mb-2">
        <div className="col-md-11 m-auto p-2 box-shadow">
          <table className="table center-aligned-table mb-0">
            <thead>
              <tr>
                <th />
                <th style={{ minWidth: 250 }}>
                  {t('label.modules')}
                  <i className="fa fa-star is-required" />
                </th>
                <th>
                  {t('label.active')}
                  <i className="fa fa-star is-required" />
                </th>
                <th>
                  {t('label.logUsageContainer')}
                  <i className="fa fa-star is-required" />
                </th>
                <th>{t('label.remove')}</th>
              </tr>
            </thead>
            {!data ||
              (data.length === 0 && (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center">
                      {t('message.noModules')}
                    </td>
                  </tr>
                </tbody>
              ))}
            <SortableListTable
              items={newDataRemoveIndex}
              useDragHandle
              onSortEnd={this.onSortEnd}
              onRemove={this.onRemoveItem}
              onChange={this.onHandleChagneTable}
            />
          </table>
          <div className="col-md-12 row justify-content-center mt-3">
            <button type="button" className="button button-border gray x-small" onClick={this.onAddNewModule}>
              {t('label.addAnotherModule')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TableChildren.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onUpdateValue: PropTypes.func,
};

TableChildren.defaultProps = {
  data: [],
  onUpdateValue: () => {},
};

export default withTranslation('common')(TableChildren);
