import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import OfferForm from './OfferForm';
import { makeErrorMessage } from '../selectors';
import { createPriceOffer, getUsageContainerSpec } from '../actions';
import {
  getCcpPropertiesConfig,
  getCcpDateTimeConfig,
  getAccumulatorsConfig,
  getGrantsConfig,
  searchProvisioningAttributesConfigItem,
} from '../../App/actions';
import { makeGetCcpTime, makeGetCcpPropertiesConfig } from '../../App/selectors';

class PricingPriceOfferAdd extends Component {
  constructor(props) {
    const { location } = props;
    super(props);
    this.state = {
      isSubmitting: false,
      newPricingOffer:
        location && location.state && !location.state.filter
          ? location.state
          : {
              // salesChannel: 'NONE',
              // marketSegment: 'NONE',
              // accountType: 'NONE',
              // accountSubType: 'NONE',
              status: 'CREATE',
              minimumQuantity: '1',
              pricingType: 'CUSTOMER',
            },
      accumulatorList: [],
      grantList: [],
      usageContainerRecordList: [],
      isShowBilledService: false,
      defaultCurrency: null,
    };
  }

  componentDidMount() {
    const {
      getAccumulatorsConfig,
      getGrantsConfig,
      getUsageContainerSpec,
      getCcpPropertiesConfig,
      ccpTime,
      getCcpDateTimeConfig,
      ccpPropertyList,
    } = this.props;
    const { newPricingOffer } = this.state;
    if (!ccpTime) {
      getCcpDateTimeConfig('', ({ success, data }) => {
        if (success)
          this.setState({
            newPricingOffer: {
              ...newPricingOffer,
              effectiveDate: newPricingOffer?.effectiveDate ? newPricingOffer.effectiveDate : data?.ccpTime || null,
            },
          });
      });
    } else {
      this.setState({
        newPricingOffer: {
          ...newPricingOffer,
          effectiveDate: newPricingOffer?.effectiveDate ? newPricingOffer.effectiveDate : ccpTime || null,
        },
      });
    }
    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({ accumulatorList: data && data.accumulatorList ? data.accumulatorList : [] });
      }
    });

    getGrantsConfig(({ data, success }) => {
      if (success) {
        this.setState({ grantList: data && data.length ? data : [] });
      }
    });

    getUsageContainerSpec(({ data, success }) => {
      if (success) {
        this.setState({ usageContainerRecordList: data && data.length ? data : [] });
      }
    });

    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.doInitCcpPropertyList(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitCcpPropertyList(ccpPropertyList);
    }
  }

  doInitCcpPropertyList = ccpPropertyList => {
    const { newPricingOffer } = this.state;

    const billedServiceProperty = ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    const countryProperty = ccpPropertyList.find(val => val.property === 'defaultCountry');
    const defaultCurrency = ccpPropertyList.find(val => val.property === 'defaultCurrency');

    this.setState({
      isShowBilledService: !!(billedServiceProperty && billedServiceProperty.value === 'true'),
      newPricingOffer: {
        ...newPricingOffer,
        omsTaskType: provisioningEnabled && provisioningEnabled?.value === 'true' ? 'BOTH' : 'BILLING_ONLY',
        country: countryProperty && countryProperty?.value ? countryProperty?.value : null,
      },
      defaultCurrency: defaultCurrency?.value ? defaultCurrency.value : null,
    });
  };

  onSave = data => {
    const { createPriceOffer, history } = this.props;
    this.setState({ isSubmitting: true });
    createPriceOffer(data, ({ success, payload }) => {
      this.setState({ isSubmitting: false });
      if (success) {
        history.push(RouteNames.prettifyPath(RouteNames.pricingPriceOfferEdit.path, payload));
      }
    });
  };

  render() {
    const {
      newPricingOffer,
      isSubmitting,
      accumulatorList,
      grantList,
      usageContainerRecordList,
      isShowBilledService,
      defaultCurrency,
    } = this.state;

    const optionsAccumulatorId = accumulatorList.map(item => ({
      label: item.accumulatorid,
      value: item.accumulatorid,
    }));

    const optionsGrantId = grantList.map(item => ({
      label: item.resourceId,
      value: item.resourceId,
    }));

    const { t, location, ccpTime, searchProvisioningAttributesConfigItem } = this.props;
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingPriceOffer.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.priceOffer'), url: RouteNames.pricingPriceOffer.path },
                  { name: t('label.add') },
                ]}
              />
            </div>
          </div>
        </div>
        <br />
        <OfferForm
          item={newPricingOffer}
          onSubmit={this.onSave}
          isSubmitting={isSubmitting}
          creatingMode
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          usageContainerRecordList={usageContainerRecordList}
          isShowBilledService={isShowBilledService}
          title="label.createNewPriceOffer"
          effectiveDate={ccpTime}
          searchProvisioningAttributesConfigItem={searchProvisioningAttributesConfigItem}
          defaultCurrency={defaultCurrency}
        />
      </div>
    );
  }
}

PricingPriceOfferAdd.propTypes = {
  createPriceOffer: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

PricingPriceOfferAdd.defaultProps = {
  location: {},
  history: {},
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
  ccpTime: makeGetCcpTime() || '',
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const mapDispatchToProps = dispatch => ({
  createPriceOffer: (e, cb) => dispatch(createPriceOffer(e, cb)),
  getAccumulatorsConfig: cb => dispatch(getAccumulatorsConfig(cb)),
  getGrantsConfig: cb => dispatch(getGrantsConfig(cb)),
  getUsageContainerSpec: cb => dispatch(getUsageContainerSpec(cb)),
  getCcpPropertiesConfig: (e, cb) => dispatch(getCcpPropertiesConfig(e, cb)),
  getCcpDateTimeConfig: (e, cb) => dispatch(getCcpDateTimeConfig(e, cb)),
  searchProvisioningAttributesConfigItem: (e, cb) => dispatch(searchProvisioningAttributesConfigItem(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'pricingPage'])(compose(withConnect)(PricingPriceOfferAdd));
