/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import constant from '../../../containers/CustomerPage/constants';
import {
  DataTable,
  FormWithTableItem,
  TablePagination,
  CheckField,
  GenericInput,
  ModalViewNote,
  ModalWithItem,
  FormCollapse,
  Switch,
  ModalAccept,
  SwitchExport,
  ExcelExport,
  ButtonExport,
} from '../../common';
import ModalSearchItem from '../../common/ModalSearchItem';
import RouteNames from '../../../containers/App/RouteNames';
import {
  validateEmail,
  formatMoneyValue,
  getPageTotalCount,
  formatNumberCaculation,
  messageCSVStamping,
  messageNotesBills,
  supportShowNumberValue,
} from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import ModalAccountId from '../../common/ModalAccountId';
import ModalSelectBillableService from '../../common/ModalSelectBillableService';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { statusDisableFielsdViewFolio } from '../../../utils/constants';
import {
  checkListPendingBillCharges,
  installmentsColumns,
  listFields,
  listInvoiceFields,
  collapsibleMenuTransactionId,
  tableTaxLines,
  tableInvoiceSummary,
  billSort,
  searchFiltersOpenClose,
} from './constants';
import ArActivity from './ArActivity';
import AROpsUnit from './AROpsUnit';
import OpenCloseSearchForm from './OpenCloseSearchForm';

let fileNameExport = '';

let invoiceUnitIdSelected = '';

const Bills = ({
  id,
  dataPendingBills,
  dataBillInvoice,
  modeSearchInvoice,
  modeGetInvoice,
  runPendingBill,
  doGetPendingOCBills,
  getOCBill,
  billingProfileId,
  sendInvoice,
  regenerateInvoice,
  modifyInvoiceStatus,
  modeSendInvoice,
  modeRegenerateInvoice,
  modeViewInvoceTemplate,
  modeApproveInvoice,
  undoManualInvoice,
  modeResyncInvoice,
  modeUndoManualInvoice,
  modifyInvoiceDueDate,
  getObjectFileById,
  history,
  invoiceAccount,
  modeGenerateInvoice,
  getInvoiceInstallmentsByInvoiceId,
  createPaymentInstallment,
  resendInvoiceVendor,
  undoInvoiceCancel,
  getInvoiceUnitByIdSelect,
  reprocessInvoice,
  getCcpPropertiesConfig,
  getPacInterfaceRecordById,
  getInvoiceTransactionSummary,
  getInvoiceTaxSummary,
  saveManualInvoice,
  getCostCenterConfig,
  currencyOptions,
  getOrderSummaryByAccountId,
  customerInfo,
  getSplitTaxPerLine,
  ccpPropertyList,
  modeGetActivity,
  modeSearchArOpsUnit,
  modeGetPaymentInstallment,
  modeModifyPaymentInstallment,
  modeCreatePaymentInstallment,
  location,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [invoiceUnitId, setInvoiceUnitId] = useState(null);
  const [billUnitId, setBillUnitId] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [emailId, setEmailId] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [invoiceNotes, setInvoiceNotes] = useState('');
  const [type, setType] = useState('');
  const [isOpenModalNotes, setOpenModalNote] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [noteItem, setNoteItem] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [isOpenModalInstallments, setIsOpenModalInstallments] = useState(false);
  const [paymentInstallmentsData, setPaymentInstallmentsData] = useState([]);
  const [dataCreate, setDataCreate] = useState(null);
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);
  const [paymentInvoiceData, setPaymentInvoiceData] = useState({});
  const [invoiceSummaryListPerTax, setInvoiceSummaryListPerTax] = useState([]);
  const [isCancelAndSubstitute, setIsCancelAndSubstitute] = useState(false);
  const [activeTab, setActiveTab] = useState({});
  const [itemSelectInvoiceLine, setItemSelectInvoiceLine] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [isSubstituteInvoice, setIsSubstituteInvoice] = useState('FALSE');
  // const [satCode, setSatCode] = useState(null);
  const [accountIdSelected, setAccountIdSelected] = useState(id);
  const [isOpenModalItem, setIsOpenModalItem] = useState(false);
  const [isOpenModalAccountId, setIsOpenModalAccountId] = useState(false);
  const [itemIdSelect, setItemIdSelect] = useState(null);
  const [indexItemIdSelect, setIndexItemIdSelect] = useState(null);
  const [sorted, setSorted] = useState({});
  const [isInvoiceApproval, setIsInvoiceApproval] = useState('false');
  const [defaultCostCenter, setDefaultCostCenter] = useState(null);
  const [isOpenModalStamping, setIsOpenModalStamping] = useState(false);
  const [stampingsData, setStampingsData] = useState([]);

  const [isOpenModalStampingCSVData, setIsOpenModalStampingCSVData] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const [isOpenModalServiceName, setIsOpenModalServiceName] = useState(false);
  const [serviceNameSelect, setServiceNameSelect] = useState(null);
  const [indexServiceNameSelect, setIndexServiceName] = useState(null);
  const [isEnableBilledServiceName, setIsEnableBilledServiceName] = useState(false);
  const [pacEnabledProperty, setIsPacEnabledProperty] = useState(false);
  const [batchFolioStamping, setIsBatchFolioStamping] = useState(false);

  const [isOpenModalBillPendingCharges, setIsOpenModalBillPendingCharges] = useState(false);
  const [typeSelectPendingCharges, settypeSelectPendingCharges] = useState('ALL');
  const [itemSelectInvoiceLinePendingCharges, setItemSelectInvoiceLinePendingCharges] = useState([]);
  const [invoiceTransactionAttributesList, setInvoiceSummaryList] = useState([]);
  const [idPendingChargesSelect, setIdPendingChargesSelect] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isOpenModalProcess, setIsOpenModalProcess] = useState(false);
  const [dataSave, setDataSave] = useState({});
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [invoiceSelected, setinvoiceSelected] = useState(null);
  const [filterOpenCloseBill, setFilterOpenCloseBill] = useState({});

  const [wasValidated, setValidate] = useState(false);
  const [wasValidatedInvoice, setValidateInvoice] = useState(false);

  const [isActivePdf, setIsActivePdf] = useState(false);
  const [invoiceSelectDetails, setInvoiceSelectDetails] = useState(null);
  const [arActivitySelect, setArActivitySelect] = useState(null);
  const [arOpsUnitSelect, setArOpsUnitSelect] = useState(null);

  const formRef = useRef();
  const formRefInvoice = useRef();
  const buttonRef = useRef();

  const validate = (out = false) => {
    const formValid = formRef && formRef.current.checkValidity();
    const { elements } = formRef.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const onHandleChange = ({ name, value }) => {
    const newData = cloneDeep(dataCreate);
    if (value && Number.parseFloat(value) < 0 && !Number.isNaN(value)) return '';
    newData[name] = value;
    setDataCreate(newData);
  };

  const onHandleChangeDataSave = ({ name, value }) => {
    const newData = cloneDeep(dataSave);
    newData[name] = value;
    setDataSave(newData);
  };

  const doGetOCBill = () => {
    const filterOCBills = {
      accountId: id,
      billingProfileId: billingProfileId || 'PRIMARY',
      ...filterOpenCloseBill,
    };
    getOCBill({
      page: page + 1,
      size,
      filter: filterOCBills,
      sort: !isEmpty(sorted) ? billSort[sorted.sortCol][sorted.sortDir] : null,
    });
  };

  const onPageChange = page => {
    setPage(page);
  };

  const onSizeChange = size => {
    setSize(size);
    setPage(0);
  };

  const onSortColumn = (sortCol, sortDir) => {
    setSorted({ sortCol, sortDir });
  };

  const validateInvoice = (out = false) => {
    const formValid = formRefInvoice && formRefInvoice.current.checkValidity();
    const { elements } = formRefInvoice.current;
    // console.log('validate')
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  const sendInvoiceToMail = () => {
    if (emailId && !validateEmail(emailId)) return toast.error(t('message.validateEmail'));
    if (!invoiceUnitId) return toast.error(t('message.validateInvoiceNumber'));
    return sendInvoice({ accountId: id, invoiceUnitId, emailId }, ({ success }) => {
      if (success) {
        setInvoiceUnitId('');
        setBillUnitId(null);
        setEmailId('');
        doGetOCBill();
      }
    });
  };

  const doModifyInvoiceStatus = (item, status) => {
    modifyInvoiceStatus(
      { invoiceId: item.invoiceUnitId, status, invoiceNotes: invoiceNotes || null },
      ({ success }) => {
        if (success) doGetOCBill();
      }
    );
  };

  const doModifyInvoiceDueDate = (evt, item) => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true)) {
      return false;
    }
    return modifyInvoiceDueDate(
      { invoiceId: item.invoiceUnitId, invoiceNotes: noteItem, dueDate: dueDate || null },
      ({ success }) => {
        if (success) {
          doGetOCBill();
          setType(null);
          setDueDate(null);
          setNoteItem('');
        }
      }
    );
  };

  const doRegenerateInvoice = () => {
    if (!invoiceUnitId) return toast.error(t('message.validateInvoiceNumber'));
    return regenerateInvoice(
      { invoiceId: invoiceUnitId, poNumber: poNumber || null, customMessage: customMessage || null },
      ({ success }) => {
        if (success) {
          doGetOCBill();
          setInvoiceUnitId('');
          setBillUnitId(null);
        }
      }
    );
  };

  const doUndoManualInvoice = () => {
    if (!invoiceUnitId) return toast.error(t('message.validateInvoiceNumber'));
    return undoManualInvoice({ billUnitid: itemSelect.billUnitId }, ({ success }) => {
      if (success) {
        setItemSelect(null);
        setInvoiceUnitId('');
        setBillUnitId(null);
        doGetOCBill();
      }
    });
  };

  const doGetObjectFileById = invoiceId => {
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.prettifyPath(RouteNames.customerDetailsBills.path, id),
            fileData: data,
          },
        });
      }
    });
  };

  const doInvoiceAccount = () => {
    invoiceAccount({ billUnitId, accountId: id }, ({ success }) => {
      if (success) {
        setItemSelect(null);
        setInvoiceUnitId('');
        setBillUnitId(null);
        doGetOCBill();
      }
    });
  };

  const doResendInvoiceVendor = () => {
    resendInvoiceVendor({ id: itemSelect.invoiceUnitId }, ({ success }) => {
      if (success) {
        setItemSelect(null);
        setInvoiceUnitId('');
        setBillUnitId(null);
        doGetOCBill();
      }
    });
  };

  const doGetInvoiceInstallmentsByInvoiceId = invoiceUnitId => {
    getInvoiceInstallmentsByInvoiceId(invoiceUnitId, ({ success, data }) => {
      setPaymentInstallmentsData(success ? data : []);
    });
  };

  const doGetInvoiceStampingByInvoiceId = invoiceUnitId => {
    getPacInterfaceRecordById(invoiceUnitId, ({ success, data }) => {
      setStampingsData(success ? data : []);
    });
  };

  const onToggleModalInstallments = item => {
    if (!isOpenModalInstallments && item) {
      invoiceUnitIdSelected = item.invoiceUnitId;
      doGetInvoiceInstallmentsByInvoiceId(item.invoiceUnitId);
    } else {
      setDataCreate(null);
    }
    setIsOpenModalInstallments(!isOpenModalInstallments);
  };

  const onToggleModalStamping = item => {
    if (!isOpenModalStamping && item) {
      invoiceUnitIdSelected = item.invoiceUnitId;
      doGetInvoiceStampingByInvoiceId(item.invoiceUnitId);
    }
    setIsOpenModalStamping(!isOpenModalStamping);
  };

  const onToggleModalStampingCSVData = item => {
    if (!isOpenModalStampingCSVData && item) {
      setCsvData(item.csvRecord);
    }
    setIsOpenModalStampingCSVData(!isOpenModalStampingCSVData);
  };

  const onToggleModalDetails = item => {
    setPaymentInvoiceData({});
    if (!isOpenModalDetails && item) {
      if (item.invoiceUnitId) {
        setInvoiceSelectDetails(item.invoiceUnitId);
        getInvoiceUnitByIdSelect(item.invoiceUnitId, ({ success, data }) => {
          invoiceUnitIdSelected = item.invoiceUnitId;
          setPaymentInvoiceData(success ? data : {});
        });
      } else {
        invoiceUnitIdSelected = null;
        getInvoiceTransactionSummary(item.billUnitId, ({ success, data }) => {
          if (paymentInvoiceData) paymentInvoiceData.invoiceTransactionAttributesList = success ? data : [];
          setPaymentInvoiceData(paymentInvoiceData);
        });

        getInvoiceTaxSummary(item.billUnitId, ({ success, data }) => {
          if (paymentInvoiceData) paymentInvoiceData.invoiceTaxList = success ? data : [];
          setPaymentInvoiceData(paymentInvoiceData);
        });
      }
    }
    setItemSelectInvoiceLine([]);
    setIsCancelAndSubstitute(false);
    setIsOpenModalDetails(false);
    setActiveTab({});
    setIsOpenModalDetails(!isOpenModalDetails);
    setValidateInvoice(false);
    setIndexItemIdSelect(false);
    setIsOpenModalItem(false);
    setAccountIdSelected(id);
    setinvoiceSelected(item);
    setIsSelectAll(false);
    setActiveTab({});
    setIsSubstituteInvoice('FALSE');
  };

  const doGetInvoiceTransactionSummary = id => {
    getInvoiceTransactionSummary(id, ({ success, data }) => {
      setIdPendingChargesSelect(id);
      setInvoiceSummaryList(
        success && data && data.length ? data.map((val, index) => ({ ...val, indexSelect: index + 1 })) || [] : []
      );
    });
  };

  const onToggleModalBillPendingCharges = item => {
    if (!isOpenModalBillPendingCharges && item) {
      doGetInvoiceTransactionSummary(item.id);
    }
    setIsOpenModalBillPendingCharges(!isOpenModalBillPendingCharges);
  };

  const onCreatePaymentInstallment = () => {
    createPaymentInstallment(dataCreate, ({ success }) => {
      if (success) {
        doGetInvoiceInstallmentsByInvoiceId(invoiceUnitIdSelected);
      }
    });
  };

  const doUndoInvoiceCancel = () => {
    undoInvoiceCancel({ id: itemSelect.invoiceUnitId }, ({ success }) => {
      if (success) {
        setItemSelect(null);
        setInvoiceUnitId('');
        setBillUnitId(null);
        doGetOCBill();
        setType(null);
      }
    });
  };

  const onToggleTab = activeTabName => {
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
    }
  };

  const onSelectItem = ({ item }) => {
    if (item.mappedIndex) {
      if (itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)) {
        const newItemSelectInvoiceLine = itemSelectInvoiceLine.filter(val => val.mappedIndex !== item.mappedIndex);
        setIsSelectAll(false);
        return setItemSelectInvoiceLine([...newItemSelectInvoiceLine]);
      }

      if (
        paymentInvoiceData?.invoiceTransactionAttributesList &&
        paymentInvoiceData.invoiceTransactionAttributesList.length &&
        [...itemSelectInvoiceLine, cloneDeep(item)].length ===
          paymentInvoiceData.invoiceTransactionAttributesList.length
      ) {
        setIsSelectAll(true);
      }
      return setItemSelectInvoiceLine([...itemSelectInvoiceLine, cloneDeep(item)]);
    }

    if (itemSelectInvoiceLine.find(val => val.index === item.index)) {
      const newItemSelectInvoiceLine = itemSelectInvoiceLine.filter(val => val.index !== item.index);
      setItemSelectInvoiceLine([...newItemSelectInvoiceLine]);
      setIsSelectAll(false);
    } else {
      if (
        paymentInvoiceData?.invoiceTransactionAttributesList &&
        paymentInvoiceData.invoiceTransactionAttributesList.length &&
        [...itemSelectInvoiceLine, cloneDeep(item)].length ===
          paymentInvoiceData.invoiceTransactionAttributesList.length
      ) {
        setIsSelectAll(true);
      }
      setItemSelectInvoiceLine([...itemSelectInvoiceLine, cloneDeep(item)]);
    }
  };

  const onSelectItemPendingCharges = ({ item }) => {
    if (item.indexSelect) {
      if (itemSelectInvoiceLinePendingCharges.find(val => val.indexSelect === item.indexSelect)) {
        const newItemSelectInvoiceLinePendingCharges = itemSelectInvoiceLinePendingCharges.filter(
          val => val.indexSelect !== item.indexSelect
        );
        return setItemSelectInvoiceLinePendingCharges([...newItemSelectInvoiceLinePendingCharges]);
      }
      return setItemSelectInvoiceLinePendingCharges([...itemSelectInvoiceLinePendingCharges, cloneDeep(item)]);
    }
  };

  const onChangeItem = ({ name, value, index }) => {
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[index][name] = value;
    if (newData.invoiceTransactionAttributesList[index].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[index].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange][name] = value;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[index].index
      );
      itemSelectInvoiceLine[indexItemSelectChange][name] = value;
    }

    setItemSelectInvoiceLine([...itemSelectInvoiceLine]);
    setPaymentInvoiceData(newData);
  };

  const onHandleSubmitFilterOpenColseBill = filter => {
    setFilterOpenCloseBill(filter);
  };

  const onSelectServiceName = (id, row) => {
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].omsInternalId = row.omsInternalId;
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].serviceName = row.serviceName;
    newData.invoiceTransactionAttributesList[indexServiceNameSelect].itemId = row.serviceId;
    if (newData.invoiceTransactionAttributesList[indexServiceNameSelect].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[indexServiceNameSelect].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange].omsInternalId = row.omsInternalId;
      itemSelectInvoiceLine[indexItemSelectChange].serviceName = row.serviceName;
      itemSelectInvoiceLine[indexItemSelectChange].itemId = row.serviceId;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[indexServiceNameSelect].index
      );
      itemSelectInvoiceLine[indexItemSelectChange].omsInternalId = row.omsInternalId;
      itemSelectInvoiceLine[indexItemSelectChange].serviceName = row.serviceName;
      itemSelectInvoiceLine[indexItemSelectChange].itemId = row.serviceId;
    }
    setItemSelectInvoiceLine([...itemSelectInvoiceLine]);
    setPaymentInvoiceData(newData);
    setIndexServiceName(null);
    setServiceNameSelect(null);
    setIsOpenModalServiceName(false);
  };

  const onToggleModalItemId = ({ index, item }) => {
    if (item) {
      setIndexItemIdSelect(index);
      setItemIdSelect(item);
      setIsOpenModalItem(true);
    } else {
      setIndexItemIdSelect(null);
      setItemIdSelect(null);
      setIsOpenModalItem(false);
    }
  };

  const onToggleModalServiceName = ({ index, item }) => {
    if (item) {
      setIndexServiceName(index);
      setServiceNameSelect(item);
      setIsOpenModalServiceName(true);
    } else {
      setIndexServiceName(null);
      setServiceNameSelect(null);
      setIsOpenModalServiceName(false);
    }
  };

  const onSelectItemId = id => {
    const newData = cloneDeep(paymentInvoiceData);
    newData.invoiceTransactionAttributesList[indexItemIdSelect].itemId = id;
    if (newData.invoiceTransactionAttributesList[indexItemIdSelect].mappedIndex) {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.mappedIndex === newData.invoiceTransactionAttributesList[indexItemIdSelect].mappedIndex
      );
      itemSelectInvoiceLine[indexItemSelectChange].itemId = id;
    } else {
      const indexItemSelectChange = itemSelectInvoiceLine.findIndex(
        val => val.index === newData.invoiceTransactionAttributesList[indexItemIdSelect].index
      );
      itemSelectInvoiceLine[indexItemSelectChange].itemId = id;
    }
    setItemSelectInvoiceLine([...itemSelectInvoiceLine]);
    setPaymentInvoiceData(newData);
    setIsOpenModalItem(false);
  };

  const doReprocessInvoice = evt => {
    evt.preventDefault();
    setValidateInvoice(true);
    if (!validateInvoice(true)) {
      return false;
    }
    const newPayload = cloneDeep(itemSelectInvoiceLine).map(val => {
      const {
        index,
        name,
        quantity,
        unitPrice,
        startDate,
        endDate,
        itemId,
        costCenter,
        currency,
        appliedStart,
        appliedEnd,
        serviceName,
        omsInternalId,
      } = val;
      return {
        index,
        name,
        quantity,
        unitPrice,
        amount: formatNumberCaculation(Number.parseFloat(unitPrice || 0) * Number.parseFloat(quantity || 0)),
        startDate: startDate || appliedStart || moment(new Date()).format('YYYY-MM-DD'),
        endDate: endDate || appliedEnd || moment(new Date()).format('YYYY-MM-DD'),
        itemId: itemId || null,
        costCenter: costCenter || null,
        currency: currency || null,
        serviceName: serviceName || null,
        omsInternalId: omsInternalId || null,
      };
    });
    reprocessInvoice(
      {
        accountId: accountIdSelected || id,
        lines: newPayload,
        invoiceId: invoiceUnitIdSelected,
        substituteInvoice: isSubstituteInvoice === 'TRUE' ? 'true' : 'false',
        // satCode: isSubstituteInvoice === 'TRUE' ? satCode || null : null,
        userId: getUserId(),
      },
      ({ success }) => {
        if (success) {
          setItemSelectInvoiceLine([]);
          setIsCancelAndSubstitute(false);
          doGetOCBill();
          setIsOpenModalDetails(false);
          setIsOpenModalProcess(false);
          setAccountIdSelected(id);
          setIsSelectAll(false);
          setActiveTab({});
          setIsSubstituteInvoice('FALSE');
          // setSatCode(null);
          setIsOpenModalProcess(false);
          setValidateInvoice(false);
        }
      }
    );
  };

  const onAddNewLine = () => {
    const newData = cloneDeep(paymentInvoiceData);
    const itemSelect = cloneDeep(itemSelectInvoiceLine);
    let lastIndex = 0;
    newData.invoiceTransactionAttributesList.forEach(item => {
      lastIndex += 1;
    });
    let costCenter = null;
    if (
      costCenterOptions &&
      costCenterOptions.length &&
      defaultCostCenter &&
      costCenterOptions.find(val => val.value === defaultCostCenter)
    ) {
      costCenter = costCenterOptions.find(val => val.value === defaultCostCenter).value;
    }
    newData.invoiceTransactionAttributesList.push({
      index: -1,
      name: null,
      quantity: null,
      unitPrice: null,
      amount: null,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      mappedIndex: lastIndex + 1,
      costCenter,
    });
    itemSelect.push({
      index: -1,
      name: null,
      quantity: null,
      unitPrice: null,
      amount: null,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      mappedIndex: lastIndex + 1,
      costCenter,
    });
    setItemSelectInvoiceLine(itemSelect);
    setPaymentInvoiceData(newData);
  };

  const onSelectAccountId = (id, row) => {
    setAccountIdSelected(id);
    setIsOpenModalAccountId(false);
  };

  const onExport = () => {
    if (isActivePdf) {
      convertJson2Pdf({
        data:
          paymentInvoiceData?.invoiceSummaryList && paymentInvoiceData?.invoiceSummaryList.length
            ? paymentInvoiceData?.invoiceSummaryList
            : [],
        t,
        title: t('label.invoiceSummary').toLocaleUpperCase(),
        fileName: fileNameExport,
        columnsTable: tableInvoiceSummary,
        fontSize: 7,
      });
    } else {
      buttonRef.current.click();
    }
  };

  const billablesServiceColumn = [];
  if (isCancelAndSubstitute) {
    billablesServiceColumn.push({
      name: 'serviceName',
      label: 'label.billableServiceName',
      style: { minWidth: '280px' },
      render: (colName, item, index) => {
        return (
          <GenericInput
            value={item.serviceName}
            wrapperClass="col-md-12 inner-popover read-only-disable-field"
            onChange={() => {}}
            name="serviceName"
            disabled={
              !isEnableBilledServiceName ||
              !isCancelAndSubstitute ||
              (item.mappedIndex
                ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                : !itemSelectInvoiceLine.find(val => val.index === item.index))
            }
            fa="fa fa-external-link"
            onClick={() => onToggleModalServiceName({ index, item })}
          />
        );
      },
    });
  }

  const renderItem = item => {
    const tableInvoiceLines = [
      {
        name: 'index',
        label: 'label.index',
      },
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item, index) => {
          return (
            <CheckField
              name="selected"
              value={item && item.isSelected}
              checked={
                item.mappedIndex
                  ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : itemSelectInvoiceLine.find(val => val.index === item.index)
              }
              onChange={evt => onSelectItem({ evt, item, index })}
              disabled={!isCancelAndSubstitute}
            />
          );
        },
      },
      {
        name: 'name',
        label: 'label.name',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.name}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => onChangeItem({ name, value, index })}
              name="name"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'startDate',
        label: 'label.startDate',
        required: true,
        style: { minWidth: '220px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.startDate || item.appliedStart || moment(new Date()).format('YYYY-MM-DD')}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => onChangeItem({ name, value, index })}
              name="startDate"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              type="date"
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'endDate',
        label: 'label.endDate',
        required: true,
        style: { minWidth: '220px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.endDate || item.appliedEnd || moment(new Date()).format('YYYY-MM-DD')}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => onChangeItem({ name, value, index })}
              name="endDate"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              type="date"
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'itemId',
        label: 'label.itemId',
        style: { minWidth: '200px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={item.itemId}
              wrapperClass="col-md-12 inner-popover read-only-disable-field"
              onChange={({ name, value }) => {}}
              name="itemId"
              disabled={
                // item.index !== -1 ||
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              fa="fa fa-external-link"
              required={item.index === -1 && isCheckRequired}
              onClick={() => onToggleModalItemId({ index, item })}
            />
          );
        },
      },
      ...billablesServiceColumn,
      {
        name: 'costCenter',
        label: t('label.costCenter'),
        style: { textAlign: 'center', minWidth: '180px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.costCenter}
            options={costCenterOptions || []}
            wrapperClass="col-md-12 p-0"
            type="select"
            menuPortalTarget
            onChange={({ name, value }) => onChangeItem({ name, value, index })}
            name="costCenter"
            disabled={
              !isCancelAndSubstitute ||
              (item.mappedIndex
                ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                : !itemSelectInvoiceLine.find(val => val.index === item.index))
            }
          />
        ),
      },
      {
        name: 'quantity',
        label: 'label.quantity',
        required: true,
        style: { minWidth: '180px' },
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={supportShowNumberValue(item.quantity)}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => onChangeItem({ name, value, index })}
              name="quantity"
              type="number"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'currency',
        label: t('label.currency'),
        style: { textAlign: 'center', minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.currency}
            wrapperClass="col-md-12 read-only-disable-field"
            onChange={({ name, value }) => onChangeItem({ name, value, index })}
            type="select"
            disabled={
              !isCancelAndSubstitute ||
              (item.mappedIndex
                ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                : !itemSelectInvoiceLine.find(val => val.index === item.index))
            }
            name="currency"
            options={currencyOptions || []}
            menuPortalTarget
          />
        ),
      },
      {
        name: 'unitPrice',
        label: 'label.unitPrice',
        style: { minWidth: '180px' },
        required: true,
        render: (colName, item, index) => {
          const isCheckRequired = item.mappedIndex
            ? itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
            : itemSelectInvoiceLine.find(val => val.index === item.index);
          return (
            <GenericInput
              value={supportShowNumberValue(item.unitPrice)}
              wrapperClass="col-md-12 read-only-disable-field"
              onChange={({ name, value }) => onChangeItem({ name, value, index })}
              name="unitPrice"
              type="number"
              disabled={
                !isCancelAndSubstitute ||
                (item.mappedIndex
                  ? !itemSelectInvoiceLine.find(val => val.mappedIndex === item.mappedIndex)
                  : !itemSelectInvoiceLine.find(val => val.index === item.index))
              }
              required={isCheckRequired}
            />
          );
        },
      },
      {
        name: 'amount',
        label: 'label.amount',
        render: (colName, item) => {
          return (
            <span>
              {formatMoneyValue(Number.parseFloat(item.unitPrice || 0) * Number.parseFloat(item.quantity || 0))}
            </span>
          );
        },
      },
      {
        name: 'gross',
        label: 'label.gross',
        render: (colName, item) => {
          return <span>{formatMoneyValue(Number.parseFloat(item.gross))}</span>;
        },
      },
      {
        name: 'discount',
        label: 'label.discount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(Number.parseFloat(item.discount))}</span>;
        },
      },
    ];

    switch (item.state) {
      case 'invoiceLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableInvoiceLines}
              data={
                paymentInvoiceData.invoiceTransactionAttributesList
                  ? paymentInvoiceData.invoiceTransactionAttributesList
                  : []
              }
            />
            <br />
            <div className="form-group col-md-12">
              <button
                type="button"
                className="button button-border black x-small"
                disabled={!isCancelAndSubstitute}
                onClick={onAddNewLine}
              >
                +
                {t('label.addNewLine')}
              </button>
            </div>
            <br />
          </div>
        );
      case 'taxLines':
        return (
          <div className="col-md-12">
            <DataTable
              columns={tableTaxLines}
              data={paymentInvoiceData.invoiceTaxList ? paymentInvoiceData.invoiceTaxList : []}
            />
          </div>
        );

      case 'invoiceSummary':
        return (
          <div className="col-md-12">
            <div className="mt-3 d-flex mb-4 pt-3 col-md-12">
              <div className="ml-auto mt-2 mb-auto mr-3">
                <SwitchExport
                  onChange={() => {
                    setIsActivePdf(!isActivePdf);
                  }}
                  wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                  title={t('label.excel')}
                  checked={isActivePdf}
                  rightTitle={t('label.pdf')}
                  name="SwitchExport"
                />
              </div>
              <>
                <ButtonExport onExport={onExport} />
                <ExcelExport
                  element={<button type="button" className="display-none" ref={buttonRef} />}
                  nameSheet={t('label.invoiceSummary').toLocaleUpperCase()}
                  multiDataSet={convertJson2Sheet({
                    // data: invoiceSummaryListPerTax && invoiceSummaryListPerTax.length ? invoiceSummaryListPerTax : [],
                    data:
                      paymentInvoiceData?.invoiceSummaryList && paymentInvoiceData?.invoiceSummaryList?.length
                        ? paymentInvoiceData?.invoiceSummaryList
                        : [],
                    t,
                    title: t('label.invoiceSummary').toLocaleUpperCase(),
                    columnsTable: tableInvoiceSummary,
                  })}
                  fileName={fileNameExport}
                />
              </>
            </div>
            {/* <DataTable columns={tableInvoiceSummary} data={invoiceSummaryListPerTax || []} /> */}
            <DataTable columns={tableInvoiceSummary} data={paymentInvoiceData?.invoiceSummaryList || []} />
          </div>
        );
      default:
        return null;
    }
  };

  let actionColumn = [];
  const arActionCol = [];
  if (modeApproveInvoice !== 0) {
    actionColumn = [
      {
        name: 'select',
        label: 'label.action',
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            disabled={!item.invoiceNotes}
            onClick={() => {
              setOpenModalNote(!isOpenModalNotes);
              setNoteContent(item.invoiceNotes);
            }}
          >
            {t('label.notes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button>
        ),
      },
    ];
  }

  if (modeGetActivity) {
    actionColumn.push({
      name: 'arActivity',
      label: 'common:label.aRActivity',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          disabled={!item.invoiceUnitId}
          onClick={() => {
            setArActivitySelect(item);
          }}
        >
          {t('label.view')}
        </button>
      ),
    });
  }

  if (modeGetPaymentInstallment) {
    actionColumn.push({
      name: 'installments',
      label: 'common:label.installments',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onToggleModalInstallments(item);
          }}
        >
          {t('label.view')}
        </button>
      ),
    });
  }

  if (modeSearchArOpsUnit) {
    arActionCol.push({
      name: 'arAction',
      label: 'common:label.arAction',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            setArOpsUnitSelect(item);
          }}
        >
          {t('label.view')}
        </button>
      ),
    });
  }

  const stampingColumn = [];

  if (pacEnabledProperty && batchFolioStamping) {
    stampingColumn.push({
      name: 'stamping',
      label: 'common:label.stamping',
      render: (colName, item) => {
        let isDisableButton = true;
        if (
          item.invoiceUnitId &&
          (item.folioStatus === 'STAMPING_REQUESTED' ||
            item.folioStatus === 'STAMPED' ||
            item.folioStatus === 'CANCELLATION_STAMPING_REQUESTED' ||
            item.folioStatus === 'CANCELLATION_STAMPED')
        ) {
          isDisableButton = false;
        }
        return (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            disabled={isDisableButton}
            onClick={() => {
              onToggleModalStamping(item);
            }}
          >
            {t('label.view')}
          </button>
        );
      },
    });
  }
  const tableColumnsOCBills = [
    {
      name: 'select',
      label: 'common:label.select',
      render: (colName, item) => {
        return (
          <CheckField
            name="select"
            value={!!item.invoiceUnitId}
            checked={
              item.invoiceUnitId
                ? !!(item.invoiceUnitId && invoiceUnitId && invoiceUnitId === item.invoiceUnitId)
                : !!(item.billUnitId && billUnitId && billUnitId === item.billUnitId)
            }
            onChange={evt => {
              if (invoiceUnitId === item.invoiceUnitId && !evt.target.checked) {
                setInvoiceUnitId('');
                setItemSelect(null);
                setType(null);
              } else {
                setInvoiceUnitId(item.invoiceUnitId);
                setItemSelect(item);
                setType(null);
              }
              if (billUnitId === item.billUnitId && !evt.target.checked) {
                setItemSelect(null);
                setBillUnitId(null);
                setType(null);
              } else {
                setBillUnitId(item.billUnitId);
                setItemSelect(item);
                setType(null);
              }
            }}
            readOnly
          />
        );
      },
    },
    {
      name: 'billUnitId',
      label: 'common:label.billUnitId',
    },
    {
      name: 'billType',
      label: 'common:label.billType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:billUnitType')().find(val => val.value === item.billType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'startDate',
      label: 'common:label.startDate',
    },
    {
      name: 'endDate',
      label: 'common:label.endDate',
    },
    {
      name: 'total',
      label: 'common:label.total',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.total)}</span>;
      },
    },
    {
      name: 'nonPayingTotal',
      label: 'common:label.nonPayingTotal',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.nonPayingTotal)}</span>;
      },
    },
    {
      name: 'billTotal',
      label: 'common:label.billTotal',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.billTotal)}</span>;
      },
    },
    {
      name: 'invoiceUnitId',
      label: 'common:label.invoiceId',
      render: (colName, item) => {
        if (modeViewInvoceTemplate === 0) return '';
        return (
          <button
            className="text-success no-border"
            type="button"
            disabled={pacEnabledProperty && statusDisableFielsdViewFolio.indexOf(item.folioStatus) === -1}
            onClick={() => doGetObjectFileById(item.invoiceUnitId)}
          >
            {item.invoiceUnitId}
          </button>
        );
      },
    },
    {
      name: 'invoiceDate',
      label: 'common:label.invoiceDate',
      sortable: true,
    },
    {
      name: 'invoiceStatus',
      label: 'common:label.invoiceStatus',
      sortable: true,
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:invoiceStatus')().find(val => val.value === item.invoiceStatus) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'folioStatus',
      label: 'common:label.folioStatus',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'type',
      label: 'common:label.invoiceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:invoiceUnitType')().find(val => val.value === item.type) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'invoiceTotal',
      label: 'common:label.invoiceTotal',
    },
    {
      name: 'due',
      label: 'common:label.due',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.due)}</span>;
      },
    },
    {
      name: 'dueDate',
      label: 'common:label.dueDate',
      sortable: true,
    },
    {
      name: 'installmentInvoice',
      label: 'common:label.installmentInvoice',
      render: (name, item) => {
        return (
          <GenericInput
            value={item.installmentInvoice}
            wrapperClass="col-md-12"
            onChange={() => {}}
            name="isChecked"
            type="checkbox"
            checked={item.installmentInvoice}
            readOnly
          />
        );
      },
    },
    ...stampingColumn,
    {
      name: 'details',
      label: 'common:label.details',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onToggleModalDetails(item);
            doGetCostCenterConfig();
          }}
        >
          {t('label.view')}
        </button>
      ),
    },
    ...actionColumn,

    {
      name: 'lastArAction',
      label: 'common:label.lastArAction',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:arAction')().find(val => val.value === item.lastArAction) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    ...arActionCol,
    {
      name: 'originalId',
      label: 'label.originalInvoiceRef',
    },
  ];

  const { t } = useTranslation('common');
  let newColumnsOCBills = cloneDeep(tableColumnsOCBills);
  const stampingRecordColumns = [
    {
      name: 'id',
      label: 'common:label.id',
    },
    {
      name: 'status',
      label: 'common:label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:pacRecordStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'extractType',
      label: 'common:label.type',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:pacExtractType')().find(val => val.value === item.extractType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'createdDate',
      label: 'common:label.createdDate',
    },
    {
      name: 'objectDate',
      label: 'common:label.modifiedDate',
    },
    {
      name: 'data',
      label: 'common:label.data',
      render: (colName, item) => (
        <button
          type="button"
          className="btn btn-outline-success btn-sm"
          onClick={() => {
            onToggleModalStampingCSVData(item);
          }}
        >
          {t('label.view')}
        </button>
      ),
    },
  ];
  if (modeSearchInvoice === 0) {
    newColumnsOCBills = newColumnsOCBills.filter(item => item.name !== 'invoiceUnitId');
    newColumnsOCBills = newColumnsOCBills.filter(item => item.name !== 'invoiceStatus');
    newColumnsOCBills = newColumnsOCBills.filter(item => item.name !== 'type');
  }

  if (modeGetInvoice === 0) {
    const findIndexInvoiceId = newColumnsOCBills.findIndex(item => item.name === 'invoiceUnitId');
    newColumnsOCBills[findIndexInvoiceId] = {
      name: 'invoiceUnitId',
      label: 'Invoice Number',
    };
  }
  let optionActions = t('selections:typeActionBill')();
  if (
    itemSelect &&
    (itemSelect.invoiceStatus === 'CLOSED' ||
      itemSelect.invoiceStatus === 'COLLECTION' ||
      itemSelect.invoiceStatus === 'SENT')
  ) {
    optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
    optionActions = optionActions.filter(val => val.value !== 'APPROVE');
    optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
  }
  if (
    (itemSelect && itemSelect.invoiceStatus === 'PENDING_APPROVAL') ||
    (itemSelect && Number.parseFloat(itemSelect.billTotal || 0) <= 0)
  ) {
    optionActions = optionActions.filter(val => val.value !== 'EMAIL_INVOICE');
    optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
    optionActions = optionActions.filter(val => val.value !== 'REGENERATE_INVOICE');
  }
  if (
    (itemSelect && itemSelect.invoiceStatus === 'APPROVED') ||
    isInvoiceApproval === 'false' ||
    (itemSelect && Number.parseFloat(itemSelect.billTotal || 0) <= 0)
  ) {
    optionActions = optionActions.filter(val => val.value !== 'APPROVE');
    optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
  }
  if (itemSelect && itemSelect.invoiceStatus === 'DISAPPROVED') {
    optionActions = optionActions.filter(val => val.value !== 'DISAPPROVE');
  }
  if (itemSelect && itemSelect.billType !== 'MANUAL_BILL') {
    optionActions = optionActions.filter(val => val.value !== 'UNDO_MANUAL_INVOICE');
  }
  if (itemSelect && ['SYNC_FAILED', 'SYNC_FAILED'].indexOf(itemSelect.invoiceStatus) === -1) {
    optionActions = optionActions.filter(val => val.value !== 'RESYNC_INVOICE');
  }
  if (
    (itemSelect && ['STAMPING_REQUESTED', 'CANCELLATION_STAMPING_REQUESTED'].indexOf(itemSelect.folioStatus) === -1) ||
    (itemSelect && Number.parseFloat(itemSelect.billTotal || 0) <= 0)
  ) {
    optionActions = optionActions.filter(val => val.value !== 'RESEND_TO_VENDOR');
  }
  if (
    (itemSelect && ['CANCELLATION_STAMPING_REQUESTED'].indexOf(itemSelect.folioStatus) === -1) ||
    (itemSelect && Number.parseFloat(itemSelect.billTotal || 0) <= 0)
  ) {
    optionActions = optionActions.filter(val => val.value !== 'UNDO_INVOICE_CANCEL');
  }

  if (invoiceUnitId) {
    optionActions = optionActions.filter(val => val.value !== 'GENERATE_INVOICE');
  }
  if (!invoiceUnitId) {
    optionActions = optionActions.filter(val => val.value !== 'EMAIL_INVOICE');
    optionActions = optionActions.filter(val => val.value !== 'REGENERATE_INVOICE');
    optionActions = optionActions.filter(val => val.value !== 'RESEND_TO_VENDOR');
  }
  if (itemSelect && Number.parseFloat(itemSelect.due || 0) <= 0) {
    optionActions = optionActions.filter(val => val.value !== 'EXTEND_DUE_DATE');
  }

  const onRunPendingBill = () => {
    setLoading(true);
    runPendingBill(
      {
        accountId: id,
        selectivePendingBillFilter: typeSelectPendingCharges || null,
        selectedItems:
          itemSelectInvoiceLinePendingCharges && itemSelectInvoiceLinePendingCharges.length
            ? itemSelectInvoiceLinePendingCharges.map(val => {
                const { name, appliedStart, appliedEnd } = val;
                return { name, appliedStart, appliedEnd };
              })
            : null,
      },
      ({ success }) => {
        if (success) {
          doGetPendingOCBills();
          doGetInvoiceTransactionSummary(idPendingChargesSelect);
          setIsOpenModalBillPendingCharges(false);
        }
        setLoading(false);
      }
    );
  };

  const onHandleSelectAll = ({ value }) => {
    setIsSelectAll(value);
    if (value) {
      return setItemSelectInvoiceLine([...paymentInvoiceData.invoiceTransactionAttributesList]);
    }
    return setItemSelectInvoiceLine([]);
  };

  const onSaveManualInvoice = evt => {
    evt.preventDefault();
    setValidateInvoice(true);
    if (!validateInvoice(true)) {
      return false;
    }
    const manualPreratedTxnRec = cloneDeep(itemSelectInvoiceLine).map(val => {
      const {
        quantity,
        unitPrice,
        startDate,
        endDate,
        itemId,
        costCenter,
        currency,
        discount,
        appliedEnd,
        appliedStart,
        serviceName,
        omsInternalId,
      } = val;
      return {
        // name,
        quantity,
        unitPrice,
        startDate: startDate || appliedStart || moment(new Date()).format('YYYY-MM-DD'),
        endDate: endDate || appliedEnd || moment(new Date()).format('YYYY-MM-DD'),
        itemId: itemId || null,
        serviceName: serviceName || null,
        omsInternalId: omsInternalId || null,
        grossAmount: formatNumberCaculation(Number.parseFloat(quantity || 0) * Number.parseFloat(unitPrice || 0)),
        netAmount:
          formatNumberCaculation(
            Number.parseFloat(quantity || 0) * Number.parseFloat(unitPrice || 0) - Number.parseFloat(discount)
          ) || 0,
        costCenter: costCenter || null,
        currency: currency || null,
        discountAmount: discount,
        accountId: accountIdSelected || id,
      };
    });
    return saveManualInvoice(
      {
        manualPreratedTxnRec,
        accountId: accountIdSelected || id,
        scheduleDate: dataSave.scheduleDate || null,
        scheduleDaily: dataSave.scheduleDaily ? (dataSave.scheduleDaily === 'TRUE' ? 'true' : 'false') : null,
        billStartDate: invoiceSelected ? invoiceSelected.startDate : null,
        billEndDate: invoiceSelected ? invoiceSelected.endDate : null,
        substituteInvoice: isSubstituteInvoice === 'TRUE' ? 'true' : 'false',
        // satCode: isSubstituteInvoice === 'TRUE' ? satCode || null : null,
        invoiceId: isSubstituteInvoice === 'TRUE' ? invoiceUnitIdSelected : null,
      },
      ({ success }) => {
        if (success) {
          setItemSelectInvoiceLine([]);
          setIsCancelAndSubstitute(false);
          doGetOCBill();
          setIsOpenModalDetails(false);
          setIsOpenModalProcess(false);
          setAccountIdSelected(id);
          setIsSelectAll(false);
          setActiveTab({});
          setIsSubstituteInvoice('FALSE');
          // setSatCode(null);
          setIsOpenModalProcess(false);
          setValidateInvoice(false);
        }
      }
    );
  };

  const tableInvoiceLines = [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'action',
      label: t('label.selected'),
      render: (colName, item, index) => {
        return (
          <CheckField
            name="selected"
            value={item && item.isSelected}
            checked={
              item.indexSelect && itemSelectInvoiceLinePendingCharges.length
                ? itemSelectInvoiceLinePendingCharges.find(val => val.indexSelect === item.indexSelect)
                : false
            }
            onChange={evt => onSelectItemPendingCharges({ evt, item, index })}
            disabled={typeSelectPendingCharges !== 'SELECTIVE'}
          />
        );
      },
    },
    {
      name: 'name',
      label: 'label.name',
      style: { minWidth: '180px' },
      required: true,
    },
    {
      name: 'appliedStart',
      label: 'label.startDate',
    },
    {
      name: 'appliedEnd',
      label: 'label.endDate',
    },
    {
      name: 'itemId',
      label: 'label.itemId',
    },
    {
      name: 'quantity',
      label: 'label.quantity',
    },
    {
      name: 'unitPrice',
      label: 'label.unitPrice',
    },
    {
      name: 'amount',
      label: 'label.amount',
      render: (colName, item) => {
        return (
          <span>
            {formatMoneyValue(Number.parseFloat(item.unitPrice || 0) * Number.parseFloat(item.quantity || 0))}
          </span>
        );
      },
    },
    {
      name: 'gross',
      label: 'label.gross',
      render: (colName, item) => {
        return <span>{formatMoneyValue(Number.parseFloat(item.gross))}</span>;
      },
    },
    {
      name: 'discount',
      label: 'label.discount',
      render: (colName, item) => {
        return <span>{formatMoneyValue(Number.parseFloat(item.discount))}</span>;
      },
    },
  ];

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  const doGetCostCenterConfig = () => {
    if (!costCenterOptions || !costCenterOptions.length)
      getCostCenterConfig({ showActiveOnly: 'true' }, ({ success, data }) => {
        if (success && data && data.length) {
          const costCenterOptions = data.map(val => {
            const { code, name } = val;
            return { label: `${code}-${name}`, value: code };
          });
          setCostCenterOptions(costCenterOptions);
        }
      });
  };

  fileNameExport = `${customerInfo?.id}_${invoiceSelectDetails ? `${invoiceSelectDetails}_` : ''}${t(
    'label.invoiceSummary'
  )
    .toLocaleLowerCase()
    .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`;

  const doSetCcpProertiesConfig = ccpPropertyList => {
    const invoiceApproval = ccpPropertyList.find(val => val.property === 'invoiceApproval');
    const defaultCostCenter = ccpPropertyList.find(val => val.property === 'defaultCostCenter');
    const billedServiceProperty = ccpPropertyList.find(val => val.property === 'billableServiceEnabled');
    const pacEnabledProperty = ccpPropertyList.find(val => val.property === 'pacEnabled');
    const batchFolioStamping = ccpPropertyList.find(val => val.property === 'batchFolioStamping');
    if (invoiceApproval && invoiceApproval.value) {
      setIsInvoiceApproval(invoiceApproval.value);
    }
    if (defaultCostCenter && defaultCostCenter.value) {
      setDefaultCostCenter(defaultCostCenter.value);
    }
    if (billedServiceProperty) {
      setIsEnableBilledServiceName(!!(billedServiceProperty && billedServiceProperty.value === 'true'));
    }
    if (pacEnabledProperty) {
      setIsPacEnabledProperty(!!(pacEnabledProperty && pacEnabledProperty.value === 'true'));
    }
    if (batchFolioStamping) {
      setIsBatchFolioStamping(!!(batchFolioStamping && batchFolioStamping.value === 'true'));
    }
  };

  useEffect(() => {
    doGetOCBill();
  }, [page, size, sorted, filterOpenCloseBill]);

  useEffect(() => {
    setTotalCount(getPageTotalCount({ totalCount, page, size, items: dataBillInvoice }));
  }, [page, size, dataBillInvoice]);

  useEffect(() => {
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          doSetCcpProertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      doSetCcpProertiesConfig(ccpPropertyList);
    }
    doGetPendingOCBills();
  }, []);
  return (
    <div className="col-md-12 mb-30">
      <FormWithTableItem
        title={t('common:label.pendingBills')}
        accountNum={id}
        key="pendingBills"
        commercialName={customerInfo?.commercialName || ''}
        isCommercialName={customerInfo?.customerSegment !== 'B2C'}
        currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
      >
        <DataTable isLoading={isLoading} columns={constant.accountBills.pendingBillsTable} data={dataPendingBills} />
        <div className="text-right pb-3 pr-2">
          <Link
            className="button button-border x-small"
            to={RouteNames.prettifyPath(RouteNames.customerDetailsManualBills.path, id)}
          >
            {t('label.manualBilling')}
          </Link>
          <button
            className="button button-border x-small"
            type="button"
            onClick={() => {
              onToggleModalBillPendingCharges(dataPendingBills && dataPendingBills.length ? dataPendingBills[0] : null);
            }}
            disabled={
              dataPendingBills && dataPendingBills.length && dataPendingBills[0] && !dataPendingBills[0].billTotal > 0
            }
          >
            {t('label.billPendingCharges')}
          </button>
        </div>
      </FormWithTableItem>
      <FormWithTableItem title={t('common:label.ocBills')} key="OCbills">
        {searchFiltersOpenClose.map(item => (
          <FormCollapse
            key={item.title}
            isActive={item.state === activeTab.name && activeTab.isActive}
            title={t(item.title) || ''}
            state={item.state}
            onToggleTab={onToggleTab}
          >
            <div className="col-md-12 p-2">
              <OpenCloseSearchForm onSubmit={onHandleSubmitFilterOpenColseBill} />
            </div>
          </FormCollapse>
        ))}
        <DataTable
          isLoading={isLoading}
          columns={newColumnsOCBills}
          onSort={onSortColumn}
          sorted={sorted}
          data={dataBillInvoice}
          isFixedHeaderTable
        />
        <div className="col-md-12 p-3 pb-4 d-flex">
          <GenericInput
            value={type}
            name="type"
            onChange={({ name, value }) => {
              setInvoiceNotes('');
              setCustomMessage('');
              setEmailId(null);
              setType(value);
              setDueDate(null);
              setNoteItem('');
            }}
            wrapperClass="col-md-3"
            label="label.action"
            options={optionActions}
            type="select"
            placeholder="<Select Action>"
            disabled={!itemSelect}
            isPlaceholder
            isClearable
          />
          {modeApproveInvoice !== 0 && type === 'APPROVE' && (
            <div className="col-md-2 mt-3 p-2">
              <button
                type="button"
                className="button x-small btn-sm"
                disabled={
                  (modeApproveInvoice === 1 && !itemSelect) ||
                  (itemSelect && itemSelect.invoiceStatus !== 'PENDING_APPROVAL')
                }
                onClick={() => doModifyInvoiceStatus(itemSelect, 'APPROVED')}
              >
                {t('label.approveInvoice')}
              </button>
            </div>
          )}

          {modeRegenerateInvoice !== 0 && type === 'REGENERATE_INVOICE' && (
            <div className="col-md-9 row">
              <GenericInput
                value={poNumber}
                name="emailId"
                onChange={({ name, value }) => setPoNumber(value)}
                wrapperClass="col-md-4"
                label="label.PO"
              />
              <GenericInput
                value={customMessage}
                name="customMessage"
                onChange={({ name, value }) => setCustomMessage(value)}
                wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                label="label.customMessage"
                type="textarea"
              />
              <div>
                <button
                  className="button x-small mt-4"
                  disabled={modeRegenerateInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                  type="button"
                  onClick={doRegenerateInvoice}
                >
                  {t('label.regenerateInvoice')}
                </button>
              </div>
            </div>
          )}

          {modeApproveInvoice !== 0 && type === 'DISAPPROVE' && (
            <div className="col-md-9 row">
              <GenericInput
                value={invoiceNotes}
                name="invoiceNotes"
                onChange={({ name, value }) => setInvoiceNotes(value)}
                wrapperClass="col-md-9 mt-auto mb-auto mr-0"
                label="label.notes"
                type="textarea"
              />
              <div>
                <button
                  className="button x-small mt-4"
                  type="button"
                  disabled={!itemSelect || itemSelect.invoiceStatus !== 'PENDING_APPROVAL' || modeApproveInvoice === 1}
                  onClick={() => doModifyInvoiceStatus(itemSelect, 'DISAPPROVED')}
                >
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}

          {type === 'UNDO_INVOICE_CANCEL' && (
            <div className="col-md-9 row">
              <div>
                <button className="button x-small mt-4" type="button" onClick={evt => doUndoInvoiceCancel()}>
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}

          {modeSendInvoice !== 0 && type === 'EMAIL_INVOICE' && (
            <div className="col-md-9 row">
              <GenericInput
                value={emailId}
                name="emailId"
                onChange={({ name, value }) => setEmailId(value)}
                wrapperClass="col-md-4"
                label="label.overrideMail"
              />
              <div>
                <button
                  className="button x-small mt-4"
                  disabled={modeSendInvoice === 1 || !itemSelect || !itemSelect.invoiceUnitId}
                  type="button"
                  onClick={sendInvoiceToMail}
                >
                  {t('label.sendInvoice')}
                </button>
              </div>
            </div>
          )}
          {modeUndoManualInvoice !== 0 && type === 'UNDO_MANUAL_INVOICE' && (
            <div className="col-md-9 row">
              <div>
                <button
                  className="button x-small mt-4"
                  type="button"
                  disabled={!itemSelect || itemSelect.billType !== 'MANUAL_BILL' || modeApproveInvoice === 1}
                  onClick={() => doUndoManualInvoice()}
                >
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}
          {modeResyncInvoice !== 0 && type === 'RESYNC_INVOICE' && (
            <div className="col-md-9 row">
              <div>
                <button
                  className="button x-small mt-4"
                  type="button"
                  disabled={!itemSelect || ['SYNC_FAILED', 'SYNC_FAILED'].indexOf(itemSelect.invoiceStatus) === -1}
                  onClick={() => doModifyInvoiceStatus(itemSelect, 'SENT')}
                >
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}
          {type === 'GENERATE_INVOICE' && modeGenerateInvoice !== 0 && (
            <div className="col-md-9 row">
              <div>
                <button
                  className="button x-small mt-4"
                  type="button"
                  disabled={
                    !itemSelect ||
                    !itemSelect.billTotal ||
                    Number.parseFloat(itemSelect.billTotal) < 0 ||
                    modeGenerateInvoice === 1
                  }
                  onClick={() => doInvoiceAccount()}
                >
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}
          {type === 'EXTEND_DUE_DATE' && (
            <form
              ref={formRef}
              noValidate
              className={`col-md-12 needs-validation ${wasValidated ? 'was-validated' : ''}`}
              onSubmit={evt => doModifyInvoiceDueDate(evt, itemSelect)}
            >
              <div className="col-md-9 row">
                <GenericInput
                  value={dueDate}
                  name="dueDate"
                  onChange={({ name, value }) => setDueDate(value)}
                  wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                  label="label.dueDate"
                  type="date"
                  required
                />
                <GenericInput
                  value={noteItem}
                  name="noteItem"
                  onChange={({ name, value }) => setNoteItem(value)}
                  wrapperClass="col-md-4 mt-auto mb-auto mr-0"
                  label="label.notes"
                  type="textarea"
                  required
                />
                <div>
                  <button className="button x-small mt-4" type="submit" disabled={!itemSelect}>
                    {t('label.submit')}
                  </button>
                </div>
              </div>
            </form>
          )}
          {type === 'RESEND_TO_VENDOR' && (
            <div className="col-md-9 row">
              <div>
                <button className="button x-small mt-4" type="button" onClick={() => doResendInvoiceVendor()}>
                  {t('label.submit')}
                </button>
              </div>
            </div>
          )}
        </div>
      </FormWithTableItem>
      <div className="mb-30 mt-3">
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={onPageChange}
          onSizeChange={onSizeChange}
        />
      </div>
      <ModalViewNote
        isOpen={isOpenModalNotes}
        message={messageNotesBills(noteContent)}
        onToggle={() => setOpenModalNote(!isOpenModalNotes)}
      />
      <ModalWithItem
        modalTitle={t('label.invoicePaymentInstallments')}
        isOpen={isOpenModalInstallments}
        onToggle={onToggleModalInstallments}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
      >
        <div className="col-md-12 pt-3 pb-3">
          {modeModifyPaymentInstallment === 2 && modeCreatePaymentInstallment === 2 && (
            <div className="col-md-12 row pb-1">
              {dataCreate &&
                (!paymentInstallmentsData || paymentInstallmentsData.length < 1) &&
                listFields.map(val => (
                  <GenericInput
                    value={dataCreate[val.name]}
                    wrapperClass="col-md-4"
                    disabled={
                      (val.name === 'monthOffset' && !!dataCreate.daysOffset) ||
                      (val.name === 'daysOffset' && !!dataCreate.monthOffset)
                    }
                    onChange={({ name, value }) => onHandleChange({ value, name })}
                    {...val}
                  />
                ))}
            </div>
          )}
          {(!paymentInstallmentsData || paymentInstallmentsData.length < 1) &&
            modeModifyPaymentInstallment === 2 &&
            modeCreatePaymentInstallment === 2 && (
              <div className="form-group col-md-12 buttons-attibute pb-3 mb-4">
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                  onClick={onCreatePaymentInstallment}
                >
                  {t('label.submit')}
                </button>
                <button
                  type="button"
                  className="button button-border black x-small mr-2 float-right"
                  disabled={dataCreate}
                  onClick={() => {
                    setDataCreate({
                      invoiceId: invoiceUnitIdSelected,
                      accountId: id,
                      numOfInstallment: null,
                      daysOffset: null,
                      monthOffset: null,
                      status: 'ACTIVE',
                    });
                  }}
                >
                  +
                  {t('label.addInstallments')}
                </button>
              </div>
            )}
          <br />
          <DataTable columns={installmentsColumns} data={paymentInstallmentsData} />
          <button
            type="button"
            onClick={onToggleModalInstallments}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
        </div>
      </ModalWithItem>
      <ModalWithItem
        modalTitle={t('label.invoiceDetails')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={isOpenModalDetails}
        onToggle={onToggleModalDetails}
      >
        <form
          ref={formRefInvoice}
          noValidate
          className={`col-md-12 pt-3 pb-3 needs-validation ${wasValidatedInvoice ? 'was-validated' : ''}`}
          onSubmit={evt => {
            if (dataSave.scheduleDate && moment(new Date()).isBefore(dataSave.scheduleDate)) {
              return onSaveManualInvoice(evt);
            }
            if (id === accountIdSelected) {
              doReprocessInvoice(evt);
            } else {
              evt.preventDefault();
              setIsOpenModalProcess(true);
            }
          }}
        >
          <div className="col-md-12 row pb-1">
            {listInvoiceFields.map(val => (
              <GenericInput
                value={
                  val.isFormatMoneyOfValue
                    ? formatMoneyValue(paymentInvoiceData[val.name])
                    : paymentInvoiceData[val.name]
                }
                wrapperClass="col-md-4"
                onChange={({ name, value }) => onHandleChange({ value, name })}
                {...val}
              />
            ))}
            <Switch
              onChange={() => {
                setIsCancelAndSubstitute(!isCancelAndSubstitute);
                onHandleSelectAll({ name: 'selectAll', value: false });
              }}
              name="isCancelAndSubstitute"
              wrapperClass={`col-md-4 pl-2 pt-4 pr-3 ${isCancelAndSubstitute ? 'switch-active' : 'switch-non-active'}`}
              title={t('common:label.createNewInvoice')}
              checked={isCancelAndSubstitute}
              disabled={!invoiceUnitIdSelected}
            />
            <GenericInput
              value={isSubstituteInvoice === 'TRUE' ? 'TRUE' : 'FALSE'}
              name="substituteInvoice"
              onChange={({ name, value }) => setIsSubstituteInvoice(value)}
              wrapperClass="col-md-4 mt-auto mb-auto mr-0"
              label="label.invoiceSubstitution"
              type="select"
              tOptions="selections:selectBool"
              disabled={!isCancelAndSubstitute}
            />
            <GenericInput
              value={accountIdSelected}
              name="accountId"
              onChange={() => {}}
              wrapperClass="col-md-4 mr-0"
              label="label.accountId"
              fa="fa fa-external-link"
              onClick={() => setIsOpenModalAccountId(true)}
              disabled={!isCancelAndSubstitute}
            />
            <GenericInput
              value={dataSave.scheduleDaily}
              wrapperClass="col-md-4"
              type="select"
              onChange={onHandleChangeDataSave}
              name="scheduleDaily"
              tOptions="selections:selectBool"
              label="label.scheduleWithDailyJobs"
              disabled={!isCancelAndSubstitute}
            />
            <GenericInput
              value={dataSave.scheduleDate}
              wrapperClass="col-md-4"
              type="date"
              onChange={onHandleChangeDataSave}
              name="scheduleDate"
              label="label.scheduleDate"
              disabled={!isCancelAndSubstitute}
            />
            <CheckField
              name="selectAll"
              id="selectAll"
              wrapperClass="col-md-4 mt-4 mb-2"
              checked={isSelectAll}
              disabled={!isCancelAndSubstitute}
              // checked={selectName === 'selectAll'}
              label={t('label.selectAll')}
              onChange={evt => onHandleSelectAll({ name: 'selectAll', value: evt.target.checked })}
            />
          </div>
          <br />
          {collapsibleMenuTransactionId.map(item => (
            <FormCollapse
              key={item.title}
              label="First Name"
              isActive={item.state === activeTab.name && activeTab.isActive}
              title={t(item.title) || ''}
              state={item.state}
              onToggleTab={onToggleTab}
            >
              {renderItem(item)}
            </FormCollapse>
          ))}
          <br />
          <button
            type="button"
            onClick={onToggleModalDetails}
            className="button button-border black x-small float-right"
          >
            {t('label.cancel')}
          </button>
          <button
            className="button x-small mr-2 float-right"
            type="submit"
            disabled={!isCancelAndSubstitute || !itemSelectInvoiceLine.length}
          >
            {t('label.process')}
          </button>
          <button
            className="button x-small mr-2 float-right"
            type="button"
            disabled={!isCancelAndSubstitute || !itemSelectInvoiceLine.length}
            onClick={onSaveManualInvoice}
          >
            {t('label.save')}
          </button>
        </form>
      </ModalWithItem>
      <ModalSearchItem
        isOpen={isOpenModalItem}
        onSelect={onSelectItemId}
        onCancel={onToggleModalItemId}
        selectedParentId={itemIdSelect && itemIdSelect.itemId ? itemIdSelect.itemId : ''}
        // defaultSearchParams={{ type: 'REVENUE' }}
        defaultSearchForm={{ type: 'RECEIVABLE' }}
        defaultFilter={{ type: 'RECEIVABLE' }}
        isWithType
      />
      {/* Modal  getPacInterfaceRecordById  */}
      <ModalWithItem
        modalTitle={t('label.stampingRecord')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={isOpenModalStamping}
        onToggle={onToggleModalStamping}
      >
        <DataTable columns={stampingRecordColumns} data={stampingsData || []} />
        <button type="button" onClick={onToggleModalStamping} className="button x-small float-right">
          {t('label.back')}
        </button>
      </ModalWithItem>

      <ModalWithItem
        modalTitle={t('label.data')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={isOpenModalStampingCSVData}
        onToggle={onToggleModalStampingCSVData}
      >
        <div className="mt-3 mb-5 csv-content">
          {/* {csvData} */}
          {messageCSVStamping(csvData)}
        </div>
        <button type="button" onClick={onToggleModalStampingCSVData} className="button x-small float-right">
          {t('label.back')}
        </button>
      </ModalWithItem>

      {/* Bill Pending Charges */}
      <ModalWithItem
        modalTitle={t('label.billPendingCharges')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={isOpenModalBillPendingCharges}
        onToggle={onToggleModalBillPendingCharges}
      >
        <div className="col-md-12 row pb-3">
          {checkListPendingBillCharges.map(val => (
            <GenericInput
              key={val.name}
              type="checkbox"
              className="col-md-2"
              checked={val.name === typeSelectPendingCharges}
              value={val.name === typeSelectPendingCharges}
              onChange={({ name }) => {
                if (name !== 'SELECTIVE' || name === typeSelectPendingCharges) {
                  setItemSelectInvoiceLinePendingCharges([]);
                }
                if (name === typeSelectPendingCharges) {
                  return settypeSelectPendingCharges(null);
                }
                return settypeSelectPendingCharges(name);
              }}
              {...val}
            />
          ))}
        </div>
        <DataTable columns={tableInvoiceLines} data={invoiceTransactionAttributesList || []} />
        <button type="button" onClick={onRunPendingBill} className="button x-small ml-2 float-right">
          {t('label.process')}
        </button>
        <button
          type="button"
          onClick={onToggleModalBillPendingCharges}
          className="button button-border black x-small float-right"
        >
          {t('label.cancel')}
        </button>
      </ModalWithItem>
      <ModalAccountId
        isOpen={isOpenModalAccountId}
        onSelect={onSelectAccountId}
        selectedParentId={accountIdSelected}
        onCancel={() => {
          setIsOpenModalAccountId(false);
        }}
      />
      <ModalAccept
        onAcceptModal={evt => doReprocessInvoice(evt)}
        isOpen={isOpenModalProcess}
        message={t('common:message.manualInvoiceMessage')}
        onToggle={() => {
          setIsOpenModalProcess(false);
        }}
      />
      <ModalSelectBillableService
        isOpen={isOpenModalServiceName}
        onSelect={onSelectServiceName}
        onCancel={onToggleModalServiceName}
        selectedParentId={serviceNameSelect && serviceNameSelect.omsInternalId ? serviceNameSelect.omsInternalId : ''}
        accountId={id}
        getOrderSummaryByAccountId={getOrderSummaryByAccountId}
      />
      <ModalWithItem
        modalTitle={t('label.aRActivity')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={!!arActivitySelect}
        onToggle={() => setArActivitySelect(null)}
      >
        <ArActivity isComponent invoiceSelect={arActivitySelect} id={id} location={location} history={history} />
        <button type="button" onClick={() => setArActivitySelect(null)} className="button x-small float-right">
          {t('label.back')}
        </button>
      </ModalWithItem>
      <ModalWithItem
        modalTitle={t('label.arOpsUnits')}
        wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={!!arOpsUnitSelect}
        onToggle={() => setArOpsUnitSelect(null)}
      >
        <AROpsUnit isComponent invoiceSelect={arOpsUnitSelect} id={id} modeSearchArOpsUnit={modeSearchArOpsUnit} />
        <button type="button" onClick={() => setArOpsUnitSelect(null)} className="button x-small float-right">
          {t('label.back')}
        </button>
      </ModalWithItem>
    </div>
  );
};

Bills.propTypes = {
  id: PropTypes.string,
  dataPendingBills: PropTypes.arrayOf(PropTypes.any),
  dataBillInvoice: PropTypes.arrayOf(PropTypes.any),
  modeSearchInvoice: PropTypes.number,
  modeGetInvoice: PropTypes.number,
  runPendingBill: PropTypes.func,
  doGetPendingOCBills: PropTypes.func,
  getOCBill: PropTypes.func.isRequired,
  getInvoiceTaxSummary: PropTypes.func.isRequired,
  getInvoiceTransactionSummary: PropTypes.func.isRequired,
  getPacInterfaceRecordById: PropTypes.func.isRequired,
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  reprocessInvoice: PropTypes.func.isRequired,
  getInvoiceUnitByIdSelect: PropTypes.func.isRequired,
  undoInvoiceCancel: PropTypes.func.isRequired,
  resendInvoiceVendor: PropTypes.func.isRequired,
  createPaymentInstallment: PropTypes.func.isRequired,
  getInvoiceInstallmentsByInvoiceId: PropTypes.func.isRequired,
  saveManualInvoice: PropTypes.func.isRequired,
  getCostCenterConfig: PropTypes.func.isRequired,
  getOrderSummaryByAccountId: PropTypes.func.isRequired,
};

Bills.defaultProps = {
  id: '',
  dataPendingBills: [],
  dataBillInvoice: [],
  modeSearchInvoice: 2,
  modeGetInvoice: 2,
  runPendingBill: () => {},
  doGetPendingOCBills: () => {},
};

export default Bills;
