import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
window.NODE_ENV = process?.env?.NODE_ENV;

let uri = process?.env?.REACT_APP_APOLLO_API_URL || 'https://service-transactional.develop.embrix.org/graphql';
if (window._env_ && window._env_.REACT_APP_APOLLO_API_URL) {
  uri = window._env_.REACT_APP_APOLLO_API_URL;
}

const client = new ApolloClient({
  link: new HttpLink({
    uri,
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

export default client;
