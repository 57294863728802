import React, { PureComponent } from 'react';
import PropsType from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FilePond } from 'react-filepond';
import XMLViewer from 'react-xml-viewer';
import { saveAs } from 'file-saver';
import { getBase64, splitF } from '../../../utils/utils';
import 'filepond/dist/filepond.min.css';

let isNeedShowMessageValid = false;

class ModalUploadFile extends PureComponent {
  state = {
    files: null,
    bass64: null,
    isUpload: false,
    xmlData: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.type || props.accept) {
      const dom = document.querySelector('.filepond--action-remove-item');
      if (dom && props.type === 'IMAGE') {
        dom.accept = 'image/*';
      }
      if (dom && props.type === 'VIDEO') {
        dom.accept = 'video/*';
      }
      if (dom && props.accept === 'VIDEO') {
        dom.accept = props.accept;
      }
    }
    if (state.oldValue === props.itemSelect) return null;
    return {
      files: props.fileSelect ? [props.fileSelect] : null,
      bass64: props.base64FileSelect || null,
      defaultBase64: props.base64FileSelect,
      oldValue: props.itemSelect,
      xmlData: null,
    };
  }

  componentDidUpdate() {
    const { type, accept } = this.props;
    const { bass64, files, xmlData } = this.state;
    setTimeout(() => {
      if (type || accept) {
        const dom = document.querySelector('.filepond--browser');
        if (dom && type === 'IMAGE') {
          dom.accept = 'image/*';
        }
        if (dom && type === 'VIDEO') {
          dom.accept = 'video/*';
        }
        if (dom && accept && !dom.accept) {
          dom.accept = accept;
        }
      }
    }, 200);
    if (bass64 && files && files[0] && files[0].type.indexOf('xml') !== -1 && !xmlData) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ xmlData: reader.result });
      };
      reader.readAsText(files[0]);
    }
  }

  onSetFile = file => {
    this.setState({ files: file, isUpload: true });
  };

  onSetBase64 = bass64 => {
    this.setState({ bass64, isUpload: true });
  };

  onUpload = () => {
    const { onUpload, onCancel } = this.props;
    const { files, defaultBase64, bass64 } = this.state;
    if (bass64 && defaultBase64 !== bass64) {
      if (onUpload) onUpload({ files });
    } else {
      onCancel();
    }
  };

  saveFileSelect = () => {
    const { files } = this.state;
    const { pathFile } = this.props;
    saveAs(files[0], `${splitF(pathFile)}`);
  };

  render() {
    const {
      title,
      isOpen,
      onCancel,
      t,
      children,
      isDisabledUpload,
      messageValid,
      acceptFileType,
      isSupportDownload,
    } = this.props;
    const { files, bass64, xmlData } = this.state;

    return (
      <Modal isOpen={isOpen} className="modal-lg info-form" toggle={onCancel}>
        <ModalHeader>{t(title)}</ModalHeader>
        <ModalBody>
          {children || ''}
          <div
            className="group-upload"
            onClick={() => {
              isNeedShowMessageValid = true;
            }}
          >
            <FilePond
              files={files}
              onupdatefiles={fileItems => {
                const filesList = fileItems.map(fileItem => fileItem.file);
                if (filesList && filesList.length && acceptFileType && acceptFileType.length) {
                  let isCorrectFile = false;
                  filesList.forEach(file => {
                    const { type } = file;
                    acceptFileType.forEach(typeValid => {
                      if (type.indexOf(typeValid) !== -1) {
                        isCorrectFile = true;
                      }
                    });
                  });
                  if (!isCorrectFile) {
                    setTimeout(() => {
                      const dom = document.querySelector('.filepond--action-remove-item');
                      if (dom) {
                        dom.click();
                      }
                    }, 300);
                    if (messageValid && isNeedShowMessageValid) {
                      toast.error(messageValid);
                      isNeedShowMessageValid = false;
                    }
                    return '';
                  }
                }
                // Set currently active file objects to this.state
                this.onSetFile(filesList);
                if (filesList[0]) {
                  getBase64(filesList[0], result => {
                    this.onSetBase64(result);
                  });
                } else {
                  this.onSetBase64(null);
                }
              }}
              // acceptedFileTypes={['application/pdf']}
              allowMultiple={false}
              maxFiles={3}
              // server="/api"
              name="files"
              labelIdle={t('message.dragDropTitle')}
            />
          </div>
          <div className="preview-group p-2">
            {bass64 && files && files[0] && files[0].type.indexOf('image') !== -1 && (
              <img
                src={bass64}
                className="new-pdf-images"
                alt="img-avatar"
                height="200 !important"
                // width="200 !important"
              />
            )}
            {bass64 && files && files[0] && files[0].type.indexOf('video') !== -1 && (
              <video controls height="200 !important" key={bass64} autoPlay controlsList="nodownload">
                <source src={bass64} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            )}

            {bass64 && files && files[0] && files[0].type.indexOf('html') !== -1 && (
              <iframe
                type="application/pdf"
                src={bass64}
                title="report_view"
                style={{ width: '95%', minHeight: '300px', marginLeft: '20px' }}
              />
            )}
            {bass64 && files && files[0] && files[0].type.indexOf('xml') !== -1 && (
              <div
                className="xml-viewer"
                style={{ width: '95%', minHeight: '740px', marginLeft: '20px', overflow: 'auto' }}
              >
                <XMLViewer xml={xmlData} />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={onCancel}>
            {t('label.cancel')}
          </button>
          <Button color="primary" onClick={this.onUpload} disabled={isDisabledUpload}>
            {t('label.upload')}
          </Button>
          {isSupportDownload && (
            <Button color="primary" onClick={this.saveFileSelect} disabled={isDisabledUpload || !files || !files[0]}>
              {t('label.download')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

ModalUploadFile.propTypes = {
  onToggle: PropsType.func,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalUploadFile.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
  onToggle: () => {},
};

export default withTranslation('common')(ModalUploadFile);
