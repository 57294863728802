import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import PricingForm from './DiscountForm';
import { onCheckSelectionsUsage } from '../../constants';
import { CheckField, GenericInput } from '../../../../components/common';

const selectionsComplex = t => [
  {
    name: 1,
    label: t('label.addCustomAttributes'),
  },
  {
    name: 2,
    label: t('label.addUsageAttributes'),
  },
  {
    name: 3,
    label: t('label.addTransactionAttributes'),
  },
  {
    name: 4,
    label: t('label.addTiers'),
  },
];

const selectionsComplexRecurring = t => [
  {
    name: 1,
    label: t('label.addCustomAttributes'),
  },
  {
    name: 4,
    label: t('label.addTiers'),
  },
];

class PricingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectField: [1, 3],
      pricings: [],
      isShowComponent: true,
      indexShowing: 1,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.pricings === props.pricings && state.discountingModel && props.discountingModel) return null;
    return {
      pricings: props.pricings || [],
      discountingModel: props.discountingModel,
      transactionType: props.transactionType,
      selectField:
        props.discountingModel === 'COMPLEX' &&
        onCheckSelectionsUsage({
          pricing: props.pricings[props.pricings.length - 1],
          transactionType: props.transactionType,
        })
          ? onCheckSelectionsUsage({
              pricing: props.pricings[props.pricings.length - 1],
              transactionType: props.transactionType,
            })
          : [],
    };
  }

  onChangeSelection = evt => {
    let { selectField, pricings, transactionType } = this.state;
    const { onChangeRemoveSelection, onChangeAddSelection, t } = this.props;
    if (evt.target.checked) {
      selectField.push(Number.parseInt(evt.target.name, 10));
      if (pricings.filter(val => size(val) > 10).length > 0) {
        onChangeAddSelection(Number.parseInt(evt.target.name, 10), selectField);
      }
    } else {
      if (transactionType === 'USAGE' && selectField.length < 3) {
        return toast.error(t('message.message2Selection'));
      }
      if (
        (transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') &&
        selectField.length < 2
      ) {
        return toast.error(t('message.message1Selection'));
      }
      selectField = selectField.filter(item => item !== Number.parseInt(evt.target.name, 10));
      if (pricings.filter(val => size(val) > 10).length > 0) {
        onChangeRemoveSelection(Number.parseInt(evt.target.name, 10));
      }
    }
    return this.setState({ selectField });
  };

  onAddSelections = () => {
    const { selectField, transactionType } = this.state;
    const { onAddSelections, t } = this.props;
    if (transactionType === 'USAGE' && selectField.length < 2) {
      return toast.error(t('message.message2Selection'));
    }
    if (
      (transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') &&
      selectField.length < 1
    ) {
      return toast.error(t('message.message1Selection'));
    }
    onAddSelections(selectField);
  };

  handleChangePricings = (index, data) => {
    const { onChangePricings } = this.props;
    const { discountingModel } = this.state;
    const { pricings } = this.state;
    const newPricing = pricings;
    if (data) {
      if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
        try {
          const idxChange = newPricing.findIndex(val => val.index === data.index);
          const newData = cloneDeep(data);
          if (newData.discounts[0]) newData.discounts[0] = { ...newData.discounts[0], index: 1 };
          newPricing[idxChange] = newData;
          newPricing.forEach((val, idx) => {
            if (data.usageAttributeDiscountingData) {
              data.usageAttributeDiscountingData.forEach((dt, idxDt) => {
                if (newPricing[idx] && newPricing[idx].usageAttributePricingData) {
                  if (!newPricing[idx].usageAttributeDiscountingData[idxDt]) {
                    newPricing[idx].usageAttributeDiscountingData.push({ attribute: '', value: 'NONE' });
                  }
                  newPricing[idx].usageAttributeDiscountingData[idxDt].attribute =
                    data.usageAttributeDiscountingData[idxDt].attribute;
                }
              });
            }
            if (newPricing[idx].discounts && newPricing[idx].discounts.length) {
              newPricing[idx].discounts[0].currencyId = data.discounts[0].currencyId;
            }
          });
          this.setState({ pricings: newPricing });
        } catch (error) {
          console.log(error);
        }
      } else {
        newPricing[index] = data;
      }
    }
    onChangePricings(newPricing);
  };

  onChangePricing = (index, data) => {
    const { pricings } = this.state;
    pricings[index] = data;
    this.setState({ pricings }, () => this.handleChangePricings());
  };

  handleRemovePricing = (pricingIdx, pricing) => {
    const { pricings, discountingModel } = this.state;
    const { creatingMode } = this.props;
    if (creatingMode) {
      pricings.splice(pricingIdx, 1);
    } else if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
      if (pricings && pricings[pricingIdx]) {
        pricings[pricingIdx] = { index: pricings[pricingIdx].index };
        // pricings.splice(pricingIdx, 1);
      }
    } else if (pricings && pricings[pricingIdx]) {
      pricings[pricingIdx] = { index: pricings[pricingIdx].index, id: pricings[pricingIdx].id };
      // pricings.splice(pricingIdx, 1);
    }
    this.setState({ pricings }, () => this.handleChangePricings());
  };

  onToggleComponent = () => {
    const { isShowComponent } = this.state;
    this.setState({ isShowComponent: !isShowComponent });
  };

  onToggleShowIndex = index => {
    const { indexShowing } = this.state;
    this.setState({ indexShowing: indexShowing === index + 1 ? 0 : index + 1 });
  };

  render() {
    const { pricings = [], selectField, isShowComponent, indexShowing } = this.state;
    const {
      discountingModel,
      optionsAccumulatorId,
      transactionType,
      handleAddTier,
      addNewCustomerPricing,
      addNewUsageAttributeBase,
      onChangeRecurringComplex,
      t,
      ...props
    } = this.props;
    const requiredTiered =
      pricings[0] &&
      pricings[0].tierType &&
      pricings[0].tierType === 'PROSPECTIVE' &&
      (discountingModel === 'TIERED' || discountingModel === 'COMPLEX');
    const fieldCustomAttribute = [
      {
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        name: 'salesChannel',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        name: 'marketSegment',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        name: 'accountType',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        name: 'accountSubType',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
        menuPortalTarget: true,
      },
    ];

    const fieldTransactionAttribute = [
      {
        label: 'label.rateUnit',
        wrapperClass: 'col-md-4',
        name: 'rateUnit',
        fa: 'fa fa-external-link',
      },
      {
        label: 'label.timeUnit',
        wrapperClass: 'col-md-4',
        name: 'timeUnit',
        type: 'select',
        tOptions: 'selections:timeUnitPricing',
        isClearable: true,
        // fa: 'fa fa-external-link',
        menuPortalTarget: true,
      },
      {
        label: 'label.zoneUnit',
        wrapperClass: 'col-md-4',
        name: 'zoneUnit',
      },
      {
        label: 'label.impactUnit',
        wrapperClass: 'col-md-4',
        name: 'impactUnit',
      },
      {
        label: 'label.holidayName',
        wrapperClass: 'col-md-4',
        name: 'holidayName',
        fa: 'fa fa-external-link',
      },
      {
        label: 'label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        isNullValue: true,
        tooltipDetails: 'common:message.poDescription',
      },
    ];

    const fieldTier = [
      {
        label: 'label.tierReference',
        wrapperClass: 'col-md-4',
        name: 'tierReference',
        type: 'select',
        tOptions: 'selections:tierReference',
        isClearable: true,
        required: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.accumulatorId',
        wrapperClass: 'col-md-4',
        name: 'accumulatorId',
        type: 'select',
        options: optionsAccumulatorId,
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.tierType',
        wrapperClass: 'col-md-4',
        name: 'tierType',
        type: 'select',
        tOptions: 'selections:tierType',
        isClearable: true,
        required: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.evaluationStart',
        wrapperClass: 'col-md-4',
        name: 'evaluationStart',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.evaluationEnd',
        wrapperClass: 'col-md-4',
        name: 'evaluationEnd',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.offsetMonths',
        wrapperClass: 'col-md-4',
        name: 'offsetMonths',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.pricingTerm',
        wrapperClass: 'col-md-4',
        name: 'nextDiscountingTerm',
        type: 'number',
        required: requiredTiered,
        disabled: !requiredTiered,
      },
      {
        label: 'label.volumeTreatment',
        wrapperClass: 'col-md-4',
        name: 'volumeTreatment',
        type: 'select',
        tOptions: 'selections:volumeTreatment',
        isClearable: true,
        required: requiredTiered,
        disabled: !requiredTiered,
        menuPortalTarget: true,
      },
    ];

    const fieldTierComplex = [
      {
        label: 'label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        required: true,
      },
    ];

    const listFieldsRecurring = [
      {
        label: 'label.recurringUnit',
        wrapperClass: 'col-md-4',
        name: 'unit',
        type: 'select',
        tOptions: 'selections:recurringUnit',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.recurringFrequency',
        wrapperClass: 'col-md-4',
        name: 'frequency',
        type: 'number',
      },
      {
        label: 'label.purchaseProration',
        wrapperClass: 'col-md-4',
        name: 'purchaseProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.cancelProration',
        wrapperClass: 'col-md-4',
        name: 'cancelProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.upgradeProration',
        wrapperClass: 'col-md-4',
        name: 'upgradeProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
      {
        label: 'label.downgradeProration',
        wrapperClass: 'col-md-4',
        name: 'downgradeProration',
        type: 'select',
        tOptions: 'selections:prorationType',
        isClearable: true,
        menuPortalTarget: true,
      },
    ];

    const PRICING_MODELS = {
      FLAT: {
        title: t('label.flatDiscounting'),
        inputs: [],
      },
      RECURRING: {
        title: t('label.recurringDiscounting'),
        inputs: [...listFieldsRecurring],
      },
      CUSTOMER_ATTRIBUTE_BASED: {
        title: t('label.customPricing'),
        inputs: fieldCustomAttribute,
      },
      RECURRING_TIERED: {
        title: t('label.recurringTieredDiscountingModel'),
        inputs: [
          {
            label: 'label.recurringUnit',
            wrapperClass: 'col-md-4',
            name: 'unit',
            type: 'select',
            tOptions: 'selections:recurringUnit',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.recurringFrequency',
            wrapperClass: 'col-md-4',
            name: 'frequency',
            type: 'number',
          },
          {
            label: 'label.purchaseProration',
            wrapperClass: 'col-md-4',
            name: 'purchaseProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.cancelProration',
            wrapperClass: 'col-md-4',
            name: 'cancelProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.upgradeProration',
            wrapperClass: 'col-md-4',
            name: 'upgradeProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
          {
            label: 'label.downgradeProration',
            wrapperClass: 'col-md-4',
            name: 'downgradeProration',
            type: 'select',
            tOptions: 'selections:prorationType',
            isClearable: true,
            menuPortalTarget: true,
          },
        ],
      },
      TRANSACTION_ATTRIBUTE_BASED: {
        title: t('label.transactionAttributeBasedPricingModel'),
        inputs: fieldTransactionAttribute,
      },
      TIERED: {
        title: t('label.tieredDiscountingModel'),
        inputs: [
          {
            label: 'label.name',
            wrapperClass: 'col-md-4',
            name: 'name',
            required: true,
          },
          {
            label: 'label.description',
            wrapperClass: 'col-md-4',
            name: 'description',
            required: true,
          },
          ...fieldTier,
        ],
      },
      COMPLEX_USAGE: {
        title: t('label.complexDiscounting'),
        inputs: [],
      },
      COMPLEX_RECURRING: {
        title: t('label.complexDiscountingRecurring'),
        inputs: [],
      },
      USAGE_ATTRIBUTE_BASED: {
        title: t('label.usageAttributeBase'),
        inputs: [],
      },
    };
    const subInputRecurringTirred =
      discountingModel === 'RECURRING_TIERED'
        ? [
            {
              label: 'label.name',
              wrapperClass: 'col-md-4',
              name: 'name',
              required: true,
            },
            {
              label: 'label.description',
              wrapperClass: 'col-md-4',
              name: 'description',
              required: true,
            },
            {
              label: 'label.tierType',
              wrapperClass: 'col-md-4',
              name: 'tierType',
              type: 'select',
              tOptions: 'selections:tierType',
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
            {
              label: 'label.tierReference',
              wrapperClass: 'col-md-4',
              name: 'tierReference',
              type: 'select',
              tOptions: 'selections:tierReference',
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
            {
              label: 'label.accumulator',
              wrapperClass: 'col-md-4',
              name: 'accumulatorId',
              type: 'select',
              options: optionsAccumulatorId,
              isClearable: true,
              required: true,
              menuPortalTarget: true,
            },
          ]
        : [];

    let pm = '';
    if (transactionType === 'USAGE' && discountingModel === 'COMPLEX') {
      pm = PRICING_MODELS.COMPLEX_USAGE;
    } else if (
      (transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') &&
      discountingModel === 'COMPLEX'
    ) {
      pm = PRICING_MODELS.COMPLEX_RECURRING;
    } else {
      pm = cloneDeep(PRICING_MODELS[discountingModel]);
    }
    const isComplexUsage =
      (transactionType === 'USAGE' ||
        transactionType === 'FORWARD_RECURRING' ||
        transactionType === 'ARREARS_RECURRING') &&
      discountingModel === 'COMPLEX';

    const listSelections =
      isComplexUsage && transactionType === 'USAGE' ? selectionsComplex(t) : selectionsComplexRecurring(t);
    const pricingTitle = pm ? pm.title : '';
    if (!pm) return null;
    return (
      <div>
        <div className="card nested-section-card">
          <button
            type="button"
            className={`card-header text-left no-border btn-collapsible ${isShowComponent ? 'active-main' : ''}`}
            onClick={this.onToggleComponent}
          >
            {pricingTitle}
            {isShowComponent ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
          </button>
          <div className={isShowComponent ? 'show-content' : 'hidden-content'}>
            {(transactionType === 'USAGE' ||
              transactionType === 'FORWARD_RECURRING' ||
              transactionType === 'ARREARS_RECURRING') &&
              discountingModel === 'COMPLEX' && [
                <div key="1">
                  {(transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING') && (
                    <div className="col-md-12 p-3 row">
                      {listFieldsRecurring.map(val => {
                        return (
                          <GenericInput
                            key={val.name}
                            value={pricings[0] && pricings[0][val.name]}
                            label={t(val.label)}
                            wrapperClass="col-md-3"
                            onChange={onChangeRecurringComplex}
                            {...val}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>,
                <div key="2" className="col-md-12 p-3 row">
                  <div className="col-md-10 row">
                    {listSelections.map(item => (
                      <div className="col-md-3" key={item.name}>
                        <CheckField
                          checked={selectField.findIndex(val => val === item.name) !== -1}
                          name={item.name}
                          label={item.label}
                          onChange={this.onChangeSelection}
                          disabled={pricings.filter(val => size(val) > 10).length > 1 || !props.creatingMode}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="col-md-2 row">
                    <button
                      type="button"
                      className="button button-border gray x-small float-right"
                      disabled={pricings.filter(val => size(val) > 10).length > 0 || !props.creatingMode}
                      onClick={this.onAddSelections}
                    >
                      {t('label.addSelections')}
                    </button>
                  </div>
                </div>,
              ]}
            <div className="card-body">
              {pricings &&
                pricings
                  .filter(val => size(val) > (isComplexUsage ? 2 : 1))
                  .map((pricing, pricingIdx) => {
                    let inputFields = pm.inputs;
                    const isTier = !!(pricing.tiers && pricing.tiers[0]);
                    const newSelectField = [];
                    if (
                      (transactionType === 'USAGE' ||
                        transactionType === 'FORWARD_RECURRING' ||
                        transactionType === 'ARREARS_RECURRING') &&
                      discountingModel === 'COMPLEX'
                    ) {
                      if (
                        pricing.salesChannel ||
                        pricing.marketSegment ||
                        pricing.accountType ||
                        pricing.accountSubType
                      ) {
                        newSelectField.push(1);
                        inputFields = inputFields.concat({
                          title: 'label.customerAttributes',
                          listField: fieldCustomAttribute,
                        });
                      }
                      if (
                        pricing.rateUnit ||
                        pricing.timeUnit ||
                        pricing.zoneUnit ||
                        pricing.impactUnit ||
                        pricing.holidayName
                      ) {
                        newSelectField.push(3);
                        inputFields = inputFields.concat({
                          title: 'label.transactionAttributes',
                          listField: fieldTransactionAttribute,
                        });
                      }
                      if (pricing.usageAttributeDiscountingData) {
                        newSelectField.push(2);
                        inputFields = inputFields.concat({
                          title: 'label.usageAttributes',
                          isRenderSubField: true,
                          listField: [],
                        });
                      }
                      if (pricing.tiers && pricing.tiers.length) {
                        newSelectField.push(4);
                        inputFields = inputFields.concat({
                          title: 'label.tiers',
                          listField: [
                            ...fieldTierComplex,
                            ...fieldTier.map(val => {
                              const { required, ...rest } = val;
                              return rest;
                            }),
                          ],
                        });
                      }
                    }
                    return (
                      <PricingForm
                        key={pricingIdx}
                        pricing={pricing}
                        index={pricingIdx}
                        pricingInputs={isComplexUsage ? [] : inputFields}
                        inputFieldsUsageComplex={inputFields}
                        onChangePricing={this.handleChangePricings}
                        onRemovePricing={this.handleRemovePricing}
                        pricingRemoveable={pricings.length > 1}
                        optionsAccumulatorId={optionsAccumulatorId}
                        discountingModel={discountingModel}
                        selectField={newSelectField}
                        checkModel={discountingModel}
                        isComplexUsage={isComplexUsage}
                        subInputRecurringTirred={subInputRecurringTirred}
                        indexShowing={indexShowing}
                        onToggleShowIndex={this.onToggleShowIndex}
                        handleAddTier={
                          isComplexUsage ? () => handleAddTier({ isTier, pricingIdx, isComplexUsage }) : handleAddTier
                        }
                        transactionType={transactionType}
                        {...props}
                      />
                    );
                  })}
            </div>
          </div>
        </div>

        <div className="mt-4">
          {isComplexUsage && pricings.filter(val => size(val) > 10).length > 0 && (
            <div className="p-3 text-center">
              <button
                type="button"
                className="button button-border gray x-small"
                onClick={() => {
                  this.onToggleShowIndex(pricings.length);
                  props.onCopyOldComplexUsagePricing(0);
                }}
              >
                {`+ ${t('label.addAnotherRowForComplexPricing')}`}
              </button>
            </div>
          )}
          {discountingModel === 'CUSTOMER_ATTRIBUTE_BASED' && (
            <button
              type="button"
              className="button button-border x-small float-left"
              onClick={() => {
                addNewCustomerPricing();
                this.onToggleShowIndex(pricings.length);
              }}
            >
              {t('label.addNewCustomerPricing')}
            </button>
          )}
          {discountingModel === 'TRANSACTION_ATTRIBUTE_BASED' && (
            <button
              type="button"
              className="button button-border x-small float-left"
              onClick={() => {
                addNewCustomerPricing();
                this.onToggleShowIndex(pricings.length);
              }}
            >
              {t('label.addNewTransactionAttributePricing')}
            </button>
          )}
          {discountingModel === 'USAGE_ATTRIBUTE_BASED' && (
            <button
              type="button"
              className="button button-border x-small float-left"
              onClick={() => {
                addNewUsageAttributeBase();
                this.onToggleShowIndex(pricings.filter(i => size(i) > 1).length);
              }}
            >
              {t('label.addNewUsageAttributePricing')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

PricingSection.propTypes = {
  onChangePricings: PropTypes.func.isRequired,
  pricings: PropTypes.arrayOf(PropTypes.object),
  discountingModel: PropTypes.string,
  creatingMode: PropTypes.bool,
};

PricingSection.defaultProps = {
  pricings: [{}],
  creatingMode: true,
};

export default withTranslation('common')(PricingSection);
