import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, isArray } from 'lodash';
import { withTranslation } from 'react-i18next';
import { getPageTotalCount } from 'utils/utils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CheckField, DataTable, TablePagination } from '../common';

class ModalSelector extends Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sort: '',
    sorted: {},
    totalCount: null,
    isSearching: false,
    listItems: [],
    selectedParentId: null,
    rowItem: null,
    initSelectedParentId: null,
    isFirstSearchDefault: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initSelectedParentId === props.selectedParentId) return null;
    return {
      selectedParentId: props.selectedParentId,
      initSelectedParentId: props.selectedParentId,
      isMultiple: props.isMultiple,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isNeedFetch, isOpen } = this.props;
    if ((isNeedFetch && isNeedFetch !== prevProps.isNeedFetch) || (isOpen && prevProps.isOpen !== isOpen))
      this.doSearchItems();
  }

  onSelectParent = e => {
    e.preventDefault();
    const { onSelect } = this.props;
    const { selectedParentId } = this.state;
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(selectedParentId, this.state.rowItem);
      this.setState({ filter: {}, selectedParentId: null, isFirstSearchDefault: false });
    }
  };

  onCancel = e => {
    e.preventDefault();
    const { onCancel } = this.props;
    if (onCancel) {
      this.setState({ filter: {}, isFirstSearchDefault: false }, () => onCancel(false));
    }
  };

  onUnselectParent = e => {
    e.preventDefault();
    const { onSelect, isRequired } = this.props;
    if (isRequired) return false;
    this.setState({ selectedParentId: null });
    if (onSelect) {
      this.setState({ filter: {} });
      onSelect(null);
    }
  };

  onClickRow = (e, rowItem) => {
    e.preventDefault();
    const { fieldDisableCheck, fieldsEnableCheck, checkDisabledFunc } = this.props;
    let isDisable = false;
    if (checkDisabledFunc) isDisable = checkDisabledFunc(rowItem);
    if (fieldDisableCheck) {
      map(fieldDisableCheck, (value, key) => {
        if (isArray(value)) {
          if (value.indexOf(rowItem[key]) > -1) isDisable = true;
        } else if (rowItem[key] === value) isDisable = true;
      });
    }
    if (fieldsEnableCheck) {
      map(fieldsEnableCheck, (value, key) => {
        if (isArray(value)) {
          if (value.indexOf(rowItem[key]) === -1) isDisable = true;
        } else if (rowItem[key] !== value) isDisable = true;
      });
    }
    if (isDisable) return '';
    if (this.state.selectedParentId === rowItem[this.props.selectFieldName])
      return this.setState({ selectedParentId: null, rowItem });
    return this.setState({ selectedParentId: rowItem[this.props.selectFieldName], rowItem });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchItems());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchItems());
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: filteredData, page: 0 }, () => this.doSearchItems());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchItems());
  };

  doSearchItems = () => {
    const { page, size, sort, filter = {}, totalCount, sorted, isFirstSearchDefault } = this.state;
    const { arraySort, defaultSearchParams, defaultSearchForm } = this.props;
    let filteredParams = { ...filter };
    if (defaultSearchParams) {
      filteredParams = { ...filteredParams, ...defaultSearchParams };
    }
    if (defaultSearchForm && !isFirstSearchDefault) {
      filteredParams = { ...filteredParams, ...defaultSearchForm };
    }
    this.props.searchItems(
      {
        page: page + 1,
        size,
        filter: filteredParams,
        sort: !isEmpty(sorted) && !isEmpty(arraySort) ? arraySort[sorted.sortCol][sorted.sortDir] : null,
      },
      resp => {
        const listItems = resp.success ? resp.data : [];
        this.setState({
          isSearching: false,
          filter,
          sort,
          listItems,
          totalCount: getPageTotalCount({ page, size, totalCount: totalCount || listItems.length, items: listItems }),
          isFirstSearchDefault: true,
        });
      }
    );
  };

  render() {
    const { listItems, sorted } = this.state;
    const {
      tableColumns,
      modalTitle,
      isNoNeedSelector,
      SearchForm,
      isRequired,
      toggleModal,
      t,
      isNotRenderPagination,
      isBack,
      children,
      fieldDisableCheck,
      defaultSearchForm,
      fieldsEnableCheck,
      isTopChildren,
      checkDisabledFunc,
    } = this.props;
    const columns = [
      {
        name: 'action',
        label: t('label.selected'),
        render: (colName, item) => {
          let isDisable = false;
          if (fieldDisableCheck) {
            map(fieldDisableCheck, (value, key) => {
              if (isArray(value)) {
                if (value.indexOf(item[key]) > -1) isDisable = true;
              } else if (item[key] === value) isDisable = true;
            });
          }
          if (checkDisabledFunc) isDisable = checkDisabledFunc(item);
          if (fieldsEnableCheck) {
            map(fieldsEnableCheck, (value, key) => {
              if (isArray(value)) {
                if (value.indexOf(item[key]) === -1) isDisable = true;
              } else if (item[key] !== value) isDisable = true;
            });
          }
          return (
            <CheckField
              name="selectedParentId"
              value={item[this.props.selectFieldName]}
              checked={item[this.props.selectFieldName] === this.state.selectedParentId}
              readOnly
              disabled={isDisable}
            />
          );
        },
      },
      ...tableColumns,
    ];
    if (isNoNeedSelector) columns.splice(0, 1);
    const tableConfig = {
      columns,
      data: listItems,
      getRowClass: item => {
        return item[this.props.selectFieldName] === this.state.selectedParentId ? 'selected-row' : '';
      },
      sorted,
    };
    return (
      <Modal
        size="lg"
        className="modal-custom modal-70 bd-example-modal-lg modal-selector"
        isOpen={this.props.isOpen}
        toggle={this.onCancel}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        {isTopChildren && children ? children : ''}
        <ModalBody>
          {SearchForm && (
            <div className="col-md-12 mb-30">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <div className="repeater-file">
                    <div data-repeater-list="group-a">
                      <SearchForm
                        onSubmit={this.onSubmitSearch}
                        defaultFilter={defaultSearchForm || null}
                        isSubmitting={this.state.isSearching}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isTopChildren && children ? children : ''}
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <div className="card-body">
                <DataTable
                  {...tableConfig}
                  onClickRow={this.onClickRow}
                  nullIsLoading={false}
                  isLoading={this.state.isSearching}
                  onSort={this.onSortColumn}
                  fixedHeader
                  tableClass="card-table-body"
                />
              </div>
            </div>
          </div>
          {!isNotRenderPagination && (
            <div className="group-pagination-selector">
              <TablePagination
                pageNumber={this.state.page}
                pageSize={this.state.size}
                totalCount={this.state.totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          )}
          {!isNoNeedSelector && (
            <ModalFooter className="text-right">
              <button type="button" className="btn btn-secondary" onClick={this.onCancel}>
                {t('label.cancel')}
              </button>
              {!isRequired && (
                <button type="button" className="btn btn-primary" onClick={this.onUnselectParent}>
                  {t('label.unselect')}
                </button>
              )}
              <button
                type="button"
                // disabled={!this.state.selectedParentId}
                className="btn btn-success"
                onClick={this.onSelectParent}
              >
                {t('label.select')}
              </button>
            </ModalFooter>
          )}
          {isBack && (
            <ModalFooter className="text-right">
              <button type="button" className="button x-small" onClick={this.onCancel}>
                {t('label.back')}
              </button>
            </ModalFooter>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ModalSelector.propTypes = {
  selectFieldName: PropTypes.string,
  isOpen: PropTypes.bool,
  isRequired: PropTypes.bool,
  isMultiple: PropTypes.bool,
  selectedParentId: PropTypes.any,
  tableColumns: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchItems: PropTypes.func.isRequired,
  SearchForm: PropTypes.any,
  defaultSearchParams: PropTypes.any,
};
ModalSelector.defaultProps = {
  selectFieldName: 'id',
  isRequired: true,
  isMultiple: false,
};

export default withTranslation('common')(ModalSelector);
