import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { sortBy, isEmpty, uniqBy } from 'lodash';
import { FormWithTableItem, DataTable, GenericInput } from '../../common';
import RouteNames from '../../../containers/App/RouteNames';
import { orderEnum } from '../../../constantsApp';
import ModalProvisioningData from '../../common/ModalProvisioningData';

const inCompleteOrderColumns = [
  {
    name: 'id',
    label: 'common:label.id',
    sortable: true,
    render: (colName, item) => (
      <Link className="text-success" to={RouteNames.prettifyPath(RouteNames.orderDetail.path, item.id)}>
        {item.id}
      </Link>
    ),
  },
  {
    name: 'type',
    label: 'common:label.type',
    // sortable: true,
    style: { minWidth: '90px' },
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:types')().find(val => val.value === item.type) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'accountId',
    label: 'common:label.accountId',
  },
  {
    name: 'userId',
    label: 'common:label.userId',
    // sortable: true,
  },
  {
    name: 'status',
    label: 'common:label.status',
    // sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusOrder')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'reason',
    label: 'common:label.reason',
  },

  {
    name: 'effectiveDate',
    label: 'common:label.effectiveDate',
    // sortable: true,
    style: { minWidth: '120px' },
  },
  {
    name: 'submittedDate',
    label: 'common:label.submittedDate',
  },
];

const ServicesForm = ({
  id,
  dataFieldSubscription,
  dataSubsciption,
  // isOrtherBtn,
  dataFieldService,
  dataService,
  // isResumeBtn,
  // isCreateBtn,
  isDisableSubmit,
  modeGetSubscription,
  modeGetServiceUnit,
  getSubscription,
  getServiceUnitAc,
  searchOrderInComplete,
  modeUpdateOrder,
  modeCancelOrder,
  modeSubmitOrder,
  updateOrderCustomerStatus,
  getFlexAttributesMappingConfig,
  getCcpDateTimeConfig,
  currencyOptions,
  customerInfo,
  keyRefreshSerives,
}) => {
  const { t } = useTranslation('common');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [inCompleteData, setInCompleteData] = useState([]);
  const [isOpenModalProvisioningData, setIsOpenModalProvisioningData] = useState(false);
  const [itemProvisioningDataSelect, setItemProvisioningDataSelect] = useState(null);
  const [currentCcpDateTime, setCurrentCcpDateTime] = useState(null);
  const [executeFutureOrderNow, setExecuteFutureOrderNow] = useState(false);
  const [optionsSelectAttr, setOptionsSelectAttr] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);
  const [isDefaultDisabledCreateButton, setIsDefaultDisabledCreateButton] = useState(true);

  const doSearchOrderInComplete = useCallback(() => {
    searchOrderInComplete(
      { page: page + 1, size, filter: { accountId: id, inFlightOrder: true } },
      ({ success, data }) => {
        if (success) {
          setInCompleteData(data);
          setExecuteFutureOrderNow(false);
        }
      }
    );
  }, [id, page, searchOrderInComplete, size]);

  const doUpdateOrderStatus = (status, orderDetail, isSubmitOrder) => {
    const payload = {};
    payload.id = orderDetail.id;
    payload.status = status;
    if (isSubmitOrder && executeFutureOrderNow) payload.executeFutureOrderNow = executeFutureOrderNow;
    updateOrderCustomerStatus(payload, () => doSearchOrderInComplete());
  };

  // const onPageChange = page => {
  //   setPage(page);
  // };

  // const onSizeChange = size => {
  //   setSize(size);
  // };

  const newColumnsService = [
    ...dataFieldService,
    {
      name: 'status',
      label: 'common:label.viewProvisioningData',
      render: (colName, item) => {
        return (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              setIsOpenModalProvisioningData(true);
              setItemProvisioningDataSelect(item);
              doGetFlexAttributesMappingConfig();
            }}
          >
            {t('label.view')}
          </button>
        );
      },
    },
  ];

  const newOptionAttr = [];
  optionsSelectAttr.forEach(val => {
    if (!newOptionAttr.find(i => i.value === val.value)) {
      newOptionAttr.push(val);
    }
  });

  let currencyLabel = '';
  if (currencyOptions && currencyOptions.length && customerInfo?.currency) {
    const currencySelect = currencyOptions.find(val => val.value === customerInfo?.currency);
    currencyLabel = currencySelect ? currencySelect.normalLabel : '';
  }

  const allStatuSubScription =
    dataSubsciption && dataSubsciption.length ? dataSubsciption.map(item => item.status) : [];
  const isCreateBtn =
    isEmpty(dataSubsciption) ||
    (allStatuSubScription.indexOf('CLOSED') > -1 &&
      allStatuSubScription.indexOf('ACTIVE') === -1 &&
      allStatuSubScription.indexOf('SUSPENDED') === -1);

  const isResumeBtn = itemSelect && itemSelect.status === 'SUSPENDED' && itemSelect.status !== 'ACTIVE';
  const isOrtherBtn = itemSelect && itemSelect.status === 'ACTIVE';

  const newColumnsSubscription = [
    {
      name: 'select',
      label: 'label.select',
      render: (colName, item) => (
        <GenericInput
          type="checkbox"
          name={item.id}
          checked={itemSelect && item.id === itemSelect.id}
          value={itemSelect && item.id === itemSelect.id}
          disabled={item.status && item.status === 'CLOSED'}
          onChange={() => {
            if (itemSelect && item.id === itemSelect.id) {
              setItemSelect(null);
            } else setItemSelect(item);
          }}
        />
      ),
    },
    ...dataFieldSubscription,
  ];

  const doGetFlexAttributesMappingConfig = () => {
    if (!optionsSelectAttr || !optionsSelectAttr.length)
      getFlexAttributesMappingConfig({ objectType: 'SERVICE' }, ({ success, data }) => {
        if (success) {
          const optionsList =
            data && data.types && data.types.length
              ? data.types.map(val => ({ value: val.provAttributeType, label: val.provAttributeType }))
              : [];
          setOptionsSelectAttr(optionsList ? uniqBy(sortBy(optionsList, ['label']), 'label') : []);
        }
      });
  };

  useEffect(() => {
    getCcpDateTimeConfig('', ({ success, data }) => {
      if (success) {
        setCurrentCcpDateTime(data ? data.ccpTime : null);
      }
    });
  }, [getCcpDateTimeConfig, getServiceUnitAc, getSubscription, id, page, size]);

  useEffect(() => {
    doSearchOrderInComplete();
    getSubscription(id, () => {
      setIsDefaultDisabledCreateButton(false);
    });
    getServiceUnitAc(id);
  }, [doSearchOrderInComplete, getServiceUnitAc, getSubscription, id, keyRefreshSerives]);

  useEffect(() => {
    if (dataSubsciption && dataSubsciption.length) {
      const dataSubsciptionActive = dataSubsciption.find(it => it.status !== 'CLOSED');
      setItemSelect(dataSubsciptionActive || null);
    }
  }, [dataSubsciption]);

  return (
    <div className="col-md-12 mb-30">
      {modeGetSubscription !== 0 && (
        <FormWithTableItem
          title={t('label.subscription')}
          accountNum={id}
          key="subsciption"
          commercialName={customerInfo?.commercialName || ''}
          isCommercialName={customerInfo?.customerSegment !== 'B2C'}
          currencyLabel={currencyLabel ? `(${currencyLabel})` : ''}
        >
          <DataTable columns={newColumnsSubscription} data={dataSubsciption} />
          {!isDisableSubmit && (
            <div className="form-group col-md-12 action-buttons btn-subsciption embrix-disabled-link">
              <Link
                to={{
                  pathname: RouteNames.newOrder.path,
                  state: {
                    accountId: id,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                    accountCategory: customerInfo?.accountCategory || null,
                    billingProfiles: customerInfo?.billingProfiles ? customerInfo?.billingProfiles[0] : null,
                  },
                }}
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isCreateBtn || isDefaultDisabledCreateButton}
              >
                {t('label.create')}
              </Link>
              <Link
                to={{
                  pathname: RouteNames.modifyOrder.path,
                  state: {
                    accountId: id,
                    data: itemSelect,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                  },
                }}
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isOrtherBtn}
              >
                {t('label.modify')}
              </Link>
              <Link
                to={{
                  pathname: RouteNames.cancelOrder.path,
                  state: {
                    data: itemSelect,
                    accountId: id,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                  },
                }}
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isOrtherBtn}
              >
                {t('label.cancel')}
              </Link>
              <Link
                to={{
                  pathname: RouteNames.suspendOrder.path,
                  state: {
                    data: itemSelect,
                    accountId: id,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                  },
                }}
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isOrtherBtn}
              >
                {t('label.suspend')}
              </Link>
              <Link
                to={{
                  pathname: RouteNames.renewOrder.path,
                  state: {
                    data: itemSelect,
                    accountId: id,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                  },
                }}
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isOrtherBtn}
              >
                {t('label.renew')}
              </Link>
              <Link
                className="button button-border x-small float-right"
                disabled={isDisableSubmit || !isResumeBtn}
                to={{
                  pathname: RouteNames.resumeOrder.path,
                  state: {
                    data: itemSelect,
                    accountId: id,
                    backLink: RouteNames.prettifyPath(RouteNames.customerDetailsServices.path, id),
                  },
                }}
              >
                {t('label.resume')}
              </Link>
              <button type="button" className="button button-border x-small float-right" disabled>
                {t('label.upgrade')}
              </button>
              <button type="button" className="button button-border x-small  float-right" disabled>
                {t('label.downgrade')}
              </button>
              <span className="float-right text-order-group">
                {t('label.placeOrder')}
:
              </span>
            </div>
          )}
        </FormWithTableItem>
      )}
      {modeGetServiceUnit !== 0 && (
        <FormWithTableItem title={t('label.serviceUnits')} key="serviceUnits">
          <DataTable columns={newColumnsService} data={dataService} />
        </FormWithTableItem>
      )}
      <FormWithTableItem title={t('label.inCompleteOrders')} key="inCompleteOrders">
        <DataTable columns={inCompleteOrderColumns} data={inCompleteData} />
        <div className="form-group col-md-12 action-buttons btn-subsciption">
          <button
            type="button"
            className="button button-border x-small float-right"
            onClick={() => doUpdateOrderStatus(orderEnum.status.approved, inCompleteData[0])}
            disabled={
              !inCompleteData ||
              !inCompleteData[0] ||
              inCompleteData[0].status === 'SUBMITTED' ||
              inCompleteData[0].status === 'PROCESSING'
            }
          >
            {t('label.approveOrder')}
          </button>
          {modeCancelOrder !== 0 && (
            <button
              type="button"
              className="button button-border x-small float-right"
              disabled={
                !inCompleteData ||
                !inCompleteData[0] ||
                inCompleteData[0].status === 'SUBMITTED' ||
                inCompleteData[0].status === 'PROCESSING' ||
                modeCancelOrder === 1
              }
              onClick={() => doUpdateOrderStatus(orderEnum.status.cancelled, inCompleteData[0])}
            >
              {t('label.cancelOrder')}
            </button>
          )}
          {modeSubmitOrder !== 0 && (
            <button
              type="button"
              className="button button-border x-small float-right"
              disabled={
                !inCompleteData ||
                !inCompleteData[0] ||
                inCompleteData[0].status === 'SUBMITTED' ||
                inCompleteData[0].status === 'PROCESSING' ||
                modeSubmitOrder === 1 ||
                (inCompleteData[0].effectiveDate &&
                  currentCcpDateTime &&
                  moment(inCompleteData[0].effectiveDate).isAfter(currentCcpDateTime) &&
                  !executeFutureOrderNow)
              }
              onClick={() => doUpdateOrderStatus(orderEnum.status.submitted, inCompleteData[0], true)}
            >
              {t('label.submitOrder')}
            </button>
          )}
          {modeUpdateOrder !== 0 && (
            <button
              type="button"
              className="button button-border x-small float-right"
              disabled={
                !inCompleteData ||
                !inCompleteData[0] ||
                inCompleteData[0].status === 'SUBMITTED' ||
                inCompleteData[0].status === 'APPROVED' ||
                inCompleteData[0].status === 'PROCESSING' ||
                modeUpdateOrder === 1
              }
              // onClick={doUpdateOrderStatus(orderEnum.status.cancelled, inCompleteData[0], true)}
            >
              {t('label.updateOrder')}
            </button>
          )}
          <GenericInput
            type="checkbox"
            label="label.submitOrderAdvance"
            className="mt-4 pt-1 col-md-4"
            name="executeFutureOrderNow"
            checked={executeFutureOrderNow}
            value={executeFutureOrderNow}
            onChange={({ value }) => setExecuteFutureOrderNow(value)}
          />
        </div>
      </FormWithTableItem>
      <ModalProvisioningData
        isOpen={isOpenModalProvisioningData}
        optionsSelectAttr={newOptionAttr}
        // onSelect={this.onSelectItemId}
        onCancel={() => {
          setIsOpenModalProvisioningData(false);
          setItemProvisioningDataSelect(null);
        }}
        defaultSearchParams={{
          serviceType: itemProvisioningDataSelect ? itemProvisioningDataSelect.type : null,
          accountId: id,
        }}
        // selectedParentId={itemSelect && itemSelect.itemId ? itemSelect.itemId : ''}
      />
    </div>
  );
};

ServicesForm.propTypes = {
  id: PropTypes.string,
  dataFieldSubscription: PropTypes.arrayOf(PropTypes.any),
  dataSubsciption: PropTypes.arrayOf(PropTypes.any),
  isOrtherBtn: PropTypes.bool,
  isResumeBtn: PropTypes.bool,
  isCreateBtn: PropTypes.bool,
  isDisableSubmit: PropTypes.bool,
  dataFieldService: PropTypes.arrayOf(PropTypes.any),
  dataService: PropTypes.arrayOf(PropTypes.any),
};

ServicesForm.defaultProps = {
  id: '',
  dataFieldSubscription: [],
  dataSubsciption: [],
  isOrtherBtn: false,
  isResumeBtn: false,
  isCreateBtn: false,
  isDisableSubmit: false,
  dataFieldService: [],
  dataService: [],
};

export default ServicesForm;
