import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { queryRequest } from '../../utils/request';
import {
  getNewCustomerReport,
  getInvoiceReport,
  getPaymentsReport,
  getCustomerBillReport,
  getAgeingReport,
  getAccountClosureReport,
  getOrderReport,
  getBillProductSummaryReport,
  getBilledTransactionSummaryReport,
  getJobScheduleReport,
  getFailedJobsReport,
  getTrialSubscriptionReport,
  getSubscriptionReport,
  getCollection0To30DaysReport,
  getCollection30To60DaysReport,
  getCollection60To90DaysReport,
  getCollectionMoreThan90DaysReport,
  getFailedPaymentsReport,
  getPaymentSuspenseReport,
  getArOpsReport,
  getDisputeReport,
  getWriteoffReport,
  getUnbilledTransactionReport,
  getBillInvoiceReconcileReport,
  getInvoicePaymentReconcileReport,
  getUsageSummaryReport,
  getUsageDetailReport,
  getUsageReconciliationReport,
  getRevenueSummaryReport,
  getRevenueRecognitionReport,
  getActiveSubscriptionReport,
  getJobDetailReport,
  getTaxSummaryReport,
  getTaxDetailReport,
  getSubscriptionContractExpiryReport,
  getTrialToPaidSubscriptionReport,
  getRevenueForecastReport,
  getMRRForecastReport,
  getVolumeAmountByUsageTypeReport,
  getVolumeAmountOperatorReport,
  getExcessVoiceTrafficReport,
  getVoiceTrafficByLdDistinationReport,
  getDataServiceReportData,
  getRawInterconnectSmsReportData,
  getNewSmsReportData,
  getRawInterconnectVoiceReportData,
  getCreditNoteReport,
  getVoiceCallTypeSummaryReport,
  getInternetOperatorDataCostReport,
  getTelecomSummaryReport,
  searchAccountReport,
  searchMediationFilesStats,
  getUsageTransactionDetailReport,
  getBillableServicesReport,
  getAccountingReconciliationReport,
  getRawCdrData,
  getRawSmsData,
  getNonBroadSoftRawCdrData,
  getAssetsForecastReport,
  employeeCostingPNLReport,
  getBillableServicesForecastReport,
  getObjectFilesReport,
  getProvisioningDataReport,
  billingTrackerReport,
  employeeCostingYearlyPNLReport,
  getCommitmentReport,
  getPaymentAgeingReport,
  getFileProcessingStats,
  searchServiceCancellationExtract,
  searchFolioDataExtract,
  getBroadsoftZeroDurationCdrsReport,
  getNonBroadsoftZeroDurationCdrsReport,
  searchDetailRecords,
  getBIExtract,
  getRegR00105Report,
  getRegR00106Report,
  getRegR00501Report,
  getRegR00502Report,
  getRegR00503Report,
  getRegR00504Report,
  getRegR00505Report,
  getRegR00506Report,
  getRegR00401Report,
  getRegR00405Report,
  getCreditBureauReport,
  getDataServiceReportDetails,
  pushKPIExtract,
  getTaxExemptionReport,
  getFonatelReport,
  getPhcAssetReport,
  getServiceReconReport,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';

import i18n from '../../i18n';

export function* getReportNewCustomerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNewCustomerReport(payload));
    if (response.getNewCustomerReport && response.getNewCustomerReport) {
      yield put(actions.getReportNewCustomerSuccess(response.getNewCustomerReport));
      if (cb) yield cb({ success: true, data: response.getNewCustomerReport });
    } else {
      yield put(actions.getReportNewCustomerSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getReportNewCustomerSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllListNewCustomerSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNewCustomerReport(payload));
    if (response.getNewCustomerReport && response.getNewCustomerReport) {
      yield put(actions.getAllListNewCustomerSuccess(response.getNewCustomerReport));
      if (cb) yield cb({ success: true, data: response.getNewCustomerReport });
    } else {
      yield put(actions.getAllListNewCustomerSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllListNewCustomerSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getInvoicingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceReport(payload));
    if (response.getInvoiceReport && response.getInvoiceReport) {
      yield put(actions.getInvoicingSuccess(response.getInvoiceReport));
      if (cb) yield cb({ success: true, data: response.getInvoiceReport });
    } else {
      yield put(actions.getInvoicingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getInvoicingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllInvoicingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceReport(payload));
    if (response.getInvoiceReport && response.getInvoiceReport) {
      yield put(actions.getAllInvoicingSuccess(response.getInvoiceReport));
      if (cb) yield cb({ success: true, data: response.getInvoiceReport });
    } else {
      yield put(actions.getAllInvoicingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllInvoicingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentsReport(payload));
    if (response.getPaymentsReport && response.getPaymentsReport) {
      yield put(actions.getListPaymentSuccess(response.getPaymentsReport));
      if (cb) yield cb({ success: true, data: response.getPaymentsReport });
    } else {
      yield put(actions.getListPaymentSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListPaymentSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentsReport(payload));
    if (response.getPaymentsReport && response.getPaymentsReport) {
      yield put(actions.getListAllPaymentSuccess(response.getPaymentsReport));
      if (cb) yield cb({ success: true, data: response.getPaymentsReport });
    } else {
      yield put(actions.getListAllPaymentSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllPaymentSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListBillingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCustomerBillReport(payload));
    if (response.getCustomerBillReport && response.getCustomerBillReport) {
      yield put(actions.getBillingSuccess(response.getCustomerBillReport));
      if (cb) yield cb({ success: true, data: response.getCustomerBillReport });
    } else {
      yield put(actions.getBillingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllBillingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCustomerBillReport(payload));
    if (response.getCustomerBillReport && response.getCustomerBillReport) {
      yield put(actions.getListAllBillingSuccess(response.getCustomerBillReport));
      if (cb) yield cb({ success: true, data: response.getCustomerBillReport });
    } else {
      yield put(actions.getListAllBillingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllBillingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAgeingSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAgeingReport(payload));
    if (response.getAgeingReport && response.getAgeingReport) {
      yield put(actions.getAgeingSummarySuccess(response.getAgeingReport));
      if (cb) yield cb({ success: true, data: response.getAgeingReport });
    } else {
      yield put(actions.getAgeingSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAgeingSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllAgeingSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAgeingReport(payload));
    if (response.getAgeingReport && response.getAgeingReport) {
      yield put(actions.getListAllAgeingSummarySuccess(response.getAgeingReport));
      if (cb) yield cb({ success: true, data: response.getAgeingReport });
    } else {
      yield put(actions.getListAllAgeingSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllAgeingSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAccountClosureSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountClosureReport(payload));
    if (response.getAccountClosureReport && response.getAccountClosureReport) {
      yield put(actions.getAccountClosureSuccess(response.getAccountClosureReport));
      if (cb) yield cb({ success: true, data: response.getAccountClosureReport });
    } else {
      yield put(actions.getAccountClosureSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAccountClosureSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllAccountClosureSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountClosureReport(payload));
    if (response.getAccountClosureReport && response.getAccountClosureReport) {
      yield put(actions.getAllAccountClosureSuccess(response.getAccountClosureReport));
      if (cb) yield cb({ success: true, data: response.getAccountClosureReport });
    } else {
      yield put(actions.getAllAccountClosureSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllAccountClosureSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOrderReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderReport(payload));
    if (response.getOrderReport && response.getOrderReport) {
      yield put(actions.getOrderReportSuccess(response.getOrderReport));
      if (cb) yield cb({ success: true, data: response.getOrderReport });
    } else {
      yield put(actions.getOrderReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOrderReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllOrderReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderReport(payload));
    if (response.getOrderReport && response.getOrderReport) {
      yield put(actions.getAllOrderReportSuccess(response.getOrderReport));
      if (cb) yield cb({ success: true, data: response.getOrderReport });
    } else {
      yield put(actions.getAllOrderReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllOrderReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillProductSummaryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillProductSummaryReport(payload));
    if (response.getBillProductSummaryReport && response.getBillProductSummaryReport) {
      yield put(actions.getBillProductSummarySuccess(response.getBillProductSummaryReport));
      if (cb) yield cb({ success: true, data: response.getBillProductSummaryReport });
    } else {
      yield put(actions.getBillProductSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillProductSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllBillProductSummaryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillProductSummaryReport(payload));
    if (response.getBillProductSummaryReport && response.getBillProductSummaryReport) {
      yield put(actions.getAllBillProductSummarySuccess(response.getBillProductSummaryReport));
      if (cb) yield cb({ success: true, data: response.getBillProductSummaryReport });
    } else {
      yield put(actions.getAllBillProductSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBillProductSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillTransactionSummaryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBilledTransactionSummaryReport(payload));
    if (response.getBilledTransactionSummaryReport && response.getBilledTransactionSummaryReport) {
      yield put(actions.getBillTransactionSummarySuccess(response.getBilledTransactionSummaryReport));
      if (cb) yield cb({ success: true, data: response.getBilledTransactionSummaryReport });
    } else {
      yield put(actions.getBillTransactionSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillTransactionSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllBillTransactionSummaryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBilledTransactionSummaryReport(payload));
    if (response.getBilledTransactionSummaryReport && response.getBilledTransactionSummaryReport) {
      yield put(actions.getAllBillTransactionSummarySuccess(response.getBilledTransactionSummaryReport));
      if (cb) yield cb({ success: true, data: response.getBilledTransactionSummaryReport });
    } else {
      yield put(actions.getAllBillTransactionSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBillTransactionSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getJobScheduleReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobScheduleReport(payload));
    if (response.getJobScheduleReport && response.getJobScheduleReport) {
      yield put(actions.getJobScheduleSuccess(response.getJobScheduleReport));
      if (cb) yield cb({ success: true, data: response.getJobScheduleReport });
    } else {
      yield put(actions.getJobScheduleSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getJobScheduleSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllJobScheduleReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobScheduleReport(payload));
    if (response.getJobScheduleReport && response.getJobScheduleReport) {
      yield put(actions.getAllJobScheduleSuccess(response.getJobScheduleReport));
      if (cb) yield cb({ success: true, data: response.getJobScheduleReport });
    } else {
      yield put(actions.getAllJobScheduleSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllJobScheduleSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getJobFailedReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFailedJobsReport(payload));
    if (response.getFailedJobsReport && response.getFailedJobsReport) {
      yield put(actions.getJobFailedSuccess(response.getFailedJobsReport));
      if (cb) yield cb({ success: true, data: response.getFailedJobsReport });
    } else {
      yield put(actions.getJobFailedSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getJobFailedSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllJobFailedReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFailedJobsReport(payload));
    if (response.getFailedJobsReport && response.getFailedJobsReport) {
      yield put(actions.getAllJobFailedSuccess(response.getFailedJobsReport));
      if (cb) yield cb({ success: true, data: response.getFailedJobsReport });
    } else {
      yield put(actions.getAllJobFailedSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllJobFailedSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getTrialSubscriptionReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTrialSubscriptionReport(payload));
    if (response.getTrialSubscriptionReport && response.getTrialSubscriptionReport) {
      yield put(actions.getTrialSubscriptionSuccess(response.getTrialSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getTrialSubscriptionReport });
    } else {
      yield put(actions.getTrialSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTrialSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllTrialSubscriptionReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTrialSubscriptionReport(payload));
    if (response.getTrialSubscriptionReport && response.getTrialSubscriptionReport) {
      yield put(actions.getAllTrialSubscriptionSuccess(response.getTrialSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getTrialSubscriptionReport });
    } else {
      yield put(actions.getAllTrialSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllTrialSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getSubscriptionExpiryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionReport(payload));
    if (response.getSubscriptionReport && response.getSubscriptionReport) {
      yield put(actions.getSubscriptionExpirySuccess(response.getSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getSubscriptionReport });
    } else {
      yield put(actions.getSubscriptionExpirySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getSubscriptionExpirySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllSubscriptionExpiryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionReport(payload));
    if (response.getSubscriptionReport && response.getSubscriptionReport) {
      yield put(actions.getAllSubscriptionExpirySuccess(response.getSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getSubscriptionReport });
    } else {
      yield put(actions.getAllSubscriptionExpirySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllSubscriptionExpirySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCollection030ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection0To30DaysReport(payload));
    if (response.getCollection0To30DaysReport && response.getCollection0To30DaysReport) {
      yield put(actions.getCollection030Success(response.getCollection0To30DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection0To30DaysReport });
    } else {
      yield put(actions.getCollection030Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCollection030Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllCollection030ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection0To30DaysReport(payload));
    if (response.getCollection0To30DaysReport && response.getCollection0To30DaysReport) {
      yield put(actions.getAllCollection030Success(response.getCollection0To30DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection0To30DaysReport });
    } else {
      yield put(actions.getAllCollection030Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCollection030Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCollection3060ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection30To60DaysReport(payload));
    if (response.getCollection30To60DaysReport && response.getCollection30To60DaysReport) {
      yield put(actions.getCollection3060Success(response.getCollection30To60DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection30To60DaysReport });
    } else {
      yield put(actions.getCollection3060Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCollection3060Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllCollection3060ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection30To60DaysReport(payload));
    if (response.getCollection30To60DaysReport && response.getCollection30To60DaysReport) {
      yield put(actions.getAllCollection3060Success(response.getCollection30To60DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection30To60DaysReport });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCollection3060Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCollection6090ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection60To90DaysReport(payload));
    if (response.getCollection60To90DaysReport && response.getCollection60To90DaysReport) {
      yield put(actions.getCollection6090Success(response.getCollection60To90DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection60To90DaysReport });
    } else {
      yield put(actions.getCollection6090Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCollection6090Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllCollection6090ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollection60To90DaysReport(payload));
    if (response.getCollection60To90DaysReport && response.getCollection60To90DaysReport) {
      yield put(actions.getAllCollection6090Success(response.getCollection60To90DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollection60To90DaysReport });
    } else {
      yield put(actions.getAllCollection6090Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCollection6090Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCollectionMoreThan90ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollectionMoreThan90DaysReport(payload));
    if (response.getCollectionMoreThan90DaysReport && response.getCollectionMoreThan90DaysReport) {
      yield put(actions.getCollectionMoreThan90Success(response.getCollectionMoreThan90DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollectionMoreThan90DaysReport });
    } else {
      yield put(actions.getCollectionMoreThan90Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCollectionMoreThan90Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllCollectionMoreThan90ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollectionMoreThan90DaysReport(payload));
    if (response.getCollectionMoreThan90DaysReport && response.getCollectionMoreThan90DaysReport) {
      yield put(actions.getAllCollectionMoreThan90Success(response.getCollectionMoreThan90DaysReport));
      if (cb) yield cb({ success: true, data: response.getCollectionMoreThan90DaysReport });
    } else {
      yield put(actions.getAllCollectionMoreThan90Success([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCollectionMoreThan90Success([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getFailedPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFailedPaymentsReport(payload));
    if (response.getFailedPaymentsReport && response.getFailedPaymentsReport) {
      yield put(actions.getFailedPaymentSuccess(response.getFailedPaymentsReport));
      if (cb) yield cb({ success: true, data: response.getFailedPaymentsReport });
    } else {
      yield put(actions.getFailedPaymentSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getFailedPaymentSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllFailedPaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFailedPaymentsReport(payload));
    if (response.getFailedPaymentsReport && response.getFailedPaymentsReport) {
      yield put(actions.getAllFailedPaymentSuccess(response.getFailedPaymentsReport));
      if (cb) yield cb({ success: true, data: response.getFailedPaymentsReport });
    } else {
      yield put(actions.getAllFailedPaymentSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllFailedPaymentSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getPaymentSuspenseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentSuspenseReport(payload));
    if (response.getPaymentSuspenseReport && response.getPaymentSuspenseReport) {
      yield put(actions.getPaymentSuspenseSuccess(response.getPaymentSuspenseReport));
      if (cb) yield cb({ success: true, data: response.getPaymentSuspenseReport });
    } else {
      yield put(actions.getPaymentSuspenseSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getPaymentSuspenseSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllPaymentSuspenseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentSuspenseReport(payload));
    if (response.getPaymentSuspenseReport && response.getPaymentSuspenseReport) {
      yield put(actions.getAllPaymentSuspenseSuccess(response.getPaymentSuspenseReport));
      if (cb) yield cb({ success: true, data: response.getPaymentSuspenseReport });
    } else {
      yield put(actions.getAllPaymentSuspenseSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllPaymentSuspenseSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAROpsDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getArOpsReport(payload));
    if (response.getArOpsReport && response.getArOpsReport) {
      yield put(actions.getAROpsDetailsSuccess(response.getArOpsReport));
      if (cb) yield cb({ success: true, data: response.getArOpsReport });
    } else {
      yield put(actions.getAROpsDetailsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAROpsDetailsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllAROpsDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getArOpsReport(payload));
    if (response.getArOpsReport && response.getArOpsReport) {
      yield put(actions.getAllAROpsDetailsSuccess(response.getArOpsReport));
      if (cb) yield cb({ success: true, data: response.getArOpsReport });
    } else {
      yield put(actions.getAllAROpsDetailsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllAROpsDetailsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOpenDisputeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDisputeReport(payload));
    if (response.getDisputeReport && response.getDisputeReport) {
      yield put(actions.getOpenDisputeSuccess(response.getDisputeReport));
      if (cb) yield cb({ success: true, data: response.getDisputeReport });
    } else {
      yield put(actions.getOpenDisputeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOpenDisputeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllOpenDisputeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDisputeReport(payload));
    if (response.getDisputeReport && response.getDisputeReport) {
      yield put(actions.getAllOpenDisputeSuccess(response.getDisputeReport));
      if (cb) yield cb({ success: true, data: response.getDisputeReport });
    } else {
      yield put(actions.getAllOpenDisputeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllOpenDisputeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getOpenWriteOffSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getWriteoffReport(payload));
    if (response.getWriteoffReport && response.getWriteoffReport) {
      yield put(actions.getOpenWriteOffSuccess(response.getWriteoffReport));
      if (cb) yield cb({ success: true, data: response.getWriteoffReport });
    } else {
      yield put(actions.getOpenWriteOffSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getOpenWriteOffSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllOpenWriteOffSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getWriteoffReport(payload));
    if (response.getWriteoffReport && response.getWriteoffReport) {
      yield put(actions.getAllOpenWriteOffSuccess(response.getWriteoffReport));
      if (cb) yield cb({ success: true, data: response.getWriteoffReport });
    } else {
      yield put(actions.getAllOpenWriteOffSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllOpenWriteOffSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getUnbilledTransactionsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUnbilledTransactionReport(payload));
    if (response.getUnbilledTransactionReport && response.getUnbilledTransactionReport) {
      yield put(actions.getUnbilledTransactionsSuccess(response.getUnbilledTransactionReport));
      if (cb) yield cb({ success: true, data: response.getUnbilledTransactionReport });
    } else {
      yield put(actions.getUnbilledTransactionsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUnbilledTransactionsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllUnbilledTransactionsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUnbilledTransactionReport(payload));
    if (response.getUnbilledTransactionReport && response.getUnbilledTransactionReport) {
      yield put(actions.getAllUnbilledTransactionsSuccess(response.getUnbilledTransactionReport));
      if (cb) yield cb({ success: true, data: response.getUnbilledTransactionReport });
    } else {
      yield put(actions.getAllUnbilledTransactionsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllUnbilledTransactionsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillingInvoicingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillInvoiceReconcileReport(payload));
    if (response.getBillInvoiceReconcileReport && response.getBillInvoiceReconcileReport) {
      yield put(actions.getListBillingInvoicingSuccess(response.getBillInvoiceReconcileReport));
      if (cb) yield cb({ success: true, data: response.getBillInvoiceReconcileReport });
    } else {
      yield put(actions.getListBillingInvoicingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListBillingInvoicingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllBillingInvoicingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillInvoiceReconcileReport(payload));
    if (response.getBillInvoiceReconcileReport && response.getBillInvoiceReconcileReport) {
      yield put(actions.getListAllBillingInvoicingSuccess(response.getBillInvoiceReconcileReport));
      if (cb) yield cb({ success: true, data: response.getBillInvoiceReconcileReport });
    } else {
      yield put(actions.getListAllBillingInvoicingSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllBillingInvoicingSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillingARSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoicePaymentReconcileReport(payload));
    if (response.getInvoicePaymentReconcileReport && response.getInvoicePaymentReconcileReport) {
      yield put(actions.getBillingARSuccess(response.getInvoicePaymentReconcileReport));
      if (cb) yield cb({ success: true, data: response.getInvoicePaymentReconcileReport });
    } else {
      yield put(actions.getBillingARSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillingARSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllBillingARSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoicePaymentReconcileReport(payload));
    if (response.getInvoicePaymentReconcileReport && response.getInvoicePaymentReconcileReport) {
      yield put(actions.getAllBillingARSuccess(response.getInvoicePaymentReconcileReport));
      if (cb) yield cb({ success: true, data: response.getInvoicePaymentReconcileReport });
    } else {
      yield put(actions.getAllBillingARSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBillingARSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getUsageSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageSummaryReport(payload));
    if (response.getUsageSummaryReport && response.getUsageSummaryReport) {
      yield put(actions.getUsageSummarySuccess(response.getUsageSummaryReport));
      if (cb) yield cb({ success: true, data: response.getUsageSummaryReport });
    } else {
      yield put(actions.getUsageSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllUsageSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageSummaryReport(payload));
    if (response.getUsageSummaryReport && response.getUsageSummaryReport) {
      yield put(actions.getAllUsageSummarySuccess(response.getUsageSummaryReport));
      if (cb) yield cb({ success: true, data: response.getUsageSummaryReport });
    } else {
      yield put(actions.getAllUsageSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllUsageSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getUsageDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageDetailReport(payload));
    if (response.getUsageDetailReport && response.getUsageDetailReport) {
      yield put(actions.getUsageDetailSuccess(response.getUsageDetailReport));
      if (cb) yield cb({ success: true, data: response.getUsageDetailReport });
    } else {
      yield put(actions.getUsageDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllUsageDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageDetailReport(payload));
    if (response.getUsageDetailReport && response.getUsageDetailReport) {
      yield put(actions.getAllUsageDetailSuccess(response.getUsageDetailReport));
      if (cb) yield cb({ success: true, data: response.getUsageDetailReport });
    } else {
      yield put(actions.getAllUsageDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllUsageDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getUsageReconciliationSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageReconciliationReport(payload));
    if (response.getUsageReconciliationReport && response.getUsageReconciliationReport) {
      yield put(actions.getUsageReconciliationSuccess(response.getUsageReconciliationReport));
      if (cb) yield cb({ success: true, data: response.getUsageReconciliationReport });
    } else {
      yield put(actions.getUsageReconciliationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageReconciliationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllUsageReconciliationSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageReconciliationReport(payload));
    if (response.getUsageReconciliationReport && response.getUsageReconciliationReport) {
      yield put(actions.getAllUsageReconciliationSuccess(response.getUsageReconciliationReport));
      if (cb) yield cb({ success: true, data: response.getUsageReconciliationReport });
    } else {
      yield put(actions.getAllUsageReconciliationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllUsageReconciliationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRevenueSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueSummaryReport(payload));
    if (response.getRevenueSummaryReport && response.getRevenueSummaryReport) {
      yield put(actions.getRevenueSummarySuccess(response.getRevenueSummaryReport));
      if (cb) yield cb({ success: true, data: response.getRevenueSummaryReport });
    } else {
      yield put(actions.getRevenueSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRevenueSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllRevenueSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueSummaryReport(payload));
    if (response.getRevenueSummaryReport && response.getRevenueSummaryReport) {
      yield put(actions.getAllRevenueSummarySuccess(response.getRevenueSummaryReport));
      if (cb) yield cb({ success: true, data: response.getRevenueSummaryReport });
    } else {
      yield put(actions.getAllRevenueSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRevenueSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRevenueRecognitionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueRecognitionReport(payload));
    if (response.getRevenueRecognitionReport && response.getRevenueRecognitionReport) {
      yield put(actions.getRevenueRecognitionSuccess(response.getRevenueRecognitionReport));
      if (cb) yield cb({ success: true, data: response.getRevenueRecognitionReport });
    } else {
      yield put(actions.getRevenueRecognitionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRevenueRecognitionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllRevenueRecognitionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueRecognitionReport(payload));
    if (response.getRevenueRecognitionReport && response.getRevenueRecognitionReport) {
      yield put(actions.getAllRevenueRecognitionSuccess(response.getRevenueRecognitionReport));
      if (cb) yield cb({ success: true, data: response.getRevenueRecognitionReport });
    } else {
      yield put(actions.getAllRevenueRecognitionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRevenueRecognitionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getActiveSubscriptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getActiveSubscriptionReport(payload));
    if (response.getActiveSubscriptionReport && response.getActiveSubscriptionReport) {
      yield put(actions.getActiveSubscriptionSuccess(response.getActiveSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getActiveSubscriptionReport });
    } else {
      yield put(actions.getActiveSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getActiveSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getListAllActiveSubscriptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getActiveSubscriptionReport(payload));
    if (response.getActiveSubscriptionReport && response.getActiveSubscriptionReport) {
      yield put(actions.getAllActiveSubscriptionSuccess(response.getActiveSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getActiveSubscriptionReport });
    } else {
      yield put(actions.getAllActiveSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllActiveSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getJobDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobDetailReport(payload));
    if (response.getJobDetailReport && response.getJobDetailReport) {
      yield put(actions.getJobDetailSuccess(response.getJobDetailReport));
      if (cb) yield cb({ success: true, data: response.getJobDetailReport });
    } else {
      yield put(actions.getJobDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getJobDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllJobDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobDetailReport(payload));
    if (response.getJobDetailReport && response.getJobDetailReport) {
      yield put(actions.getAllJobDetailSuccess(response.getJobDetailReport));
      if (cb) yield cb({ success: true, data: response.getJobDetailReport });
    } else {
      yield put(actions.getAllJobDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllJobDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getTaxSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxSummaryReport(payload));
    if (response.getTaxSummaryReport && response.getTaxSummaryReport) {
      yield put(actions.getTaxSummarySuccess(response.getTaxSummaryReport));
      if (cb) yield cb({ success: true, data: response.getTaxSummaryReport });
    } else {
      yield put(actions.getTaxSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTaxSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllTaxSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxSummaryReport(payload));
    if (response.getTaxSummaryReport && response.getTaxSummaryReport) {
      yield put(actions.getListAllTaxSummarySuccess(response.getTaxSummaryReport));
      if (cb) yield cb({ success: true, data: response.getTaxSummaryReport });
    } else {
      yield put(actions.getListAllTaxSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllTaxSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getTaxDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxDetailReport(payload));
    if (response.getTaxDetailReport && response.getTaxDetailReport) {
      yield put(actions.getTaxDetailSuccess(response.getTaxDetailReport));
      if (cb) yield cb({ success: true, data: response.getTaxDetailReport });
    } else {
      yield put(actions.getTaxDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTaxDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllTaxDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxDetailReport(payload));
    if (response.getTaxDetailReport && response.getTaxDetailReport) {
      yield put(actions.getListAllTaxDetailSuccess(response.getTaxDetailReport));
      if (cb) yield cb({ success: true, data: response.getTaxDetailReport });
    } else {
      yield put(actions.getListAllTaxDetailSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllTaxDetailSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getSubscriptionContractExpirySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionContractExpiryReport(payload));
    if (response.getSubscriptionContractExpiryReport && response.getSubscriptionContractExpiryReport) {
      yield put(actions.getSubscriptionContractExpirySuccess(response.getSubscriptionContractExpiryReport));
      if (cb) yield cb({ success: true, data: response.getSubscriptionContractExpiryReport });
    } else {
      yield put(actions.getSubscriptionContractExpirySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getSubscriptionContractExpirySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllSubscriptionContractExpirySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionContractExpiryReport(payload));
    if (response.getSubscriptionContractExpiryReport && response.getSubscriptionContractExpiryReport) {
      yield put(actions.getListAllSubscriptionContractExpirySuccess(response.getSubscriptionContractExpiryReport));
      if (cb) yield cb({ success: true, data: response.getSubscriptionContractExpiryReport });
    } else {
      yield put(actions.getListAllSubscriptionContractExpirySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllSubscriptionContractExpirySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getTrialToPaidSubscriptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTrialToPaidSubscriptionReport(payload));
    if (response.getTrialToPaidSubscriptionReport && response.getTrialToPaidSubscriptionReport) {
      yield put(actions.getTrialToPaidSubscriptionSuccess(response.getTrialToPaidSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getTrialToPaidSubscriptionReport });
    } else {
      yield put(actions.getTrialToPaidSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTrialToPaidSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllTrialToPaidSubscriptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTrialToPaidSubscriptionReport(payload));
    if (response.getTrialToPaidSubscriptionReport && response.getTrialToPaidSubscriptionReport) {
      yield put(actions.getListAllTrialToPaidSubscriptionSuccess(response.getTrialToPaidSubscriptionReport));
      if (cb) yield cb({ success: true, data: response.getTrialToPaidSubscriptionReport });
    } else {
      yield put(actions.getListAllTrialToPaidSubscriptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllTrialToPaidSubscriptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRevenueForecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueForecastReport(payload));
    if (response.getRevenueForecastReport && response.getRevenueForecastReport) {
      yield put(actions.getRevenueForecastSuccess(response.getRevenueForecastReport));
      if (cb) yield cb({ success: true, data: response.getRevenueForecastReport });
    } else {
      yield put(actions.getRevenueForecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRevenueForecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRevenueForecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueForecastReport(payload));
    if (response.getRevenueForecastReport && response.getRevenueForecastReport) {
      yield put(actions.getListAllRevenueForecastSuccess(response.getRevenueForecastReport));
      if (cb) yield cb({ success: true, data: response.getRevenueForecastReport });
    } else {
      yield put(actions.getListAllRevenueForecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllRevenueForecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getMRRForecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getMRRForecastReport(payload));
    if (response.getMRRForecastReport && response.getMRRForecastReport) {
      yield put(actions.getMRRForecastSuccess(response.getMRRForecastReport));
      if (cb) yield cb({ success: true, data: response.getMRRForecastReport });
    } else {
      yield put(actions.getMRRForecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getMRRForecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllMRRForecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getMRRForecastReport(payload));
    if (response.getMRRForecastReport && response.getMRRForecastReport) {
      yield put(actions.getListAllMRRForecastSuccess(response.getMRRForecastReport));
      if (cb) yield cb({ success: true, data: response.getMRRForecastReport });
    } else {
      yield put(actions.getListAllMRRForecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllMRRForecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getVolumeAmountByUsageTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVolumeAmountByUsageTypeReport(payload));
    if (response.getVolumeAmountByUsageTypeReport && response.getVolumeAmountByUsageTypeReport) {
      yield put(actions.getVolumeAmountByUsageTypeSuccess(response.getVolumeAmountByUsageTypeReport));
      if (cb) yield cb({ success: true, data: response.getVolumeAmountByUsageTypeReport });
    } else {
      yield put(actions.getVolumeAmountByUsageTypeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getVolumeAmountByUsageTypeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllVolumeAmountByUsageTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVolumeAmountByUsageTypeReport(payload));
    if (response.getVolumeAmountByUsageTypeReport && response.getVolumeAmountByUsageTypeReport) {
      yield put(actions.getListAllVolumeAmountByUsageTypeSuccess(response.getVolumeAmountByUsageTypeReport));
      if (cb) yield cb({ success: true, data: response.getVolumeAmountByUsageTypeReport });
    } else {
      yield put(actions.getListAllVolumeAmountByUsageTypeSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllVolumeAmountByUsageTypeSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getVolumeAmountOperatorSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVolumeAmountOperatorReport(payload));
    if (response.getVolumeAmountOperatorReport && response.getVolumeAmountOperatorReport) {
      yield put(actions.getVolumeAmountOperatorSuccess(response.getVolumeAmountOperatorReport));
      if (cb) yield cb({ success: true, data: response.getVolumeAmountOperatorReport });
    } else {
      yield put(actions.getVolumeAmountOperatorSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getVolumeAmountOperatorSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllVolumeAmountOperatorSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVolumeAmountOperatorReport(payload));
    if (response.getVolumeAmountOperatorReport && response.getVolumeAmountOperatorReport) {
      yield put(actions.getListAllVolumeAmountOperatorSuccess(response.getVolumeAmountOperatorReport));
      if (cb) yield cb({ success: true, data: response.getVolumeAmountOperatorReport });
    } else {
      yield put(actions.getListAllVolumeAmountOperatorSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllVolumeAmountOperatorSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getExcessVoiceTrafficSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExcessVoiceTrafficReport(payload));
    if (response.getExcessVoiceTrafficReport && response.getExcessVoiceTrafficReport) {
      yield put(actions.getExcessVoiceTrafficSuccess(response.getExcessVoiceTrafficReport));
      if (cb) yield cb({ success: true, data: response.getExcessVoiceTrafficReport });
    } else {
      yield put(actions.getExcessVoiceTrafficSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getExcessVoiceTrafficSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllExcessVoiceTrafficSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExcessVoiceTrafficReport(payload));
    if (response.getExcessVoiceTrafficReport && response.getExcessVoiceTrafficReport) {
      yield put(actions.getListAllExcessVoiceTrafficSuccess(response.getExcessVoiceTrafficReport));
      if (cb) yield cb({ success: true, data: response.getExcessVoiceTrafficReport });
    } else {
      yield put(actions.getListAllExcessVoiceTrafficSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllExcessVoiceTrafficSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getVoiceTrafficByLdDistinationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVoiceTrafficByLdDistinationReport(payload));
    if (response.getVoiceTrafficByLdDistinationReport && response.getVoiceTrafficByLdDistinationReport) {
      yield put(actions.getVoiceTrafficByLdDistinationSuccess(response.getVoiceTrafficByLdDistinationReport));
      if (cb) yield cb({ success: true, data: response.getVoiceTrafficByLdDistinationReport });
    } else {
      yield put(actions.getVoiceTrafficByLdDistinationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getVoiceTrafficByLdDistinationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllVoiceTrafficByLdDistinationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVoiceTrafficByLdDistinationReport(payload));
    if (response.getVoiceTrafficByLdDistinationReport && response.getVoiceTrafficByLdDistinationReport) {
      yield put(actions.getListAllVoiceTrafficByLdDistinationSuccess(response.getVoiceTrafficByLdDistinationReport));
      if (cb) yield cb({ success: true, data: response.getVoiceTrafficByLdDistinationReport });
    } else {
      yield put(actions.getListAllVoiceTrafficByLdDistinationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllVoiceTrafficByLdDistinationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getDataServiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportData(payload));
    if (response.getDataServiceReportData && response.getDataServiceReportData) {
      yield put(actions.getDataServiceReportDataSuccess(response.getDataServiceReportData));
      if (cb) yield cb({ success: true, data: response.getDataServiceReportData });
    } else {
      yield put(actions.getDataServiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getDataServiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllDataServiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportData(payload));
    if (response.getDataServiceReportData && response.getDataServiceReportData) {
      yield put(actions.getListAllDataServiceReportDataSuccess(response.getDataServiceReportData));
      if (cb) yield cb({ success: true, data: response.getDataServiceReportData });
    } else {
      yield put(actions.getListAllDataServiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllDataServiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawInterconnectSmsReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawInterconnectSmsReportData(payload));
    if (response.getRawInterconnectSmsReportData && response.getRawInterconnectSmsReportData) {
      yield put(actions.getRawInterconnectSmsReportDataSuccess(response.getRawInterconnectSmsReportData));
      if (cb) yield cb({ success: true, data: response.getRawInterconnectSmsReportData });
    } else {
      yield put(actions.getRawInterconnectSmsReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawInterconnectSmsReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawInterconnectSmsReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawInterconnectSmsReportData(payload));
    if (response.getRawInterconnectSmsReportData && response.getRawInterconnectSmsReportData) {
      yield put(actions.getListAllRawInterconnectSmsReportDataSuccess(response.getRawInterconnectSmsReportData));
      if (cb) yield cb({ success: true, data: response.getRawInterconnectSmsReportData });
    } else {
      yield put(actions.getListAllRawInterconnectSmsReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllRawInterconnectSmsReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getNewSmsReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNewSmsReportData(payload));
    if (response.getNewSmsReportData && response.getNewSmsReportData) {
      yield put(actions.getNewSmsReportDataSuccess(response.getNewSmsReportData));
      if (cb) yield cb({ success: true, data: response.getNewSmsReportData });
    } else {
      yield put(actions.getNewSmsReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getNewSmsReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllgetNewSmsReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNewSmsReportData(payload));
    if (response.getNewSmsReportData && response.getNewSmsReportData) {
      yield put(actions.getListAllNewSmsReportDataSuccess(response.getNewSmsReportData));
      if (cb) yield cb({ success: true, data: response.getNewSmsReportData });
    } else {
      yield put(actions.getListAllNewSmsReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllNewSmsReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawInterconnectVoiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawInterconnectVoiceReportData(payload));
    if (response.getRawInterconnectVoiceReportData && response.getRawInterconnectVoiceReportData) {
      yield put(actions.getRawInterconnectVoiceReportDataSuccess(response.getRawInterconnectVoiceReportData));
      if (cb) yield cb({ success: true, data: response.getRawInterconnectVoiceReportData });
    } else {
      yield put(actions.getRawInterconnectVoiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawInterconnectVoiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllgetRawInterconnectVoiceReportDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawInterconnectVoiceReportData(payload));
    if (response.getRawInterconnectVoiceReportData && response.getRawInterconnectVoiceReportData) {
      yield put(actions.getListAllRawInterconnectVoiceReportDataSuccess(response.getRawInterconnectVoiceReportData));
      if (cb) yield cb({ success: true, data: response.getRawInterconnectVoiceReportData });
    } else {
      yield put(actions.getListAllRawInterconnectVoiceReportDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllRawInterconnectVoiceReportDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCreditNoteReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCreditNoteReport(payload));
    if (response.getCreditNoteReport && response.getCreditNoteReport) {
      yield put(actions.getCreditNoteSuccess(response.getCreditNoteReport));
      if (cb) yield cb({ success: true, data: response.getCreditNoteReport });
    } else {
      yield put(actions.getCreditNoteSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCreditNoteSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllCreditNoteReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCreditNoteReport(payload));
    if (response.getCreditNoteReport && response.getCreditNoteReport) {
      yield put(actions.getListAllCreditNoteSuccess(response.getCreditNoteReport));
      if (cb) yield cb({ success: true, data: response.getCreditNoteReport });
    } else {
      yield put(actions.getListAllCreditNoteSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllCreditNoteSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getVoiceCallTypeSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVoiceCallTypeSummaryReport(payload));
    if (response.getVoiceCallTypeSummaryReport && response.getVoiceCallTypeSummaryReport) {
      yield put(actions.getVoiceCallTypeSummarySuccess(response.getVoiceCallTypeSummaryReport));
      if (cb) yield cb({ success: true, data: response.getVoiceCallTypeSummaryReport });
    } else {
      yield put(actions.getVoiceCallTypeSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getVoiceCallTypeSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllVoiceCallTypeSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getVoiceCallTypeSummaryReport(payload));
    if (response.getVoiceCallTypeSummaryReport && response.getVoiceCallTypeSummaryReport) {
      yield put(actions.getListAllVoiceCallTypeSummarySuccess(response.getVoiceCallTypeSummaryReport));
      if (cb) yield cb({ success: true, data: response.getVoiceCallTypeSummaryReport });
    } else {
      yield put(actions.getListAllVoiceCallTypeSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllVoiceCallTypeSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getInternetOperatorDataCostReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInternetOperatorDataCostReport(payload));
    if (response.getInternetOperatorDataCostReport && response.getInternetOperatorDataCostReport) {
      yield put(actions.getInternetOperatorDataCostSuccess(response.getInternetOperatorDataCostReport));
      if (cb) yield cb({ success: true, data: response.getInternetOperatorDataCostReport });
    } else {
      yield put(actions.getInternetOperatorDataCostSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getInternetOperatorDataCostSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllInternetOperatorDataCostSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInternetOperatorDataCostReport(payload));
    if (response.getInternetOperatorDataCostReport && response.getInternetOperatorDataCostReport) {
      yield put(actions.getListAllInternetOperatorDataCostSuccess(response.getInternetOperatorDataCostReport));
      if (cb) yield cb({ success: true, data: response.getInternetOperatorDataCostReport });
    } else {
      yield put(actions.getListAllInternetOperatorDataCostSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllInternetOperatorDataCostSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getTelecomSummaryReportReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTelecomSummaryReport(payload));
    if (response.getTelecomSummaryReport && response.getTelecomSummaryReport) {
      yield put(actions.getTelecomSummarySuccess(response.getTelecomSummaryReport));
      if (cb) yield cb({ success: true, data: response.getTelecomSummaryReport });
    } else {
      yield put(actions.getTelecomSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTelecomSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllTelecomSummaryReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTelecomSummaryReport(payload));
    if (response.getTelecomSummaryReport && response.getTelecomSummaryReport) {
      yield put(actions.getListAllTelecomSummarySuccess(response.getTelecomSummaryReport));
      if (cb) yield cb({ success: true, data: response.getTelecomSummaryReport });
    } else {
      yield put(actions.getListAllTelecomSummarySuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getListAllTelecomSummarySuccess([]));
    if (cb) yield cb({ success: false });
  }
}

// Get Account report
export function* getReportAccountReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchAccountReport(payload));
    if (response.searchAccountReport && response.searchAccountReport) {
      yield put(actions.getReportAccountReportSuccess(response.searchAccountReport));
      if (cb) yield cb({ success: true, data: response.searchAccountReport });
    } else {
      yield put(actions.getReportAccountReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getReportAccountReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllListAccountReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchAccountReport(payload));
    if (response.searchAccountReport && response.searchAccountReport) {
      yield put(actions.getAllListAccountReportSuccess(response.searchAccountReport));
      if (cb) yield cb({ success: true, data: response.searchAccountReport });
    } else {
      yield put(actions.getAllListAccountReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllListAccountReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

// Get Raw File
export function* getRawFileReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchMediationFilesStats(payload));
    if (response.searchMediationFilesStats && response.searchMediationFilesStats) {
      yield put(actions.getRawFileSuccess(response.searchMediationFilesStats));
      if (cb) yield cb({ success: true, data: response.searchMediationFilesStats });
    } else {
      yield put(actions.getRawFileSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawFileSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawFileReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchMediationFilesStats(payload));
    if (response.searchMediationFilesStats && response.searchMediationFilesStats) {
      yield put(actions.getAllRawFileSuccess(response.searchMediationFilesStats));
      if (cb) yield cb({ success: true, data: response.searchMediationFilesStats });
    } else {
      yield put(actions.getAllRawFileSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRawFileSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getUsageTransactionDetailReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageTransactionDetailReport(payload));
    if (response.getUsageTransactionDetailReport && response.getUsageTransactionDetailReport) {
      yield put(actions.getUsageTransactionDetailReportSuccess(response.getUsageTransactionDetailReport));
      if (cb) yield cb({ success: true, data: response.getUsageTransactionDetailReport });
    } else {
      yield put(actions.getUsageTransactionDetailReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getUsageTransactionDetailReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllUsageTransactionDetailReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUsageTransactionDetailReport(payload));
    if (response.getUsageTransactionDetailReport && response.getUsageTransactionDetailReport) {
      yield put(actions.getAllUsageTransactionDetailReportSuccess(response.getUsageTransactionDetailReport));
      if (cb) yield cb({ success: true, data: response.getUsageTransactionDetailReport });
    } else {
      yield put(actions.getAllUsageTransactionDetailReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllUsageTransactionDetailReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillableServicesReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicesReport(payload));
    if (response.getBillableServicesReport && response.getBillableServicesReport) {
      yield put(actions.getBillableServicesReportSuccess(response.getBillableServicesReport));
      if (cb) yield cb({ success: true, data: response.getBillableServicesReport });
    } else {
      yield put(actions.getBillableServicesReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillableServicesReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllBillableServicesReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicesReport(payload));
    if (response.getBillableServicesReport && response.getBillableServicesReport) {
      yield put(actions.getAllBillableServicesReportSuccess(response.getBillableServicesReport));
      if (cb) yield cb({ success: true, data: response.getBillableServicesReport });
    } else {
      yield put(actions.getAllBillableServicesReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBillableServicesReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAccountingReconciliationReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountingReconciliationReport(payload));
    if (response.getAccountingReconciliationReport && response.getAccountingReconciliationReport) {
      yield put(actions.getAccountingReconciliationSuccess(response.getAccountingReconciliationReport));
      if (cb) yield cb({ success: true, data: response.getAccountingReconciliationReport });
    } else {
      yield put(actions.getAccountingReconciliationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAccountingReconciliationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllAccountingReconciliationReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountingReconciliationReport(payload));
    if (response.getAccountingReconciliationReport && response.getAccountingReconciliationReport) {
      yield put(actions.getAllAccountingReconciliationSuccess(response.getAccountingReconciliationReport));
      if (cb) yield cb({ success: true, data: response.getAccountingReconciliationReport });
    } else {
      yield put(actions.getAllAccountingReconciliationSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllAccountingReconciliationSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawCdrData(payload));
    if (response.getBroadSoftRawCdrData && response.getBroadSoftRawCdrData) {
      yield put(actions.getRawCdrDataSuccess(response.getBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getBroadSoftRawCdrData });
    } else {
      yield put(actions.getRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawCdrData(payload));
    if (response.getBroadSoftRawCdrData && response.getBroadSoftRawCdrData) {
      yield put(actions.getAllRawCdrDataSuccess(response.getBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getBroadSoftRawCdrData });
    } else {
      yield put(actions.getAllRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRawSmsDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawSmsData(payload));
    if (response.getRawSmsData && response.getRawSmsData) {
      yield put(actions.getRawSmsDataSuccess(response.getRawSmsData));
      if (cb) yield cb({ success: true, data: response.getRawSmsData });
    } else {
      yield put(actions.getRawSmsDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRawSmsDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRawSmsDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRawSmsData(payload));
    if (response.getRawSmsData && response.getRawSmsData) {
      yield put(actions.getAllRawSmsDataSuccess(response.getRawSmsData));
      if (cb) yield cb({ success: true, data: response.getRawSmsData });
    } else {
      yield put(actions.getAllRawSmsDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRawSmsDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getNonBroadSoftRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadSoftRawCdrData(payload));
    if (response.getNonBroadSoftRawCdrData && response.getNonBroadSoftRawCdrData) {
      yield put(actions.getNonBroadSoftRawCdrDataSuccess(response.getNonBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getNonBroadSoftRawCdrData });
    } else {
      yield put(actions.getNonBroadSoftRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getNonBroadSoftRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllNonBroadSoftRawCdrDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadSoftRawCdrData(payload));
    if (response.getNonBroadSoftRawCdrData && response.getNonBroadSoftRawCdrData) {
      yield put(actions.getAllNonBroadSoftRawCdrDataSuccess(response.getNonBroadSoftRawCdrData));
      if (cb) yield cb({ success: true, data: response.getNonBroadSoftRawCdrData });
    } else {
      yield put(actions.getAllNonBroadSoftRawCdrDataSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllNonBroadSoftRawCdrDataSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getServicesForcecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAssetsForecastReport(payload));
    if (response.getAssetsForecastReport && response.getAssetsForecastReport) {
      yield put(actions.getServicesForcecastSuccess(response.getAssetsForecastReport));
      if (cb) yield cb({ success: true, data: response.getAssetsForecastReport });
    } else {
      yield put(actions.getServicesForcecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getServicesForcecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllServicesForcecastSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAssetsForecastReport(payload));
    if (response.getAssetsForecastReport && response.getAssetsForecastReport) {
      yield put(actions.getAllServicesForcecastSuccess(response.getAssetsForecastReport));
      if (cb) yield cb({ success: true, data: response.getAssetsForecastReport });
    } else {
      yield put(actions.getAllServicesForcecastSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllServicesForcecastSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* employeeCostingPNLReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, employeeCostingPNLReport(payload));
    if (response.employeeCostingPNLReport && response.employeeCostingPNLReport) {
      yield put(actions.employeeCostingPNLReportSuccess(response.employeeCostingPNLReport));
      if (cb) yield cb({ success: true, data: response.employeeCostingPNLReport });
    } else {
      yield put(actions.employeeCostingPNLReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.employeeCostingPNLReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* employeeAllCostingPNLReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, employeeCostingPNLReport(payload));
    if (response.employeeCostingPNLReport && response.employeeCostingPNLReport) {
      yield put(actions.employeeAllCostingPNLReportSuccess(response.employeeCostingPNLReport));
      if (cb) yield cb({ success: true, data: response.employeeCostingPNLReport });
    } else {
      yield put(actions.employeeAllCostingPNLReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.employeeAllCostingPNLReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBillableServicesForecastReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicesForecastReport(payload));
    if (response.getBillableServicesForecastReport && response.getBillableServicesForecastReport) {
      yield put(actions.getBillableServicesForecastReportSuccess(response.getBillableServicesForecastReport));
      if (cb) yield cb({ success: true, data: response.getBillableServicesForecastReport });
    } else {
      yield put(actions.getBillableServicesForecastReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBillableServicesForecastReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllBillableServicesForecastReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicesForecastReport(payload));
    if (response.getBillableServicesForecastReport && response.getBillableServicesForecastReport) {
      yield put(actions.getAllBillableServicesForecastReportSuccess(response.getBillableServicesForecastReport));
      if (cb) yield cb({ success: true, data: response.getBillableServicesForecastReport });
    } else {
      yield put(actions.getAllBillableServicesForecastReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBillableServicesForecastReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getObjectFilesReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getObjectFilesReport(payload));
    if (response.getObjectFilesReport && response.getObjectFilesReport) {
      yield put(actions.getObjectFilesReportSuccess(response.getObjectFilesReport));
      if (cb) yield cb({ success: true, data: response.getObjectFilesReport });
    } else {
      yield put(actions.getObjectFilesReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getObjectFilesReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllObjectFilesReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getObjectFilesReport(payload));
    if (response.getObjectFilesReport && response.getObjectFilesReport) {
      yield put(actions.getAllObjectFilesReportSuccess(response.getObjectFilesReport));
      if (cb) yield cb({ success: true, data: response.getObjectFilesReport });
    } else {
      yield put(actions.getAllObjectFilesReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllObjectFilesReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getProvisioningDataReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProvisioningDataReport(payload));
    if (response.getProvisioningDataReport && response.getProvisioningDataReport) {
      yield put(actions.getProvisioningDataReportSuccess(response.getProvisioningDataReport));
      if (cb) yield cb({ success: true, data: response.getProvisioningDataReport });
    } else {
      yield put(actions.getProvisioningDataReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getProvisioningDataReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllProvisioningDataReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProvisioningDataReport(payload));
    if (response.getProvisioningDataReport && response.getProvisioningDataReport) {
      yield put(actions.getAllProvisioningDataReportSuccess(response.getProvisioningDataReport));
      if (cb) yield cb({ success: true, data: response.getProvisioningDataReport });
    } else {
      yield put(actions.getAllProvisioningDataReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllProvisioningDataReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* billingTrackerReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, billingTrackerReport(payload));
    if (response.billingTrackerReport && response.billingTrackerReport) {
      yield put(actions.billingTrackerReportSuccess(response.billingTrackerReport));
      if (cb) yield cb({ success: true, data: response.billingTrackerReport });
    } else {
      yield put(actions.billingTrackerReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.billingTrackerReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* billingAllTrackerReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, billingTrackerReport(payload));
    if (response.billingTrackerReport && response.billingTrackerReport) {
      yield put(actions.billingAllTrackerReportSuccess(response.billingTrackerReport));
      if (cb) yield cb({ success: true, data: response.billingTrackerReport });
    } else {
      yield put(actions.billingAllTrackerReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.billingAllTrackerReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* employeeCostingYearlyPNLReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, employeeCostingYearlyPNLReport(payload));
    if (response.employeeCostingYearlyPNLReport && response.employeeCostingYearlyPNLReport) {
      yield put(actions.employeeCostingYearlyPNLReportSuccess(response.employeeCostingYearlyPNLReport));
      if (cb) yield cb({ success: true, data: response.employeeCostingYearlyPNLReport });
    } else {
      yield put(actions.employeeCostingYearlyPNLReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.employeeCostingYearlyPNLReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* employeeAllCostingYearlyPNLReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, employeeCostingYearlyPNLReport(payload));
    if (response.employeeCostingYearlyPNLReport && response.employeeCostingYearlyPNLReport) {
      yield put(actions.employeeAllCostingYearlyPNLReportSuccess(response.employeeCostingYearlyPNLReport));
      if (cb) yield cb({ success: true, data: response.employeeCostingYearlyPNLReport });
    } else {
      yield put(actions.employeeAllCostingYearlyPNLReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.employeeAllCostingYearlyPNLReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCommitmentReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCommitmentReport(payload));
    if (response.getCommitmentReport && response.getCommitmentReport) {
      yield put(actions.getCommitmentReportSuccess(response.getCommitmentReport));
      if (cb) yield cb({ success: true, data: response.getCommitmentReport });
    } else {
      yield put(actions.getCommitmentReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCommitmentReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllCommitmentReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCommitmentReport(payload));
    if (response.getCommitmentReport && response.getCommitmentReport) {
      yield put(actions.getAllCommitmentReportSuccess(response.getCommitmentReport));
      if (cb) yield cb({ success: true, data: response.getCommitmentReport });
    } else {
      yield put(actions.getAllCommitmentReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCommitmentReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getPaymentAgeingReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentAgeingReport(payload));
    if (response.getPaymentAgeingReport && response.getPaymentAgeingReport) {
      yield put(actions.getPaymentAgeingReportSuccess(response.getPaymentAgeingReport));
      if (cb) yield cb({ success: true, data: response.getPaymentAgeingReport });
    } else {
      yield put(actions.getPaymentAgeingReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getPaymentAgeingReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllPaymentAgeingReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentAgeingReport(payload));
    if (response.getPaymentAgeingReport && response.getPaymentAgeingReport) {
      yield put(actions.getAllPaymentAgeingReportSuccess(response.getPaymentAgeingReport));
      if (cb) yield cb({ success: true, data: response.getPaymentAgeingReport });
    } else {
      yield put(actions.getAllPaymentAgeingReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllPaymentAgeingReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getFileProcessingStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFileProcessingStats(payload));
    if (response.getFileProcessingStats && response.getFileProcessingStats) {
      yield put(actions.getFileProcessingStatsSuccess(response.getFileProcessingStats));
      if (cb) yield cb({ success: true, data: response.getFileProcessingStats });
    } else {
      yield put(actions.getFileProcessingStatsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getFileProcessingStatsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllFileProcessingStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFileProcessingStats(payload));
    if (response.getFileProcessingStats && response.getFileProcessingStats) {
      yield put(actions.getAllFileProcessingStatsSuccess(response.getFileProcessingStats));
      if (cb) yield cb({ success: true, data: response.getFileProcessingStats });
    } else {
      yield put(actions.getAllFileProcessingStatsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllFileProcessingStatsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchServiceCancellationExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchServiceCancellationExtract(payload));
    if (response.searchServiceCancellationExtract && response.searchServiceCancellationExtract) {
      yield put(actions.searchServiceCancellationExtractSuccess(response.searchServiceCancellationExtract));
      if (cb) yield cb({ success: true, data: response.searchServiceCancellationExtract });
    } else {
      yield put(actions.searchServiceCancellationExtractSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchServiceCancellationExtractSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchAllServiceCancellationExtractStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchServiceCancellationExtract(payload));
    if (response.searchServiceCancellationExtract && response.searchServiceCancellationExtract) {
      yield put(actions.searchAllServiceCancellationExtractStatsSuccess(response.searchServiceCancellationExtract));
      if (cb) yield cb({ success: true, data: response.searchServiceCancellationExtract });
    } else {
      yield put(actions.searchAllServiceCancellationExtractStatsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllServiceCancellationExtractStatsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchFolioDataExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchFolioDataExtract(payload));
    if (response.searchFolioDataExtract && response.searchFolioDataExtract) {
      yield put(actions.searchFolioDataExtractSuccess(response.searchFolioDataExtract));
      if (cb) yield cb({ success: true, data: response.searchFolioDataExtract });
    } else {
      yield put(actions.searchFolioDataExtractSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchFolioDataExtractSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchAllFolioDataExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchFolioDataExtract(payload));
    if (response.searchFolioDataExtract && response.searchFolioDataExtract) {
      yield put(actions.searchAllFolioDataExtractSuccess(response.searchFolioDataExtract));
      if (cb) yield cb({ success: true, data: response.searchFolioDataExtract });
    } else {
      yield put(actions.searchAllFolioDataExtractSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllFolioDataExtractSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBroadsoftZeroDurationCdrsReport(payload));
    if (response.getBroadsoftZeroDurationCdrsReport && response.getBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess(response.getBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBroadsoftZeroDurationCdrsReport(payload));
    if (response.getBroadsoftZeroDurationCdrsReport && response.getBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess(response.getBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getNonBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadsoftZeroDurationCdrsReport(payload));
    if (response.getNonBroadsoftZeroDurationCdrsReport && response.getNonBroadsoftZeroDurationCdrsReport) {
      yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess(response.getNonBroadsoftZeroDurationCdrsReport));
      if (cb) yield cb({ success: true, data: response.getNonBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getNonBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllNonBroadsoftZeroDurationCdrsReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNonBroadsoftZeroDurationCdrsReport(payload));
    if (response.getNonBroadsoftZeroDurationCdrsReport && response.getNonBroadsoftZeroDurationCdrsReport) {
      yield put(
        actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess(response.getNonBroadsoftZeroDurationCdrsReport)
      );
      if (cb) yield cb({ success: true, data: response.getNonBroadsoftZeroDurationCdrsReport });
    } else {
      yield put(actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllNonBroadsoftZeroDurationCdrsReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchDetailRecordsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchDetailRecords(payload));
    if (response.searchDetailRecords && response.searchDetailRecords) {
      yield put(actions.searchDetailRecordsSuccess(response.searchDetailRecords));
      if (cb) yield cb({ success: true, data: response.searchDetailRecords });
    } else {
      yield put(actions.searchDetailRecordsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchDetailRecordsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* searchAllDetailRecordsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchDetailRecords(payload));
    if (response.searchDetailRecords && response.searchDetailRecords) {
      yield put(actions.searchAllDetailRecordsSuccess(response.searchDetailRecords));
      if (cb) yield cb({ success: true, data: response.searchDetailRecords });
    } else {
      yield put(actions.searchAllDetailRecordsSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.searchAllDetailRecordsSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getBIExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBIExtract(payload));
    if (response.getBIExtract && response.getBIExtract) {
      yield put(actions.getBIExtractSuccess(response.getBIExtract));
      if (cb) yield cb({ success: true, data: response.getBIExtract });
    } else {
      yield put(actions.getBIExtractSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getBIExtractSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllBIExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBIExtract(payload));
    if (response.getBIExtract && response.getBIExtract) {
      yield put(actions.getAllBIExtractSuccess(response.getBIExtract));
      if (cb) yield cb({ success: true, data: response.getBIExtract });
    } else {
      yield put(actions.getAllBIExtractSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllBIExtractSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00105ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00105Report(payload));
    if (response.getRegR00105Report && response.getRegR00105Report) {
      yield put(actions.getRegR00105ReportSuccess(response.getRegR00105Report));
      if (cb) yield cb({ success: true, data: response.getRegR00105Report });
    } else {
      yield put(actions.getRegR00105ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00105ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00105ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00105Report(payload));
    if (response.getRegR00105Report && response.getRegR00105Report) {
      yield put(actions.getAllRegR00105ReportSuccess(response.getRegR00105Report));
      if (cb) yield cb({ success: true, data: response.getRegR00105Report });
    } else {
      yield put(actions.getAllRegR00105ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00105ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00106ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00106Report(payload));
    if (response.getRegR00106Report && response.getRegR00106Report) {
      yield put(actions.getRegR00106ReportSuccess(response.getRegR00106Report));
      if (cb) yield cb({ success: true, data: response.getRegR00106Report });
    } else {
      yield put(actions.getRegR00106ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00106ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00106ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00106Report(payload));
    if (response.getRegR00106Report && response.getRegR00106Report) {
      yield put(actions.getAllRegR00106ReportSuccess(response.getRegR00106Report));
      if (cb) yield cb({ success: true, data: response.getRegR00106Report });
    } else {
      yield put(actions.getAllRegR00106ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00106ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00501ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00501Report(payload));
    if (response.getRegR00501Report && response.getRegR00501Report) {
      yield put(actions.getRegR00501ReportSuccess(response.getRegR00501Report));
      if (cb) yield cb({ success: true, data: response.getRegR00501Report });
    } else {
      yield put(actions.getRegR00501ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00501ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00501ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00501Report(payload));
    if (response.getRegR00501Report && response.getRegR00501Report) {
      yield put(actions.getAllRegR00501ReportSuccess(response.getRegR00501Report));
      if (cb) yield cb({ success: true, data: response.getRegR00501Report });
    } else {
      yield put(actions.getAllRegR00501ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00501ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00502ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00502Report(payload));
    if (response.getRegR00502Report && response.getRegR00502Report) {
      yield put(actions.getRegR00502ReportSuccess(response.getRegR00502Report));
      if (cb) yield cb({ success: true, data: response.getRegR00502Report });
    } else {
      yield put(actions.getRegR00502ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00502ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00502ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00502Report(payload));
    if (response.getRegR00502Report && response.getRegR00502Report) {
      yield put(actions.getAllRegR00502ReportSuccess(response.getRegR00502Report));
      if (cb) yield cb({ success: true, data: response.getRegR00502Report });
    } else {
      yield put(actions.getAllRegR00502ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00502ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00503ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00503Report(payload));
    if (response.getRegR00503Report && response.getRegR00503Report) {
      yield put(actions.getRegR00503ReportSuccess(response.getRegR00503Report));
      if (cb) yield cb({ success: true, data: response.getRegR00503Report });
    } else {
      yield put(actions.getRegR00503ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00503ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00503ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00503Report(payload));
    if (response.getRegR00503Report && response.getRegR00503Report) {
      yield put(actions.getAllRegR00503ReportSuccess(response.getRegR00503Report));
      if (cb) yield cb({ success: true, data: response.getRegR00503Report });
    } else {
      yield put(actions.getAllRegR00503ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00503ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00504ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00504Report(payload));
    if (response.getRegR00504Report && response.getRegR00504Report) {
      yield put(actions.getRegR00504ReportSuccess(response.getRegR00504Report));
      if (cb) yield cb({ success: true, data: response.getRegR00504Report });
    } else {
      yield put(actions.getRegR00504ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00504ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00504ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00504Report(payload));
    if (response.getRegR00504Report && response.getRegR00504Report) {
      yield put(actions.getAllRegR00504ReportSuccess(response.getRegR00504Report));
      if (cb) yield cb({ success: true, data: response.getRegR00504Report });
    } else {
      yield put(actions.getAllRegR00504ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00504ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00505ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00505Report(payload));
    if (response.getRegR00505Report && response.getRegR00505Report) {
      yield put(actions.getRegR00505ReportSuccess(response.getRegR00505Report));
      if (cb) yield cb({ success: true, data: response.getRegR00505Report });
    } else {
      yield put(actions.getRegR00505ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00505ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00505ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00505Report(payload));
    if (response.getRegR00505Report && response.getRegR00505Report) {
      yield put(actions.getAllRegR00505ReportSuccess(response.getRegR00505Report));
      if (cb) yield cb({ success: true, data: response.getRegR00505Report });
    } else {
      yield put(actions.getAllRegR00505ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00505ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00506ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00506Report(payload));
    if (response.getRegR00506Report && response.getRegR00506Report) {
      yield put(actions.getRegR00506ReportSuccess(response.getRegR00506Report));
      if (cb) yield cb({ success: true, data: response.getRegR00506Report });
    } else {
      yield put(actions.getRegR00506ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00506ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00506ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00506Report(payload));
    if (response.getRegR00506Report && response.getRegR00506Report) {
      yield put(actions.getAllRegR00506ReportSuccess(response.getRegR00506Report));
      if (cb) yield cb({ success: true, data: response.getRegR00506Report });
    } else {
      yield put(actions.getAllRegR00506ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00506ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00401ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00401Report(payload));
    if (response.getRegR00401Report && response.getRegR00401Report) {
      yield put(actions.getRegR00401ReportSuccess(response.getRegR00401Report));
      if (cb) yield cb({ success: true, data: response.getRegR00401Report });
    } else {
      yield put(actions.getRegR00401ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00401ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00401ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00401Report(payload));
    if (response.getRegR00401Report && response.getRegR00401Report) {
      yield put(actions.getAllRegR00401ReportSuccess(response.getRegR00401Report));
      if (cb) yield cb({ success: true, data: response.getRegR00401Report });
    } else {
      yield put(actions.getAllRegR00401ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00401ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getRegR00405ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00405Report(payload));
    if (response.getRegR00405Report && response.getRegR00405Report) {
      yield put(actions.getRegR00405ReportSuccess(response.getRegR00405Report));
      if (cb) yield cb({ success: true, data: response.getRegR00405Report });
    } else {
      yield put(actions.getRegR00405ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getRegR00405ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllRegR00405ReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegR00405Report(payload));
    if (response.getRegR00405Report && response.getRegR00405Report) {
      yield put(actions.getAllRegR00405ReportSuccess(response.getRegR00405Report));
      if (cb) yield cb({ success: true, data: response.getRegR00405Report });
    } else {
      yield put(actions.getAllRegR00405ReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllRegR00405ReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getCreditBureauReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCreditBureauReport(payload));
    if (response.getCreditBureauReport && response.getCreditBureauReport) {
      yield put(actions.getCreditBureauReportSuccess(response.getCreditBureauReport));
      if (cb) yield cb({ success: true, data: response.getCreditBureauReport });
    } else {
      yield put(actions.getCreditBureauReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCreditBureauReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllCreditBureauReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCreditBureauReport(payload));
    if (response.getCreditBureauReport && response.getCreditBureauReport) {
      yield put(actions.getAllCreditBureauReportSuccess(response.getCreditBureauReport));
      if (cb) yield cb({ success: true, data: response.getCreditBureauReport });
    } else {
      yield put(actions.getAllCreditBureauReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllCreditBureauReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getDataServiceReportDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportDetails(payload));
    if (response.getDataServiceReportDetails && response.getDataServiceReportDetails) {
      if (cb) yield cb({ success: true, data: response.getDataServiceReportDetails });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());

    if (cb) yield cb({ success: false });
  }
}

export function* getAllDataServiceReportDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDataServiceReportDetails(payload));
    if (response.getDataServiceReportDetails && response.getDataServiceReportDetails) {
      if (cb) yield cb({ success: true, data: response.getDataServiceReportDetails });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* pushKPIExtractSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, pushKPIExtract(payload));
    if (response.pushKPIExtract && response.pushKPIExtract) {
      toast.success(response.pushKPIExtract);
      if (cb) yield cb({ success: true, data: response.pushKPIExtract });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getTaxExemptionReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxExemptionReport(payload));
    if (response.getTaxExemptionReport && response.getTaxExemptionReport) {
      yield put(actions.getTaxExemptionReportSuccess(response.getTaxExemptionReport));
      if (cb) yield cb({ success: true, data: response.getTaxExemptionReport });
    } else {
      yield put(actions.getTaxExemptionReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getTaxExemptionReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllListTaxExemptionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxExemptionReport(payload));
    if (response.getTaxExemptionReport && response.getTaxExemptionReport) {
      yield put(actions.getAllListTaxExemptionSuccess(response.getTaxExemptionReport));
      if (cb) yield cb({ success: true, data: response.getTaxExemptionReport });
    } else {
      yield put(actions.getAllListTaxExemptionSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllListTaxExemptionSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getFonatelReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFonatelReport(payload));
    if (response.getFonatelReport && response.getFonatelReport) {
      yield put(actions.getFonatelReportSuccess(response.getFonatelReport));
      if (cb) yield cb({ success: true, data: response.getFonatelReport });
    } else {
      yield put(actions.getFonatelReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getFonatelReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllFonatelReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFonatelReport(payload));
    if (response.getFonatelReport && response.getFonatelReport) {
      yield put(actions.getAllFonatelReportSuccess(response.getFonatelReport));
      if (cb) yield cb({ success: true, data: response.getFonatelReport });
    } else {
      yield put(actions.getAllFonatelReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllFonatelReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getPhcAssetReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPhcAssetReport(payload));
    if (response.getPhcAssetReport && response.getPhcAssetReport) {
      yield put(actions.getPhcAssetReportSuccess(response.getPhcAssetReport));
      if (cb) yield cb({ success: true, data: response.getPhcAssetReport });
    } else {
      yield put(actions.getPhcAssetReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getPhcAssetReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllListPhcAssetReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPhcAssetReport(payload));
    if (response.getPhcAssetReport && response.getPhcAssetReport) {
      yield put(actions.getAllListPhcAssetReportSuccess(response.getPhcAssetReport));
      if (cb) yield cb({ success: true, data: response.getPhcAssetReport });
    } else {
      yield put(actions.getAllListPhcAssetReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllListPhcAssetReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getServiceReconReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getServiceReconReport(payload));
    if (response.getServiceReconReport && response.getServiceReconReport) {
      yield put(actions.getServiceReconReportSuccess(response.getServiceReconReport));
      if (cb) yield cb({ success: true, data: response.getServiceReconReport });
    } else {
      yield put(actions.getServiceReconReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getServiceReconReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export function* getAllListServiceReconReportSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getServiceReconReport(payload));
    if (response.getServiceReconReport && response.getServiceReconReport) {
      yield put(actions.getAllListServiceReconReportSuccess(response.getServiceReconReport));
      if (cb) yield cb({ success: true, data: response.getServiceReconReport });
    } else {
      yield put(actions.getAllListServiceReconReportSuccess([]));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getAllListServiceReconReportSuccess([]));
    if (cb) yield cb({ success: false });
  }
}

export default function* reportSaga() {
  yield takeLatest(types.GET_REPORT_NEW_CUSTOMER, getReportNewCustomerSaga);
  yield takeLatest(types.GET_ALL_LIST_NEW_CUSTOMER, getAllListNewCustomerSaga);
  yield takeLatest(types.GET_LIST_INVOICING, getInvoicingSaga);
  yield takeLatest(types.GET_ALL_LIST_INVOICING, getAllInvoicingSaga);
  yield takeLatest(types.GET_LIST_PAYMENT, getListPaymentSaga);
  yield takeLatest(types.GET_LIST_ALL_PAYMENT, getListAllPaymentSaga);
  yield takeLatest(types.GET_LIST_BILLING, getListBillingSaga);
  yield takeLatest(types.GET_LIST_ALL_BILLING, getListAllBillingSaga);
  yield takeLatest(types.GET_LIST_AGEING_SUMMARY, getAgeingSummarySaga);
  yield takeLatest(types.GET_LIST_ALL_AGEING_SUMMARY, getListAllAgeingSummarySaga);
  yield takeLatest(types.GET_REPORT_ACCOUNT_CLOSURE, getAccountClosureSaga);
  yield takeLatest(types.GET_ALL_LIST_ACCOUNT_CLOSURE, getListAllAccountClosureSaga);
  yield takeLatest(types.GET_LIST_ORDER, getOrderReportSaga);
  yield takeLatest(types.GET_ALL_LIST_ORDER, getListAllOrderReportSaga);
  yield takeLatest(types.GET_LIST_BILL_PRODUCT_SUMMARY, getBillProductSummaryReportSaga);
  yield takeLatest(types.GET_ALL_LIST_BILL_PRODUCT_SUMMARY, getListAllBillProductSummaryReportSaga);
  yield takeLatest(types.GET_LIST_BILL_TRANSACTION_SUMMARY, getBillTransactionSummaryReportSaga);
  yield takeLatest(types.GET_ALL_LIST_BILL_TRANSACTION_SUMMARY, getListAllBillTransactionSummaryReportSaga);
  yield takeLatest(types.GET_LIST_JOB_SCHEDULE, getJobScheduleReportSaga);
  yield takeLatest(types.GET_ALL_LIST_JOB_SCHEDULE, getListAllJobScheduleReportSaga);
  yield takeLatest(types.GET_LIST_JOB_FAILED, getJobFailedReportSaga);
  yield takeLatest(types.GET_ALL_LIST_JOB_FAILED, getListAllJobFailedReportSaga);
  yield takeLatest(types.GET_LIST_TRIAL_SUBSCRIPTION, getTrialSubscriptionReportSaga);
  yield takeLatest(types.GET_ALL_LIST_TRIAL_SUBSCRIPTION, getListAllTrialSubscriptionReportSaga);
  yield takeLatest(types.GET_LIST_SUBSCRIPTION_EXPIRY, getSubscriptionExpiryReportSaga);
  yield takeLatest(types.GET_ALL_LIST_SUBSCRIPTION_EXPIRY, getListAllSubscriptionExpiryReportSaga);
  yield takeLatest(types.GET_LIST_COLLECTION_030, getCollection030ReportSaga);
  yield takeLatest(types.GET_ALL_LIST_COLLECTION_030, getListAllCollection030ReportSaga);
  yield takeLatest(types.GET_LIST_COLLECTION_3060, getCollection3060ReportSaga);
  yield takeLatest(types.GET_ALL_LIST_COLLECTION_3060, getListAllCollection3060ReportSaga);
  yield takeLatest(types.GET_LIST_COLLECTION_6090, getCollection6090ReportSaga);
  yield takeLatest(types.GET_ALL_LIST_COLLECTION_6090, getListAllCollection6090ReportSaga);
  yield takeLatest(types.GET_LIST_COLLECTION_MORE_THAN_90, getCollectionMoreThan90ReportSaga);
  yield takeLatest(types.GET_ALL_LIST_COLLECTION_MORE_THAN_90, getListAllCollectionMoreThan90ReportSaga);
  yield takeLatest(types.GET_LIST_FAILED_PAYMENT, getFailedPaymentSaga);
  yield takeLatest(types.GET_ALL_LIST_FAILED_PAYMENT, getListAllFailedPaymentSaga);
  yield takeLatest(types.GET_LIST_PAYMENT_SUSPENSE, getPaymentSuspenseSaga);
  yield takeLatest(types.GET_ALL_LIST_PAYMENT_SUSPENSE, getListAllPaymentSuspenseSaga);
  yield takeLatest(types.GET_LIST_AR_OPS_DETAILS, getAROpsDetailsSaga);
  yield takeLatest(types.GET_ALL_LIST_AR_OPS_DETAILS, getListAllAROpsDetailsSaga);
  yield takeLatest(types.GET_LIST_OPEN_DISPUTE, getOpenDisputeSaga);
  yield takeLatest(types.GET_ALL_LIST_OPEN_DISPUTE, getListAllOpenDisputeSaga);
  yield takeLatest(types.GET_LIST_OPEN_WRITE_OFF, getOpenWriteOffSaga);
  yield takeLatest(types.GET_ALL_LIST_OPEN_WRITE_OFF, getListAllOpenWriteOffSaga);
  yield takeLatest(types.GET_LIST_BILLED_TRANSACTIONS, getUnbilledTransactionsSaga);
  yield takeLatest(types.GET_ALL_LIST_BILLED_TRANSACTIONS, getListAllUnbilledTransactionsSaga);
  yield takeLatest(types.GET_LIST_BILLING_INVOICING, getBillingInvoicingSaga);
  yield takeLatest(types.GET_ALL_LIST_BILLING_INVOICING, getListAllBillingInvoicingSaga);
  yield takeLatest(types.GET_LIST_BILLING_AR, getBillingARSaga);
  yield takeLatest(types.GET_ALL_LIST_BILLING_AR, getListAllBillingARSaga);
  yield takeLatest(types.GET_LIST_USAGE_SUMMARY, getUsageSummarySaga);
  yield takeLatest(types.GET_ALL_LIST_USAGE_SUMMARY, getListAllUsageSummarySaga);
  yield takeLatest(types.GET_LIST_USAGE_DETAIL, getUsageDetailSaga);
  yield takeLatest(types.GET_ALL_LIST_USAGE_DETAIL, getListAllUsageDetailSaga);
  yield takeLatest(types.GET_LIST_USAGE_RECONCILIATION_SUMMARY, getUsageReconciliationSummarySaga);
  yield takeLatest(types.GET_ALL_LIST_USAGE_RECONCILIATION_SUMMARY, getListAllUsageReconciliationSummarySaga);
  yield takeLatest(types.GET_LIST_REVENUE_SUMMARY, getRevenueSummarySaga);
  yield takeLatest(types.GET_ALL_LIST_REVENUE_SUMMARY, getListAllRevenueSummarySaga);
  yield takeLatest(types.GET_LIST_REVENUE_RECOGNITION, getRevenueRecognitionSaga);
  yield takeLatest(types.GET_ALL_LIST_REVENUE_RECOGNITION, getListAllRevenueRecognitionSaga);
  yield takeLatest(types.GET_LIST_ACTIVE_SUBSCRIPTION, getActiveSubscriptionSaga);
  yield takeLatest(types.GET_ALL_LIST_ACTIVE_SUBSCRIPTION, getListAllActiveSubscriptionSaga);
  yield takeLatest(types.GET_LIST_JOB_DETAIL, getJobDetailSaga);
  yield takeLatest(types.GET_ALL_LIST_JOB_DETAIL, getAllJobDetailSaga);
  yield takeLatest(types.GET_LIST_TAX_SUMMARY, getTaxSummarySaga);
  yield takeLatest(types.GET_ALL_LIST_TAX_SUMMARY, getAllTaxSummarySaga);
  yield takeLatest(types.GET_LIST_TAX_DETAIL, getTaxDetailSaga);
  yield takeLatest(types.GET_ALL_LIST_TAX_DETAIL, getAllTaxDetailSaga);
  yield takeLatest(types.GET_LIST_SUBSCRIPTION_CONTRACT_EXPIRY, getSubscriptionContractExpirySaga);
  yield takeLatest(types.GET_ALL_LIST_SUBSCRIPTION_CONTRACT_EXPIRY, getAllSubscriptionContractExpirySaga);
  yield takeLatest(types.GET_LIST_TRIAL_TO_PAID_SUBSCRIPTION, getTrialToPaidSubscriptionSaga);
  yield takeLatest(types.GET_ALL_LIST_TRIAL_TO_PAID_SUBSCRIPTION, getAllTrialToPaidSubscriptionSaga);
  yield takeLatest(types.GET_LIST_REVENUE_FORCEAST, getRevenueForecastSaga);
  yield takeLatest(types.GET_ALL_LIST_REVENUE_FORCEAST, getAllRevenueForecastSaga);
  yield takeLatest(types.GET_LIST_MMR_FORCEAST, getMRRForecastSaga);
  yield takeLatest(types.GET_ALL_LIST_MMR_FORCEAST, getAllMRRForecastSaga);
  yield takeLatest(types.GET_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE, getVolumeAmountByUsageTypeSaga);
  yield takeLatest(types.GET_ALL_LIST_VOLUME_AMOUNT_BY_USAGE_TYPE, getAllVolumeAmountByUsageTypeSaga);
  yield takeLatest(types.GET_LIST_VOLUME_AMOUNT_OPERATOR, getVolumeAmountOperatorSaga);
  yield takeLatest(types.GET_ALL_LIST_VOLUME_AMOUNT_OPERATOR, getAllVolumeAmountOperatorSaga);
  yield takeLatest(types.GET_LIST_EXCESS_VOICE_TRAFFIC, getExcessVoiceTrafficSaga);
  yield takeLatest(types.GET_ALL_LIST_EXCESS_VOICE_TRAFFIC, getAllExcessVoiceTrafficSaga);
  yield takeLatest(types.GET_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION, getVoiceTrafficByLdDistinationSaga);
  yield takeLatest(types.GET_ALL_LIST_VOICE_TRAFFIC_BY_LD_DISTINATION, getAllVoiceTrafficByLdDistinationSaga);
  yield takeLatest(types.GET_LIST_ON_DEMAND, getDataServiceReportDataSaga);
  yield takeLatest(types.GET_ALL_LIST_ON_DEMAND, getAllDataServiceReportDataSaga);
  yield takeLatest(types.GET_LIST_NEW_SMS, getNewSmsReportDataSaga);
  yield takeLatest(types.GET_ALL_LIST_NEW_SMS, getAllgetNewSmsReportDataSaga);
  yield takeLatest(types.GET_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA, getRawInterconnectVoiceReportDataSaga);
  yield takeLatest(types.GET_ALL_LIST_RAW_INTERCONNECT_VOICE_REPORT_DATA, getAllgetRawInterconnectVoiceReportDataSaga);
  yield takeLatest(types.GET_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA, getRawInterconnectSmsReportDataSaga);
  yield takeLatest(types.GET_ALL_LIST_RAW_INTERCONNECT_SMS_REPORT_DATA, getAllRawInterconnectSmsReportDataSaga);
  yield takeLatest(types.GET_LIST_CREDIT_NOTES, getCreditNoteReportSaga);
  yield takeLatest(types.GET_ALL_LIST_CREDIT_NOTES, getAllCreditNoteReportSaga);
  yield takeLatest(types.GET_LIST_VOICE_CALL_TYPE_SUMMARY, getVoiceCallTypeSummarySaga);
  yield takeLatest(types.GET_ALL_LIST_VOICE_CALL_TYPE_SUMMARY, getAllVoiceCallTypeSummarySaga);
  yield takeLatest(types.GET_LIST_INTERNET_OPERATOR_DATA_COST, getInternetOperatorDataCostReportSaga);
  yield takeLatest(types.GET_ALL_LIST_INTERNET_OPERATOR_DATA_COST, getAllInternetOperatorDataCostSaga);
  yield takeLatest(types.GET_LIST_TELECOM_SUMMARY, getTelecomSummaryReportReportSaga);
  yield takeLatest(types.GET_ALL_LIST_TELECOM_SUMMARY, getAllTelecomSummaryReportSaga);
  yield takeLatest(types.GET_LIST_ACCOUNT, getReportAccountReportSaga);
  yield takeLatest(types.GET_ALL_LIST_ACCOUNT, getAllListAccountReportSaga);
  yield takeLatest(types.GET_RAW_FILE, getRawFileReportSaga);
  yield takeLatest(types.GET_ALL_RAW_FILE, getAllRawFileReportSaga);
  yield takeLatest(types.GET_REPORT_USAGE_TRANSACTION_DETAILS, getUsageTransactionDetailReportSaga);
  yield takeLatest(types.GET_ALL_LIST_USAGE_TRANSACTION_DETAILS, getAllUsageTransactionDetailReportSaga);
  yield takeLatest(types.GET_BILLABLE_SERVICES_REPORT, getBillableServicesReportSaga);
  yield takeLatest(types.GET_ALL_BILLABLE_SERVICES_REPORT_DETAILS, getAllBillableServicesReportSaga);
  yield takeLatest(types.GET_ACCOUNTING_RECONCILIATION_REPORT, getAccountingReconciliationReportSaga);
  yield takeLatest(types.GET_ALL_ACCOUNTING_RECONCILIATION_REPORT_DETAILS, getAllAccountingReconciliationReportSaga);
  yield takeLatest(types.GET_RAW_CDR_DATA_REPORT, getRawCdrDataSaga);
  yield takeLatest(types.GET_ALL_RAW_CDR_DATA_REPORT_DETAILS, getAllRawCdrDataSaga);
  yield takeLatest(types.GET_RAW_SMS_DATA_REPORT, getRawSmsDataSaga);
  yield takeLatest(types.GET_ALL_RAW_SMS_DATA_REPORT_DETAILS, getAllRawSmsDataSaga);
  yield takeLatest(types.GET_NON_BROAD_SOFT_RAW_CDR_DATA, getNonBroadSoftRawCdrDataSaga);
  yield takeLatest(types.GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA, getAllNonBroadSoftRawCdrDataSaga);
  yield takeLatest(types.GET_SERVICES_FORCECAST, getServicesForcecastSaga);
  yield takeLatest(types.GET_ALL_SERVICES_FORCECAST, getAllServicesForcecastSaga);
  yield takeLatest(types.EMPLOYEE_COSTING_PNL, employeeCostingPNLReportSaga);
  yield takeLatest(types.ALL_EMPLOYEE_COSTING_PNL, employeeAllCostingPNLReportSaga);
  yield takeLatest(types.GET_BILLABLE_SERVICES_FORECAST_REPORT, getBillableServicesForecastReportSaga);
  yield takeLatest(types.GET_ALL_BILLABLE_SERVICES_FORECAST_REPORT, getAllBillableServicesForecastReportSaga);
  yield takeLatest(types.GET_OBJECT_FILE_REPORT, getObjectFilesReportSaga);
  yield takeLatest(types.GET_ALL_OBJECT_FILE_REPORT, getAllObjectFilesReportSaga);
  yield takeLatest(types.GET_PROVISIONING_DATA_REPORT, getProvisioningDataReportSaga);
  yield takeLatest(types.GET_ALL_PROVISIONING_DATA_REPORT, getAllProvisioningDataReportSaga);
  yield takeLatest(types.BILLING_TRACKER_REPORT, billingTrackerReportSaga);
  yield takeLatest(types.GET_ALL_BILLING_TRACKER_REPORT, billingAllTrackerReportSaga);
  yield takeLatest(types.EMPLOYEE_COSTING_YEARLY_PL, employeeCostingYearlyPNLReportSaga);
  yield takeLatest(types.ALL_EMPLOYEE_COSTING_YEARLY_PL, employeeAllCostingYearlyPNLReportSaga);
  yield takeLatest(types.GET_COMMITMENT_REPORT, getCommitmentReportSaga);
  yield takeLatest(types.GET_ALL_COMMITMENT_REPORT, getAllCommitmentReportSaga);
  yield takeLatest(types.GET_PAYMENT_AGEING_REPORT, getPaymentAgeingReportSaga);
  yield takeLatest(types.GET_ALL_PAYMENT_AGEING_REPORT, getAllPaymentAgeingReportSaga);
  yield takeLatest(types.GET_FILE_PROCESSING_STATS, getFileProcessingStatsSaga);
  yield takeLatest(types.GET_ALL_FILE_PROCESSING_STATS, getAllFileProcessingStatsSaga);
  yield takeLatest(types.SEARCH_SERVICE_CANCELLATION_EXTRACT, searchServiceCancellationExtractSaga);
  yield takeLatest(types.SEARCH_ALL_SERVICE_CANCELLATION_EXTRACT, searchAllServiceCancellationExtractStatsSaga);
  yield takeLatest(types.SEARCH_FOLIO_DATA_EXTRACT, searchFolioDataExtractSaga);
  yield takeLatest(types.SEARCH_ALL_FOLIO_DATA_EXTRACT, searchAllFolioDataExtractSaga);
  yield takeLatest(types.GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(types.GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getAllBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(types.GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT, getNonBroadsoftZeroDurationCdrsReportSaga);
  yield takeLatest(
    types.GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT,
    getAllNonBroadsoftZeroDurationCdrsReportSaga
  );
  yield takeLatest(types.SEARCH_DETAIL_RECORDS_REPORT, searchDetailRecordsSaga);
  yield takeLatest(types.SEARCH_ALL_DETAIL_RECORDS_REPORT, searchAllDetailRecordsSaga);
  yield takeLatest(types.GET_BI_EXTRACT, getBIExtractSaga);
  yield takeLatest(types.GET_ALL_BI_EXTRACT, getAllBIExtractSaga);
  yield takeLatest(types.GET_REG_R00105_REPORT, getRegR00105ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00105_REPORT, getAllRegR00105ReportSaga);
  yield takeLatest(types.GET_REG_R00106_REPORT, getRegR00106ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00106_REPORT, getAllRegR00106ReportSaga);
  yield takeLatest(types.GET_REG_R00501_REPORT, getRegR00501ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00501_REPORT, getAllRegR00501ReportSaga);
  yield takeLatest(types.GET_REG_R00502_REPORT, getRegR00502ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00502_REPORT, getAllRegR00502ReportSaga);
  yield takeLatest(types.GET_REG_R00503_REPORT, getRegR00503ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00503_REPORT, getAllRegR00503ReportSaga);
  yield takeLatest(types.GET_REG_R00504_REPORT, getRegR00504ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00504_REPORT, getAllRegR00504ReportSaga);
  yield takeLatest(types.GET_REG_R00505_REPORT, getRegR00505ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00505_REPORT, getAllRegR00505ReportSaga);
  yield takeLatest(types.GET_REG_R00506_REPORT, getRegR00506ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00506_REPORT, getAllRegR00506ReportSaga);
  yield takeLatest(types.GET_REG_R00401_REPORT, getRegR00401ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00401_REPORT, getAllRegR00401ReportSaga);
  yield takeLatest(types.GET_REG_R00405_REPORT, getRegR00405ReportSaga);
  yield takeLatest(types.GET_ALL_REG_R00405_REPORT, getAllRegR00405ReportSaga);
  yield takeLatest(types.GET_CREDIT_BUREAUR_EPORT, getCreditBureauReportSaga);
  yield takeLatest(types.GET_ALL_CREDIT_BUREAUR_EPORT, getAllCreditBureauReportSaga);
  yield takeLatest(types.GET_DATA_SERVICE_REPORT_DETAILS, getDataServiceReportDetailsSaga);
  yield takeLatest(types.GET_ALL_DATA_SERVICE_REPORT_DETAILS, getAllDataServiceReportDetailsSaga);
  yield takeLatest(types.PUSH_KPI_EXTRACT, pushKPIExtractSaga);
  yield takeLatest(types.GET_TAX_EXEMPTION_REPORT, getTaxExemptionReportSaga);
  yield takeLatest(types.GET_ALL_TAX_EXEMPTION_REPORT, getAllListTaxExemptionSaga);
  yield takeLatest(types.GET_FONATEL_REPORT, getFonatelReportSaga);
  yield takeLatest(types.GET_ALL_FONATEL_REPORT, getAllFonatelReportSaga);
  yield takeLatest(types.GET_PHC_ASSET_REPORT, getPhcAssetReportSaga);
  yield takeLatest(types.GET_ALL_PHC_ASSET_REPORT, getAllListPhcAssetReportSaga);
  yield takeLatest(types.SERVICE_RECON_REPORT, getServiceReconReportSaga);
  yield takeLatest(types.GET_ALL_SERVICE_RECON_REPORT, getAllListServiceReconReportSaga);
}
