const formatStringUrl = (...args) => {
  let i = 1;
  const str = args[0];
  return str.replace(/\:[a-zA-Z0-9]*/g, () => args[i++]); // eslint-disable-line
};

const prettifyPath = (path, ...args) => formatStringUrl(path, ...args);

export default {
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
  },

  userPreferences: {
    path: '/dashboard/user-preferences',
    name: 'userPreferences',
    exact: true,
  },

  myDashboard: {
    path: '/dashboard/me',
    name: 'myDashboard',
    exact: true,
  },

  dashboardNewCustomer: {
    path: '/dashboard/customer-new',
    name: 'dashboardNewCustomer',
    exact: true,
  },

  dashboardCustomerChurn: {
    path: '/dashboard/customer-churn',
    name: 'dashboardCustomerChurn',
    exact: true,
  },

  dashboardAccountType: {
    path: '/dashboard/account-type',
    name: 'dashboardAccountType',
    exact: true,
  },

  dashboardSellingCompany: {
    path: '/dashboard/selling-company',
    name: 'dashboardSellingCompany',
    exact: true,
  },

  acitveSubscriptions: {
    path: '/dashboard/acitve-subscriptions',
    name: 'acitveSubscriptions',
    exact: true,
  },

  trialToPaidConversion: {
    path: '/dashboard/trial-to-paid-conversion',
    name: 'trialToPaidConversion',
    exact: true,
  },

  subscriptionCancellations: {
    path: '/dashboard/subscription-cancellations',
    name: 'subscriptionCancellations',
    exact: true,
  },

  billedRevenue: {
    path: '/dashboard/billed-revenue',
    name: 'billedRevenue',
    exact: true,
  },

  billedCustomer: {
    path: '/dashboard/billed-customer',
    name: 'billedCustomer',
    exact: true,
  },

  folioSummary: {
    path: '/dashboard/folio-summary',
    name: 'folioSummary',
    exact: true,
  },

  folioCountSummary: {
    path: '/dashboard/folio-count',
    name: 'folioCountSummary',
    exact: true,
  },

  paymentSuccess: {
    path: '/dashboard/payment-success',
    name: 'paymentSuccess',
    exact: true,
  },

  paymentSuspenseDashboard: {
    path: '/dashboard/payment-suspense',
    name: 'paymentSuspenseDashboard',
    exact: true,
  },

  paymentFailed: {
    path: '/dashboard/payment-failed',
    name: 'paymentFailed',
    exact: true,
  },

  accountsWithFailedPayment: {
    path: '/dashboard/accounts-with-failed-payment',
    name: 'accountsWithFailedPayment',
    exact: true,
  },

  collection030Days: {
    path: '/dashboard/collection-0-30-days',
    name: 'collection030Days',
    exact: true,
  },

  collection3060Days: {
    path: '/dashboard/collection-30-60-days',
    name: 'collection030Days',
    exact: true,
  },

  collection6090Days: {
    path: '/dashboard/collection-60-90-days',
    name: 'collection6090Days',
    exact: true,
  },

  collectionOver90Days: {
    path: '/dashboard/collection-over-90-days',
    name: 'collectionOver90Days',
    exact: true,
  },

  totalRevenue: {
    path: '/dashboard/total-revenue',
    name: 'totalRevenue',
    exact: true,
  },

  recognizedRevenue: {
    path: '/dashboard/recongnized-revenue',
    name: 'recognizedRevenue',
    exact: true,
  },

  revenueType: {
    path: '/dashboard/revenue-type',
    name: 'revenueType',
    exact: true,
  },

  productCompany: {
    path: '/dashboard/product-company',
    name: 'productCompany',
    exact: true,
  },

  revenueForecastDashboard: {
    path: '/dashboard/forecast-revenue-details',
    name: 'revenueForecastDashboard',
    exact: true,
  },

  monthlyRecurringRevenue: {
    path: '/dashboard/monthly-recurring-revenue',
    name: 'monthlyRecurringRevenue',
    exact: true,
  },

  totalWriteOff: {
    path: '/dashboard/total-write-off',
    name: 'totalWriteOff',
    exact: true,
  },

  debitAdjustments: {
    path: '/dashboard/debit-adjustments',
    name: 'debitAdjustments',
    exact: true,
  },

  failedOrder: {
    path: '/dashboard/failed-order',
    name: 'failedOrder',
    exact: true,
  },

  submittedOrder: {
    path: '/dashboard/submitted-order',
    name: 'submittedOrder',
    exact: true,
  },

  jobsFailed: {
    path: '/dashboard/jobs-failed',
    name: 'jobsFailed',
    exact: true,
  },

  billsFailed: {
    path: '/dashboard/bills-failed',
    name: 'billsFailed',
    exact: true,
  },

  invoicesFailed: {
    path: '/dashboard/invoices-failed',
    name: 'invoicesFailed',
    exact: true,
  },

  totalJobs: {
    path: '/dashboard/total-jobs',
    name: 'totalJobs',
    exact: true,
  },

  totalCreditAdjustments: {
    path: '/dashboard/total-credit-adjustments',
    name: 'totalCreditAdjustments',
    exact: true,
  },

  totalDisputes: {
    path: '/dashboard/total-disputes',
    name: 'totalDisputes',
    exact: true,
  },

  totalSettlements: {
    path: '/dashboard/total-settlements',
    name: 'totalSettlements',
    exact: true,
  },

  usageTransaction: {
    path: '/dashboard/usage-transaction',
    name: 'usageTransaction',
    exact: true,
  },

  ratedUsageTransaction: {
    path: '/dashboard/reated-usage-transaction',
    name: 'ratedUsageTransaction',
    exact: true,
  },

  creditNoteDashboard: {
    path: '/dashboard/telecom-traffic-dashboard',
    name: 'creditNoteDashboard',
    exact: true,
  },

  // handle Dashboard end

  customers: {
    path: '/customers',
    name: 'CustomersPage',
    exact: true,
  },

  customersNew: {
    path: '/customers/create/',
    name: 'CustomerNew',
    exact: true,
  },

  customersCreate: {
    path: '/customers/create/:childRoute',
    name: 'CustomerNew',
    exact: true,
  },

  customersCreateInfo: {
    path: '/customers/create/info',
    name: 'customersCreateInfo',
    exact: true,
  },

  customerInfo: {
    path: '/customers/:id/info',
    name: 'customerInfo',
    exact: true,
  },

  customerDetails: {
    path: '/customers/:id/:childRoute',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsInfo: {
    path: '/customers/:id/info',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsContacts: {
    path: '/customers/:id/contacts',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsAddresses: {
    path: '/customers/:id/addresses',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsPaymentProfiles: {
    path: '/customers/:id/payment-profile',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBillingProfile: {
    path: '/customers/:id/bill-profile',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsServices: {
    path: '/customers/:id/services',
    name: 'CustomerDetails',
    exact: true,
  },
  subscriptionView: {
    path: '/customers/:id/subscription-view',
    name: 'subscriptionView',
    exact: true,
  },
  customerDetailsAssets: {
    path: '/customers/:id/offers',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBillableServices: {
    path: '/customers/:id/billable-services',
    name: 'customerDetailsBillableServices',
    exact: true,
  },
  customerProvisioningData: {
    path: '/customers/:id/provisioning-data',
    name: 'customerProvisioningData',
    exact: true,
  },
  customerDetailsBalances: {
    path: '/customers/:id/balances',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBills: {
    path: '/customers/:id/bills',
    name: 'CustomerDetails',
    exact: true,
  },
  ladingBill: {
    path: '/customers/:id/lading-bill',
    name: 'ladingBill',
    exact: true,
  },
  customerDetailsManualBills: {
    path: '/customers/:id/manual-billing',
    name: 'customerDetailsManualBills',
    exact: true,
  },
  customerDetailsTransactions: {
    path: '/customers/:id/transactions',
    name: 'CustomerDetails',
    exact: true,
  },
  ratedUsage: {
    path: '/customers/:id/rated-usage',
    name: 'ratedUsage',
    exact: true,
  },
  usageRecords: {
    path: '/customers/:id/usage-records',
    name: 'usageRecords',
    exact: true,
  },
  customerDetailsArActivity: {
    path: '/customers/:id/ar-activity',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsArOpsUnit: {
    path: '/customers/:id/ar-ops-unit',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsPayments: {
    path: '/customers/:id/payment-details',
    name: 'customerDetailsPayments',
    exact: true,
  },
  customerDetailsCreditNotes: {
    path: '/customers/:id/credit-notes',
    name: 'creditNotes',
    exact: true,
  },
  customerDetailsInvoicePaymentInstallment: {
    path: '/customers/:id/invoice-payment-installment',
    name: 'invoicePaymentInstallment',
    exact: true,
  },
  customerDetailsActivities: {
    path: '/customers/:id/activities',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsHierarchy: {
    path: '/customers/:id/hierarchy',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsTasks: {
    path: '/customers/:id/tasks',
    name: 'customerDetailsTasks',
    exact: true,
  },
  paymentInstallment: {
    path: '/customers/:id/payment-installment',
    name: 'CustomerDetails',
    exact: true,
  },
  customerXChangeRates: {
    path: '/customers/:id/x-change-rates',
    name: 'customerXChangeRates',
    exact: true,
  },
  customerDetailsCustomAttributes: {
    path: '/customers/:id/custom-attributes',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsTaxExemptions: {
    path: '/customers/:id/tax-exemptions',
    name: 'customerDetailsTaxExemptions',
    exact: true,
  },

  accountStatement: {
    path: '/customers/:id/account-statement',
    name: 'accountStatement',
    exact: true,
  },

  customerExternalPO: {
    path: '/customers/:id/external-po',
    name: 'customerExternalPO',
    exact: true,
  },

  customerAttribute: {
    path: '/customers/custom-attributes-config',
    name: 'CustomerAttribute',
    exact: true,
  },

  customerAttributeDetails: {
    path: '/customers/:id/custom-attributes-config',
    name: 'CustomerAttributeDetails',
    exact: true,
  },

  customerTopup: {
    path: '/customers/:id/topup',
    name: 'customerTopup',
    exact: true,
  },

  customerAttributes: {
    path: '/customers/:id/attributes',
    name: 'customerAttributes',
    exact: true,
  },

  chargeShare: {
    path: '/customers/:id/charge-share',
    name: 'chargeShare',
    exact: true,
  },

  discountShare: {
    path: '/customers/:id/discount-share',
    name: 'discountShare',
    exact: true,
  },

  customerAttributeCreate: {
    path: '/customers/create/custom-attributes-config',
    name: 'CustomerAttributeDetails',
    exact: true,
  },

  creditProfile: {
    path: '/customers/credit-profile',
    name: 'CreditProfile',
    exact: true,
  },

  merge: {
    path: '/customers/merge',
    name: 'merge',
    exact: true,
  },

  split: {
    path: '/customers/split',
    name: 'split',
    exact: true,
  },

  delayedBilling: {
    path: '/billing/delayed-billing',
    name: 'delayedBilling',
    exact: true,
  },

  inAdvanceBilling: {
    path: '/billing/advance-billing',
    name: 'inAdvanceBilling',
    exact: true,
  },

  specialPricing: {
    path: '/customers/:id/special-pricing',
    name: 'specialPricing',
    exact: true,
  },

  creditProfileDetails: {
    path: '/customers/:id/credit-profile',
    name: 'CreditProfileDetails',
    exact: true,
  },

  searchAccountInAccountDetails: {
    path: '/customers/:id/search-accounts',
    name: 'searchAccountInAccountDetails',
    exact: true,
  },

  creditProfileCreate: {
    path: '/customers/create/credit-profile',
    name: 'CreditProfileDetails',
    exact: true,
  },

  quickCreateCustomer: {
    path: '/customers/create/quick-create',
    name: 'quickCreateCustomer',
    exact: true,
  },

  invoices: {
    path: '/invoices',
    name: 'Invoices',
    exact: true,
  },
  invoicesInfo: {
    path: '/invoices/:id',
    name: 'Invoices',
    exact: true,
  },

  viewFileInfo: {
    path: '/view-file/:id',
    name: 'viewFileInfo',
    exact: true,
  },
  pricingHub: {
    path: '/pricing-management',
    name: 'PricingManagement',
    exact: false,
  },
  pricingManagement: {
    path: '/pricing-management',
    name: 'PricingItem',
    exact: true,
  },
  pricingItem: {
    path: '/pricing-management/items',
    name: 'PricingItem',
    exact: true,
  },

  productFamilyPricing: {
    path: '/pricing-management/product-family',
    name: 'productFamilyPricing',
    exact: true,
  },

  itemServices: {
    path: '/pricing-management/item-services',
    name: 'itemServices',
    exact: true,
  },
  pricingItemEdit: {
    path: '/pricing-management/items/:id',
    name: 'PricingItemEdit',
    exact: true,
  },
  pricingItemAdd: {
    path: '/pricing-management/items/add',
    name: 'PricingItemAdd',
    exact: true,
  },
  pricingPriceOffer: {
    path: '/pricing-management/offers',
    name: 'PricingPriceOffer',
    exact: true,
  },
  pricingPriceOfferEdit: {
    path: '/pricing-management/offers/:id',
    name: 'PricingPriceOfferEdit',
    exact: true,
  },
  pricingPriceOfferAdd: {
    path: '/pricing-management/offers/add',
    name: 'PricingPriceOfferAdd',
    exact: true,
  },
  pricingDiscountOffer: {
    path: '/pricing-management/discount',
    name: 'PricingDiscountOffer',
    exact: true,
  },
  pricingDiscountAdd: {
    path: '/pricing-management/discount/add',
    name: 'pricingDiscountAdd',
    exact: true,
  },
  pricingDiscountEdit: {
    path: '/pricing-management/discount/:id',
    name: 'pricingDiscountEdit',
    exact: true,
  },
  bundleManagement: {
    path: '/bundle-management',
    name: 'BundleItem',
    exact: false,
  },
  bundleItem: {
    path: '/bundle-management/bundles',
    name: 'BundleItem',
    exact: true,
  },
  bundleItemAdd: {
    path: '/bundle-management/bundles/add',
    name: 'BundleItemAdd',
    exact: true,
  },
  bundleItemEdit: {
    path: '/bundle-management/bundles/:id',
    name: 'BundleItemEdit',
    exact: true,
  },
  packageItem: {
    path: '/bundle-management/packages',
    name: 'PackageItem',
    exact: true,
  },
  packageItemAdd: {
    path: '/bundle-management/packages/add',
    name: 'PackageItemAdd',
    exact: true,
  },
  packageItemEdit: {
    path: '/bundle-management/packages/:id',
    name: 'PackageItemEdit',
    exact: true,
  },
  dependencyItem: {
    path: '/bundle-management/dependencies',
    name: 'DependencyItem',
    exact: true,
  },
  dependencyItemAdd: {
    path: '/bundle-management/dependencies/add',
    name: 'DependencyItemAdd',
    exact: true,
  },
  dependencyItemEdit: {
    path: '/bundle-management/dependencies/:id',
    name: 'DependencyItemEdit',
    exact: true,
  },
  paymentHub: {
    path: '/payment',
    name: 'paymentHub',
    exact: true,
  },
  paymentConfiguration: {
    path: '/payment/payment-admin/configuration',
    name: 'paymentConfiguration',
    exact: false,
  },
  paymentSurcharge: {
    path: '/payment/payment-admin/payment-surcharge',
    name: 'paymentSurcharge',
    exact: false,
  },
  batchPaymentFile: {
    path: '/payment/payment-admin/batch-payment-file',
    name: 'batchPaymentFile',
    exact: false,
  },
  paymentItemMap: {
    path: '/payment/payment-admin/payment-item-map',
    name: 'paymentItemMap',
    exact: false,
  },
  paymentAgent: {
    path: '/payment/payment-agent/:childRoute',
    name: 'CollectionConfig',
    exact: true,
  },

  // paymentSearchAccount: {
  //   path: '/payment/payment-agent/search-accounts',
  //   name: 'paymentSearchAccount',
  //   exact: false,
  // },

  paymentOneOff: {
    path: '/payment/payment-agent/manual-payment',
    name: 'oneOffPayment',
    exact: false,
  },

  paymentActivity: {
    path: '/payment/payment-agent/view-payment-activity',
    name: 'paymentActivity',
    exact: false,
  },
  paymentAllocation: {
    path: '/payment/payment-agent/payment-allocation',
    name: 'paymentAllocation',
    exact: false,
  },
  paymentSuspense: {
    path: '/payment/payment-agent/payment-suspense',
    name: 'paymentSuspense',
    exact: false,
  },
  allocationRules: {
    path: '/payment/payment-agent/allocation-rules',
    name: 'allocationRules',
    exact: false,
  },
  paymentCustomerRefunds: {
    path: '/payment/customer-refunds',
    name: 'paymentCustomerRefunds',
    exact: false,
  },

  paymentManualChargebacks: {
    path: '/payment/manual-chargebacks',
    name: 'paymentManualChargebacks',
    exact: false,
  },
  batchPayment: {
    path: '/payment/batch-payments',
    name: 'batchPayment',
    exact: false,
  },

  uploadPayment: {
    path: '/payment/upload-payments',
    name: 'uploadPayment',
    exact: false,
  },

  processPayment: {
    path: '/payment/process-payments',
    name: 'processPayment',
    exact: false,
  },

  batchRefunds: {
    path: '/payment/batch-refunds',
    name: 'batchRefunds',
    exact: false,
  },
  batchReversals: {
    path: '/payment/batch-reversals',
    name: 'batchReversals',
    exact: false,
  },

  arOperations: {
    path: '/ar-operations',
    name: 'ArOperations',
    exact: true,
  },

  arOperationsConfig: {
    path: '/ar-operations/ar-ops-config',
    name: 'AROpsConfig',
    exact: true,
  },

  arOperationsAdjustments: {
    path: '/ar-operations/adjustments',
    name: 'Adjustments',
    exact: true,
  },

  arOperationsBulkAdjustments: {
    path: '/ar-operations/bulk-adjustments',
    name: 'BulkAdjustments',
    exact: true,
  },

  arOperationsAdjustmentDetail: {
    path: '/ar-operations/adjustments/:id/detail',
    name: 'AdjustmentDetail',
    exact: true,
  },

  arOperationsAdjustmentApply: {
    path: '/ar-operations/adjustments/apply',
    name: 'AdjustmentApply',
    exact: true,
  },

  arOperationsDisputes: {
    path: '/ar-operations/disputes',
    name: 'Disputes',
    exact: true,
  },

  arOperationsDisputesDetail: {
    path: '/ar-operations/disputes/:id/detail',
    name: 'DisputesDetail',
    exact: true,
  },

  arOperationsDisputesApply: {
    path: '/ar-operations/disputes/apply',
    name: 'DisputesApply',
    exact: true,
  },

  arOperationsSettleDisputes: {
    path: '/ar-operations/settlements/apply',
    name: 'SettleDisputes',
    exact: true,
  },

  arOperationsWriteOffs: {
    path: '/ar-operations/write-offs',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsWriteOffsDetails: {
    path: '/ar-operations/write-offs/:id/detail',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsWriteOffsApply: {
    path: '/ar-operations/write-offs/apply',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsReverseWriteOffs: {
    path: '/ar-operations/reverse-write-offs/apply',
    name: 'ReverseWriteOffs',
    exact: true,
  },

  arOperationsConfigGL: {
    path: '/ar-operations/config-gl-setup',
    name: 'arOperationsConfigGL',
    exact: true,
  },

  arOperationsConfigItemMap: {
    path: '/ar-operations/config-item-map',
    name: 'arOperationsConfigItemMap',
    exact: true,
  },

  arOperationsConfigReasonCode: {
    path: '/ar-operations/config-reason-code',
    name: 'arOperationsConfigReasonCode',
    exact: true,
  },

  arOperationsConfigGetReasonGroup: {
    path: '/ar-operations/config-reason-groups',
    name: 'arOperationsConfigGetReasonGroup',
    exact: true,
  },

  arOperationsARThresholds: {
    path: '/ar-operations/ar-thresholds',
    name: 'arOperationsARThresholds',
    exact: true,
  },

  arOperationsConfigGetReasonGroupAdd: {
    path: '/ar-operations/config-reason-groups/add',
    name: 'arOperationsConfigGetReasonGroupAdd',
    exact: true,
  },

  arOperationsConfigGetReasonGroupModify: {
    path: '/ar-operations/config-reason-groups/modify',
    name: 'arOperationsConfigGetReasonGroupModify',
    exact: true,
  },

  arCreditNotesReport: {
    path: '/ar-operations/credit-notes-report',
    name: 'arCreditNotesReport',
    exact: true,
  },

  revenueConfig: {
    path: '/revenue',
    name: 'revenueConfig',
    exact: true,
  },

  accountingLog: {
    path: '/revenue/accounting-log',
    name: 'accountingLog',
    exact: true,
  },

  journals: {
    path: '/revenue/journals',
    name: 'journals',
    exact: true,
  },

  erpExtracts: {
    path: '/revenue/erp-extracts',
    name: 'erpExtracts',
    exact: true,
  },

  revenueAccountingReconciliation: {
    path: '/revenue/revenue-accounting-reconciliation',
    name: 'revenueAccountingReconciliation',
    exact: true,
  },

  revenueConfiguration: {
    path: '/revenue-configuration',
    name: 'revenueConfiguration',
    exact: true,
  },

  revenueConfigurationLocation: {
    path: '/revenue-configuration/location',
    name: 'revenueConfigurationLocation',
    exact: true,
  },

  revenueConfigurationEnterprise: {
    path: '/revenue-configuration/enterprise',
    name: 'revenueConfiguration',
    exact: true,
  },

  revenueConfigurationDivisions: {
    path: '/revenue-configuration/divisions',
    name: 'revenueConfigurationDivisions',
    exact: true,
  },

  revenueConfigurationLegalEntity: {
    path: '/revenue-configuration/legal-entity',
    name: 'revenueConfigurationLegalEntity',
    exact: true,
  },

  revenueConfigurationBusinessUnit: {
    path: '/revenue-configuration/business-unit',
    name: 'revenueConfigurationBusinessUnit',
    exact: true,
  },
  revenueConfigurationDepartment: {
    path: '/revenue-configuration/department',
    name: 'revenueConfigurationDepartment',
    exact: true,
  },

  revenueConfigurationCostCenter: {
    path: '/revenue-configuration/cost-center',
    name: 'revenueConfigurationCostCenter',
    exact: true,
  },

  revenueConfigurationCalendar: {
    path: '/revenue-configuration/calendar',
    name: 'revenueConfigurationCalendar',
    exact: true,
  },

  revenueConfigurationGLSetup: {
    path: '/revenue-configuration/gl-setup',
    name: 'revenueConfigurationGLSetup',
    exact: true,
  },

  revenueAccountingPolicies: {
    path: '/revenue-configuration/accounting-policies',
    name: 'revenueAccountingPolicies',
    exact: true,
  },

  revenueConfigurationChartOfAccount: {
    path: '/revenue-configuration/chart-of-account',
    name: 'revenueConfigurationChartOfAccount',
    exact: true,
  },

  revenueConfigurationPrimary: {
    path: '/revenue-configuration/primary',
    name: 'revenueConfigurationPrimary',
    exact: true,
  },

  revenueConfigurationSecondary: {
    path: '/revenue-configuration/secondary',
    name: 'revenueConfigurationSecondary',
    exact: true,
  },

  revenueConfigurationPeporting: {
    path: '/revenue-configuration/peporting',
    name: 'revenueConfigurationPeporting',
    exact: true,
  },

  revenueConfigurationMilestone: {
    path: '/revenue-configuration/milestone',
    name: 'revenueConfigurationMilestone',
    exact: true,
  },

  costCenterMapping: {
    path: '/revenue-configuration/cost-center-mapping',
    name: 'costCenterMapping',
    exact: true,
  },

  revenueConfigurationMilestoneCreate: {
    path: '/revenue-configuration/milestone/create-new',
    name: 'revenueConfigurationMilestoneCreate',
    exact: true,
  },

  revenueConfigurationMilestoneModify: {
    path: '/revenue-configuration/milestone/modify/:id',
    name: 'revenueConfigurationMilestoneModify',
    exact: true,
  },

  revenueConfigurationCurrencyExchange: {
    path: '/revenue-configuration/currency-exchange',
    name: 'revenueConfigurationCurrencyExchange',
    exact: true,
  },

  revenueConfigurationCurrencyExchangeCreate: {
    path: '/revenue-configuration/currency-exchange/create-new',
    name: 'revenueConfigurationCurrencyExchangeCreate',
    exact: true,
  },

  revenueConfigurationCurrencyExchangeModify: {
    path: '/revenue-configuration/currency-exchange/modify/:id',
    name: 'revenueConfigurationCurrencyExchangeModify',
    exact: true,
  },

  revenueConfigurationGLAccounts: {
    path: '/revenue-configuration/gl-accounts',
    name: 'revenueConfigurationGLAccounts',
    exact: true,
  },

  revenueCompanySetup: {
    path: '/revenue/config/company-setup',
    name: 'revenueCompanySetup',
    exact: true,
  },

  revenueCurrencyExchange: {
    path: '/revenue/config/currency-exchange',
    name: 'revenueCurrencyExchange',
    exact: true,
  },

  revenueCurrencyExchangeCreate: {
    path: '/revenue/config/currency-exchange/create-new',
    name: 'revenueCurrencyExchangeCreate',
    exact: true,
  },

  revenueCurrencyExchangeModify: {
    path: '/revenue/config/currency-exchange/modify/:id',
    name: 'revenueCurrencyExchangeModify',
    exact: true,
  },

  revenueMilestone: {
    path: '/revenue/config/milestone',
    name: 'revenueMilestone',
    exact: true,
  },

  revenueMilestoneCreate: {
    path: '/revenue/config/milestone/create-new',
    name: 'revenueMilestoneCreate',
    exact: true,
  },

  revenueMilestoneModify: {
    path: '/revenue/config/milestone/modify/:id',
    name: 'revenueMilestoneModify',
    exact: true,
  },

  revenueGlAccount: {
    path: '/revenue/config/gl-account',
    name: 'revenueGlAccount',
    exact: true,
  },

  billingConfig: {
    path: '/billing',
    name: 'billingConfig',
    exact: true,
  },

  collections: {
    path: '/collections',
    name: 'Collections',
    exact: true,
  },

  collectionsAdmin: {
    path: '/collections/collection-admin/:childRoute',
    name: 'CollectionConfig',
    exact: true,
  },

  collectionsConfig: {
    path: '/collections/collection-admin/collection-config',
    name: 'CollectionConfig',
    exact: true,
  },

  collectionActionsConfig: {
    path: '/collections/collection-config/collection-action',
    name: 'collectionActionsConfig',
    exact: true,
  },

  collectionSchedule: {
    path: '/collections/collection-config/collection-schedule',
    name: 'collectionSchedule',
    exact: true,
  },

  collectionAgents: {
    path: '/collections/collection-config/collection-agents',
    name: 'collectionAgents',
    exact: true,
  },

  collectionProfile: {
    path: '/collections/collection-config/collection-profile',
    name: 'collectionProfile',
    exact: true,
  },

  collectionsAccounts: {
    path: '/collections/collection-admin/accounts-in-collection',
    name: 'CollectionAccounts',
    exact: true,
  },

  collectionsActivitySummary: {
    path: '/collections/collection-admin/agent-activity-summary',
    name: 'ActivitySummary',
    exact: true,
  },

  schedulePatternConfig: {
    path: '/collections/collection-admin/schedule-pattern-config',
    name: 'schedulePatternConfig',
    exact: true,
  },

  collectionsAgent: {
    path: '/collections/collection-agent',
    name: 'CollectionsAgent',
    exact: true,
  },

  collectionsAgentChild: {
    path: '/collections/collection-agent/:childRoute/:id?',
    name: 'CollectionsAgentChild',
    exact: true,
  },

  collectionsSearchAccount: {
    path: '/collections/collection-agent/search-accounts',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsInvoiceUnits: {
    path: '/collections/collection-agent/invoice-units',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsInvoiceUnitsDetails: {
    path: '/collections/collection-agent/invoice-units/:id',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsHistory: {
    path: '/collections/collection-agent/collection-aging',
    name: 'CollectionsHistory',
    exact: true,
  },

  collectionsHistoryDetails: {
    path: '/collections/collection-agent/collection-history/:id',
    name: 'CollectionsHistoryDetails',
    exact: true,
  },

  searchAndList: {
    path: '/orders/search-list',
    name: 'searchList',
    exact: false,
  },
  orderDetail: {
    path: '/orders/:id/detail',
    name: 'searchList',
    exact: false,
  },
  orders: {
    path: '/orders',
    name: 'OrdersPage',
    exact: true,
  },
  newOrder: {
    path: '/orders/new-order',
    name: 'newOrder',
    exact: false,
  },
  trialSubscription: {
    path: '/orders/trial-subscription',
    name: 'trialSubscription',
    exact: false,
  },
  modifyOrder: {
    path: '/orders/modify-order',
    name: 'modifyOrder',
    exact: true,
  },
  moveProvisioning: {
    path: '/orders/move-provisioning',
    name: 'moveProvisioning',
    exact: true,
  },
  suspendOrder: {
    path: '/orders/suspend',
    name: 'suspendOrder',
    exact: false,
  },
  resumeOrder: {
    path: '/orders/resume',
    name: 'resumeOrder',
    exact: false,
  },
  cancelOrder: {
    path: '/orders/cancel',
    name: 'cancelOrder',
    exact: false,
  },
  renewOrder: {
    path: '/orders/renew',
    name: 'renewOrder',
    exact: false,
  },
  downgradeOrder: {
    path: '/orders/downgrade',
    name: 'downgradeOrder',
    exact: false,
  },

  orderSequence: {
    path: '/orders/order-sequence',
    name: 'orderSequence',
    exact: false,
  },

  orderResonCode: {
    path: '/orders/order-reson-code',
    name: 'orderResonCode',
    exact: false,
  },

  provisioningSequence: {
    path: '/orders/provision-sequence',
    name: 'provisioningSequence',
    exact: false,
  },

  provisioningMapping: {
    path: '/orders/provisioning-mapping',
    name: 'provisioningMapping',
    exact: false,
  },

  provisioningAttributes: {
    path: '/pricing-management/provisioning-attributes',
    name: 'provisioningAttributes',
    exact: false,
  },

  customAttributes: {
    path: '/orders/custom-attributes',
    name: 'CustomAttributes',
    exact: false,
  },

  optIn: {
    path: '/orders/opt-in',
    name: 'optIn',
    exact: false,
  },
  optOut: {
    path: '/orders/opt-out',
    name: 'optOut',
    exact: false,
  },
  upgradeOrder: {
    path: '/orders/upgrade-order',
    name: 'upgradeOrder',
    exact: false,
  },
  userManagement: {
    path: '/user-management',
    name: 'UserManagement',
    exact: true,
  },
  userManagementUser: {
    path: '/user-management/users',
    name: 'UserManagementUser',
    exact: true,
  },
  userManagementUserGroups: {
    path: '/user-management/users-group',
    name: 'userManagementUserGroups',
    exact: true,
  },
  userManagementUserGroupsAdd: {
    path: '/user-management/users-group/add',
    name: 'userManagementUserGroupsAdd',
    exact: true,
  },
  userManagementUserGroupsModify: {
    path: '/user-management/users-group/modify',
    name: 'userManagementUserGroupsModify',
    exact: true,
  },
  userManagementApproval: {
    path: '/user-management/approval',
    name: 'userManagementApproval',
    exact: true,
  },
  userManagementApprovalAdd: {
    path: '/user-management/approval/add',
    name: 'userManagementApprovalAdd',
    exact: true,
  },
  userManagementApprovalModify: {
    path: '/user-management/approval/modify',
    name: 'userManagementApprovalModify',
    exact: true,
  },
  userManagementUserAdd: {
    path: '/user-management/users/add',
    name: 'UserManagementUserAdd',
    exact: true,
  },
  userManagementUserEdit: {
    path: '/user-management/users/:id',
    name: 'UserManagementUserEdit',
    exact: true,
  },
  userManagementRole: {
    path: '/user-management/roles',
    name: 'UserManagementRole',
    exact: true,
  },
  userManagementRoleAdd: {
    path: '/user-management/roles/add',
    name: 'UserManagementRoleAdd',
    exact: true,
  },
  userManagementRoleEdit: {
    path: '/user-management/roles/:id',
    name: 'UserManagementRoleEdit',
    exact: true,
  },
  userManagementRoleGroup: {
    path: '/user-management/role-groups',
    name: 'UserManagementRoleGroup',
    exact: true,
  },
  userManagementRoleGroupAdd: {
    path: '/user-management/role-groups/add',
    name: 'UserManagementRoleGroupAdd',
    exact: true,
  },
  userManagementRoleGroupEdit: {
    path: '/user-management/role-groups/:id',
    name: 'UserManagementRoleGroupEdit',
    exact: true,
  },
  jobsManagment: {
    path: '/jobs-management',
    name: 'JobsManagment',
    exact: true,
  },
  jobsManagmentJobConfig: {
    path: '/jobs-management/job-config',
    name: 'JobsManagmentJobConfig',
    exact: true,
  },
  jobsManagmentJobSchedule: {
    path: '/jobs-management/job-schedule',
    name: 'JobsManagmentJobSchedule',
    exact: true,
  },

  // Tenant Management
  tenantManagement: {
    path: '/tenant-management',
    name: 'TenantManagement',
    exact: true,
  },

  tenantData: {
    path: '/tenant-management/tenant-data',
    name: 'tenantData',
    exact: true,
  },

  tenantProfile: {
    path: '/tenant-management/tenant-profile',
    name: 'tenantProfile',
    exact: true,
  },

  idSequenceConfig: {
    path: '/tenant-management/id-sequence-config',
    name: 'idSequenceConfig',
    exact: true,
  },

  tenantPlatformDefaults: {
    path: '/tenant-management/platform-defaults',
    name: 'platformDefaults',
    exact: true,
  },

  tenantTemplates: {
    path: '/tenant-management/templates',
    name: 'tenantTemplates',
    exact: true,
  },

  tenantTask: {
    path: '/tenant-management/tasks',
    name: 'tenantTask',
    exact: true,
  },

  tenantSelfCareMakerting: {
    path: '/tenant-management/self-care-makerting',
    name: 'tenantSelfCareMakerting',
    exact: true,
  },

  tenantPaymentGateway: {
    path: '/tenant-management/payment-gateway',
    name: 'tenantPaymentGateway',
    exact: true,
  },

  // tenantMerchantData: {
  //   path: '/tenant-management/payment-gateway/merchant-data',
  //   name: 'tenantMerchantData',
  //   exact: true,
  // },

  paymentGateway: {
    path: '/tenant-management/payment-gateway',
    name: 'paymentGateway',
    exact: true,
  },

  // tenantCustomRecords: {
  //   path: '/tenant-management/payment-gateway/custom-records',
  //   name: 'tenantCustomRecords',
  //   exact: true,
  // },

  // tenantUrlMapping: {
  //   path: '/tenant-management/payment-gateway/url-mapping',
  //   name: 'tenantUrlMapping',
  //   exact: true,
  // },

  // tenantRequestResponseMapping: {
  //   path: '/tenant-management/payment-gateway/request-response-mapping',
  //   name: 'tenantRequestResponseMapping',
  //   exact: true,
  // },

  // Teanat tax gateway
  tenantTaxGateway: {
    path: '/tenant-management/tax-gateway',
    name: 'tenantTaxGateway',
    exact: true,
  },

  // tenantTaxMerchantData: {
  //   path: '/tenant-management/tax-gateway/merchant-data',
  //   name: 'tenantTaxMerchantData',
  //   exact: true,
  // },

  // tenantTaxCustomRecords: {
  //   path: '/tenant-management/tax-gateway/custom-records',
  //   name: 'tenantTaxCustomRecords',
  //   exact: true,
  // },

  // tenantTaxUrlMapping: {
  //   path: '/tenant-management/tax-gateway/url-mapping',
  //   name: 'tenantTaxUrlMapping',
  //   exact: true,
  // },

  // tenantTaxRequestResponseMapping: {
  //   path: '/tenant-management/tax-gateway/request-response-mapping',
  //   name: 'tenantTaxRequestResponseMapping',
  //   exact: true,
  // },

  // Teanat tax gateway
  tenantFinanceGateway: {
    path: '/tenant-management/finance-gateway',
    name: 'tenantFinanceGateway',
    exact: true,
  },

  // tenantFinanceMerchantData: {
  //   path: '/tenant-management/finance-gateway/merchant-data',
  //   name: 'tenantFinanceMerchantData',
  //   exact: true,
  // },

  // tenantFinanceCustomRecords: {
  //   path: '/tenant-management/finance-gateway/custom-records',
  //   name: 'tenantFinanceCustomRecords',
  //   exact: true,
  // },

  // tenantFinanceUrlMapping: {
  //   path: '/tenant-management/finance-gateway/url-mapping',
  //   name: 'tenantFinanceUrlMapping',
  //   exact: true,
  // },

  // tenantFinanceRequestResponseMapping: {
  //   path: '/tenant-management/finance-gateway/request-response-mapping',
  //   name: 'tenantFinanceRequestResponseMapping',
  //   exact: true,
  // },

  projectGateway: {
    path: '/tenant-management/project-gateway',
    name: 'projectGateway',
    exact: true,
  },

  // tenantProjectCustomRecords: {
  //   path: '/tenant-management/project-gateway/custom-records',
  //   name: 'tenantProjectCustomRecords',
  //   exact: true,
  // },

  // tenantProjectUrlMapping: {
  //   path: '/tenant-management/project-gateway/url-mapping',
  //   name: 'tenantProjectUrlMapping',
  //   exact: true,
  // },

  // tenantProjectRequestResponseMapping: {
  //   path: '/tenant-management/project-gateway/request-response-mapping',
  //   name: 'tenantProjectRequestResponseMapping',
  //   exact: true,
  // },

  customerSupportGateway: {
    path: '/tenant-management/customer-support-gateway',
    name: 'customerSupportGateway',
    exact: true,
  },

  // tenantCustomerSupportCustomRecords: {
  //   path: '/tenant-management/customer-support-gateway/custom-records',
  //   name: 'tenantCustomerSupportCustomRecords',
  //   exact: true,
  // },

  // tenantCustomerSupportUrlMapping: {
  //   path: '/tenant-management/customer-support-gateway/url-mapping',
  //   name: 'tenantCustomerSupportUrlMapping',
  //   exact: true,
  // },

  // tenantCustomerSupportRequestResponseMapping: {
  //   path: '/tenant-management/customer-support-gateway/request-response-mapping',
  //   name: 'tenantCustomerSupportRequestResponseMapping',
  //   exact: true,
  // },

  documentGateway: {
    path: '/tenant-management/document-gateway',
    name: 'documentGateway',
    exact: true,
  },

  // tenantDocumentCustomRecords: {
  //   path: '/tenant-management/document-gateway/custom-records',
  //   name: 'tenantDocumentCustomRecords',
  //   exact: true,
  // },

  // tenantDocumentUrlMapping: {
  //   path: '/tenant-management/document-gateway/url-mapping',
  //   name: 'tenantDocumentUrlMapping',
  //   exact: true,
  // },

  // tenantDocumentRequestResponseMapping: {
  //   path: '/tenant-management/document-gateway/request-response-mapping',
  //   name: 'tenantCustomerSupportRequestResponseMapping',
  //   exact: true,
  // },

  operationGateway: {
    path: '/tenant-management/operation-gateway',
    name: 'operationGateway',
    exact: true,
  },

  tenantCrmGateway: {
    path: '/tenant-management/crm-gateway',
    name: 'tenantCrmGateway',
    exact: true,
  },

  // tenantCrmCustomRecords: {
  //   path: '/tenant-management/crm-gateway/custom-records',
  //   name: 'tenantcrmCustomRecords',
  //   exact: true,
  // },

  // tenantCrmUrlMapping: {
  //   path: '/tenant-management/crm-gateway/url-mapping',
  //   name: 'tenantcrmUrlMapping',
  //   exact: true,
  // },

  // tenantCrmRequestResponseMapping: {
  //   path: '/tenant-management/crm-gateway/request-response-mapping',
  //   name: 'tenantCrmRequestResponseMapping',
  //   exact: true,
  // },

  // Provisioning Gateway
  tenantProvisioningGateway: {
    path: '/tenant-management/provisioning-gateway',
    name: 'tenantProvisioningGateway',
    exact: true,
  },

  // tenantProvisioningMerchantData: {
  //   path: '/tenant-management/provisioning-gateway/merchant-data',
  //   name: 'tenantProvisioningMerchantData',
  //   exact: true,
  // },

  // tenantProvisioningCustomRecords: {
  //   path: '/tenant-management/provisioning-gateway/custom-records',
  //   name: 'tenantProvisioningCustomRecords',
  //   exact: true,
  // },

  // tenantProvisioningUrlMapping: {
  //   path: '/tenant-management/provisioning-gateway/url-mapping',
  //   name: 'tenantProvisioningUrlMapping',
  //   exact: true,
  // },

  // tenantProvisioningRequestResponseMapping: {
  //   path: '/tenant-management/provisioning-gateway/request-response-mapping',
  //   name: 'tenantProvisioningRequestResponseMapping',
  //   exact: true,
  // },

  tenantSchedulePatternConfig: {
    path: '/tenant-management/schedule-pattern-config',
    name: 'tenantSchedulePatternConfig',
    exact: true,
  },

  manageProfile: {
    path: '/manage-profile',
    name: 'ManageProfile',
    exact: true,
  },

  baseConfigurations: {
    path: '/base-configurations',
    name: 'BaseConfigurations',
    exact: true,
  },

  resources: {
    path: '/base-configurations/resources',
    name: 'Resources',
    exact: true,
  },

  currency: {
    path: '/base-configurations/resources/currency',
    name: 'Currency',
    exact: true,
  },

  creditLimit: {
    path: '/base-configurations/resources/credit-limit',
    name: 'CreditLimit',
    exact: true,
  },

  grants: {
    path: '/base-configurations/resources/grants',
    name: 'Grants',
    exact: true,
  },

  accumulators: {
    path: '/base-configurations/resources/accumulators',
    name: 'Accumulators',
    exact: true,
  },

  usageRating: {
    path: '/base-configurations/pricing-config',
    name: 'usageRating',
    exact: true,
  },

  rateUnit: {
    path: '/base-configurations/pricing-config/rate-unit',
    name: 'RateUnit',
    exact: true,
  },

  unitOfMeasure: {
    path: '/base-configurations/pricing-config/unit-of-measure',
    name: 'unitOfMeasure',
    exact: true,
  },

  regulatoryProductCodes: {
    path: '/base-configurations/pricing-config/regulatory-product-codes',
    name: 'regulatoryProductCodes',
    exact: true,
  },

  topupOffers: {
    path: '/pricing-management/topup-offers',
    name: 'topupOffers',
    exact: true,
  },

  rateUnitMap: {
    path: '/base-configurations/pricing-config/rate-unit-map',
    name: 'RateUnit',
    exact: true,
  },

  timeUnit: {
    path: '/base-configurations/pricing-config/time-unit',
    name: 'TimeUnit',
    exact: true,
  },

  usageType: {
    path: '/base-configurations/pricing-config/usage-type',
    name: 'usageType',
    exact: true,
  },

  voicePlans: {
    path: '/usage-config/voice-plans',
    name: 'voicePlans',
    exact: true,
  },

  PNNList: {
    path: '/usage-config/pnn-list',
    name: 'PNNList',
    exact: true,
  },

  countryCodes: {
    path: '/base-configurations/pricing-config/country-codes',
    name: 'countryCodes',
    exact: true,
  },

  zoneUnit: {
    path: '/base-configurations/pricing-config/zone-unit',
    name: 'ZoneUnit',
    exact: true,
  },

  zoneUnitEdit: {
    path: '/base-configurations/pricing-config/zone-unit/:id',
    name: 'zoneUnitEdit',
    exact: true,
  },

  zoneUnitAdd: {
    path: '/base-configurations/pricing-config/zone-unit/add',
    name: 'zoneUnitAdd',
    exact: true,
  },

  usageMapping: {
    path: '/base-configurations/usage-mapping',
    name: 'usageMapping',
    exact: true,
  },

  fileMapping: {
    path: '/usage-config/file-mapping',
    name: 'FileMapping',
    exact: true,
  },

  customRecords: {
    path: '/usage-config/custom-records',
    name: 'CustomRecords',
    exact: true,
  },

  processingStreams: {
    path: '/usage-config/processing-streams',
    name: 'ProcessingStreams',
    exact: true,
  },

  taxation: {
    path: '/taxation',
    name: 'taxation',
    exact: true,
  },

  taxCodeConfig: {
    path: '/taxation/tax-code-config',
    name: 'taxCodeConfig',
    exact: true,
  },

  usage: {
    path: '/usage-config',
    name: 'usage',
    exact: true,
  },

  operatorUsage: {
    path: '/usage-config/operator',
    name: 'operatorUsage',
    exact: true,
  },

  recordLogUsage: {
    path: '/usage-config/record-log',
    name: 'recordLogUsage',
    exact: true,
  },

  invoiceUsage: {
    path: '/usage-config/invoice',
    name: 'invoiceUsage',
    exact: true,
  },

  platformsUsage: {
    path: '/usage-config/platforms',
    name: 'platformsUsage',
    exact: true,
  },

  disputeUsage: {
    path: '/usage-config/dispute',
    name: 'disputeUsage',
    exact: true,
  },

  tenantDispute: {
    path: '/usage-config/tenant-dispute',
    name: 'tenantDispute',
    exact: true,
  },

  tenantRecordLog: {
    path: '/usage-config/tenant-record-log',
    name: 'tenantRecordLog',
    exact: true,
  },

  rerating: {
    path: '/usage-config/rerating',
    name: 'rerating',
    exact: true,
  },

  reprocessingSuspendedFailedRecords: {
    path: '/usage-config/reprocessing-suspended-failed-records',
    name: 'reprocessingSuspendedFailedRecords',
    exact: true,
  },

  testUsage: {
    path: '/usage-config/test-usage',
    name: 'testUsage',
    exact: true,
  },

  reratingCreate: {
    path: '/usage-config/rerating/create',
    name: 'reratingCreate',
    exact: true,
  },

  reratingModify: {
    path: '/usage-config/rerating/modify/:id',
    name: 'reratingModify',
    exact: true,
  },

  rawUsageFilesUsage: {
    path: '/usage-config/raw-usage-files-usage',
    name: 'rawUsageFilesUsage',
    exact: true,
  },

  cdrReconciliationSummaryUsage: {
    path: '/usage-config/cdr-reconciliation-summary-usage',
    name: 'cdrReconciliationSummaryUsage',
    exact: true,
  },

  taxTypeConfig: {
    path: '/taxation/tax-type-config',
    name: 'taxTypeConfig',
    exact: true,
  },

  thirdPartyTaxConfig: {
    path: '/taxation/third-party-tax-config',
    name: 'thirdPartyTaxConfig',
    exact: true,
  },

  regionMapping: {
    path: '/usage-config/region-mapping',
    name: 'RegionMapping',
    exact: true,
  },

  billing: {
    path: '/billing',
    name: 'billing',
    exact: true,
  },

  pendingBillTrigger: {
    path: '/billing/pending-bill-trigger',
    name: 'pendingBillTrigger',
    exact: true,
  },

  advanceBilling: {
    path: '/billing/advance-billing',
    name: 'advanceBilling',
    exact: true,
  },

  TEExpenseType: {
    path: '/billing/te-expense-type',
    name: 'TEExpenseType',
    exact: true,
  },

  TEExpenseData: {
    path: '/billing/te-expense-data',
    name: 'TEExpenseData',
    exact: true,
  },

  employeeBonus: {
    path: '/billing/employee-bonus',
    name: 'employeeBonus',
    exact: true,
  },

  employeeCostingPnl: {
    path: '/billing/employee-costing-pnl',
    name: 'employeeCostingPnl',
    exact: true,
  },

  employeeYearlyPl: {
    path: '/billing/employee-yearly-pl',
    name: 'employeeYearlyPl',
    exact: true,
  },

  clientBilling: {
    path: '/billing/client',
    name: 'clientBilling',
    exact: true,
  },

  employeeBilling: {
    path: '/billing/employee',
    name: 'employeeBilling',
    exact: true,
  },

  savedManualBills: {
    path: '/billing/save-manual-bills',
    name: 'savedManualBills',
    exact: true,
  },

  invoicingBilling: {
    path: '/billing/invoicing-billing',
    name: 'invoicingBilling',
    exact: true,
  },

  zeroPriceUsage: {
    path: '/usage-config/zero-price-usage',
    name: 'zeroPriceUsage',
    exact: true,
  },

  operatorMap: {
    path: '/usage-config/operator-map',
    name: 'operatorMap',
    exact: true,
  },

  trunkRule: {
    path: '/usage-config/trunk-rule',
    name: 'trunkRule',
    exact: true,
  },

  // Report page
  report: {
    path: '/report',
    name: 'report',
    exact: true,
  },

  newCustomerReport: {
    path: '/report/new-customer',
    name: 'newCustomerReport',
    exact: true,
  },

  accountClosure: {
    path: '/report/account-closure',
    name: 'accountClosure',
    exact: true,
  },

  taxExemptionReport: {
    path: '/report/tax-exemption',
    name: 'TaxExemptionReport',
    exact: true,
  },

  searchAccount: {
    path: '/report/search-accounts-report',
    name: 'searchAccount',
    exact: true,
  },

  subscriptionExpiry: {
    path: '/report/subscription-expiry',
    name: 'subscriptionExpiry',
    exact: true,
  },

  trialExpiry: {
    path: '/report/trial-expiry',
    name: 'trialExpiry',
    exact: true,
  },

  trialToPaidSubscription: {
    path: '/report/trial-to-paid-subscription',
    name: 'trialToPaidSubscription',
    exact: true,
  },

  activeSubscriptions: {
    path: '/report/active-subscriptions',
    name: 'activeSubscriptions',
    exact: true,
  },

  subscriptionContractExpiry: {
    path: '/report/active-subscription-contract-expiry',
    name: 'subscriptionContractExpiry',
    exact: true,
  },

  subscriptionBillableServices: {
    path: '/report/active-subscription-billable-services',
    name: 'subscriptionBillableServices',
    exact: true,
  },

  servicesForcecast: {
    path: '/report/active-subscription-services-forcecast',
    name: 'servicesForcecast',
    exact: true,
  },

  provisioningData: {
    path: '/report/provisioning-data',
    name: 'provisioningData',
    exact: true,
  },

  contractServiceReconciliation: {
    path: '/report/contract-service-reconciliation',
    name: 'contractServiceReconciliation',
    exact: true,
  },

  orderReport: {
    path: '/report/order',
    name: 'orderReport',
    exact: true,
  },

  billingReport: {
    path: '/report/rp-billing',
    name: 'billingReport',
    exact: true,
  },

  folioCount: {
    path: '/report/folio-count',
    name: 'folioCount',
    exact: true,
  },

  billingTracker: {
    path: '/report/rp-billing-tracker',
    name: 'billingTracker',
    exact: true,
  },

  productSummary: {
    path: '/report/product-summary',
    name: 'productSummary',
    exact: true,
  },

  invoicingReport: {
    path: '/report/invoicing',
    name: 'invoicingReport',
    exact: true,
  },

  transactionSummary: {
    path: '/report/transaction-summary',
    name: 'transactionSummary',
    exact: true,
  },

  taxSummary: {
    path: '/report/tax-summary',
    name: 'taxSummary',
    exact: true,
  },

  taxDetail: {
    path: '/report/tax-detail',
    name: 'taxDetail',
    exact: true,
  },

  billableServicesForecast: {
    path: '/report/billable-services-forecast',
    name: 'billableServicesForecast',
    exact: true,
  },

  paymentReport: {
    path: '/report/payment',
    name: 'paymentReport',
    exact: true,
  },

  failPayment: {
    path: '/report/fail-payment',
    name: 'failPayment',
    exact: true,
  },

  paymentSuspenseReport: {
    path: '/report/payment-suspense-report',
    name: 'paymentSuspenseReport',
    exact: true,
  },

  collection030DaysReport: {
    path: '/report/collection-0-30-days',
    name: 'collection030DaysReport',
    exact: true,
  },

  collection3060DaysReport: {
    path: '/report/collection-30-60-days',
    name: 'collection3060DaysReport',
    exact: true,
  },

  collection6090DaysReport: {
    path: '/report/collection-60-90-days',
    name: 'collection6090DaysReport',
    exact: true,
  },

  collectionOver90DaysReport: {
    path: '/report/collection-over-90-days',
    name: 'collectionOver90DaysReport',
    exact: true,
  },

  collectionAgeingSummaryReport: {
    path: '/report/ageing-summary',
    name: 'collectionAgeingSummaryReport',
    exact: true,
  },

  arOpsDetails: {
    path: '/report/ar-ops-details',
    name: 'arOpsDetails',
    exact: true,
  },

  openDispute: {
    path: '/report/open-dispute',
    name: 'openDispute',
    exact: true,
  },

  openWriteOff: {
    path: '/report/open-write-off',
    name: 'openWriteOff',
    exact: true,
  },

  creditNotesReport: {
    path: '/report/credit-notes',
    name: 'creditNotesReport',
    exact: true,
  },

  nonBroadSoftRawCdrData: {
    path: '/report/non-broad-soft-raw-cdr-data',
    name: 'nonBroadSoftRawCdrData',
    exact: true,
  },

  rawUsageFile: {
    path: '/report/raw-usage-file',
    name: 'rawUsageFile',
    exact: true,
  },

  broadsoftZeroDurationCdrs: {
    path: '/report/broadsoft-zero-duration-cdrs',
    name: 'BroadsoftZeroDurationCdrs',
    exact: true,
  },

  nonBroadsoftZeroDurationCdrs: {
    path: '/report/non-broadsoft-zero-duration-cdrs',
    name: 'nonBroadsoftZeroDurationCdrs',
    exact: true,
  },

  suspendedFailedRecords: {
    path: '/report/suspended-faile-records',
    name: 'suspendedFailedRecords',
    exact: true,
  },

  extractBI: {
    path: '/report/extract-bi',
    eame: 'ExtractBI',
    exact: true,
  },

  usageSummary: {
    path: '/report/summary-usage',
    name: 'usageSummary',
    exact: true,
  },

  usageDetail: {
    path: '/report/details-usage',
    name: 'usageDetail',
    exact: true,
  },

  CDRReconciliationSummary: {
    path: '/report/cdr-reconciliation-summary',
    name: 'CDRReconciliationSummary',
    exact: true,
  },

  revenueSummary: {
    path: '/report/rvn-summary',
    name: 'revenueSummary',
    exact: true,
  },

  revenueRecognition: {
    path: '/report/rvn-recognition',
    name: 'revenueRecognition',
    exact: true,
  },
  revenueForecast: {
    path: '/report/forecast',
    name: 'revenueForecast',
    exact: true,
  },

  revenueMMRForecast: {
    path: '/report/mmr-forecast',
    name: 'revenueMMRForecast',
    exact: true,
  },

  employeeCostingPNL: {
    path: '/report/employee-costing-pnl',
    name: 'employeeCostingPNL',
    exact: true,
  },

  employeeCostingYearlyPL: {
    path: '/report/employee-yearly-pl',
    name: 'employeeCostingYearlyPL',
    exact: true,
  },

  commitmentData: {
    path: '/report/commitment-data',
    name: 'commitmentData',
    exact: true,
  },

  fonatelReport: {
    path: '/report/fonatel-report',
    name: 'fonatelReport',
    exact: true,
  },

  phcReport: {
    path: '/report/phc-report',
    name: 'phcReport',
    exact: true,
  },

  ageingPayment: {
    path: '/report/ageing-payment',
    name: 'ageingPayment',
    exact: true,
  },

  voiceCustomerCallTypeSummary: {
    path: '/report/voice-customer-call-type-summary',
    name: 'voiceCustomerCallTypeSummary',
    exact: true,
  },

  voiceOperatorCallTypeSummary: {
    path: '/report/voice-operator-call-type-summary',
    name: 'voiceOperatorCallTypeSummary',
    exact: true,
  },

  voiceExcessTrafficReport: {
    path: '/report/voice-excess-traffic-report',
    name: 'voiceExcessTrafficReport',
    exact: true,
  },

  voiceTrafficByLdDistination: {
    path: '/report/voice-traffic-by-ld-distination-report',
    name: 'voiceTrafficByLdDistination',
    exact: true,
  },

  onDemand: {
    path: '/report/on-demand',
    name: 'onDemand',
    exact: true,
  },

  newSmsReportData: {
    path: '/report/new-sms-report-data',
    name: 'newSmsReportData',
    exact: true,
  },

  interconnectVoiceReport: {
    path: '/report/interconnect-voice',
    name: 'interconnectVoiceReport',
    exact: true,
  },

  interconnectSmsReport: {
    path: '/report/interconnect-sms',
    name: 'interconnectSmsReport',
    exact: true,
  },

  voiceCallTypeSummaryReport: {
    path: '/report/voice-call-type-summary-report',
    name: 'voiceCallTypeSummaryReport',
    exact: true,
  },

  internetOperatorDataCost: {
    path: '/report/internet-operator-data-cost-report',
    name: 'internetOperatorDataCost',
    exact: true,
  },

  telcoSummary: {
    path: '/report/telco-summary-report',
    name: 'telcoSummary',
    exact: true,
  },

  rawCdr: {
    path: '/report/raw-cdr-report',
    name: 'rawCdr',
    exact: true,
  },

  rawSMSFiles: {
    path: '/report/raw-sms-report',
    name: 'rawSMSFiles',
    exact: true,
  },

  jobScheduleSummary: {
    path: '/report/job-schedule-summary',
    name: 'jobScheduleSummary',
    exact: true,
  },

  jobFailureActivity: {
    path: '/report/job-failure-activity',
    name: 'jobFailureActivity',
    exact: true,
  },

  jobDetail: {
    path: '/report/job-detail',
    name: 'jobDetail',
    exact: true,
  },

  unbilledTransactions: {
    path: '/report/unbilled-transactions',
    name: 'unbilledTransactions',
    exact: true,
  },

  billingInvoicing: {
    path: '/report/billing-invoicing',
    name: 'billingInvoicing',
    exact: true,
  },

  commissionsCancellations: {
    path: '/report/commissions-cancellation',
    name: 'commissionsCancellation',
    exact: true,
  },

  kpi: {
    path: '/report/kpi',
    name: 'kpi',
    exact: true,
  },

  r00105: {
    path: '/report/r00105',
    name: 'r00105',
    exact: true,
  },

  r00106: {
    path: '/report/r00106',
    name: 'r00106',
    exact: true,
  },

  r00501: {
    path: '/report/r00501',
    name: 'r00501',
    exact: true,
  },

  r00502: {
    path: '/report/r00502',
    name: 'r00502',
    exact: true,
  },

  r00503: {
    path: '/report/r00503',
    name: 'r00503',
    exact: true,
  },

  r00504: {
    path: '/report/r00504',
    name: 'r00504',
    exact: true,
  },

  r00505: {
    path: '/report/r00505',
    name: 'r00505',
    exact: true,
  },

  r00506: {
    path: '/report/r00506',
    name: 'r00506',
    exact: true,
  },

  r00401: {
    path: '/report/r00401',
    name: 'r00401',
    exact: true,
  },

  r00405: {
    path: '/report/r00405',
    name: 'r00405',
    exact: true,
  },

  creditBureau: {
    path: '/report/credit-bureau',
    name: 'creditBureau',
    exact: true,
  },

  billingAR: {
    path: '/report/invoicing-ar',
    name: 'billingAR',
    exact: true,
  },
  accountingReconciliation: {
    path: '/report/accounting-reconciliation',
    name: 'accountingReconciliation',
    exact: true,
  },
  // report page end

  creditNotes: {
    path: '/credit-notes',
    name: 'creditNotes',
    exact: true,
  },

  creditNotesInfo: {
    path: '/credit-notes/:id',
    name: 'creditNotesInfo',
    exact: true,
  },

  correspondence: {
    path: '/correspondence',
    name: 'correspondence',
    exact: true,
  },

  correspondenceConfigTemplate: {
    path: '/correspondence/config-template',
    name: 'correspondenceConfigTemplate',
    exact: true,
  },
  quotePage: {
    path: '/quote',
    name: 'quotePage',
    exact: true,
  },

  searchQuoteAndList: {
    path: '/quote/search-list',
    name: 'searchQuoteAndList',
    exact: true,
  },

  newQuote: {
    path: '/quote/new-quote',
    name: 'newQuote',
    exact: false,
  },

  modifyQuote: {
    path: '/quote/modify-quote',
    name: 'modifyQuote',
    exact: false,
  },

  upgradeQuote: {
    path: '/quote/upgrade-quote',
    name: 'upgradeQuote',
    exact: false,
  },

  downgradeQuote: {
    path: '/quote/downgrade-quote',
    name: 'downgradeQuote',
    exact: false,
  },

  discountTrigger: {
    path: '/pricing-management/discount-trigger',
    name: 'discountTrigger',
    exact: true,
  },
  discountTriggerEdit: {
    path: '/pricing-management/discount-trigger/:name',
    name: 'discountTriggerEdit',
    exact: true,
  },
  discountTriggerAdd: {
    path: '/pricing-management/discount-trigger/add',
    name: 'discountTriggerAdd',
    exact: true,
  },

  searchQuoteAndListChildroute: {
    path: '/quote/search-list/:childRoute',
    name: 'searchQuoteAndList',
    exact: true,
  },

  taskManagement: {
    path: '/task-management',
    name: 'taskManagement',
    exact: true,
  },

  tasksList: {
    path: '/task-management/tasks-list',
    name: 'tasksList',
    exact: true,
  },

  ticketsList: {
    path: '/task-management/tickets-list',
    name: 'ticketsList',
    exact: true,
  },

  prettifyPath,
};
