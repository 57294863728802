import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ScaleLoader } from 'react-spinners';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cloneDeep } from 'lodash';
import { RowContent, RowHeader } from './TableRow';

class Table extends React.PureComponent {
  state = {
    indexScroll: 1,
    isLoadMore: false,
  };

  tableRef = React.createRef();

  componentDidMount() {
    this.injectJquery();
  }

  injectJquery = () => {
    const { fixedHeader } = this.props;
    if (!fixedHeader) return null;
    window.jQuery(this.tableRef.current).on('scroll', () => {
      window
        .jQuery(this.tableRef.current)
        .find('thead th')
        .css('transform', `translateY(${window.jQuery(this.tableRef.current).scrollTop()}px)`);
    });
  };

  onClickRow = (e, item) => {
    const { onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(e, item);
    }
  };

  fetchMoreData = () => {
    const { indexScroll } = this.state;
    this.setState({ isLoadMore: true });
    setTimeout(() => {
      this.setState({ indexScroll: indexScroll + 1, isLoadMore: false });
    }, 500);
  };

  render() {
    const {
      fixedHeader,
      columns,
      data,
      rowClass,
      noDataMessage,
      getRowClass,
      sorted,
      onSort,
      tableClass,
      nullIsLoading,
      isLoading,
      indexParent, // Using when map & render table
      onCheckAll,
      t,
      onClickRow,
      isSupportRemoveIndex,
      isFixedHeaderTable,
      isNoNeedFixedHeader,
      isSupportScroll,
      ...rest
    } = this.props;
    const { indexScroll, isLoadMore } = this.state;
    let newData = [];
    if (isSupportScroll) {
      const newDefaultData = cloneDeep(data);
      newData = [...newDefaultData.splice(0, indexScroll * 20)];
    }
    return isSupportScroll ? (
      <InfiniteScroll
        dataLength={newData.length}
        next={this.fetchMoreData}
        hasMore={data && newData && newData.length > 0 && data.length > newData.length}
        scrollableTarget="scrollableDiv"
        className="sticky-thc"
        //             loader={(
        //               <div className="text-center">
        //                 <ScaleLoader loading size={100} color="#28a745" />
        //               </div>
        // )}
      >
        <div
          className={`table-responsive mt-15 ${
            isNoNeedFixedHeader || fixedHeader ? 'no-fixed-header' : 'sticky-header'
          } table-form ${tableClass} ${fixedHeader ? 'tableFixHead' : ''}`}
          ref={this.tableRef}
          id="scrollableDiv"
        >
          <table className="table center-aligned-table mb-0">
            <thead>
              <RowHeader
                columns={columns}
                sorted={sorted}
                onSort={onSort}
                isLoading={isLoading}
                onCheckAll={onCheckAll}
                t={t}
              />
            </thead>
            <tbody>
              {!isLoading &&
                newData &&
                newData.length > 0 &&
                newData.map((item, idx) => {
                  if (isSupportRemoveIndex && item && Object.keys(item).length < 2) return '';
                  return (
                    <>
                      <RowContent
                        key={`row-${idx}`}
                        columns={columns}
                        item={item}
                        onClickRow={this.onClickRow}
                        className={rowClass}
                        getRowClass={getRowClass}
                        indexParent={indexParent}
                        indexItem={idx}
                        {...rest}
                      />
                    </>
                  );
                })}
              {isLoadMore && (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    {isLoadMore && <ScaleLoader loading size={100} color="#28a745" />}
                  </td>
                </tr>
              )}
              {(isLoading || !newData || newData.length === 0) && (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    {!isLoading && !nullIsLoading && <span>{noDataMessage || t('label.noRecord')}</span>}
                    {(isLoading || ((!newData || newData.length === 0) && nullIsLoading)) && (
                      <ScaleLoader loading size={100} color="#28a745" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    ) : (
      <div
        className={`table-responsive mt-15 ${
          isNoNeedFixedHeader || fixedHeader ? 'no-fixed-header' : 'sticky-header'
        } table-form ${tableClass} ${fixedHeader ? 'tableFixHead' : ''}`}
        ref={this.tableRef}
        id="scrollableDiv"
      >
        <table className="table center-aligned-table mb-0">
          <thead>
            <RowHeader
              columns={columns}
              sorted={sorted}
              onSort={onSort}
              isLoading={isLoading}
              onCheckAll={onCheckAll}
              t={t}
            />
          </thead>
          <tbody>
            {!isLoading &&
              data &&
              data.length > 0 &&
              data.map((item, idx) => {
                if (isSupportRemoveIndex && item && Object.keys(item).length < 2) return '';
                return (
                  <RowContent
                    key={`row-${idx}`}
                    columns={columns}
                    item={item}
                    onClickRow={this.onClickRow}
                    className={rowClass}
                    getRowClass={getRowClass}
                    indexParent={indexParent}
                    indexItem={idx}
                    {...rest}
                  />
                );
              })}
            {(isLoading || !data || data.length === 0) && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  {!isLoading && !nullIsLoading && <span>{noDataMessage || t('label.noRecord')}</span>}
                  {(isLoading || ((!data || data.length === 0) && nullIsLoading)) && (
                    <ScaleLoader loading size={100} color="#28a745" />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.object,
  data: PropTypes.array.isRequired,
  rowClass: PropTypes.string,
  tableClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  nullIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getRowClass: PropTypes.func,
  onCheckAll: PropTypes.func,
  onSort: PropTypes.func,
  fixedHeader: PropTypes.bool,
  indexParent: PropTypes.number,
};

Table.defaultProps = {
  columns: [],
  sorted: {},
  data: [],
  rowClass: '',
  tableClass: '',
  getRowClass: null,
  isLoading: false,
  nullIsLoading: false,
  onSort: null,
  noDataMessage: '',
  fixedHeader: false,
  indexParent: 0,
  onCheckAll: () => {},
};

export default withTranslation('common')(Table);
