import { groupNamePermissions } from './constant';
import { getTypePermission } from '../../utils/utils';

const checkPermissionGetTenantInfo = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getTenantInfo });
};

const checkPermissionCreateTenantInfo = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createTenantInfo });
};

const checkPermissionSetLincenseData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setLincenseData });
};

const checkPermissionModifyLicenseData = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyLicenseData });
};

const checkPermissionSetCompanyProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setCompanyProfile });
};

const checkPermissionModifyCompanyProfile = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCompanyProfile });
};

const checkPermissionSetPropertyDefaults = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setPropertyDefaults });
};

const checkPermissionModifyPropertyDefaults = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPropertyDefaults });
};

const checkPermissionSetPyamentGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setPyamentGatewayMerchant });
};

const checkPermissionSetTaxGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setTaxGatewayMerchant });
};

const checkPermissionSetFinanceGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setFinanceGatewayMerchant });
};

const checkPermissionSetCrmGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setCrmGatewayProvider });
};

const checkPermissionSetDataGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setDataGatewayProvider });
};

const checkPermissionSetUsageGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setUsageGatewayProvider });
};

const checkPermissionSetProvisioningGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.setProvisioningGatewayProvider });
};

const checkPermissionModifyPaymentGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentGatewayMerchant });
};

const checkPermissionModifyTaxGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyTaxGatewayMerchant });
};

const checkPermissionModifyFinanceGatewayMerchant = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyFinanceGatewayMerchant });
};

const checkPermissionModifyCrmGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCrmGatewayProvider });
};

const checkPermissionModifyDataGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyDataGatewayProvider });
};

const checkPermissionModifyUsageGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyUsageGatewayProvider });
};

const checkPermissionModifyProvisioningGatewayProvider = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyProvisioningGatewayProvider });
};

const checkPermissionCreateGatewayCanonical = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createGatewayCanonical });
};

const checkPermissionModifyGatewayCanonical = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyGatewayCanonical });
};

const checkPermissionGetGatewayCanonical = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getGatewayCanonical });
};

const checkPermissionCreatePaymentMethodUrl = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createPaymentMethodUrl });
};

const checkPermissionModifyPaymentMethodUrl = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyPaymentMethodUrl });
};
const checkPermissionGetPaymentMethodUrl = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getPaymentMethodUrl });
};
const checkPermissionGetGatewayCanonicalSpec = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getGatewayCanonicalSpec });
};

const checkPermissionCreateCustomDBConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.createCustomDBConfig });
};
const checkPermissionModifyCustomDBConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.modifyCustomDBConfig });
};
const checkPermissionGetCustomDBConfig = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getCustomDBConfig });
};

export {
  checkPermissionGetTenantInfo,
  checkPermissionCreateTenantInfo,
  checkPermissionSetLincenseData,
  checkPermissionModifyLicenseData,
  checkPermissionSetCompanyProfile,
  checkPermissionModifyCompanyProfile,
  checkPermissionSetPropertyDefaults,
  checkPermissionModifyPropertyDefaults,
  checkPermissionSetPyamentGatewayMerchant,
  checkPermissionSetTaxGatewayMerchant,
  checkPermissionSetFinanceGatewayMerchant,
  checkPermissionSetCrmGatewayProvider,
  checkPermissionSetDataGatewayProvider,
  checkPermissionSetUsageGatewayProvider,
  checkPermissionSetProvisioningGatewayProvider,
  checkPermissionModifyPaymentGatewayMerchant,
  checkPermissionModifyTaxGatewayMerchant,
  checkPermissionModifyFinanceGatewayMerchant,
  checkPermissionModifyCrmGatewayProvider,
  checkPermissionModifyDataGatewayProvider,
  checkPermissionModifyUsageGatewayProvider,
  checkPermissionModifyProvisioningGatewayProvider,
  checkPermissionCreateGatewayCanonical,
  checkPermissionModifyGatewayCanonical,
  checkPermissionGetGatewayCanonical,
  checkPermissionCreatePaymentMethodUrl,
  checkPermissionModifyPaymentMethodUrl,
  checkPermissionGetPaymentMethodUrl,
  checkPermissionGetGatewayCanonicalSpec,
  checkPermissionCreateCustomDBConfig,
  checkPermissionModifyCustomDBConfig,
  checkPermissionGetCustomDBConfig,
};
