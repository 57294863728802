import * as types from './types';

export function getTenantConfig(cb) {
  return {
    type: types.GET_TENANT_CONFIG,
    cb,
  };
}

export function getTenantConfigSuccess(payload) {
  return {
    type: types.GET_TENANT_CONFIG_SUCCESS,
    payload,
  };
}

export function getTenantDataConfig(cb) {
  return {
    type: types.GET_TENANT_DATA_CONFIG,
    cb,
  };
}

export function getTenantDataConfigSuccess(payload) {
  return {
    type: types.GET_TENANT_DATA_CONFIG_SUCCESS,
    payload,
  };
}

export function getTenantProfileConfig(cb) {
  return {
    type: types.GET_TENANT_PROFILE_CONFIG,
    cb,
  };
}

export function getTenantProfileConfigSuccess(payload) {
  return {
    type: types.GET_TENANT_PROFILE_CONFIG_SUCCESS,
    payload,
  };
}

export function getTenantMerchantDataConfig(cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_CONFIG,
    cb,
  };
}

export function getTenantMerchantDataProjectConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_PROJECT_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataPaymentConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_PAYMENT_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataTaxConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_TAX_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataFinanceConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_FINANCE_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataProvisioningConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_PROVISIONING_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataCustomerSupportConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_CUSTOMER_SUPPORT_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataDocumentConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_DOCUMENT_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataOperationConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_OPERATION_CONFIG,
    payload,
    cb,
  };
}

export function getTenantMerchantDataCRMConfig(payload, cb) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_CRM_CONFIG,
    payload,
    cb,
  };
}

export function getTenantFinanceGateway(payload, cb) {
  return {
    type: types.GET_TENANT_FINANCE_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantCRMGateway(payload, cb) {
  return {
    type: types.GET_TENANT_CRM_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantCustomerSupportGateway(payload, cb) {
  return {
    type: types.GET_TENANT_CUSTOMER_SPORT_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantDocumentGateway(payload, cb) {
  return {
    type: types.GET_TENANT_DOCUMENT_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantOperationGateway(payload, cb) {
  return {
    type: types.GET_TENANT_OPERATION_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantPaymentGateway(payload, cb) {
  return {
    type: types.GET_TENANT_PAYMENT_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantProjectGateway(payload, cb) {
  return {
    type: types.GET_TENANT_PROJECT_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantProvisionGateway(payload, cb) {
  return {
    type: types.GET_TENANT_PROVISION_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantTaxGateway(payload, cb) {
  return {
    type: types.GET_TENANT_TAX_GATEWAY,
    payload,
    cb,
  };
}

export function getTenantMerchantDataConfigSuccess(payload) {
  return {
    type: types.GET_TENANT_MERCHANT_DATA_CONFIG_SUCCESS,
    payload,
  };
}

export function modifyTenant(payload, cb) {
  return {
    type: types.MODIFY_TENANT,
    payload,
    cb,
  };
}

export function modifyTenantSuccess(payload) {
  return {
    type: types.MODIFY_TENANT_SUCCESS,
    payload,
  };
}

export function createTenant(payload, cb) {
  return {
    type: types.CREATE_TENANT,
    payload,
    cb,
  };
}

export function getCcpProperties(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES,
    payload,
    cb,
  };
}

export function getCcpPropertiesSuccess(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES_SUCCESS,
    payload,
    cb,
  };
}

export function modifyCcpProperties(payload, cb) {
  return {
    type: types.MODIFY_CCP_PROPERTIES,
    payload,
    cb,
  };
}

export function getPaymentMethodUrls(payload, cb) {
  return {
    type: types.GET_PAYMENT_METHOD_URL,
    payload,
    cb,
  };
}

export function getPaymentMethodUrlsSuccess(payload, cb) {
  return {
    type: types.GET_PAYMENT_METHOD_URL_SUCCESS,
    payload,
    cb,
  };
}

export function modifyPaymentMethodUrls(payload, cb) {
  return {
    type: types.MODIFY_PAYMENT_METHOD_URL,
    payload,
    cb,
  };
}

export function getCustomAttributesByObjectTypeTenant(cb) {
  return {
    type: types.GET_CUSTOM_ATTRIBUTES_TENANT,
    cb,
  };
}
export function ModifyCustomAttributesTenant(payload, cb) {
  return {
    type: types.MODIFY_CUSTOM_ATTRIBUTES_TENANT,
    payload,
    cb,
  };
}

export function createCustomAttributesTenant(payload, cb) {
  return {
    type: types.CREATE_CUSTOM_ATTRIBUTES_TENANT,
    payload,
    cb,
  };
}

export function getGatewayCanonicalConfigByName(payload, cb) {
  return {
    type: types.GET_GATEWAY_CANONICAL_CONFIG_BY_NAME,
    payload,
    cb,
  };
}

export function getGatewayCanonicalConfigByNameSuccess(payload, cb) {
  return {
    type: types.GET_GATEWAY_CANONICAL_CONFIG_BY_NAME_SUCCESS,
    payload,
    cb,
  };
}

export function getGatewayCanonicalByObjectType(payload, cb) {
  return {
    type: types.GET_GATEWAY_CANONICAL_BY_OBJECT_TYPE,
    payload,
    cb,
  };
}

export function modifyGatewayCanonicalMapCfg(payload, cb) {
  return {
    type: types.MODIFY_GETWAY_CANONICAL_CONFIG,
    payload,
    cb,
  };
}

export function getGatewayApiMapByName(payload, cb) {
  return {
    type: types.GET_GATEWAY_API_MAP_BY_NAME,
    payload,
    cb,
  };
}

export function getGatewayApiMapByNameSuccess(payload, cb) {
  return {
    type: types.GET_GATEWAY_API_MAP_BY_NAME_SUCCESS,
    payload,
    cb,
  };
}

export function getGatewayApiMapById(payload, cb) {
  return {
    type: types.GET_GETEWAY_API_MAP_BY_ID,
    payload,
    cb,
  };
}

export function getGatewayApiMapByIdSuccess(payload, cb) {
  return {
    type: types.GET_GETEWAY_API_MAP_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function modifyGatewayApiMapping(payload, cb) {
  return {
    type: types.MODIFY_GETEWAY_API_MAPPING,
    payload,
    cb,
  };
}

export function createGatewayApiMapping(payload, cb) {
  return {
    type: types.CREATE_GETEWAY_API_MAPPING,
    payload,
    cb,
  };
}

export function getApiByGatewayName(payload, cb) {
  return {
    type: types.GET_API_BY_GETEWAY_NAME,
    payload,
    cb,
  };
}

export function saveSelfcareConfig(payload, cb) {
  return {
    type: types.SAVE_SELF_CARE_CONFIG,
    payload,
    cb,
  };
}

export function getTenantSelfCareConfig(payload, cb) {
  return {
    type: types.GET_TENANT_SELF_CARE_CONFIG,
    payload,
    cb,
  };
}

export function modifyTenantSelfCareConfig(payload, cb) {
  return {
    type: types.MODIFY_TENANT_SELF_CARE_CONFIG,
    payload,
    cb,
  };
}

// Id Sequence Config
export function getIdSequenceConfig(payload, cb) {
  return {
    type: types.GET_ID_SEQUENCE_CONFIG,
    payload,
    cb,
  };
}

export function getIdSequenceConfigSuccess(payload, cb) {
  return {
    type: types.GET_ID_SEQUENCE_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function modifyIdSequenceConfig(payload, cb) {
  return {
    type: types.MODIFY_ID_SEQUENCE_CONFIG,
    payload,
    cb,
  };
}

export function createIdSequenceConfig(payload, cb) {
  return {
    type: types.CREATE_ID_SEQUENCE_CONFIG,
    payload,
    cb,
  };
}

export function searchSchedulePattern(payload, cb) {
  return {
    type: types.SEARCH_SCHEDULE_PATTERN,
    payload,
    cb,
  };
}

export function getSchedulePatternById(payload, cb) {
  return {
    type: types.GET_SCHEDULE_PATTERN_BY_ID,
    payload,
    cb,
  };
}

export function createSchedulePattern(payload, cb) {
  return {
    type: types.CREATE_SCHEDULE_PATTERN,
    payload,
    cb,
  };
}

export function modifySchedulePattern(payload, cb) {
  return {
    type: types.MODIFY_SCHEDULE_PATTERN,
    payload,
    cb,
  };
}

export function getGatewayData(payload, cb) {
  return {
    type: types.GET_GATEWAY_DATA,
    payload,
    cb,
  };
}

export function getGatewayDataSuccess(payload, cb) {
  return {
    type: types.GET_GATEWAY_DATA_SUCCESS,
    payload,
    cb,
  };
}

export function getTemplate(payload, cb) {
  return {
    type: types.GET_TEMPLATE,
    payload,
    cb,
  };
}

export function createTemplate(payload, cb) {
  return {
    type: types.CREATE_TEMPLATE,
    payload,
    cb,
  };
}

export function modifyTemplate(payload, cb) {
  return {
    type: types.MODIFY_TEMPLATE,
    payload,
    cb,
  };
}
