import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from '../../utils/request';
import {
  getTenantConfig,
  modifyTenant,
  createTenant,
  getCcpProperties,
  setCcpProperties,
  getPaymentMethodUrls,
  modifyPaymentMethodUrls,
  getCustomAttributesByObjectType,
  ModifyCustomAttributes,
  createCustomAttributes,
  getGatewayCanonicalConfigByName,
  getGatewayCanonicalByObjectType,
  modifyGatewayCanonicalMapCfg,
  getGatewayApiMapByName,
  getGatewayApiMapById,
  createGatewayApiMapping,
  modifyGatewayApiMapping,
  getApiByGatewayName,
  getTenantSelfCareConfig,
  getTenantMerchantDataConfig,
  uploadfFile,
  getCustomDBSeq,
  modifyCustomDBSeqObject,
  createCustomDBSeqObject,
  getTenantDataConfig,
  getTenantProfileConfig,
  getTenantMerchantDataProjectConfig,
  getTenantMerchantDataCRMConfig,
  getTenantMerchantDataCustomerSupportConfig,
  getTenantMerchantDataDocumentConfig,
  getTenantMerchantDataFinanceConfig,
  getTenantMerchantDataOperationConfig,
  getTenantMerchantDataPaymentConfig,
  getTenantMerchantDataProvisioningConfig,
  getTenantMerchantDataTaxConfig,
  getTenantFinanceGateway,
  getTenantCRMGateway,
  getTenantCustomerSupportGateway,
  getTenantDocumentGateway,
  getTenantOperationGateway,
  getTenantPaymentGateway,
  getTenantProjectGateway,
  getTenantProvisionGateway,
  getTenantTaxGateway,
  searchSchedulePattern,
  createSchedulePattern,
  modifySchedulePattern,
  getSchedulePatternById,
  getGatewayData,
  getTemplate,
  createTemplate,
  modifyTemplate,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getTenantSaga({ cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantConfig());
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantDataSaga({ cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantDataConfig());
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantDataConfigSuccess(response.getTenantConfig));
    } else {
      if (cb) yield cb({ success: false });
      yield put(actions.getTenantDataConfigSuccess({}));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getTenantDataConfigSuccess({}));
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantProfileSaga({ cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantProfileConfig());
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantProfileConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataConfig());
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataProjectConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataProjectConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataCRMConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataCRMConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataCustomerSupportConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataCustomerSupportConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataDocumentConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataDocumentConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataFinanceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataFinanceConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataOperationConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataOperationConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataPaymentConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataPaymentConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataProvisioningConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataProvisioningConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantMerchantDataTaxConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantMerchantDataTaxConfig(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantFinanceGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantFinanceGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantCRMGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantCRMGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantCustomerSupportGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantCustomerSupportGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantDocumentGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantDocumentGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantOperationGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantOperationGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantPaymentGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantPaymentGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantProjectGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantProjectGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantProvisionGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantProvisionGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getTenantTaxGatewaySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantTaxGateway(payload));
    if (response.getTenantConfig) {
      if (cb) yield cb({ success: true, data: response.getTenantConfig });
      yield put(actions.getTenantMerchantDataConfigSuccess(response.getTenantConfig));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* modifyTenantSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTenant(payload));
    if (response.modifyTenant) {
      yield put(isEndConnected());
      // yield put(actions.getTenantConfig());
      toast.success(i18n.t('common:apiMessage.modifyTenantSuccessfully'));
      if (cb) yield cb({ success: true });
    } else {
      yield put(isEndConnected());
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* createTenantSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTenant(payload));
    if (response.createTenant) {
      yield put(isEndConnected());
      yield put(actions.getTenantConfig());
      toast.success(i18n.t('common:apiMessage.createTenantSuccessfully'));
      if (cb) yield cb({ success: true });
    } else {
      yield put(isEndConnected());
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getCcpPropertiesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCcpProperties(payload));
    if (response.getCcpProperties) {
      if (cb) yield cb({ success: true, data: response.getCcpProperties });
      yield put(actions.getCcpPropertiesSuccess(response.getCcpProperties));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyCcpPropertiesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, setCcpProperties(payload));
    if (response.setCcpProperties) {
      yield put(isEndConnected());
      toast.success(i18n.t('common:apiMessage.modifyPropertiesSuccessfully'));
      if (cb) yield cb({ success: true });
    } else {
      yield put(isEndConnected());
      if (cb) yield cb({ success: false });
    }
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getPaymentMethodUrlsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentMethodUrls(payload));
    if (response.getPaymentMethodUrls) {
      if (cb) yield cb({ success: true, data: response.getPaymentMethodUrls });
      yield put(actions.getPaymentMethodUrlsSuccess(response.getPaymentMethodUrls));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyPaymentMethodUrlsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyPaymentMethodUrls(payload));
    if (response.modifyPaymentMethodUrls) {
      if (cb) yield cb({ success: true, data: response.modifyPaymentMethodUrls });
      toast.success(i18n.t('common:apiMessage.modifyURLMappingSuccessfully'));
      // yield put(actions.getPaymentMethodUrlsSuccess(response.getPaymentMethodUrls));
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyCustomAttributesByObjectTypeTenantSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    if (dataCreate.id) delete dataCreate.id;
    const res = yield call(mutationRequest, ModifyCustomAttributes(dataCreate));
    if (cb) cb({ success: true, data: res.modifyCustomAttributes });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyCustomAttributesSuccessfully'));
  } catch (err) {
    if (cb) cb();
    yield put(isEndConnected());
  }
}
export function* createCustomAttributesByObjectTypeTenantSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataCreate = payload;
    if (dataCreate.id) delete dataCreate.id;
    dataCreate.objectType = 'ACCOUNT'; // Hard code object type when create attribute
    const res = yield call(mutationRequest, createCustomAttributes(dataCreate));
    if (cb) cb({ success: true, data: res.createCustomAttributes });
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.createCustomAttributesSuccessfully'));
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
    yield put(isEndConnected());
  }
}

export function* getCustomAttributesByObjectTypeTenantSaga({ cb }) {
  try {
    const response = yield call(queryRequest, getCustomAttributesByObjectType());
    if (response.getCustomAttributesByObjectType) {
      if (cb) cb({ success: true, data: response.getCustomAttributesByObjectType });
    } else if (cb) cb({ success: false });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getGatewayCanonicalConfigByNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGatewayCanonicalConfigByName(payload));
    if (response.getGatewayCanonicalConfigByName) {
      if (cb) cb({ success: true, data: response.getGatewayCanonicalConfigByName });
      yield put(actions.getGatewayCanonicalConfigByNameSuccess(response.getGatewayCanonicalConfigByName));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getGatewayCanonicalByObjectTypeSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getGatewayCanonicalByObjectType(payload));
    if (response.getGatewayCanonicalByObjectType) {
      if (cb) cb({ success: true, data: response.getGatewayCanonicalByObjectType });
    } else if (cb) cb({ success: false });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* modifyGatewayCanonicalMapCfgSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyGatewayCanonicalMapCfg(payload));
    if (response.modifyGatewayCanonicalMapCfg) {
      if (cb) cb({ success: true, data: response.modifyGatewayCanonicalMapCfg });
      toast.success(i18n.t('common:apiMessage.modifyPaymentGatewaySuccessfully'));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getGatewayApiMapByNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGatewayApiMapByName(payload));
    if (response.getGatewayApiMapByName) {
      yield put(actions.getGatewayApiMapByNameSuccess(response.getGatewayApiMapByName));
      if (cb) cb({ success: true, data: response.getGatewayApiMapByName });
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getGatewayApiMapByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGatewayApiMapById(payload));
    if (response.getGatewayApiMapById) {
      if (cb) cb({ success: true, data: response.getGatewayApiMapById });
      yield put(actions.getGatewayApiMapByIdSuccess(response.getGatewayApiMapById));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* createGatewayApiMappingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createGatewayApiMapping(payload));
    if (response.createGatewayApiMapping) {
      if (cb) cb({ success: true, data: response.createGatewayApiMapping });
      toast.success(i18n.t('common:apiMessage.createAPIMappingSuccessfully'));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* modifyGatewayApiMappingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyGatewayApiMapping(payload));
    if (response.modifyGatewayApiMapping) {
      if (cb) cb({ success: true, data: response.modifyGatewayApiMapping });
      toast.success(i18n.t('common:apiMessage.modifyRequestResponseMappingSuccessfully'));
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getApiByGatewayNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, getApiByGatewayName(payload));
    if (response.getApiByGatewayName) {
      if (cb) cb({ success: true, data: response.getApiByGatewayName });
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getTenantSelfCareSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTenantSelfCareConfig(payload));
    if (response.getTenantSelfCareConfig) {
      if (cb) cb({ success: true, data: response.getTenantSelfCareConfig });
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* saveSelfCareConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  // try {
  //   const response = yield call(queryRequest, SaveTenantSelfCareConfig(payload));
  //   if (response.SaveTenantSelfCareConfig) {
  //     toast.success('Create Self Care successfully!');
  //     if (cb) cb({ success: true, data: response.SaveTenantSelfCareConfig });
  //   } else if (cb) cb({ success: true });
  //   yield put(isEndConnected());
  // } catch (err) {
  //   if (cb) cb({ success: false });
  //   yield put(isEndConnected());
  // }
  try {
    const response = yield call(uploadfFile, payload);
    if (response.data.errors && response.data.errors[0]) {
      toast.error(response.data.errors[0].message);
      if (cb) cb({ success: false });
    } else {
      toast.success(i18n.t('common:apiMessage.createSelfCareConfigSuccessfully'));
      if (cb) cb({ success: true, data: response });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* modifyTenantSelfCareConfigSaga({ payload, cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(uploadfFile, payload);
    if (response.data.errors && response.data.errors[0]) {
      toast.error(response.data.errors[0].message);
      if (cb) cb({ success: false });
    } else {
      toast.success(i18n.t('common:apiMessage.modifySelfCareConfigSuccessfully'));
      if (cb) cb({ success: true, data: response });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

// Id Sequence Config
export function* getIdSequenceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCustomDBSeq(payload));
    if (response.getCustomDBSeq) {
      if (cb) cb({ success: true, data: response.getCustomDBSeq });
      yield put(actions.getIdSequenceConfigSuccess(response.getCustomDBSeq));
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* createIdSequenceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCustomDBSeqObject(payload));
    if (response.createCustomDBSeq) {
      if (cb) cb({ success: true, data: response.createCustomDBSeq });
      toast.success(i18n.t('common:apiMessage.createIdSequenceConfigSuccessfully'));
    } else if (cb) cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* modifyIdSequenceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCustomDBSeqObject(payload));
    if (response.modifyCustomDBSeq) {
      if (cb) cb({ success: true, data: response.modifyCustomDBSeq });
      toast.success(i18n.t('common:apiMessage.modifyIdSequenceConfigSuccessfully'));
    } else if (cb) cb({ success: true });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* searchSchedulePatternSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchSchedulePattern({ page, size, filter, sort }));
    if (response.searchSchedulePattern) {
      if (cb) cb({ success: true, data: response.searchSchedulePattern });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getSchedulePatternByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSchedulePatternById(payload));
    if (response.getSchedulePatternById) {
      if (cb) cb({ success: true, data: response.getSchedulePatternById });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createSchedulePatternSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createSchedulePattern(payload));
    if (response.createSchedulePattern) {
      toast.success(i18n.t('common:apiMessage.createSchedulePatternSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createSchedulePattern });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifySchedulePatternSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifySchedulePattern(payload));
    if (response.modifySchedulePattern) {
      toast.success(i18n.t('common:apiMessage.modifySchedulePatternSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifySchedulePattern });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* getGatewayDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGatewayData(payload));
    if (response.getTenantConfig) {
      yield put(actions.getGatewayDataSuccess(response.getTenantConfig));
      if (cb) cb({ success: true, data: response.getTenantConfig });
    } else {
      yield put(actions.getGatewayDataSuccess(null));
      if (cb) cb({ success: false, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getGatewayDataSuccess(null));
    if (cb) cb({ success: false, data: null });
  }
}

export function* getTemplateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTemplate(payload));
    if (response.getTemplate) {
      if (cb) cb({ success: true, data: response.getTemplate });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* createTemplateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createTemplate(payload));
    if (response.createTemplate) {
      toast.success(i18n.t('common:apiMessage.createTemplateSuccessfully'));
      if (cb) yield cb({ success: true, data: response.createTemplate });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyTemplateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyTemplate(payload));
    if (response.modifyTemplate) {
      toast.success(i18n.t('common:apiMessage.modifyTemplateSuccessfully'));
      if (cb) yield cb({ success: true, data: response.modifyTemplate });
    } else if (cb) yield cb({ success: false });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export default function* tenantManagementSaga() {
  yield takeLatest(types.GET_TENANT_CONFIG, getTenantSaga);
  yield takeLatest(types.MODIFY_TENANT, modifyTenantSaga);
  yield takeLatest(types.CREATE_TENANT, createTenantSaga);
  yield takeLatest(types.GET_CCP_PROPERTIES, getCcpPropertiesSaga);
  yield takeLatest(types.MODIFY_CCP_PROPERTIES, modifyCcpPropertiesSaga);
  yield takeLatest(types.GET_PAYMENT_METHOD_URL, getPaymentMethodUrlsSaga);
  yield takeLatest(types.MODIFY_PAYMENT_METHOD_URL, modifyPaymentMethodUrlsSaga);
  yield takeLatest(types.GET_CUSTOM_ATTRIBUTES_TENANT, getCustomAttributesByObjectTypeTenantSaga);
  yield takeLatest(types.MODIFY_CUSTOM_ATTRIBUTES_TENANT, modifyCustomAttributesByObjectTypeTenantSaga);
  yield takeLatest(types.CREATE_CUSTOM_ATTRIBUTES_TENANT, createCustomAttributesByObjectTypeTenantSaga);
  yield takeLatest(types.GET_GATEWAY_CANONICAL_CONFIG_BY_NAME, getGatewayCanonicalConfigByNameSaga);
  yield takeLatest(types.GET_GATEWAY_CANONICAL_BY_OBJECT_TYPE, getGatewayCanonicalByObjectTypeSaga);
  yield takeLatest(types.MODIFY_GETWAY_CANONICAL_CONFIG, modifyGatewayCanonicalMapCfgSaga);
  yield takeLatest(types.GET_GATEWAY_API_MAP_BY_NAME, getGatewayApiMapByNameSaga);
  yield takeLatest(types.GET_GETEWAY_API_MAP_BY_ID, getGatewayApiMapByIdSaga);
  yield takeLatest(types.CREATE_GETEWAY_API_MAPPING, createGatewayApiMappingSaga);
  yield takeLatest(types.MODIFY_GETEWAY_API_MAPPING, modifyGatewayApiMappingSaga);
  yield takeLatest(types.GET_API_BY_GETEWAY_NAME, getApiByGatewayNameSaga);
  yield takeLatest(types.GET_TENANT_SELF_CARE_CONFIG, getTenantSelfCareSaga);
  yield takeLatest(types.SAVE_SELF_CARE_CONFIG, saveSelfCareConfigSaga);
  // yield takeEvery(types.SAVE_SELF_CARE_CONFIG, saveSelfCareConfigSaga);
  yield takeLatest(types.MODIFY_TENANT_SELF_CARE_CONFIG, modifyTenantSelfCareConfigSaga);
  yield takeLatest(types.CREATE_ID_SEQUENCE_CONFIG, createIdSequenceConfigSaga);
  yield takeLatest(types.MODIFY_ID_SEQUENCE_CONFIG, modifyIdSequenceConfigSaga);
  yield takeLatest(types.GET_ID_SEQUENCE_CONFIG, getIdSequenceConfigSaga);
  yield takeLatest(types.GET_TENANT_DATA_CONFIG, getTenantDataSaga);
  yield takeLatest(types.GET_TENANT_PROFILE_CONFIG, getTenantProfileSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_CONFIG, getTenantMerchantDataConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_PROJECT_CONFIG, getTenantMerchantDataProjectConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_PAYMENT_CONFIG, getTenantMerchantDataPaymentConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_TAX_CONFIG, getTenantMerchantDataTaxConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_FINANCE_CONFIG, getTenantMerchantDataFinanceConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_PROVISIONING_CONFIG, getTenantMerchantDataProvisioningConfigSaga);
  yield takeLatest(
    types.GET_TENANT_MERCHANT_DATA_CUSTOMER_SUPPORT_CONFIG,
    getTenantMerchantDataCustomerSupportConfigSaga
  );
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_DOCUMENT_CONFIG, getTenantMerchantDataDocumentConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_OPERATION_CONFIG, getTenantMerchantDataOperationConfigSaga);
  yield takeLatest(types.GET_TENANT_MERCHANT_DATA_CRM_CONFIG, getTenantMerchantDataCRMConfigSaga);
  yield takeLatest(types.GET_TENANT_FINANCE_GATEWAY, getTenantFinanceGatewaySaga);
  yield takeLatest(types.GET_TENANT_CRM_GATEWAY, getTenantCRMGatewaySaga);
  yield takeLatest(types.GET_TENANT_CUSTOMER_SPORT_GATEWAY, getTenantCustomerSupportGatewaySaga);
  yield takeLatest(types.GET_TENANT_DOCUMENT_GATEWAY, getTenantDocumentGatewaySaga);
  yield takeLatest(types.GET_TENANT_OPERATION_GATEWAY, getTenantOperationGatewaySaga);
  yield takeLatest(types.GET_TENANT_PAYMENT_GATEWAY, getTenantPaymentGatewaySaga);
  yield takeLatest(types.GET_TENANT_PROJECT_GATEWAY, getTenantProjectGatewaySaga);
  yield takeLatest(types.GET_TENANT_PROVISION_GATEWAY, getTenantProvisionGatewaySaga);
  yield takeLatest(types.GET_TENANT_TAX_GATEWAY, getTenantTaxGatewaySaga);
  yield takeLatest(types.SEARCH_SCHEDULE_PATTERN, searchSchedulePatternSaga);
  yield takeLatest(types.CREATE_SCHEDULE_PATTERN, createSchedulePatternSaga);
  yield takeLatest(types.MODIFY_SCHEDULE_PATTERN, modifySchedulePatternSaga);
  yield takeLatest(types.GET_SCHEDULE_PATTERN_BY_ID, getSchedulePatternByIdSaga);
  yield takeLatest(types.GET_GATEWAY_DATA, getGatewayDataSaga);
  yield takeLatest(types.GET_TEMPLATE, getTemplateSaga);
  yield takeLatest(types.CREATE_TEMPLATE, createTemplateSaga);
  yield takeLatest(types.MODIFY_TEMPLATE, modifyTemplateSaga);
}
