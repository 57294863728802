import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isArray, size } from 'lodash';
import listCurrency from '../../../../constantsApp/currency.json';
import SubForm from '../../../../components/form/SubForm';
import TierForm from './TierForm';
import { FormCollapse } from '../../../../components/common';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const priceInputs = [
  {
    label: 'common:label.currency',
    wrapperClass: 'col-md-4',
    name: 'currencyId',
    type: 'select',
    options: currencyOptions,
    menuPortalTarget: true,
  },
  {
    label: 'common:label.amount',
    wrapperClass: 'col-md-4',
    name: 'amount',
    type: 'number',
    required: true,
    isNegativeValue: true,
  },
];

const tierInputs = [
  {
    label: 'label.startRange',
    wrapperClass: 'col-md-4',
    name: 'minimum',
    type: 'number',
    required: true,
  },
  {
    label: 'label.endRange',
    wrapperClass: 'col-md-4',
    name: 'maximum',
    type: 'number',
    required: true,
  },
];

const PricesFormRecurringTiered = ({
  headerTitle,
  item,
  index,
  onRemoveItem,
  priceRemovable,
  onChangePrice,
  creatingMode,
  onChangeTier,
  onAddCurrency,
  onAddGrantsRecurring,
  onAddAccumulatorsRecurring,
  handleRemovePrice,
  handleRemoveGrantsRecurring,
  handleRemoveAccumulatorsRecurring,
  handleAddTierRecurring,
  optionsAccumulatorId,
  onRemoveTier,
  onUpdateTierItem,
  optionsGrantId,
  discountingModel,
  onChangeRecurringPriceStructure,
  isComplexUsage,
  onAddAccumulatorsWithOtherType,
  onAddGrantWithOtherType,
  onChangeGrantWithOtherType,
  onChangeAccumulatorsWithOtherType,
}) => {
  const [activeTab, setActiveTab] = useState({});

  const onToggleTab = activeTabName => {
    // const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
      // this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };
  const adaptedInputs = priceInputs.map(pin => ({
    ...pin,
    disabled: !creatingMode && pin.name !== 'amount',
  }));
  const { t } = useTranslation('common');

  // if (!item.discounts && !item.tiers) return '';
  const grantInputs = [
    {
      label: 'label.grantId',
      wrapperClass: 'col-md-4',
      name: 'resourceId',
      type: 'select',
      required: true,
      options: optionsGrantId,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'grantUnit',
      type: 'select',
      required: true,
      tOptions: 'selections:grantUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'grantDuration',
      type: 'number',
      required: true,
    },
  ];

  const accumulatorInputs = [
    {
      label: 'label.accumulatorId',
      wrapperClass: 'col-md-4',
      name: 'accumulatorId',
      type: 'select',
      required: true,
      options: optionsAccumulatorId,
      isClearable: true,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'unit',
      type: 'select',
      required: true,
      tOptions: 'selections:accumulatorUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'duration',
      type: 'number',
      required: true,
    },
  ];

  return (
    <div>
      {item &&
        item.length &&
        item.map((val, indexTierPricing) => (
          <div className="card price-panel" key={`recurring-${indexTierPricing}`}>
            <div className="card-header">
              {t('label.discount')}
#
              {index + 1}
            </div>
            <div className="card-body">
              {val.tiers &&
                val.tiers
                  .filter(v => size(v) > 1)
                  .map((tier, idx) => {
                    let title = '-';
                    if (tier.minimum && tier.minimum !== '0') {
                      title = `<${tier.minimum}>${title}`;
                    } else {
                      title = `<0>${title}`;
                    }
                    if (tier.maximum && tier.maximum !== '0') {
                      title = `${title}<${tier.maximum}>`;
                    } else {
                      title = `${title}<0>`;
                    }
                    return (
                      <div className="card-body" key={`tier-${index}-${idx}`}>
                        <FormCollapse
                          key={`tier-${idx}`}
                          label="First Name"
                          isActive={activeTab && `tier-${index}-${idx}` === activeTab.name && activeTab.isActive}
                          title={title}
                          state={`tier-${index}-${idx}`}
                          onToggleTab={onToggleTab}
                        >
                          <div className="card-header">
                            {`${t('label.addTier')}#`}
                            {idx + 1}
                            {tier && tier.minimum ? <span className="ml-3">{tier.minimum}</span> : ''}
                            {tier && tier.maximum && tier.minimum ? '-' : ''}
                            {tier && tier.maximum ? <span>{tier.maximum}</span> : ''}
                            {val.tiers.length > 1 && (
                              <button
                                type="button"
                                className="btn-rm-card"
                                onClick={() => onRemoveTier({ idx, index, indexTierPricing })}
                              >
                                <i className="fa fa-trash" />
                              </button>
                            )}
                          </div>
                          <SubForm
                            headerTitle={`#${idx + 1}`}
                            inputs={tierInputs}
                            onRemoveItem={e => {
                              e.preventDefault();
                              return onRemoveItem(idx, val);
                            }}
                            item={tier}
                            onChangeItem={data => onChangeTier(idx, data)}
                            isRemoveable={creatingMode && val.tiers.length > 1 && priceRemovable}
                          />
                          <TierForm
                            data={tier}
                            onAddCurrency={() => onAddCurrency({ idx, index, indexTierPricing })}
                            handleRemovePrice={idxPrice => handleRemovePrice({ idxPrice, idx })}
                            onAddAccumulators={() => onAddAccumulatorsRecurring({ idx, indexTierPricing })}
                            handleRemoveGrants={idxGrant =>
                              handleRemoveGrantsRecurring({ idxGrant, idx, indexTierPricing })
                            }
                            handleRemoveAccumulators={idxAccumulator =>
                              handleRemoveAccumulatorsRecurring({ idxAccumulator, idx, indexTierPricing })
                            }
                            optionsAccumulatorId={optionsAccumulatorId}
                            optionsGrantId={optionsGrantId}
                            onChangeRecurringPriceStructure={data =>
                              onChangeRecurringPriceStructure({ data, idx, index })
                            }
                            grantInputs={grantInputs}
                            accumulatorInputs={accumulatorInputs}
                            isTier={false}
                          />
                        </FormCollapse>
                      </div>
                    );
                  })}
              <div className="row col-md-12 pl-3 pb-2">
                {val.tiers && isArray(val.tiers) && (
                  <div className="p-2 m-auto">
                    <button
                      type="button"
                      className="button button-border gray x-small"
                      onClick={() => handleAddTierRecurring({ indexTierPricing })}
                    >
                      {`+ ${t('label.addTier')}`}
                    </button>
                  </div>
                )}
                {item.accumulators && (
                  <button
                    type="button"
                    className="button button-border mt-3 black x-small"
                    onClick={() => onAddAccumulatorsWithOtherType({ index })}
                    disabled={item.accumulators.length >= optionsAccumulatorId.length}
                  >
                    {`+ ${t('label.addNewAccumulators')}`}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

PricesFormRecurringTiered.propTypes = {
  item: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
  priceRemovable: PropTypes.bool,
  creatingMode: PropTypes.bool,
};

PricesFormRecurringTiered.defaultProps = {
  item: [],
  isSubmitting: false,
  priceRemovable: true,
  creatingMode: true,
  onRemoveItem: () => {},
};
export default PricesFormRecurringTiered;
