const options = {
  apiVersion: 'v1',
  endpoint: process?.env?.REACT_APP_VAULT_URL || 'http://54.147.94.240:8200',
  token: process?.env?.REACT_APP_VAULT_TOKEN || '3LaGr4o7UxwYHfdfMR7BQz1W',
};
const vault = require('node-vault')(options);

export default () => {
  return vault.read(process?.env?.REACT_APP_VAULT_FOLDER_PATH || 'secret/application/service/app-common/pg');
};
