import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormWithTableItem, DataTable, GenericInput } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import RouteNames from '../../App/RouteNames';
import { getARReasonCodeConfig, modifyARReasonCodeConfig, createARReasonCodeConfig } from '../actions';
// import { makeGetPermissionsArOpsManagement } from '../../App/selectors';
import Footer from '../../../components/Footer';
import { getIndexData, convertBooleanDataView } from '../../../utils/utils';

class ReasonCode extends React.PureComponent {
  state = {
    data: [],
    isLoading: false,
    wasValidated: false,
    itemConfig: {},
    isModify: true,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.doGetARReasonCodeConfig();
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  doGetARReasonCodeConfig = () => {
    const { getARReasonCodeConfig } = this.props;
    this.setState({ isLoading: true });
    getARReasonCodeConfig('', ({ success, data }) => {
      this.setState({ isLoading: false });
      if (success) {
        this.setState({
          data: data && data.arReasonCodes ? data.arReasonCodes : [],
          itemConfig: data,
          isModify: !!(data && data.id),
          wasValidated: false,
        });
      }
    });
  };

  onChange = ({ index, name, value }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    let newValue = value;
    if (newValue === 'TRUE' || newValue === 'FALSE') newValue = newValue === 'TRUE' ? 'true' : 'false';
    newData[getIndexData({ index, data: newData })][name] = newValue;
    this.setState({ data: newData });
  };

  addNewItem = () => {
    try {
      const { data } = this.state;
      let newData = cloneDeep(data);
      let lastIndex = 0;
      newData.forEach(val => {
        if (val.index > lastIndex) lastIndex = val.index;
      });
      const newDataItem = {
        index: lastIndex + 1,
        reasonCode: null,
        description: null,
        customReasonCode: null,
        stampingRequired: 'false',
      };
      newData = [newDataItem, ...newData];
      this.setState({ data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveItem = ({ index }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    newData[getIndexData({ index, data: newData })] = {
      index: newData[getIndexData({ index, data: newData })].index,
    };
    this.setState({ data: newData });
  };

  onHandleSave = evt => {
    evt.preventDefault();
    const { data, itemConfig, isModify } = this.state;
    const { modifyARReasonCodeConfig, createARReasonCodeConfig } = this.props;
    this.setState({ wasValidated: true });
    if (!this.validate(true)) {
      return false;
    }
    const arReasonCodes = cloneDeep(data);
    if (isModify) {
      modifyARReasonCodeConfig({ type: itemConfig && itemConfig.type ? itemConfig.type : '', arReasonCodes }, () =>
        this.doGetARReasonCodeConfig()
      );
    } else {
      const arReasonCodesCreate = arReasonCodes.map(val => {
        const { index, ...rest } = val;
        return rest;
      });
      createARReasonCodeConfig({ type: 'AR_REASON_CODE', arReasonCodes: arReasonCodesCreate }, () =>
        this.doGetARReasonCodeConfig()
      );
    }
  };

  render() {
    const { t } = this.props;
    const { data, isLoading, wasValidated, isModify } = this.state;
    const tableColumns = [
      {
        name: 'reasonCode',
        label: 'label.reasonCode',
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.reasonCode}
              name="reasonCode"
              type="select"
              tOptions="selections:reasonConfig"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'customReasonCode',
        label: 'label.customReasonCode',
        style: { minWidth: '280px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.customReasonCode}
              name="customReasonCode"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="textarea"
            />
          );
        },
      },
      {
        name: 'description',
        label: 'label.description',
        style: { minWidth: '280px' },
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={item.description}
              name="description"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              required
              type="textarea"
            />
          );
        },
      },
      {
        name: 'invoiceCancellation',
        label: 'label.invoiceCancellation',
        style: { minWidth: '100px' },
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={convertBooleanDataView(item.invoiceCancellation)}
              name="invoiceCancellation"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'fullCredit',
        label: 'label.fullCredit',
        style: { minWidth: '100px' },
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={convertBooleanDataView(item.fullCredit)}
              name="fullCredit"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'stampingRequired',
        label: 'label.stamping',
        style: { minWidth: '100px' },
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={convertBooleanDataView(item.stampingRequired)}
              name="stampingRequired"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:selectBool"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'objectType',
        label: 'label.objectType',
        required: true,
        style: { minWidth: '200px' },
        render: (name, item) => {
          return (
            <GenericInput
              value={item.objectType}
              name="objectType"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:arReasonCodeObjectType"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'status',
        label: 'label.status',
        style: { minWidth: '200px' },
        required: true,
        render: (name, item) => {
          return (
            <GenericInput
              value={item.status}
              name="status"
              onChange={({ name, value }) => this.onChange({ index: item.index, name, value })}
              wrapperClass="col-md-12"
              type="select"
              tOptions="selections:arReasonCodeStatus"
              menuPortalTarget
            />
          );
        },
      },
      {
        name: 'remove',
        label: t('label.remove'),
        render: (colName, item) => {
          return (
            <button type="button" className="btn-phone" onClick={() => this.onRemoveItem({ index: item.index })}>
              <i className="fa fa-trash" />
            </button>
          );
        },
      },
    ];

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteNames.arOperationsAdjustments.path}
          titleBtn={t('label.back')}
          titleRight={t('label.arReasonCodeConfig')}
          isShowNoteConfig
        />
        <div className="col-md-12 mb-30">
          <FormWithTableItem title={t('label.arReasonCodeConfig')}>
            <form
              noValidate
              ref={this.formRef}
              onSubmit={this.onHandleSave}
              className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
            >
              <div className="form-group col-md-12 buttons-attibute">
                <button type="button" className="button button-border black x-small" onClick={this.addNewItem}>
                  +
                  {t('label.addNewReasonCodeMapping')}
                </button>
                <button
                  type="submit"
                  className="button button-border x-small float-right"
                  // onClick={() => this.onHandleSave()}
                >
                  {t('label.saveConfig')}
                </button>
                <button
                  type="button"
                  onClick={this.doGetARReasonCodeConfig}
                  className="button button-border black x-small float-right"
                >
                  {t('label.cancel')}
                </button>
              </div>
              <DataTable
                columns={tableColumns}
                data={data && data.length ? data.filter(val => size(val) > 1) : []}
                isLoading={isLoading}
              />
            </form>
          </FormWithTableItem>
        </div>
        <Footer className="footer-bottom" />
      </div>
    );
  }
}

ReasonCode.propTypes = {
  getARReasonCodeConfig: PropTypes.func,
  modifyARReasonCodeConfig: PropTypes.func,
};

ReasonCode.defaultProps = {
  getARReasonCodeConfig: () => {},
  modifyARReasonCodeConfig: () => {},
};

const mapStateToProps = createStructuredSelector({});

export default withTranslation('common')(
  connect(mapStateToProps, { getARReasonCodeConfig, modifyARReasonCodeConfig, createARReasonCodeConfig })(
    withRouter(ReasonCode)
  )
);
