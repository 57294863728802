import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { searchUserByTypeAgent } from '../../containers/App/actions';
import ModalSelector from '../Modals/ModalSelector';
import { UserSearchForm } from '../OperationHub';

const UserSort = {
  userId: {
    asc: 'userId_ASC',
    desc: 'userId_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const tableColumns = [
  {
    name: 'id',
    label: 'label.id',
  },
  {
    name: 'userId',
    label: 'label.userId',
    sortable: true,
  },
  {
    name: 'type',
    label: 'label.type',
    sortable: true,
  },
  {
    name: 'category',
    label: 'label.category',
    sortable: true,
    style: { minWidth: '100px' },
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:category')().find(val => val.value === item.category) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'status',
    label: 'label.status',
    sortable: true,
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
];

class ModalSelectUserId extends PureComponent {
  render() {
    const {
      isOpen,
      searchUserByTypeAgent,
      onSelect,
      onCancel,
      selectedParentId,
      t,
      defaultSearchParams,
      checkDisabledFunc,
    } = this.props;
    return (
      <ModalSelector
        modalTitle={t('label.chooseUser')}
        isOpen={isOpen}
        SearchForm={props => <UserSearchForm defaultFilter={defaultSearchParams || null} {...props} />}
        searchItems={searchUserByTypeAgent}
        tableColumns={tableColumns}
        onSelect={onSelect}
        onCancel={onCancel}
        toggleModal={onCancel}
        selectedParentId={selectedParentId}
        arraySort={UserSort}
        defaultSearchParams={defaultSearchParams}
        checkDisabledFunc={checkDisabledFunc}
      />
    );
  }
}

ModalSelectUserId.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  selectedParentId: PropTypes.string,
  searchUserByTypeAgent: PropTypes.func,
  defaultSearchParams: PropTypes.arrayOf(PropTypes.any),
};

ModalSelectUserId.defaultProps = {
  onSelect: () => {},
  onCancel: () => {},
  searchUserByTypeAgent: () => {},
  selectedParentId: '',
  defaultSearchParams: null,
};

const mapStateToProps = createStructuredSelector({});

const newModalSelectItem = connect(mapStateToProps, { searchUserByTypeAgent })(ModalSelectUserId);

export default withTranslation(['common', 'selections'])(newModalSelectItem);
