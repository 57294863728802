import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { ProcessingStreamsParentForm } from '../../../../components/BaseConfigurationsHub';
import { validate } from '../../../../utils/utils';

class NewProcessingStreams extends PureComponent {
  state = { data: [], wasValidated: false };

  formRef = React.createRef();

  componentDidMount() {
    const { data } = this.props;
    if (data) this.setState({ data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }
  }

  onHanldeSubmit = evt => {
    evt.preventDefault();

    const { data } = this.state;
    const { onSaveData, indexExpand, t } = this.props;
    this.setState({ wasValidated: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    this.setState({ wasValidated: false });
    return onSaveData(data, indexExpand);
  };

  onUpdateProcessingValue = ({ dataProcess, index }) => {
    const { data } = this.state;
    data.usageProcessStreamSeqCfg = dataProcess;
    this.setState({ ...data });
  };

  onChangeRating = ({ value, name }) => {
    const { data } = this.state;
    const newData = cloneDeep(data);
    if (typeof value === 'boolean') {
      newData[name] = value ? 'true' : 'false';
    } else newData[name] = value;
    this.setState({ data: newData });
  };

  render() {
    const { data, wasValidated } = this.state;
    const { t, onRemove, isHasNew, onAddNewItem, indexExpand } = this.props;
    return (
      <form
        noValidate
        ref={this.formRef}
        onSubmit={this.onHanldeSubmit}
        className={`pr-2 pb-4 needs-validation ${wasValidated ? 'was-validated' : ''}`}
      >
        <div className="form-config-streams">
          <ProcessingStreamsParentForm
            data={data}
            onUpdateProcessingValue={this.onUpdateProcessingValue}
            onChangeRating={this.onChangeRating}
            onRemoveItem={onRemove}
          />
        </div>
        <div className="form-group col-md-12 pt-3 pb-3">
          <button type="submit" className="button button-border x-small float-right ml-2">
            {t('label.saveConfig')}
          </button>
          <button
            type="button"
            className="button button-border black x-small float-right"
            onClick={() => onAddNewItem(indexExpand)}
            disabled={isHasNew}
          >
            {`+ ${t('label.copyToAdd')}`}
          </button>
          {/* <button
            type="button"
            className="button button-border gray x-small float-right"
            onClick={this.onToggleModalNotes}
          >
            {t('label.quickNotes')}
            <i className="fa fa-external-link ml-2" aria-hidden="true" />
          </button> */}
        </div>
        {/* <ModalViewNote isOpen={isOpenModalNotes} message={messageNotesStream(t)} onToggle={this.onToggleModalNotes} /> */}
      </form>
    );
  }
}

NewProcessingStreams.propTypes = {};

NewProcessingStreams.defaultProps = {};

export default withTranslation('common')(NewProcessingStreams);
