import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GenericInput } from '../../common';
import { collectionsSelect } from '../../../constantsApp';
import ModalSelectUserId from '../ModalSelectUserId';

export default function FormAddCollectionAgent({
  itemNew,
  onChange,
  onAddNew,
  isEnableAddBtn,
  modeCreateCollectionAgentConfig,
}) {
  const { t } = useTranslation('common');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idSelect, setIdSelect] = useState('');

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="form-group col-md-12 p-5">
      <div className="col-md-12 d-flex">
        <GenericInput
          name="userId"
          label="common:label.userId"
          value={itemNew.userId}
          // options={[
          //   { value: 'USER10000000001', label: 'USER10000000001' },
          //   { value: 'USER10000000002', label: 'USER10000000002' },
          //   { value: 'UserX001', label: 'UserX001' },
          //   { value: 'UserX002', label: 'UserX002' },
          //   { value: 'UserX003', label: 'UserX003' },
          // ]}
          onChange={() => {}}
          onClick={toggleModal}
          fa="fa fa-external-link"
          wrapperClass="col-md-3"
        />
        <GenericInput
          name="firstName"
          label="common:label.firstName"
          value={itemNew.firstName}
          disabled
          wrapperClass="col-md-3"
          options={collectionsSelect.action}
          onChange={onChange}
        />
        <GenericInput
          label="common:label.lastName"
          type="text"
          onChange={onChange}
          name="lastName"
          disabled
          value={itemNew.lastName}
          wrapperClass="col-md-3"
        />
        <GenericInput
          label="common:label.email"
          type="text"
          onChange={onChange}
          name="emailId"
          disabled
          value={itemNew.emailId}
          wrapperClass="col-md-3"
        />
      </div>
      <div className="col-md-12 pt-3 action-buttons">
        <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onAddNew}
          disabled={!isEnableAddBtn || modeCreateCollectionAgentConfig === 1}
        >
          {`+ ${t('label.addNewAgent')}`}
        </button>
        {/* <button
          type="button"
          className="button button-border x-small float-right"
          onClick={onRemove}
          disabled={isEnableRemove}
        >
          Remove Agent
        </button> */}
      </div>
      <ModalSelectUserId
        isOpen={isOpenModal}
        selectedParentId={idSelect}
        onSelect={(id, row) => {
          onChange({ name: 'userId', value: row.userId, item: row });
          setIdSelect(id);
          toggleModal();
        }}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        defaultSearchParams={{ category: 'AGENT' }}
        checkDisabledFunc={item => {
          if (item && item.contact && item.contact[0] && item.contact[0].firstName && item.contact[0].lastName)
            return false;
          return true;
        }}
      />
    </div>
  );
}

FormAddCollectionAgent.propTypes = {
  isEnableAddBtn: PropTypes.bool,
  onChange: PropTypes.func,
  onAddNew: PropTypes.func,
  itemNew: PropTypes.objectOf(PropTypes.any),
  modeCreateCollectionAgentConfig: PropTypes.number,
};

FormAddCollectionAgent.defaultProps = {
  isEnableAddBtn: false,
  itemNew: {},
  onChange: () => {},
  onAddNew: () => {},
  modeCreateCollectionAgentConfig: 0,
};
