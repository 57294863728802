import * as types from './constants';

export const doLogin = ({ email, password }, callback = () => {}) => ({
  type: types.LOGIN_REQUEST,
  payload: { email, password },
  callback,
});

export const doLogout = (callback = () => {}) => ({
  type: types.LOGOUT,
  callback,
});

export const loadUser = (callback = () => {}) => ({
  type: types.LOAD_USER_REQUEST,
  callback,
});

// ====================================
// old actions
// ====================================
export function selectItemsId(payload, cb) {
  return {
    type: types.SELECT_ITEMS_ID,
    payload,
    cb,
  };
}

export function selectPriceOfferId(payload, cb) {
  return {
    type: types.SELECT_PRICE_OFFER_ID,
    payload,
    cb,
  };
}

export function selectBundleId(payload, cb) {
  return {
    type: types.SELECT_BUNDLE_ID,
    payload,
    cb,
  };
}

export function selectPackageId(payload, cb) {
  return {
    type: types.SELECT_PACKAGE_ID,
    payload,
    cb,
  };
}

export function selectAccountId(payload, cb) {
  return {
    type: types.SELECT_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function selectInvoiceId(payload, cb) {
  return {
    type: types.SELECT_INVOICE_UNITS_ID,
    payload,
    cb,
  };
}

export function getBundleByBundleId(payload, cb) {
  return {
    type: types.GET_BUNDLE_BY_BUNDLE_ID,
    payload,
    cb,
  };
}

export function selectBillUnitId(payload, cb) {
  return {
    type: types.SELECT_BILL_UNITS_ID,
    payload,
    cb,
  };
}

export function getAccountDetail(payload, cb) {
  return {
    type: types.GET_ACCOUNT_DETAIL,
    payload,
    cb,
  };
}

// Logout
export function logout() {
  return {
    type: types.LOGOUT,
  };
}

// get Details Login
export function checkLogin(cb) {
  return {
    type: types.CHECK_LOGIN_USER,
    cb,
  };
}

// change password first time
export function handleChangePassword(newPassword, cb) {
  return {
    type: types.HANDLE_CHANGE_PASSWORD,
    newPassword,
    cb,
  };
}

// permissions
export function getRoleAndRoleGroupById(payload, cb) {
  return {
    type: types.GET_ROLE_AND_ROLE_GROUP_BY_ID,
    payload,
    cb,
  };
}

export function getRoleById(payload, cb) {
  return {
    type: types.GET_ROLE_BY_ID,
    payload,
    cb,
  };
}

export function getRoleIdByRoleGroups(payload, cb) {
  return {
    type: types.GET_ROLE_ID_BY_ROLEGROUP,
    payload,
    cb,
  };
}

export function getPermissionByIdSuccess(payload, cb) {
  return {
    type: types.GET_PERMISSIONS_BY_ID_SUCCESS,
    payload,
    cb,
  };
}

export function removePermissions() {
  return {
    type: types.REMOVE_PERMISSIONS,
  };
}

export function checkAuth() {
  return {
    type: types.CHECK_AUTH,
  };
}

export function searchUserByTypeAgent(payload, cb) {
  return {
    type: types.SEARCH_USER_BY_TYPE_AGENT,
    payload,
    cb,
  };
}

export function searchUserByUserIdCollection(payload, cb) {
  return {
    type: types.SEARCH_USER_BY_USER_ID_COLLECTION,
    payload,
    cb,
  };
}

export function getUsageContainerSpecConfig(payload, cb) {
  return {
    type: types.GET_USAGE_CONTAINER_SPEC_CONFIG,
    payload,
    cb,
  };
}

export function getUniqueProductFamily(payload, cb) {
  return {
    type: types.GET_UNIQUE_PRODUCT_FAMILY,
    payload,
    cb,
  };
}

export function getUniqueProductLine(payload, cb) {
  return {
    type: types.GET_UNIQUE_PRODUCT_LINE,
    payload,
    cb,
  };
}

export function getUniqueProductSubType(payload, cb) {
  return {
    type: types.GET_UNIQUE_PRODUCT_SUB_TYPE,
    payload,
    cb,
  };
}

export function getUniqueProductType(payload, cb) {
  return {
    type: types.GET_UNIQUE_PRODUCT_TYPE,
    payload,
    cb,
  };
}

export function getAccountItems(payload, cb) {
  return {
    type: types.GET_ACCOUNT_ITEMS,
    payload,
    cb,
  };
}

export function selectRateUnitId(payload, cb) {
  return {
    type: types.SELECT_RATE_UNITS_ID,
    payload,
    cb,
  };
}

export function selectTimeUnit(payload, cb) {
  return {
    type: types.SELECT_TIME_UNITS_ID,
    payload,
    cb,
  };
}

export function searchTransactionUnitByAccountId(payload, cb) {
  return {
    type: types.GET_TRANSACTION_UNIT_BY_ACCOUNT_ID,
    payload,
    cb,
  };
}

export function selectMiletstoneId(payload, cb) {
  return {
    type: types.SELECT_MILETSTONE_ID,
    payload,
    cb,
  };
}

export function getCcpPropertiesConfig(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES_CONFIG,
    payload,
    cb,
  };
}

export function getCcpPropertiesConfigSuccess(payload, cb) {
  return {
    type: types.GET_CCP_PROPERTIES_CONFIG_SUCESS,
    payload,
    cb,
  };
}

export function getPackageByPackageId(payload, cb) {
  return {
    type: types.GET_PACKAGE_BY_PACKAGE_ID,
    payload,
    cb,
  };
}

export function selectDisscountOfferId(payload, cb) {
  return {
    type: types.SELECT_DISSCOUNT_OFFER_ID,
    payload,
    cb,
  };
}

export function getS3Buctket(payload, cb) {
  return {
    type: types.GET_S3_BUCKET,
    payload,
    cb,
  };
}

export function getS3BuctketSucess(payload, cb) {
  return {
    type: types.GET_S3_BUCKET_SUCESS,
    payload,
    cb,
  };
}

export function readFileFromS3(payload, cb) {
  return {
    type: types.READ_FILE_FROM_S3,
    payload,
    cb,
  };
}

export function uploadMultiPartFiles(payload, cb, isSendEmail) {
  return {
    type: types.UPLOAD_MULTI_PART_FILE,
    payload,
    cb,
    isSendEmail,
  };
}

export function searchConfigOperatorMapList(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_OPERATOR_MAP_LIST,
    payload,
    cb,
  };
}

export function searchUsersList(payload, cb) {
  return {
    type: types.SEARCH_USERS_LIST,
    payload,
    cb,
  };
}

export function getARReasonCodeSelectionConfig(payload, cb) {
  return {
    type: types.GET_AR_REASON_CODE_SELECTION,
    payload,
    cb,
  };
}

export function getUserGroupsConfig(payload, cb) {
  return {
    type: types.GET_USER_GROUPS_CONFIG,
    payload,
    cb,
  };
}

export function searchObjectApprovals(payload, cb) {
  return {
    type: types.SEARCH_OBJECT_APPROVALS,
    payload,
    cb,
  };
}

export function searchServiceProvisioningData(payload, cb) {
  return {
    type: types.SEARCH_SERVICE_PROVISIONING_DATA,
    payload,
    cb,
  };
}

export function getFlexAttributesMappingConfig(payload, cb) {
  return {
    type: types.GET_FLEX_ATTRIBUTE_MAPPING_CONFIG,
    payload,
    cb,
  };
}

export function getDistinctUsageTypes(payload, cb) {
  return {
    type: types.GET_DISTINCT_USAGE_TYPES,
    payload,
    cb,
  };
}

export function getCcpDateTimeConfig(payload, cb) {
  return {
    type: types.GET_CCP_DATE_TIME_CONFIG,
    payload,
    cb,
  };
}

export function getCcpDateTimeConfigSuccess(payload, cb) {
  return {
    type: types.GET_CCP_DATE_TIME_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getDistinctOrganization(payload, cb) {
  return {
    type: types.GET_DISTINCT_ORGANIZATION,
    payload,
    cb,
  };
}

export function getAwsS3Credential(payload, cb) {
  return {
    type: types.GET_AWS_S3_CREDENTIAL,
    payload,
    cb,
  };
}

export function modifyAccountServiceLocations(payload, cb) {
  return {
    type: types.MODIFY_ACCOUNT_SERVICE_LOCATION,
    payload,
    cb,
  };
}

export function getPaymentItemMapModal(cb) {
  return {
    type: types.GET_PAYMENT_ITEM_MAP_MODAL,
    cb,
  };
}

export function searchPaymentByAccount(payload, cb) {
  return {
    type: types.SEARCH_PAYMENT_BY_ACCOUNT,
    payload,
    cb,
  };
}

export function getObjectFileById(payload, cb) {
  return {
    type: types.GET_OBJECT_FILE_BY_ID,
    payload,
    cb,
  };
}

export function getTenantDefaultConfig(cb) {
  return {
    type: types.GET_TENANT_DEFAULT_CONFIG,
    cb,
  };
}

// export function getTenantDefaultConfigSuccess(payload, cb) {
//   return {
//     type: types.GET_TENANT_DEFAULT_CONFIG_SUCCESS,
//     payload,
//     cb,
//   };
// }

export function searchItemServiceSelect(payload, cb) {
  return {
    type: types.SEARCH_ITEM_SERVICE,
    payload,
    cb,
  };
}

export function modifyAccountService(payload, cb) {
  return {
    type: types.MODIFY_ACCOUNT_SERVICE,
    payload,
    cb,
  };
}

export function reversePaymentService(payload, cb) {
  return {
    type: types.REVERSE_PAYMENT_SERVICE,
    payload,
    cb,
  };
}

export function allocatePaymentService(payload, cb) {
  return {
    type: types.ALLOCATE_PAYMENT_SERVICE,
    payload,
    cb,
  };
}

export function getTaxCodeConfigSelect(cb) {
  return {
    type: types.GET_TAX_CODE_CONFIG_SELECT,
    cb,
  };
}

export function getUomConfig(payload, cb) {
  return {
    type: types.GET_UOM_CONFIG,
    payload,
    cb,
  };
}

export function getBillableServicePricing(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICE_PRICING,
    payload,
    cb,
  };
}

export function modifyOrderBillableServices(payload, cb) {
  return {
    type: types.MODIFY_BILLABLE_SERVICE,
    payload,
    cb,
  };
}

export function getCurrencyConfigApp(payload, cb) {
  return {
    type: types.GET_CURRENCY_CONFIG,
    payload,
    cb,
  };
}

export function getCurrencyConfigAppSuccess(payload, cb) {
  return {
    type: types.GET_CURRENCY_CONFIG_SUCCESS,
    payload,
    cb,
  };
}

export function getOrderBillableByIdAndIndex(payload, cb) {
  return {
    type: types.GET_ORDER_BILLABLE_BY_INDEX_AND_ID,
    payload,
    cb,
  };
}

export function reGeneratePayment(payload, cb) {
  return {
    type: types.RE_GENERATE_PAYMENT,
    payload,
    cb,
  };
}

export function getConfigUsageTypeConfig(payload, cb) {
  return {
    type: types.GET_USAGE_TYPE_CONFIG,
    payload,
    cb,
  };
}

export function getRegionMapConfigData(payload, cb) {
  return {
    type: types.GET_REGION_MAP_CONFIG_DATA,
    payload,
    cb,
  };
}

export function getProvisioningInstanceData(payload, cb) {
  return {
    type: types.GET_PROVISIONING_INSTANCE_DATA,
    payload,
    cb,
  };
}

export function updateProvisioningData(payload, cb) {
  return {
    type: types.UPDATE_PROVISIONING_DATA,
    payload,
    cb,
  };
}

export function updatePaymentStatus(payload, cb) {
  return {
    type: types.UPDATE_PAYMENT_STATUS,
    payload,
    cb,
  };
}

export function getGLAccountConfig(payload, cb) {
  return {
    type: types.GET_GL_ACCOUNT_CONFIG,
    payload,
    cb,
  };
}

export function getBatchPaymentFileConfigSelect(payload, cb) {
  return {
    type: types.GET_PAYMENT_FILE_CONFIG,
    payload,
    cb,
  };
}

export function getInvoiceUnitByIdSelect(payload, cb) {
  return {
    type: types.GET_INVOICE_UNIT_BY_ID,
    payload,
    cb,
  };
}

export function getPACProductCodesConfig(payload, cb) {
  return {
    type: types.GET_PAC_PRODUCT_CODES_CONFIG,
    payload,
    cb,
  };
}

export function getPaymentConfigByTypeApp(payload, cb) {
  return {
    type: types.GET_PAYMENT_CONFIG_BY_TYPE,
    payload,
    cb,
  };
}

export function getCollectionAgentByTypeConfig(payload, cb) {
  return {
    type: types.GET_COLLECTION_AGENT_BY_TYPE,
    payload,
    cb,
  };
}

export function getCostCenterConfig(payload, cb) {
  return {
    type: types.GET_COST_CENTER_CONFIG,
    payload,
    cb,
  };
}

export function getPendingAccountMovementData(payload, cb) {
  return {
    type: types.GET_PENDING_ACCOUNT_MOVEMENT_DATA,
    payload,
    cb,
  };
}

export function deletePendingAccountMovementData(payload, cb) {
  return {
    type: types.DELETE_PENDING_ACCOUNT_MOVEMENT_DATA,
    payload,
    cb,
  };
}

export function getPaymentAllocation(payload, cb) {
  return {
    type: types.GET_PAYMENT_ALLOCATION,
    payload,
    cb,
  };
}

export function getConfigOperatorByServiceType(payload, cb) {
  return {
    type: types.GET_CONFIG_OPERATOR_BY_SERVICE_TYPE,
    payload,
    cb,
  };
}

export function getAccountInfo(payload, cb) {
  return {
    type: types.GET_ACCOUNT_INFO,
    payload,
    cb,
  };
}

export function getPaymentActivity(payload, cb) {
  return {
    type: types.GET_PAYMENT_ACTIVITY,
    payload,
    cb,
  };
}

export function getOrderSummaryByAccountIdConfig(payload, cb) {
  return {
    type: types.GET_ORDER_SUMMARY_BY_ACCOUNT_ID_CONFIG,
    payload,
    cb,
  };
}

export function saveManualInvoiceConfig(payload, cb) {
  return {
    type: types.SAVE_MANUAL_INVOICE_CONFIG,
    payload,
    cb,
  };
}

export function reprocessInvoiceConfig(payload, cb) {
  return {
    type: types.RE_PROCESS_INVOICE_CONFIG,
    payload,
    cb,
  };
}

export function modifyInvoiceStatusConfig(payload, cb) {
  return {
    type: types.MODIFY_INVOICE_STATUS_CONFIG,
    payload,
    cb,
  };
}

export function regenerateInvoiceConfig(payload, cb) {
  return {
    type: types.REGENERATE_INVOICE_CONFIG,
    payload,
    cb,
  };
}

export function undoInvoiceCancelConfig(payload, cb) {
  return {
    type: types.UNDO_INVOICE_CANCEL_CONFIG,
    payload,
    cb,
  };
}

export function sendInvoiceConfig(payload, cb) {
  return {
    type: types.SEND_INVOICE_CONFIG,
    payload,
    cb,
  };
}

export function undoManualInvoiceConfig(payload, cb) {
  return {
    type: types.UNDO_MANUAL_INVOICE_CONFIG,
    payload,
    cb,
  };
}

export function modifyInvoiceDueDateConfig(payload, cb) {
  return {
    type: types.MODIFY_INVOICE_DUE_DATE_CONFIG,
    payload,
    cb,
  };
}

export function resendInvoiceVendorConfig(payload, cb) {
  return {
    type: types.RESEND_INVOICE_VENDOR_CONFIG,
    payload,
    cb,
  };
}

export function getNotesUnitbyIdConfig(payload, cb) {
  return {
    type: types.GET_NOTES_UNIT_BY_ID_CONFIG,
    payload,
    cb,
  };
}

export function modifyNotesUnitConfig(payload, cb) {
  return {
    type: types.MODIFY_NOTES_UNIT_CONFIG,
    payload,
    cb,
  };
}

export function resendNotesVendorConfig(payload, cb) {
  return {
    type: types.RESEND_NOTES_VENDOR_CONFIG,
    payload,
    cb,
  };
}

export function getBillableServicePricingHistory(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICE_PRICING_HISTORY,
    payload,
    cb,
  };
}

export function getPacInterfaceRecordByIdConfig(payload, cb) {
  return {
    type: types.GET_PAC_INTERFACE_RECORD_BY_ID_CONFIG,
    payload,
    cb,
  };
}

export function sendFolio(payload, cb) {
  return {
    type: types.SEND_FOLIO,
    payload,
    cb,
  };
}

export function getInvoiceInstallmentsByInvoiceIdConfig(payload, cb) {
  return {
    type: types.GET_INVOICE_INSTALLMENTS_BY_INVOICEID_CONFIG,
    payload,
    cb,
  };
}

export function createPaymentInstallmentConfig(payload, cb) {
  return {
    type: types.CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID_CONFIG,
    payload,
    cb,
  };
}

export function getBillableServiceProvisioningAttributes(payload, cb) {
  return {
    type: types.GET_BILLABLE_SERVICE_PROVISIONING_ATTRIBUTES,
    payload,
    cb,
  };
}

export function getConfigUsagePlatforms(payload, cb) {
  return {
    type: types.GET_CONNFIG_USAGE_PLATFORM_LIST,
    payload,
    cb,
  };
}

export function getOutputTemplateByType(payload, cb) {
  return {
    type: types.GET_OUTPUT_TEMPLATE_BY_TYPE,
    payload,
    cb,
  };
}

export function saveOutputTemplateByType(payload, cb) {
  return {
    type: types.SAVE_OUTPUT_TEMPLATE_BY_TYPE,
    payload,
    cb,
  };
}

export function bulkProcess(payload, cb) {
  return {
    type: types.BULK_PROCESS,
    payload,
    cb,
  };
}

export function getConfigFailureCodes(payload, cb) {
  return {
    type: types.GET_CONFIG_FAILURE_CODES,
    payload,
    cb,
  };
}

export function getConfigIdd(payload, cb) {
  return {
    type: types.GET_CONFIG_IDD,
    payload,
    cb,
  };
}

export function getConfigIdo(payload, cb) {
  return {
    type: types.GET_CONFIG_IDO,
    payload,
    cb,
  };
}

export function getConfigIncomingOutgoingRoute(payload, cb) {
  return {
    type: types.GET_CONFIG_INCOMING_OUTGOING_ROUTE,
    payload,
    cb,
  };
}

export function getSplitTaxPerLineConfig(payload, cb) {
  return {
    type: types.GET_SPLIT_TAX_PER_LINE_CONFIG,
    payload,
    cb,
  };
}

export function getConfigFailureCodesList(payload, cb) {
  return {
    type: types.GET_CONFIG_FAILURE_CODES_LIST,
    payload,
    cb,
  };
}

export function getConfigSreserved(payload, cb) {
  return {
    type: types.GET_CONFIG_SRESERVED,
    payload,
    cb,
  };
}

export function getConfigOrganization(payload, cb) {
  return {
    type: types.GET_CONFIG_ORGANIZATION,
    payload,
    cb,
  };
}

export function searchProvisioningAttributesConfigItem(payload, cb) {
  return {
    type: types.SEARCH_PROVISIONING_ATTRIBUTES_CONFIG_ITEM,
    payload,
    cb,
  };
}

export function onReloadAvatar(payload, cb) {
  return {
    type: types.ON_RELOAD_AVATAR,
    payload,
    cb,
  };
}

export function searchDiscountTriggersConfig(payload, cb) {
  return {
    type: types.SEARCH_DISCOUNT_TRIGGER_CONFIG,
    payload,
    cb,
  };
}

export function getUserByIdDetails(payload, cb) {
  return {
    type: types.GET_USER_BY_ID_DETAILS,
    payload,
    cb,
  };
}

export function getApiByGatewayNameConfig(payload, cb) {
  return {
    type: types.GET_API_BY_GETEWAY_NAME_CONFIG,
    payload,
    cb,
  };
}

export function getCloudFileDataByFileName(payload, cb) {
  return {
    type: types.GET_CLOUD_FILE_DATA_BY_NAME,
    payload,
    cb,
  };
}

export function viewBulkAdjustmentUploadStats(payload, cb) {
  return {
    type: types.VIEW_BULK_ADJUSTMENT_UPLOAD_STATS,
    payload,
    cb,
  };
}

export function searchConfigCreditProfile(payload, cb) {
  return {
    type: types.SEARCH_CONFIG_CREDIT_PROFILE,
    payload,
    cb,
  };
}

export function getAccumulatorsConfig(cb) {
  return {
    type: types.GET_ACCUMULATORS_CONFIG,
    cb,
  };
}

export function getGrantsConfig(cb) {
  return {
    type: types.GET_GRANTS_CONFIG,
    cb,
  };
}

export function getDiscountOfferById(payload, cb) {
  return {
    type: types.GET_DISCOUNT_OFFER_BY_ID,
    payload,
    cb,
  };
}

export function selectBundleIdList(payload, cb) {
  return {
    type: types.SELECT_BUNDLE_ID_LIST,
    payload,
    cb,
  };
}

export function selectPackageIdList(payload, cb) {
  return {
    type: types.SELECT_PACKAGE_ID_LIST,
    payload,
    cb,
  };
}

export function searchCollectionProfilesConfig(payload, cb) {
  return {
    type: types.SEARCH_COLLECTION_PROFILE_CONFIG,
    payload,
    cb,
  };
}

export function selectBillInvoice(payload, cb) {
  return {
    type: types.SELECT_BILL_INVOICE,
    payload,
    cb,
  };
}

export function getTaxTypeConfigSelect(payload, cb) {
  return {
    type: types.GET_TAX_TYPE_CONFIG_SELECT,
    payload,
    cb,
  };
}

export function searchChartOfAccountConfig(payload, cb) {
  return {
    type: types.SEARCH_CHART_OF_ACCOUNT_CONFIG,
    payload,
    cb,
  };
}

export function searchOrdersConfig(payload, cb) {
  return {
    type: types.SEARCH_ORDERS_CONFIG,
    payload,
    cb,
  };
}

export function searchEmployeeCostingConfig(payload, cb) {
  return {
    type: types.SEARCH_EMPLOYEE_COSTING_CONFIG,
    payload,
    cb,
  };
}
