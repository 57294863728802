import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { queryRequest, mutationRequest } from 'utils/request';
import {
  searchItems,
  searchPriceOffers,
  searchBundles,
  searchPackages,
  searchInvoiceUnits,
  getListCustomers,
  getBundleByBundleId,
  getPackageDetail,
  searchBillUnits,
  getAccountDetail,
  userLogin,
  modifyUser,
  getRoleAndRoleGroupById,
  getPermissionsByRoleId,
  getRoleIdByRoleGroups,
  searchUsers,
  getUserByUserId,
  getUsageContainerSpec,
  getUniqueProductFamily,
  getUniqueProductLine,
  getUniqueProductSubType,
  getUniqueProductType,
  getAccountItems,
  getRateUnit,
  getTimeUnitCfg,
  getTransactionUnit,
  searchRevenueMilestone,
  getCcpProperties,
  searchDiscountOffers,
  getTenantConfigLogo,
  readFileFromS3,
  uploadfFile,
  searchConfigOperatorMapList,
  getARReasonCodeConfig,
  getUserGroups,
  searchObjectApprovals,
  searchServiceProvisioningData,
  getFlexAttributesMapping,
  getDistinctUsageTypes,
  getCcpDateTime,
  getDistinctOrganization,
  getAwsS3Credential,
  modifyAccountServiceLocations,
  getPaymentItemMap,
  searchPayment,
  getObjectFileById,
  searchItemServices,
  modifyAccountService,
  reversePayment,
  allocatePayment,
  getTaxCodeConfig,
  searchUom,
  getBillableServicePricing,
  modifyOrderBillableServices,
  getCurrencyConfig,
  getOrderBillableByIdAndIndex,
  regeneratePaymentFolio,
  getConfigUsageType,
  getRegionMapConfig,
  getProvisioningInstanceData,
  updateProvisioningData,
  updatePaymentStatus,
  getGlAccount,
  getBatchPaymentFileConfig,
  getInvoiceUnitById,
  searchRegulatoryProductCodes,
  getPaymentConfigByType,
  getCollectionAgentByType,
  getCostCenter,
  getPendingAccountMovementData,
  deletePendingAccountMovementData,
  getPaymentAllocation,
  getConfigOperatorByServiceType,
  getAccountInfo,
  getPaymentActivity,
  getOrderSummaryByAccountId,
  saveManualInvoice,
  reprocessInvoice,
  modifyInvoiceStatus,
  regenerateInvoice,
  undoInvoiceCancel,
  sendInvoice,
  undoManualInvoice,
  modifyInvoiceDueDate,
  resendInvoiceVendor,
  getNotesUnitById,
  modifyNotesUnit,
  resendNotesVendor,
  getBillableServicePricingHistory,
  getPacInterfaceRecordById,
  sendFolio,
  getInvoiceInstallmentsByInvoiceId,
  createPaymentInstallment,
  getBillableServiceProvisioningAttributes,
  getConfigUsagePlatforms,
  getOutputTemplateByType,
  saveOutputTemplateByType,
  bulkProcess,
  getConfigFailureCodes,
  getConfigIdd,
  getConfigIdo,
  getConfigIncomingOutgoingRoute,
  getSplitTaxPerLine,
  getConfigFailureCodesList,
  getConfigSreserved,
  getConfigOrganization,
  searchProvisioningAttributesConfig,
  searchDiscountTriggers,
  getUserById,
  getApiByGatewayName,
  getCloudFileDataByFileName,
  viewBulkAdjustmentUploadStats,
  searchConfigCreditProfile,
  getAccumulatorsConfig,
  getGrantsConfig,
  getDiscountOfferById,
  searchPackagesList,
  searchBundlesList,
  searchCollectionProfiles,
  searchBillInvoice,
  getTaxTypeConfig,
  searchChartOfAccount,
  searchOrders,
  searchEmployeeCosting,
} from 'api';
import { mutationRequest as mutationRequestJob } from '../../utils/reuqestJob';
import {
  setUserId,
  getUserId,
  getPassword,
  getId,
  setPassword,
  setFirstTimeLogin,
  getSSOLogin,
  setDaysForPasswordChange,
  setLastPasswordChangeDate,
  clearAllLocalStorage,
} from '../../localStorages';
import * as actions from './actions';
import * as types from './constants';
import { isConnecting, isEndConnected } from '../Loader/actions';
import i18n from '../../i18n';

export function* selectItemsId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchItems({ page, size, filter }));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchItems });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* selectPriceOfferId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchPriceOffers({ page, size, filter }));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchPriceOffers });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* selectBundleId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchBundles({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchBundles });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* selectPackageId({ payload, cb }) {
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchPackages({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchPackages });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* selectAccountId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, getListCustomers({ page, size, filter, sort }));
    if (cb) cb({ success: true, data: response.searchAccounts });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* selectInvoiceUnitsId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchInvoiceUnits({ page, size, filter, sort }));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchInvoiceUnits });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getBundleById({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBundleByBundleId(payload));
    if (cb) cb({ success: true, data: response.getBundleById });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* selectBillUnitsId({ payload, cb }) {
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchBillUnits({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchBillUnits });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getAccountDetailSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountDetail(payload));
    if (cb) cb({ success: true, data: response.getAccountById });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* logoutSaga() {
  let isSSO = false;
  if (getSSOLogin()) isSSO = true;
  clearAllLocalStorage();
  yield put(actions.removePermissions());
  if (isSSO) {
    window.open(`https://service-sso.develop.embrix.org/api/saml/logout?redirect=${window.location.href}`, '_self');
  } else {
    yield put(push('/login'));
  }
}

export function* checkLoginSaga({ cb }) {
  try {
    const response = yield call(queryRequest, userLogin({ userName: getUserId(), password: getPassword() })) || {};
    if (response.userLogin) {
      if (cb) cb(response.userLogin);
    } else {
      setUserId('');
      yield put(push('/login'));
    }
  } catch (err) {
    setUserId('');
    yield put(push('/login'));
  }
}

export function* changePasswordSaga({ newPassword, cb }) {
  const payload = {
    id: getId(),
    userId: getUserId(),
    password: newPassword,
  };
  try {
    const response = yield call(mutationRequest, modifyUser(payload));
    if (response && response.modifyUser) {
      setPassword(newPassword);
      setFirstTimeLogin('false');
      if (cb) {
        cb(true);
      }
      toast.success(i18n.t('common:apiMessage.modifyUserSuccessfully'));
    } else {
      if (cb) {
        cb(false);
      }
      toast.success(i18n.t('common:apiMessage.modifyUserFailed'));
    }
  } catch (err) {
    // const messageErr = `Modify password failed ${err}`;
    if (cb) {
      cb(false);
    }
    // toast.error(messageErr);
  }
}

export function* getRolesAndRoleGroupByUserIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getRoleAndRoleGroupById(payload));
    if (response.getUserById) {
      if (cb) cb({ success: true, data: response.getUserById });
    } else if (cb) cb({ success: false, data: {} });
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

export function* getRoleByIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getPermissionsByRoleId(payload));
    if (response.getRoleById) {
      yield put(actions.getPermissionByIdSuccess(response.getRoleById));
      if (cb) cb({ success: true, data: response.getRoleById });
    }
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

export function* getRoleIdByRoleGroupSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getRoleIdByRoleGroups(payload));
    if (response.getRoleGroupById) {
      if (cb) cb({ success: true, data: response.getRoleGroupById.roleGroupRoles || null });
    }
  } catch (err) {
    if (cb) cb({ success: false, data: null });
  }
}

export function* checkAuthSaga() {
  const payload = {
    userName: getUserId(),
    password: getPassword(),
  };
  try {
    if (!getSSOLogin()) {
      const response = yield call(queryRequest, userLogin(payload));
      if (response.userLogin) {
        setFirstTimeLogin(response.userLogin.firstTimeLogin);
        setDaysForPasswordChange(response.userLogin.daysForPasswordChange);
        setLastPasswordChangeDate(response.userLogin.lastPasswordChangeDate);
      }
      if (!response.userLogin) {
        yield put(actions.logout());
        toast.error(i18n.t('common:apiMessage.checkAuthorization'));
      }
    }
  } catch (err) {
    toast.error(i18n.t('common:apiMessage.checkAuthorization'));
  }
}

// ------ get list users by type
export function* searchUserByTypeAgentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchUsers({ page, size, filter: { ...filter }, sort }));
    if (response.searchUsers) {
      if (cb) {
        cb({ success: true, data: response.searchUsers });
      }
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

// ------ get user by user id collection actions
export function* searchUserByUserIdCollectionSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getUserByUserId(payload));
    if (response.getUserByUserId) {
      if (cb) {
        cb({ success: true, data: response.getUserByUserId });
      }
    }
  } catch (err) {
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

// ------ Get Usage Container Spec
export function* getUsageContainerSpecSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getUsageContainerSpec(payload));
    if (response.getUsageContainerSpec && response.getUsageContainerSpec.id) {
      if (cb) yield cb({ success: true, data: response.getUsageContainerSpec.usageContainerRecord });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

// ------ Config Product ----- //
export function* getUniqueProductFamilySaga({ payload, cb }) {
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getUniqueProductFamily(payload));
    if (response.getUniqueProductFamily && response.getUniqueProductFamily) {
      if (cb) yield cb({ success: true, data: response.getUniqueProductFamily });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* getUniqueProductLineSaga({ payload, cb }) {
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getUniqueProductLine(payload));
    if (response.getUniqueProductLine && response.getUniqueProductLine) {
      if (cb) yield cb({ success: true, data: response.getUniqueProductLine });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* getUniqueProductSubTypeSaga({ payload, cb }) {
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getUniqueProductSubType(payload));
    if (response.getUniqueProductSubType && response.getUniqueProductSubType) {
      if (cb) yield cb({ success: true, data: response.getUniqueProductSubType });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* getUniqueProductTypeSaga({ payload, cb }) {
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getUniqueProductType(payload));
    if (response.getUniqueProductType && response.getUniqueProductType) {
      if (cb) yield cb({ success: true, data: response.getUniqueProductType });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* getAccountItemsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountItems(payload));
    if (response.getAccountItems && response.getAccountItems.accountItemsData) {
      if (cb) yield cb({ success: true, data: response.getAccountItems.accountItemsData });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* selectRateUnitIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRateUnit(payload));
    if (response.getRateUnit && response.getRateUnit.rateUnits) {
      if (cb) yield cb({ success: true, data: response.getRateUnit.rateUnits });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* selectTimeUnitIdSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getTimeUnitCfg(payload));
    if (response.getTimeUnitCfg && response.getTimeUnitCfg) {
      if (cb) yield cb({ success: true, data: response.getTimeUnitCfg });
    } else if (cb) yield cb({ success: true, data: [] });
  } catch (err) {
    if (cb) {
      cb({ success: true, data: [] });
    }
  }
}

export function* getTransactionUnitByAccountIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, getTransactionUnit(payload));
    if (response.searchTransactionUnits) {
      if (cb) cb({ success: true, data: response.searchTransactionUnits });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());

    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchRevenueMilestoneIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevenueMilestone(payload));
    if (response.searchRevenueMilestone) {
      if (cb) cb({ success: true, data: response.searchRevenueMilestone });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getCcpPropertiesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCcpProperties(payload));
    if (response.getCcpProperties) {
      if (cb) cb({ success: true, data: response.getCcpProperties });
      yield put(actions.getCcpPropertiesConfigSuccess(response.getCcpProperties.ccpPropertyList));
    } else {
      yield put(actions.getCcpPropertiesConfigSuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(actions.getCcpPropertiesConfigSuccess([]));
    yield put(isEndConnected());
  }
}

export function* getPackageByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPackageDetail(payload));
    if (cb) cb({ success: true, data: response.getPackageById });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getDisscountOfferId({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchDiscountOffers({ page, size, filter }));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchDiscountOffers });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getS3BuctketSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAwsS3Credential(payload));
    yield put(isEndConnected());
    if (response) {
      yield put(actions.getS3BuctketSucess(response.getAwsS3Credential));
      if (cb) cb({ success: true, data: response.getAwsS3Credential });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
  // try {
  //   const response = yield call(getS3Config);
  //   yield put(actions.getS3BuctketSucess(response.data));
  //   if (cb) cb({ success: true, data: response.data });
  // } catch (err) {
  //   if (cb) cb({ success: false });
  // }
}

export function* readFileFromS3Saga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(readFileFromS3, payload);
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* uploadFileSaga({ payload, cb, isSendEmail }) {
  yield put(isConnecting());
  try {
    const response = yield call(uploadfFile, payload);
    if (response && response.data && response.data.errors && response.data.errors[0]) {
      toast.error(response.data.errors[0].message);
      if (cb) cb({ success: true, data: response });
    } else {
      if (isSendEmail) {
        toast.success(i18n.t('common:apiMessage.sentEmailSuccessfully'));
      } else toast.success(i18n.t('common:apiMessage.createFileSuccessfully'));

      if (cb) cb({ success: true, data: response });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* searchConfigOperatorMapListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigOperatorMapList(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchConfigOperatorMapList });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* searchUsersListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchUsers(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchUsers });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* getARReasonCodeSelectionSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getARReasonCodeConfig(payload));
    if (response.getARReasonCodeConfig) {
      if (cb) cb({ success: true, data: response.getARReasonCodeConfig.arReasonCodes });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* getUserGroupsConfigSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getUserGroups(payload));
    if (response.getUserGroups) {
      if (cb) cb({ success: true, data: response.getUserGroups });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    if (cb) cb({ success: false });
  }
}

export function* searchObjectApprovalsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchObjectApprovals(payload));
    if (response.searchObjectApprovals && response.searchObjectApprovals[0]) {
      if (cb) cb({ success: true, data: response.searchObjectApprovals[0].approvals });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchServiceProvisioningDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchServiceProvisioningData(payload));
    if (response.searchServiceProvisioningData) {
      if (cb) cb({ success: true, data: response.searchServiceProvisioningData });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getFlexAttributesMappingConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFlexAttributesMapping(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getFlexAttributesMapping });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getDistinctUsageTypesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDistinctUsageTypes(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getDistinctUsageTypes });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getCcpDateTimeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCcpDateTime(payload));
    yield put(isEndConnected());
    if (response?.getCcpDateTime) {
      if (cb) cb({ success: true, data: response.getCcpDateTime });
      yield put(actions.getCcpDateTimeConfigSuccess(response?.getCcpDateTime?.ccpTime || null));
    } else {
      if (cb) cb({ success: false, data: null });
      yield put(actions.getCcpDateTimeConfigSuccess(null));
    }
  } catch (err) {
    yield put(isEndConnected());
    yield put(actions.getCcpDateTimeConfigSuccess(null));
    if (cb) cb({ success: false, data: null });
  }
}

export function* getDistinctOrganizationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDistinctOrganization(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.getDistinctOrganization });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getAwsS3CredentialSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAwsS3Credential(payload));
    yield put(isEndConnected());
    if (response) {
      yield put(actions.getS3BuctketSucess(response.getAwsS3Credential));
      if (cb) cb({ success: true, data: response.getAwsS3Credential });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* modifyAccountServiceLocationsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyAccountServiceLocations(payload));
    yield put(isEndConnected());
    if (response) {
      toast.success(i18n.t('common:apiMessage.modifyServiceLocationsSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyAccountServiceLocations });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// Payment Item Map
export function* getPaymentItemMapModalSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentItemMap());
    if (response.getPaymentItemMap) {
      if (cb) cb({ success: true, data: response.getPaymentItemMap.paymentItemMaps });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchPaymentByAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchPayment(payload));
    if (response.searchPayment) {
      if (cb) cb({ success: true, data: response.searchPayment });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getObjectFileByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getObjectFileById(payload));
    if (response.getObjectFileById) {
      cb(response.getObjectFileById);
    } else {
      cb(null);
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* getTenantDefaultConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    yield put(isConnecting());
    const response = yield call(queryRequest, getTenantConfigLogo());
    if (response.getTenantConfig) {
      // yield put(actions.getTenantDefaultConfigSuccess(response.getTenantConfig));

      if (cb) yield cb({ success: true, data: response.getTenantConfig });
    } else {
      // yield put(actions.getTenantDefaultConfigSuccess(null));
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    // yield put(actions.getTenantDefaultConfigSuccess(null));
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// Item Services
export function* searchItemServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchItemServices({ page, size, filter, sort }));
    if (response.searchItemServices) {
      if (cb) yield cb({ success: true, data: response.searchItemServices });
    } else if (cb) yield cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* modifyAccountServiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyAccountService(payload));
    yield put(isEndConnected());
    if (response) {
      toast.success(i18n.t('common:apiMessage.modifyServiceSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyAccountService });
    } else if (cb) cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

// reverse payment
export function* reversePaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, reversePayment(payload));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.paymentReversedSuccessfully'));
    if (cb) cb({ success: true, data: response.reversePayment });
  } catch (err) {
    yield put(isEndConnected());
  }
  if (cb) cb({ success: false });
}

export function* allocatePaymentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, allocatePayment(payload));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.paymentAllocatedSuccessfully'));
    if (cb) cb({ success: true, data: response.allocatePayment });
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Payment allocated failed${err}`, { type: toast.TYPE.SUCCESS });
  }
  if (cb) cb({ success: false });
}

export function* getTaxCodeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxCodeConfig());
    yield put(isEndConnected());
    if (response.getTaxConfig && response.getTaxConfig) {
      if (cb) yield cb({ success: true, data: response.getTaxConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

// UMO
export function* getUomConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchUom(payload));
    yield put(isEndConnected());
    if (response.searchUom) {
      if (cb) yield cb({ success: true, data: response.searchUom });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

// UMO
export function* getBillableServicePricingSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicePricing(payload));
    yield put(isEndConnected());
    if (response.getBillableServicePricing) {
      if (cb) yield cb({ success: true, data: response.getBillableServicePricing });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyOrderBillableServicesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyOrderBillableServices(payload));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.modifyBillableServiceSuccessfully'));
    if (cb) cb({ success: true, data: response.modifyOrderBillableServices });
  } catch (err) {
    yield put(isEndConnected());
    // toast(`Payment allocated failed${err}`, { type: toast.TYPE.SUCCESS });
  }
  if (cb) cb({ success: false });
}

export function* getCurrencyConfigAppSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCurrencyConfig(payload));
    yield put(isEndConnected());
    if (response.getCurrencyConfig) {
      yield put(actions.getCurrencyConfigAppSuccess(response.getCurrencyConfig));
      if (cb) yield cb({ success: true, data: response.getCurrencyConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getOrderBillableByIdAndIndexSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderBillableByIdAndIndex(payload));
    yield put(isEndConnected());
    if (response.getOrderBillableByIdAndIndex) {
      if (cb) yield cb({ success: true, data: response.getOrderBillableByIdAndIndex });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* regeneratePaymentFolioSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, regeneratePaymentFolio(payload));
    yield put(isEndConnected());
    if (response.regeneratePaymentFolio) {
      toast.success(i18n.t('common:apiMessage.reGeneratePaymentSuccessfully'));
      if (cb) yield cb({ success: true, data: response.regeneratePaymentFolio });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigUsageTypeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigUsageType(payload));
    yield put(isEndConnected());
    if (response.getConfigUsageType) {
      if (cb) yield cb({ success: true, data: response.getConfigUsageType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getRegionMapConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRegionMapConfig(payload));
    yield put(isEndConnected());
    if (response.getRegionMapConfig) {
      if (cb) yield cb({ success: true, data: response.getRegionMapConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getProvisioningInstanceDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getProvisioningInstanceData(payload));
    yield put(isEndConnected());
    if (response.getProvisioningInstanceData) {
      if (cb) yield cb({ success: true, data: response.getProvisioningInstanceData });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* updateProvisioningDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateProvisioningData(payload));
    yield put(isEndConnected());
    if (response.updateProvisioningData) {
      toast.success(i18n.t('common:apiMessage.updateProvisioningSuccessfully'));
      if (cb) yield cb({ success: true, data: response.updateProvisioningData });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* updatePaymentStatusSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updatePaymentStatus(payload));
    yield put(isEndConnected());
    if (response.updatePaymentStatus) {
      toast.success(i18n.t('common:apiMessage.updatePaymentStatusSuccessfully'));
      if (cb) yield cb({ success: true, data: response.updatePaymentStatus });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getGlAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGlAccount(payload));
    yield put(isEndConnected());
    if (response.getGlAccount) {
      if (cb) yield cb({ success: true, data: response.getGlAccount });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getBatchPaymentFileConfigSelectSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBatchPaymentFileConfig(payload));
    yield put(isEndConnected());
    if (response.getBatchPaymentFileConfig) {
      if (cb) yield cb({ success: true, data: response.getBatchPaymentFileConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getInvoiceByIdSelectSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceUnitById(payload));
    if (response.getInvoiceUnitById) {
      if (cb) cb({ success: true, data: response.getInvoiceUnitById });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

// UMO
export function* getPACProductCodesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRegulatoryProductCodes(payload));
    yield put(isEndConnected());
    if (response.searchRegulatoryProductCodes) {
      if (cb) yield cb({ success: true, data: response.searchRegulatoryProductCodes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getPaymentConfigByTypeAppSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentConfigByType(payload));
    yield put(isEndConnected());
    if (response.getPaymentConfigByType) {
      if (cb) yield cb({ success: true, data: response.getPaymentConfigByType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getCollectionAgentByTypeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCollectionAgentByType(payload));
    yield cb(response);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb();
  }
}

export function* getCostCenterConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCostCenter(payload));
    yield put(isEndConnected());
    if (response.getCostCenter) {
      if (cb) yield cb({ success: true, data: response.getCostCenter });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getPendingAccountMovementDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPendingAccountMovementData(payload));
    yield put(isEndConnected());
    if (response.getPendingAccountMovementData) {
      if (cb) yield cb({ success: true, data: response.getPendingAccountMovementData });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* deletePendingAccountMovementDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, deletePendingAccountMovementData(payload));
    yield put(isEndConnected());
    if (response.deletePendingAccountMovementData) {
      if (cb) yield cb({ success: true, data: response.deletePendingAccountMovementData });
      toast.success(i18n.t('common:apiMessage.deletePendingAccountMovementDataSuccessfully'));
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getPaymentAllocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentAllocation(payload));
    yield put(isEndConnected());
    if (response.getPaymentAllocation) {
      if (cb) yield cb({ success: true, data: response.getPaymentAllocation });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigOperatorByServiceTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigOperatorByServiceType(payload));
    yield put(isEndConnected());
    if (response.getConfigOperatorByServiceType) {
      if (cb) yield cb({ success: true, data: response.getConfigOperatorByServiceType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getAccountInfoSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccountInfo(payload));
    yield put(isEndConnected());
    if (response.getAccountById) {
      if (cb) yield cb({ success: true, data: response.getAccountById });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getPaymentActivitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPaymentActivity(payload));
    if (response.getPaymentActivity) {
      if (cb) cb({ success: true, data: response.getPaymentActivity });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getOrderSummaryByAccountIdConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOrderSummaryByAccountId(payload));
    if (response.getOrderSummaryByAccountId) {
      if (cb) cb({ success: true, data: response.getOrderSummaryByAccountId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* saveManualInvoiceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveManualInvoice(payload));
    if (response.saveManualInvoice) {
      if (cb) cb({ success: true, data: response.saveManualInvoice });
      toast.success(i18n.t('common:apiMessage.saveManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* reprocessInvoiceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, reprocessInvoice(payload));
    if (response.reprocessInvoice) {
      if (cb) cb({ success: true, data: response.reprocessInvoice });
      toast.success(i18n.t('common:apiMessage.processInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyInvoiceStatusConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyInvoiceStatus(payload));
    if (response.modifyInvoiceStatus) {
      if (cb) cb({ success: true, data: response.modifyInvoiceStatus });
      toast.success(i18n.t('common:apiMessage.modifyInvoiceStatusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* regenerateInvoiceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, regenerateInvoice(payload));
    if (response.regenerateInvoice) {
      if (cb) cb({ success: true, data: response.regenerateInvoice });
      toast.success(i18n.t('common:apiMessage.regenerateInoivceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* undoInvoiceCancelConfigSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, undoInvoiceCancel(payload));
    if (response.undoInvoiceCancel) {
      toast.success(i18n.t('common:apiMessage.undoInvoiceCancelSuccessfully'));
      if (cb) cb({ success: true, data: response.undoInvoiceCancel });
    } else if (cb) cb({ success: false, data: [] });
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
  }
}

export function* sendInvoiceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, sendInvoice(payload));
    if (response.sendInvoice) {
      if (cb) cb({ success: true, data: response.sendInvoice });
      toast.success(i18n.t('common:apiMessage.sendInoivceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* undoManualInvoiceConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, undoManualInvoice(payload));
    if (response.undoInvoice) {
      if (cb) cb({ success: true, data: response.undoInvoice });
      toast.success(i18n.t('common:apiMessage.undoManualInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* modifyInvoiceDueDateConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyInvoiceDueDate(payload));
    if (response.modifyInvoiceDueDate) {
      if (cb) cb({ success: true, data: response.modifyInvoiceDueDate });
      toast.success(i18n.t('common:apiMessage.modifyInvoiceSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* resendInvoiceVendorConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, resendInvoiceVendor(payload));
    if (response.resendInvoiceVendor) {
      toast.success(i18n.t('common:apiMessage.invoicedVendoSuccessfully'));
      if (cb) cb({ success: true, data: response.resendInvoiceVendor });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getNotesUnitByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getNotesUnitById(payload));
    if (response.getNotesUnitById) {
      if (cb) cb({ success: true, data: response.getNotesUnitById });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* modifyNotesUnitConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyNotesUnit(payload));
    if (response.modifyNotesUnit) {
      if (cb) cb({ success: true, data: response.modifyNotesUnit });
      toast.success(i18n.t('common:apiMessage.updateNotesStatusSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* resendNotesVendorConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resendNotesVendor(payload));
    if (response.resendNotesVendor) {
      if (cb) cb({ success: true, data: response.resendNotesVendor });
      toast.success(i18n.t('common:apiMessage.resendNotesVendorSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getBillableServicePricingHistorySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServicePricingHistory(payload));
    yield put(isEndConnected());
    if (response.getBillableServicePricingHistory) {
      if (cb) yield cb({ success: true, data: response.getBillableServicePricingHistory });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getPacInterfaceRecordByIdConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPacInterfaceRecordById(payload));
    if (response.getPacInterfaceRecordById) {
      if (cb) cb({ success: true, data: response.getPacInterfaceRecordById });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* sendFolioSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, sendFolio(payload));
    if (response.sendFolio) {
      toast.success(i18n.t('common:apiMessage.folioSendEmailSuccessfully'));
      if (cb) cb({ success: true, data: response.sendFolio });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInvoiceInstallmentsByInvoiceIdConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceInstallmentsByInvoiceId(payload));
    if (response.getInvoiceInstallmentsByInvoiceId) {
      if (cb) cb({ success: true, data: response.getInvoiceInstallmentsByInvoiceId });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createPaymentInstallmentConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createPaymentInstallment(payload));
    if (response.createPaymentInstallment) {
      if (cb) cb({ success: true, data: response.createPaymentInstallment });
      toast.success(i18n.t('common:apiMessage.createPaymentInstallmentSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getBillableServiceProvisioningAttributesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBillableServiceProvisioningAttributes(payload));
    yield put(isEndConnected());
    if (response.getBillableServiceProvisioningAttributes) {
      if (cb) yield cb({ success: true, data: response.getBillableServiceProvisioningAttributes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigUsagePlatformsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigUsagePlatforms(payload));
    yield put(isEndConnected());
    if (response.getConfigUsagePlatforms) {
      if (cb) yield cb({ success: true, data: response.getConfigUsagePlatforms });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getOutputTemplateByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getOutputTemplateByType(payload));
    yield put(isEndConnected());
    if (response.getOutputTemplateByType) {
      if (cb) yield cb({ success: true, data: response.getOutputTemplateByType });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* saveOutputTemplateByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveOutputTemplateByType(payload));
    if (response.saveOutputTemplateByType) {
      if (cb) cb({ success: true, data: response.saveOutputTemplateByType });
      toast.success(i18n.t('common:apiMessage.saveOutputTemplateByTypeSuccessfully'));
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* bulkProcessSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequestJob, bulkProcess(payload));
    if (cb) cb({ success: true, data: response.bulkProcess });
    toast.success(i18n.t('common:apiMessage.processSuccessfully'));
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getConfigFailureCodesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigFailureCodes(payload));
    yield put(isEndConnected());
    if (response.getConfigFailureCodes) {
      if (cb) yield cb({ success: true, data: response.getConfigFailureCodes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIddSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIdd(payload));
    yield put(isEndConnected());
    if (response.getConfigIdd) {
      if (cb) yield cb({ success: true, data: response.getConfigIdd });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIdoSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIdo(payload));
    yield put(isEndConnected());
    if (response.getConfigIdo) {
      if (cb) yield cb({ success: true, data: response.getConfigIdo });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getConfigIncomingOutgoingRouteSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigIncomingOutgoingRoute(payload));
    yield put(isEndConnected());
    if (response.getConfigIncomingOutgoingRoute) {
      if (cb) yield cb({ success: true, data: response.getConfigIncomingOutgoingRoute });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getSplitTaxPerLineConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getSplitTaxPerLine(payload));
    if (response.getSplitTaxPerLine) {
      if (cb) cb({ success: true, data: response.getSplitTaxPerLine });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigFailureCodesListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigFailureCodesList(payload));
    if (response.getConfigFailureCodesList) {
      if (cb) cb({ success: true, data: response.getConfigFailureCodesList });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigSreservedSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigSreserved(payload));
    if (response.getConfigSreserved) {
      if (cb) cb({ success: true, data: response.getConfigSreserved });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* getConfigOrganizationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { accountId, filter } = payload;
    const response = yield call(queryRequest, getConfigOrganization(payload));
    if (response.getConfigOrganization) {
      if (cb) cb({ success: true, data: response.getConfigOrganization });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchProvisioningAttributesConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchProvisioningAttributesConfig(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchProvisioningAttributesConfig });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* searchDiscountTriggersConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchDiscountTriggers(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchDiscountTriggers });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getUserByIdDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUserById(payload));
    if (response.getUserById) {
      if (cb) cb({ success: true, data: response.getUserById });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* getApiByGatewayNameConfigSaga({ payload, cb }) {
  // yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, getApiByGatewayName(payload));
    if (response.getApiByGatewayName) {
      if (cb) cb({ success: true, data: response.getApiByGatewayName });
    } else if (cb) cb({ success: true });
    // yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    // yield put(isEndConnected());
  }
}

export function* getCloudFileDataByFileNameSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCloudFileDataByFileName(payload));
    if (response.getCloudFileDataByFileName) {
      if (cb) cb({ success: true, data: response.getCloudFileDataByFileName });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* viewBulkAdjustmentUploadStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, viewBulkAdjustmentUploadStats(payload));
    if (response.viewBulkAdjustmentUploadStats) {
      if (cb) cb({ success: true, data: response.viewBulkAdjustmentUploadStats });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchConfigCreditProfileSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigCreditProfile(payload));
    if (response.searchConfigCreditProfile) {
      if (cb) cb({ success: true, data: response.searchConfigCreditProfile });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

// Accumulators
export function* getAccumulatorsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAccumulatorsConfig());
    yield put(isEndConnected());
    if (response.getAccumulatorsConfig && response.getAccumulatorsConfig.id) {
      if (cb) yield cb({ success: true, data: response.getAccumulatorsConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

// Grants
export function* getGrantsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGrantsConfig());
    yield put(isEndConnected());
    if (response.getGrantsConfig && response.getGrantsConfig.id) {
      if (cb) yield cb({ success: true, data: response.getGrantsConfig.grantListConfig });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* getDiscountOfferByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDiscountOfferById(payload));
    if (response.getDiscountOfferById && response.getDiscountOfferById) {
      if (cb) yield cb({ success: true, data: response.getDiscountOfferById });
    } else if (cb) yield cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: null });
  }
}

export function* selectBundleIdListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchBundlesList({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchBundles });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false });
    yield put(isEndConnected());
  }
}

export function* selectPackageIdListSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter } = payload;
    const response = yield call(queryRequest, searchPackagesList({ page, size, filter }));
    if (cb) cb({ success: true, data: response.searchPackages });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false });
  }
}

export function* searchCollectionProfilesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchCollectionProfiles(payload));
    if (response.searchCollectionProfiles) {
      if (cb) cb({ success: true, data: response.searchCollectionProfiles });
    } else if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* searchBillInvoiceSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchBillInvoice(payload));
    if (response.searchBillInvoice) {
      if (cb) cb({ success: true, data: response.searchBillInvoice });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getTaxTypeConfigSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getTaxTypeConfig());
    yield put(isEndConnected());
    if (response.getTaxTypeConfig && response.getTaxTypeConfig) {
      if (cb) yield cb({ success: true, data: response.getTaxTypeConfig });
    } else if (cb) yield cb({ success: false });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export function* searchChartOfAccountConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchChartOfAccount(payload));
    if (response.searchChartOfAccount) {
      if (cb) cb({ success: true, data: response.searchChartOfAccount });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchOrdersConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchOrders(payload));
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: response.searchOrders || [] });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: [] });
  }
}

export function* searchEmployeeCostingConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchEmployeeCosting(payload));
    if (response.searchEmployeeCosting) {
      if (cb) cb({ success: true, data: response.searchEmployeeCosting });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export default function* selectSaga() {
  yield takeLatest(types.SELECT_ITEMS_ID, selectItemsId);
  yield takeLatest(types.SELECT_BUNDLE_ID, selectBundleId);
  yield takeLatest(types.SELECT_PACKAGE_ID, selectPackageId);
  yield takeLatest(types.SELECT_PRICE_OFFER_ID, selectPriceOfferId);
  yield takeEvery(types.SELECT_ACCOUNT_ID, selectAccountId);
  yield takeEvery(types.GET_BUNDLE_BY_BUNDLE_ID, getBundleById);
  yield takeLatest(types.SELECT_INVOICE_UNITS_ID, selectInvoiceUnitsId);
  yield takeLatest(types.SELECT_BILL_UNITS_ID, selectBillUnitsId);
  yield takeEvery(types.GET_ACCOUNT_DETAIL, getAccountDetailSaga);
  yield takeLatest(types.LOGOUT, logoutSaga);
  yield takeLatest(types.CHECK_LOGIN_USER, checkLoginSaga);
  yield takeLatest(types.HANDLE_CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(types.GET_ROLE_AND_ROLE_GROUP_BY_ID, getRolesAndRoleGroupByUserIdSaga);
  yield takeEvery(types.GET_ROLE_BY_ID, getRoleByIdSaga);
  yield takeEvery(types.GET_ROLE_ID_BY_ROLEGROUP, getRoleIdByRoleGroupSaga);
  yield takeLatest(types.CHECK_AUTH, checkAuthSaga);
  yield takeLatest(types.SEARCH_USER_BY_TYPE_AGENT, searchUserByTypeAgentSaga);
  yield takeEvery(types.SEARCH_USER_BY_USER_ID_COLLECTION, searchUserByUserIdCollectionSaga);
  yield takeEvery(types.GET_USAGE_CONTAINER_SPEC_CONFIG, getUsageContainerSpecSaga);
  yield takeEvery(types.GET_UNIQUE_PRODUCT_FAMILY, getUniqueProductFamilySaga);
  yield takeEvery(types.GET_UNIQUE_PRODUCT_LINE, getUniqueProductLineSaga);
  yield takeEvery(types.GET_UNIQUE_PRODUCT_SUB_TYPE, getUniqueProductSubTypeSaga);
  yield takeEvery(types.GET_UNIQUE_PRODUCT_TYPE, getUniqueProductTypeSaga);
  yield takeEvery(types.GET_ACCOUNT_ITEMS, getAccountItemsSaga);
  yield takeEvery(types.SELECT_RATE_UNITS_ID, selectRateUnitIdSaga);
  yield takeEvery(types.SELECT_TIME_UNITS_ID, selectTimeUnitIdSaga);
  yield takeEvery(types.GET_TRANSACTION_UNIT_BY_ACCOUNT_ID, getTransactionUnitByAccountIdSaga);
  yield takeEvery(types.SELECT_MILETSTONE_ID, searchRevenueMilestoneIdSaga);
  yield takeEvery(types.GET_CCP_PROPERTIES_CONFIG, getCcpPropertiesConfigSaga);
  yield takeEvery(types.GET_PACKAGE_BY_PACKAGE_ID, getPackageByIdSaga);
  yield takeEvery(types.SELECT_DISSCOUNT_OFFER_ID, getDisscountOfferId);
  yield takeLatest(types.GET_S3_BUCKET, getS3BuctketSaga);
  yield takeEvery(types.READ_FILE_FROM_S3, readFileFromS3Saga);
  yield takeLatest(types.UPLOAD_MULTI_PART_FILE, uploadFileSaga);
  yield takeEvery(types.SEARCH_CONFIG_OPERATOR_MAP_LIST, searchConfigOperatorMapListSaga);
  yield takeEvery(types.SEARCH_USERS_LIST, searchUsersListSaga);
  yield takeEvery(types.GET_AR_REASON_CODE_SELECTION, getARReasonCodeSelectionSaga);
  yield takeEvery(types.GET_USER_GROUPS_CONFIG, getUserGroupsConfigSaga);
  yield takeEvery(types.SEARCH_OBJECT_APPROVALS, searchObjectApprovalsSaga);
  yield takeEvery(types.SEARCH_SERVICE_PROVISIONING_DATA, searchServiceProvisioningDataSaga);
  yield takeEvery(types.GET_FLEX_ATTRIBUTE_MAPPING_CONFIG, getFlexAttributesMappingConfigSaga);
  yield takeEvery(types.GET_DISTINCT_USAGE_TYPES, getDistinctUsageTypesSaga);
  yield takeEvery(types.GET_CCP_DATE_TIME_CONFIG, getCcpDateTimeConfigSaga);
  yield takeEvery(types.GET_DISTINCT_ORGANIZATION, getDistinctOrganizationSaga);
  yield takeLatest(types.GET_AWS_S3_CREDENTIAL, getAwsS3CredentialSaga);
  yield takeEvery(types.MODIFY_ACCOUNT_SERVICE_LOCATION, modifyAccountServiceLocationsSaga);
  yield takeEvery(types.GET_PAYMENT_ITEM_MAP_MODAL, getPaymentItemMapModalSaga);
  yield takeEvery(types.SEARCH_PAYMENT_BY_ACCOUNT, searchPaymentByAccountSaga);
  yield takeEvery(types.GET_OBJECT_FILE_BY_ID, getObjectFileByIdSaga);
  yield takeLatest(types.GET_TENANT_DEFAULT_CONFIG, getTenantDefaultConfigSaga);
  yield takeEvery(types.SEARCH_ITEM_SERVICE, searchItemServicesSaga);
  yield takeEvery(types.MODIFY_ACCOUNT_SERVICE, modifyAccountServiceSaga);
  yield takeEvery(types.REVERSE_PAYMENT_SERVICE, reversePaymentSaga);
  yield takeEvery(types.ALLOCATE_PAYMENT_SERVICE, allocatePaymentSaga);
  yield takeEvery(types.GET_TAX_CODE_CONFIG_SELECT, getTaxCodeConfigSaga);
  yield takeEvery(types.GET_UOM_CONFIG, getUomConfigSaga);
  yield takeEvery(types.GET_BILLABLE_SERVICE_PRICING, getBillableServicePricingSaga);
  yield takeEvery(types.MODIFY_BILLABLE_SERVICE, modifyOrderBillableServicesSaga);
  yield takeEvery(types.GET_CURRENCY_CONFIG, getCurrencyConfigAppSaga);
  yield takeLatest(types.GET_ORDER_BILLABLE_BY_INDEX_AND_ID, getOrderBillableByIdAndIndexSaga);
  yield takeLatest(types.RE_GENERATE_PAYMENT, regeneratePaymentFolioSaga);
  yield takeLatest(types.GET_USAGE_TYPE_CONFIG, getConfigUsageTypeConfigSaga);
  yield takeLatest(types.GET_REGION_MAP_CONFIG_DATA, getRegionMapConfigSaga);
  yield takeLatest(types.GET_PROVISIONING_INSTANCE_DATA, getProvisioningInstanceDataSaga);
  yield takeLatest(types.UPDATE_PROVISIONING_DATA, updateProvisioningDataSaga);
  yield takeLatest(types.UPDATE_PAYMENT_STATUS, updatePaymentStatusSaga);
  yield takeLatest(types.GET_GL_ACCOUNT_CONFIG, getGlAccountSaga);
  yield takeLatest(types.GET_PAYMENT_FILE_CONFIG, getBatchPaymentFileConfigSelectSaga);
  yield takeLatest(types.GET_INVOICE_UNIT_BY_ID, getInvoiceByIdSelectSaga);
  yield takeLatest(types.GET_PAC_PRODUCT_CODES_CONFIG, getPACProductCodesConfigSaga);
  yield takeLatest(types.GET_PAYMENT_CONFIG_BY_TYPE, getPaymentConfigByTypeAppSaga);
  yield takeLatest(types.GET_COLLECTION_AGENT_BY_TYPE, getCollectionAgentByTypeConfigSaga);
  yield takeLatest(types.GET_COST_CENTER_CONFIG, getCostCenterConfigSaga);
  yield takeLatest(types.GET_PENDING_ACCOUNT_MOVEMENT_DATA, getPendingAccountMovementDataSaga);
  yield takeLatest(types.DELETE_PENDING_ACCOUNT_MOVEMENT_DATA, deletePendingAccountMovementDataSaga);
  yield takeLatest(types.GET_PAYMENT_ALLOCATION, getPaymentAllocationSaga);
  yield takeEvery(types.GET_CONFIG_OPERATOR_BY_SERVICE_TYPE, getConfigOperatorByServiceTypeSaga);
  yield takeLatest(types.GET_ACCOUNT_INFO, getAccountInfoSaga);
  yield takeEvery(types.GET_PAYMENT_ACTIVITY, getPaymentActivitySaga);
  yield takeLatest(types.GET_ORDER_SUMMARY_BY_ACCOUNT_ID_CONFIG, getOrderSummaryByAccountIdConfigSaga);
  yield takeLatest(types.SAVE_MANUAL_INVOICE_CONFIG, saveManualInvoiceConfigSaga);
  yield takeLatest(types.RE_PROCESS_INVOICE_CONFIG, reprocessInvoiceConfigSaga);
  yield takeLatest(types.MODIFY_INVOICE_STATUS_CONFIG, modifyInvoiceStatusConfigSaga);
  yield takeLatest(types.REGENERATE_INVOICE_CONFIG, regenerateInvoiceConfigSaga);
  yield takeLatest(types.UNDO_INVOICE_CANCEL_CONFIG, undoInvoiceCancelConfigSaga);
  yield takeLatest(types.SEND_INVOICE_CONFIG, sendInvoiceConfigSaga);
  yield takeLatest(types.UNDO_MANUAL_INVOICE_CONFIG, undoManualInvoiceConfigSaga);
  yield takeLatest(types.MODIFY_INVOICE_DUE_DATE_CONFIG, modifyInvoiceDueDateConfigSaga);
  yield takeLatest(types.RESEND_INVOICE_VENDOR_CONFIG, resendInvoiceVendorConfigSaga);
  yield takeLatest(types.GET_NOTES_UNIT_BY_ID_CONFIG, getNotesUnitByIdSaga);
  yield takeLatest(types.MODIFY_NOTES_UNIT_CONFIG, modifyNotesUnitConfigSaga);
  yield takeLatest(types.RESEND_NOTES_VENDOR_CONFIG, resendNotesVendorConfigSaga);
  yield takeLatest(types.GET_BILLABLE_SERVICE_PRICING_HISTORY, getBillableServicePricingHistorySaga);
  yield takeLatest(types.GET_PAC_INTERFACE_RECORD_BY_ID_CONFIG, getPacInterfaceRecordByIdConfigSaga);
  yield takeLatest(types.SEND_FOLIO, sendFolioSaga);
  yield takeLatest(types.GET_INVOICE_INSTALLMENTS_BY_INVOICEID_CONFIG, getInvoiceInstallmentsByInvoiceIdConfigSaga);
  yield takeLatest(types.CREATE_PAYMENT_INSTALLMENT_BY_ACCOUNTID_CONFIG, createPaymentInstallmentConfigSaga);
  yield takeLatest(types.GET_BILLABLE_SERVICE_PROVISIONING_ATTRIBUTES, getBillableServiceProvisioningAttributesSaga);
  yield takeLatest(types.GET_CONNFIG_USAGE_PLATFORM_LIST, getConfigUsagePlatformsSaga);
  yield takeLatest(types.GET_OUTPUT_TEMPLATE_BY_TYPE, getOutputTemplateByTypeSaga);
  yield takeLatest(types.SAVE_OUTPUT_TEMPLATE_BY_TYPE, saveOutputTemplateByTypeSaga);
  yield takeLatest(types.BULK_PROCESS, bulkProcessSaga);
  yield takeLatest(types.GET_CONFIG_FAILURE_CODES, getConfigFailureCodesSaga);
  yield takeLatest(types.GET_CONFIG_IDD, getConfigIddSaga);
  yield takeLatest(types.GET_CONFIG_IDO, getConfigIdoSaga);
  yield takeLatest(types.GET_CONFIG_INCOMING_OUTGOING_ROUTE, getConfigIncomingOutgoingRouteSaga);
  yield takeLatest(types.GET_SPLIT_TAX_PER_LINE_CONFIG, getSplitTaxPerLineConfigSaga);
  yield takeLatest(types.GET_CONFIG_FAILURE_CODES_LIST, getConfigFailureCodesListSaga);
  yield takeLatest(types.GET_CONFIG_SRESERVED, getConfigSreservedSaga);
  yield takeLatest(types.GET_CONFIG_ORGANIZATION, getConfigOrganizationSaga);
  yield takeLatest(types.SEARCH_PROVISIONING_ATTRIBUTES_CONFIG_ITEM, searchProvisioningAttributesConfigSaga);
  yield takeLatest(types.SEARCH_DISCOUNT_TRIGGER_CONFIG, searchDiscountTriggersConfigSaga);
  yield takeLatest(types.GET_USER_BY_ID_DETAILS, getUserByIdDetailsSaga);
  yield takeLatest(types.GET_API_BY_GETEWAY_NAME_CONFIG, getApiByGatewayNameConfigSaga);
  yield takeLatest(types.GET_CLOUD_FILE_DATA_BY_NAME, getCloudFileDataByFileNameSaga);
  yield takeLatest(types.VIEW_BULK_ADJUSTMENT_UPLOAD_STATS, viewBulkAdjustmentUploadStatsSaga);
  yield takeLatest(types.SEARCH_CONFIG_CREDIT_PROFILE, searchConfigCreditProfileSaga);
  yield takeLatest(types.GET_ACCUMULATORS_CONFIG, getAccumulatorsSaga);
  yield takeLatest(types.GET_GRANTS_CONFIG, getGrantsSaga);
  yield takeLatest(types.GET_DISCOUNT_OFFER_BY_ID, getDiscountOfferByIdSaga);
  yield takeLatest(types.SELECT_BUNDLE_ID_LIST, selectBundleIdListSaga);
  yield takeLatest(types.SELECT_PACKAGE_ID_LIST, selectPackageIdListSaga);
  yield takeLatest(types.SEARCH_COLLECTION_PROFILE_CONFIG, searchCollectionProfilesSaga);
  yield takeLatest(types.SELECT_BILL_INVOICE, searchBillInvoiceSaga);
  yield takeLatest(types.GET_TAX_TYPE_CONFIG_SELECT, getTaxTypeConfigSaga);
  yield takeLatest(types.SEARCH_CHART_OF_ACCOUNT_CONFIG, searchChartOfAccountConfigSaga);
  yield takeLatest(types.SEARCH_ORDERS_CONFIG, searchOrdersConfigSaga);
  yield takeLatest(types.SEARCH_EMPLOYEE_COSTING_CONFIG, searchEmployeeCostingConfigSaga);
}
