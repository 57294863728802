import React from 'react';
import utils, { formatMoneyValue } from '../../utils/utils';
import selectConstant from '../../utils/selectConstant';
import listCurrency from '../../utils/currency.json';
import { countryList } from '../../constantsApp/countryList';
import RouteNames from '../App/RouteNames';

export default {
  listInput: [
    {
      label: 'First Name',
      name: 'firstName',
    },
    {
      label: 'Last Name',
      name: 'lastName',
    },
    {
      label: 'City',
      name: 'city',
    },
    {
      label: 'State',
      name: 'state',
    },
    {
      label: 'Organization',
      name: 'organization',
    },
  ],
  newAccount: {
    collapsibleMenu: [
      {
        title: 'createAccount.createAccountInfo',
        state: 'Create Account Info',
      },
      {
        title: 'createAccount.createContact',
        state: 'Create Contact',
      },
      {
        title: 'createAccount.createAddress',
        state: 'Create Address',
      },
      {
        title: 'createAccount.createPaymentProfile',
        state: 'Create Payment Profile',
      },
      {
        title: 'createAccount.createBillingProfile',
        state: 'Create Billing Profile',
      },
      {
        title: 'createAccount.createCustomerAttributes',
        state: 'Create Customer Attributes',
      },
    ],
    createAccountPaymentProfile: [
      {
        labelFilter: 'Payment Term',
        fieldSelect: 'paymentTerm',
        optionSelect: selectConstant.paymentTerm,
      },
      {
        labelFilter: 'Payment Method',
        fieldSelect: 'paymentMethod',
        optionSelect: selectConstant.paymentMethod,
      },
    ],
  },
  sidePropIndex: {
    isExpand: false,
    items: [
      {
        isHaveExpand: true,
        mainTilte: 'customerPage:sidebarSearchPage.config.title',
        childs: [
          {
            icon: 'fa fa-list',
            label: 'customerPage:sidebarSearchPage.config.subTitle.customAttributes',
            href: RouteNames.customerAttribute.path,
          },
          {
            icon: 'ti-credit-card',
            label: 'customerPage:sidebarSearchPage.config.subTitle.creditProfile',
            href: RouteNames.creditProfile.path,
          },
          // {
          //   icon: 'ti-image',
          //   label: 'customerPage:sidebarSearchPage.config.subTitle.delayedBilling',
          //   href: RouteNames.delayedBilling.path,
          // },
        ],
      },
      {
        isHaveExpand: true,
        mainTilte: 'customerPage:sidebarSearchPage.account.searchAccounts',
        icon: 'fa fa-search',
        href: RouteNames.customers.path,
        childs: [],
      },
      {
        isHaveExpand: true,
        mainTilte: 'customerPage:sidebarSearchPage.config.subTitle.accountMovement',
        key: 'accountMovement',
        childs: [
          {
            label: 'customerPage:sidebarSearchPage.config.subTitle.merge',
            href: RouteNames.merge.path,
            icon: 'fa fa-compress',
          },
          {
            icon: 'ti-cut',
            label: 'customerPage:sidebarSearchPage.config.subTitle.disperse',
            href: RouteNames.split.path,
            // disable: true,
          },
        ],
      },
    ],
  },

  accountInfo: {
    accountInfoForm: currencyOptions => {
      return [
        {
          labelFilter: 'label.customerSegment',
          fieldSelect: 'customerSegment',
          optionSelect: selectConstant.customerSegment,
        },
        {
          labelFilter: 'label.accountCategory',
          fieldSelect: 'accountCategory',
          tOptions: 'selections:accountCategory',
          readOnly: true,
        },
        {
          labelFilter: 'label.accountType',
          fieldSelect: 'type',
          tOptions: 'selections:accountType',
        },
        {
          labelFilter: 'label.accountSubType',
          fieldSelect: 'subType',
          tOptions: 'selections:accountSubType',
        },
        // {
        //   labelFilter: 'label.creditProfileIndex',
        //   fieldName: 'creditProfileIndex',
        //   fa: 'fa fa-external-link',
        // },
        {
          labelFilter: 'label.salesChannel',
          fieldSelect: 'salesChannel',
          tOptions: 'selections:salesChannel',
        },
        {
          labelFilter: 'label.marketSegment',
          fieldSelect: 'marketSegment',
          tOptions: 'selections:marketSegment',
        },
        {
          labelFilter: 'label.status',
          fieldSelect: 'status',
          tOptions: 'selections:status',
        },
        {
          labelFilter: 'label.reason',
          fieldSelect: 'reason',
          tOptions: 'selections:reason',
        },
        {
          labelFilter: 'label.statusChangeEffectiveDate',
          fieldDate: 'statusModifiedDate',
          tooltipDetails: 'label.changeTheDateForBackdatedOrFutureDatedStatusChange',
        },
        {
          labelFilter: 'common:label.organization',
          fieldName: 'organization',
        },
        {
          labelFilter: 'label.sellingCompany',
          fieldName: 'sellingCompany',
        },
        {
          labelFilter: 'label.lineOfBusiness',
          fieldName: 'lineOfBusiness',
        },
        {
          labelFilter: 'label.legalEntity',
          fieldName: 'legalEntity',
        },
        {
          labelFilter: 'label.currency',
          fieldSelect: 'currency',
          optionSelect: currencyOptions || [],
          // optionSelect: utils.convertCurrency2Option(listCurrency.currencies),
          required: true,
        },
        {
          labelFilter: 'label.effectiveDate',
          fieldDate: 'effectiveDate',
        },
        {
          labelFilter: 'common:label.clientId',
          fieldName: 'clientId',
        },
        {
          labelFilter: 'common:label.customerId',
          fieldName: 'customerId',
        },
        {
          labelFilter: 'common:label.salesAgentId',
          fieldName: 'salesAgentId',
        },
        {
          labelFilter: 'common:label.region',
          fieldName: 'region',
        },
        {
          labelFilter: 'common:label.externalRefId',
          fieldName: 'externalRefId',
          readOnly: true,
        },
        {
          labelFilter: 'label.collectionAgentId',
          fieldName: 'collectionAgent',
          fa: 'fa fa-external-link',
        },
        {
          labelFilter: 'common:label.employeeId',
          fieldName: 'employeeId',
        },
        {
          labelFilter: 'common:label.commercialName',
          fieldName: 'commercialName',
        },
        {
          labelFilter: 'common:label.taxId',
          fieldName: 'taxId',
        },
        {
          labelFilter: 'common:label.foreignTaxId',
          fieldName: 'foreignTaxId',
        },
        {
          labelFilter: 'common:label.name',
          fieldName: 'name',
        },
        {
          labelFilter: 'common:label.partyId',
          fieldName: 'partyId',
          // readOnly: true,
        },
        {
          labelFilter: 'common:label.groupId',
          fieldName: 'groupId',
          // readOnly: true,
        },
      ];
    },

    accountNewInfoForm: (currencyOptions, data) => [
      {
        labelFilter: 'common:label.customerSegment',
        fieldSelect: 'customerSegment',
        optionSelect: selectConstant.customerSegment,
      },
      {
        labelFilter: 'common:label.currency',
        fieldSelect: 'currency',
        required: true,
        // optionSelect:currencyOptions utils.convertCurrency2Option(listCurrency.currencies),
        optionSelect: currencyOptions || [],
      },
      // {
      //   labelFilter: 'common:label.creditProfileIndex',
      //   fieldName: 'creditProfileIndex',
      //   fa: 'fa fa-external-link',
      // },
      {
        labelFilter: 'common:label.effectiveDate',
        fieldDate: 'effectiveDate',
      },
      {
        labelFilter: 'common:label.sellingCompany',
        fieldName: 'sellingCompany',
      },
      {
        labelFilter: 'common:label.organization',
        fieldName: 'organization',
      },
      {
        labelFilter: 'common:label.commercialName',
        fieldName: 'commercialName',
        isDisable: data && data.customerSegment && data.customerSegment === 'B2C',
      },
      {
        labelFilter: 'common:label.customerId',
        fieldName: 'customerId',
      },
      {
        labelFilter: 'common:label.clientId',
        fieldName: 'clientId',
        isDisable: data && data.customerSegment && data.customerSegment === 'B2C',
      },
      {
        labelFilter: 'common:label.taxId',
        fieldName: 'taxId',
      },
      {
        labelFilter: 'common:label.foreignTaxId',
        fieldName: 'foreignTaxId',
      },
      {
        labelFilter: 'common:label.legalEntity',
        fieldName: 'legalEntity',
      },
      {
        labelFilter: 'common:label.lineOfBusiness',
        fieldName: 'lineOfBusiness',
        isDisable: data && data.customerSegment && data.customerSegment === 'B2C',
      },
      {
        labelFilter: 'common:label.salesChannel',
        fieldSelect: 'salesChannel',
        tOptions: 'selections:salesChannel',
      },
      {
        labelFilter: 'common:label.marketSegment',
        fieldSelect: 'marketSegment',
        tOptions: 'selections:marketSegment',
      },
      {
        labelFilter: 'common:label.accountType',
        fieldSelect: 'type',
        tOptions: 'selections:accountType',
      },
      {
        labelFilter: 'common:label.accountSubType',
        fieldSelect: 'subType',
        tOptions: 'selections:accountSubType',
      },
      {
        labelFilter: 'label.collectionAgentId',
        fieldName: 'collectionAgent',
        fa: 'fa fa-external-link',
      },
      {
        labelFilter: 'common:label.employeeId',
        fieldName: 'employeeId',
        isDisable: !data || !data.type || data.type !== 'EMPLOYEE',
      },
      {
        labelFilter: 'common:label.salesAgentId',
        fieldName: 'salesAgentId',
      },
      {
        labelFilter: 'common:label.externalRefId',
        fieldName: 'externalRefId',
      },
      {
        labelFilter: 'common:label.name',
        fieldName: 'name',
      },
      {
        labelFilter: 'common:label.region',
        fieldName: 'region',
      },
      {
        labelFilter: 'Status',
        fieldSelect: 'status',
        optionSelect: selectConstant.status,
      },
      {
        labelFilter: 'Reason',
        fieldSelect: 'reason',
        optionSelect: selectConstant.reason,
      },
      {
        labelFilter: 'common:label.partyId',
        fieldName: 'partyId',
      },
      {
        labelFilter: 'common:label.groupId',
        fieldName: 'groupId',
      },
    ],

    btnGroup: {
      btnCancel: {
        label: 'CANCEL',
        name: 'CANCEL',
        className: 'button button-border black x-small float-right',
      },
      btnModify: {
        label: 'MODIFY',
        name: 'MODIFY',
        className: 'button button-border gray x-small float-right',
      },
      btnCreateOrder: {
        label: 'CREATE NEW ORDER',
        name: 'CREATE NEW ORDER',
        className: 'button button-border x-small float-right',
      },
    },
  },

  accountContract: {
    accountContactForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        isDisable: true,
      },
      {
        labelFilter: 'common:label.salutation',
        fieldName: 'salutation',
      },
      {
        labelFilter: 'common:label.firstName',
        fieldName: 'firstName',
        required: true,
      },
      {
        labelFilter: 'common:label.middleName',
        fieldName: 'middleName',
      },
      {
        labelFilter: 'common:label.lastName',
        fieldName: 'lastName',
        // required: true,
      },
      {
        labelFilter: 'common:label.position',
        fieldName: 'position',
      },
      {
        labelFilter: 'common:label.organization',
        fieldName: 'organization',
      },
      {
        labelFilter: 'common:label.email',
        fieldName: 'email',
        // required: true,
      },
      {
        labelFilter: 'common:label.identity',
        fieldName: 'identity',
        tooltipDetails: 'common:message.identityNote',
      },
      {
        labelFilter: 'common:label.identityDocument',
        fieldName: 'identityDocument',
        tooltipDetails: 'common:message.identityDocumentNote',
      },
      {
        labelFilter: 'common:label.phone',
        fieldPhone: 'phones',
      },
    ],

    accountNewContactDetailsForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        // required: true,
      },
      {
        labelFilter: 'common:label.salutation',
        fieldName: 'salutation',
      },
      {
        labelFilter: 'common:label.firstName',
        fieldName: 'firstName',
        required: true,
      },
      {
        labelFilter: 'common:label.middleName',
        fieldName: 'middleName',
      },
      {
        labelFilter: 'common:label.lastName',
        fieldName: 'lastName',
        required: true,
      },
      {
        labelFilter: 'common:label.position',
        fieldName: 'position',
      },
      {
        labelFilter: 'common:label.organization',
        fieldName: 'organization',
      },
      {
        labelFilter: 'common:label.email',
        fieldName: 'email',
        // required: true,
      },
      {
        labelFilter: 'common:label.identity',
        fieldName: 'identity',
        tooltipDetails: 'common:message.identityNote',
      },
      {
        labelFilter: 'common:label.identityDocument',
        fieldName: 'identityDocument',
        tooltipDetails: 'common:message.identityDocumentNote',
      },
      {
        labelFilter: 'common:label.phone',
        fieldPhone: 'phones',
      },
    ],

    accountNewContactForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        isDisable: true,
      },
      {
        labelFilter: 'common:label.salutation',
        fieldName: 'salutation',
      },
      {
        labelFilter: 'common:label.firstName',
        fieldName: 'firstName',
        required: true,
      },
      {
        labelFilter: 'common:label.middleName',
        fieldName: 'middleName',
      },
      {
        labelFilter: 'common:label.lastName',
        fieldName: 'lastName',
        // required: true,
      },
      {
        labelFilter: 'common:label.position',
        fieldName: 'position',
      },
      {
        labelFilter: 'common:label.organization',
        fieldName: 'organization',
      },
      {
        labelFilter: 'common:label.email',
        fieldName: 'email',
        // required: true,
      },
      {
        labelFilter: 'common:label.identity',
        fieldName: 'identity',
        tooltipDetails: 'common:message.identityNote',
      },
      {
        labelFilter: 'common:label.identityDocument',
        fieldName: 'identityDocument',
        tooltipDetails: 'common:message.identityDocumentNote',
      },
      {
        labelFilter: 'common:label.phone',
        fieldPhone: 'phones',
      },
    ],

    accountNewMultiContactForm: [
      {
        labelFilter: 'common:label.id',
        fieldName: 'id',
        required: true,
      },
      {
        labelFilter: 'common:label.salutation',
        fieldName: 'salutation',
      },
      {
        labelFilter: 'common:label.firstName',
        fieldName: 'firstName',
        required: true,
      },
      {
        labelFilter: 'common:label.middleName',
        fieldName: 'middleName',
      },
      {
        labelFilter: 'common:label.lastName',
        fieldName: 'lastName',
        // required: true,
      },
      {
        labelFilter: 'common:label.position',
        fieldName: 'position',
      },
      {
        labelFilter: 'common:label.organization',
        fieldName: 'organization',
      },
      {
        labelFilter: 'common:label.email',
        fieldName: 'email',
        // required: true,
      },
      {
        labelFilter: 'common:label.identity',
        fieldName: 'identity',
        tooltipDetails: 'common:message.identityNote',
      },
      {
        labelFilter: 'common:label.identityDocument',
        fieldName: 'identityDocument',
        tooltipDetails: 'common:message.identityDocumentNote',
      },
      {
        labelFilter: 'common:label.phone',
        fieldPhone: 'phones',
      },
    ],

    btnGroup: [
      {
        label: 'Delete',
        name: 'Delete',
        className: 'button button-border gray x-small float-right',
      },
      {
        label: 'CANCEL',
        name: 'CANCEL',
        className: 'button button-border black x-small float-right',
      },
      {
        label: 'MODIFY',
        name: 'MODIFY',
        className: 'button button-border x-small float-right',
      },
    ],
  },
  checkRoleGroup: [
    {
      label: 'common:label.useAsBilling',
      value: 'BILLING',
    },
    {
      label: 'common:label.useAsSoldTo',
      value: 'SOLD_TO',
    },
    {
      label: 'common:label.useAsPayment',
      value: 'PAYMENT',
    },
    {
      label: 'common:label.useAsService',
      value: 'SERVICE',
    },
  ],

  dataFieldNewAddressDetails: {
    withAddressesUSForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        // required: true,
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        type: 'textarea',
        required: true,
      },
      {
        fieldName: 'extraLine',
        labelFilter: 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        labelFilter: 'common:label.state',
        fieldSelect: 'state',
        optionSelect: selectConstant.stateUSData,
        required: true,
      },
      {
        labelFilter: 'common:label.city',
        fieldSelect: 'city',
        optionSelect: [],
        fieldName: 'city',
        required: true,
      },
      {
        labelFilter: 'common:label.postalCode',
        fieldSelect: 'postalCode',
        fieldName: 'postalCode',
        optionSelect: [],
        required: true,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ],

    withAddressesNormalForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        required: true,
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        type: 'textarea',
        required: true,
      },
      {
        fieldName: 'extraLine',
        labelFilter: 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        optionSelect: countryList,
      },
      {
        fieldName: 'state',
        labelFilter: 'common:label.state',
        required: true,
      },
      {
        labelFilter: 'common:label.city',
        fieldName: 'city',
        required: true,
      },
      {
        fieldName: 'postalCode',
        labelFilter: 'common:label.postalCode',
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
        required: true,
      },
    ],
  },

  dataFieldNewAddress: ({ landmarkLabel, extralineLabel }) => ({
    withAddressesUSForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        required: true,
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        required: true,
        type: 'textarea',
      },
      {
        fieldName: 'extraLine',
        labelFilter: extralineLabel || 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: landmarkLabel || 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        required: true,
        optionSelect: countryList,
      },
      {
        labelFilter: 'common:label.state',
        fieldSelect: 'state',
        required: true,
        optionSelect: selectConstant.stateUSData,
      },
      {
        labelFilter: 'common:label.city',
        fieldSelect: 'city',
        optionSelect: [],
        fieldName: 'city',
        required: true,
      },
      {
        labelFilter: 'common:label.postalCode',
        fieldSelect: 'postalCode',
        fieldName: 'postalCode',
        required: true,
        optionSelect: [],
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ],

    withAddressesNormalForm: [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
        required: true,
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        required: true,
        type: 'textarea',
      },
      {
        fieldName: 'extraLine',
        labelFilter: extralineLabel || 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: landmarkLabel || 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        required: true,
        optionSelect: countryList,
      },
      {
        fieldName: 'state',
        labelFilter: 'common:label.state',
        required: true,
      },
      {
        labelFilter: 'common:label.city',
        fieldName: 'city',
        required: true,
      },
      {
        fieldName: 'postalCode',
        labelFilter: 'common:label.postalCode',
        required: true,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ],
  }),

  dataFieldNewSingleAddress: ({ landmarkLabel, extralineLabel }) => ({
    withAddressesUSForm: [
      {
        fieldName: 'unit',
        labelFilter: 'common:label.unit',
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        required: true,
        type: 'textarea',
      },
      {
        fieldName: 'extraLine',
        labelFilter: extralineLabel || 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: landmarkLabel || 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        required: true,
        optionSelect: countryList,
      },
      {
        labelFilter: 'common:label.state',
        fieldSelect: 'state',
        required: true,
        optionSelect: selectConstant.stateUSData,
        isCreateOption: true,
      },
      {
        fieldName: 'district',
        labelFilter: 'common:label.district',
      },
      {
        labelFilter: 'common:label.city',
        fieldSelect: 'city',
        optionSelect: [],
        fieldName: 'city',
        required: true,
        isCreateOption: true,
      },
      {
        labelFilter: 'common:label.postalCode',
        fieldSelect: 'postalCode',
        fieldName: 'postalCode',
        required: true,
        optionSelect: [],
        isCreateOption: true,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ],

    withAddressesNormalForm: [
      {
        fieldName: 'unit',
        labelFilter: 'common:label.unit',
      },
      {
        fieldName: 'street',
        labelFilter: 'common:label.street',
        type: 'textarea',
        required: true,
      },
      {
        fieldName: 'extraLine',
        labelFilter: extralineLabel || 'common:label.extraLine',
      },
      {
        fieldName: 'landmark',
        labelFilter: landmarkLabel || 'common:label.landmark',
      },
      {
        labelFilter: 'common:label.country',
        fieldSelect: 'country',
        required: true,
        optionSelect: countryList,
      },
      {
        fieldName: 'state',
        labelFilter: 'common:label.state',
        required: true,
      },
      {
        fieldName: 'district',
        labelFilter: 'common:label.district',
      },
      {
        labelFilter: 'common:label.city',
        fieldName: 'city',
        required: true,
      },
      {
        fieldName: 'postalCode',
        labelFilter: 'common:label.postalCode',
        required: true,
      },
      {
        fieldName: 'code',
        labelFilter: 'common:label.code',
        isDisable: true,
      },
    ],
  }),

  accountAddresses: {
    btnGroup: [
      {
        label: 'Delete',
        name: 'Delete',
        className: 'button button-border gray x-small float-right',
      },
      {
        label: 'CANCEL',
        name: 'CANCEL',
        className: 'button button-border black x-small float-right',
      },
      {
        label: 'MODIFY',
        name: 'MODIFY',
        className: 'button button-border x-small float-right',
      },
    ],
  },

  accountBillingProfile: {
    accountBillingProfileForm: (data, isSupport31DayBilling) => [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
      },
      {
        fieldName: 'parentId',
        labelFilter: 'common:label.parentId',
        isDisable: true,
      },
      {
        fieldName: 'billingSegment',
        labelFilter: 'common:label.billingSegment',
      },
      {
        fieldSelect: 'billingFrequency',
        labelFilter: 'common:label.billingFrequency',
        tOptions: 'selections:billingFrequency',
      },
      {
        fieldName: 'duration',
        labelFilter: 'common:label.duration',
        type: 'number',
      },
      {
        fieldName: 'billingDom',
        labelFilter: 'common:label.billingDom',
        type: 'number',
        isDisable: data && data.billingFrequency && data.billingFrequency === 'DAYS',
        required: true,
        minValue: 1,
        maxValue: isSupport31DayBilling ? 31 : 28,
      },
      {
        fieldSelect: 'invoiceType',
        labelFilter: 'common:label.invoiceType',
        tOptions: 'selections:invoiceType',
        required: true,
      },
      {
        fieldSelect: 'invoiceDelivery',
        labelFilter: 'common:label.invoiceDelivery',
        tOptions: 'selections:invoiceDelivery',
        required: true,
      },
      {
        fieldName: 'paymentProfileId',
        labelFilter: 'common:label.paymentProfileId',
      },
      {
        labelFilter: 'label.collectionProfile',
        fieldName: 'collectionProfile',
        fa: 'fa fa-external-link',
      },
      {
        fieldName: 'pauseBilling',
        labelFilter: 'common:label.billing',
        type: 'switch',
        itemClassName: 'ml-3 check-item-account',
        deactiveAttr: 'label.pause',
        activeAttr: 'label.resume',
      },
      {
        fieldName: 'pauseInvoicing',
        labelFilter: 'common:label.invoicing',
        type: 'switch',
        itemClassName: 'check-item-account',
        deactiveAttr: 'label.pause',
        activeAttr: 'label.resume',
      },
    ],

    accountNewBillingProfileForm: (data, isSupport31DayBilling) => [
      {
        fieldName: 'id',
        labelFilter: 'common:label.id',
      },
      {
        fieldName: 'parentId',
        labelFilter: 'common:label.parentId',
        isDisable: true,
      },
      {
        fieldName: 'billingSegment',
        labelFilter: 'common:label.billingSegment',
      },
      {
        fieldSelect: 'billingFrequency',
        labelFilter: 'common:label.billingFrequency',
        required: true,
        tOptions: 'selections:billingFrequency',
      },
      {
        fieldName: 'duration',
        labelFilter: 'common:label.duration',
        type: 'number',
        // required: data && data.billingFrequency && data.billingFrequency === 'DAYS',
      },
      {
        fieldName: 'billingDom',
        labelFilter: 'common:label.billingDom',
        type: 'number',
        isDisable: data && data.billingFrequency && data.billingFrequency === 'DAYS',
        minValue: 1,
        maxValue: isSupport31DayBilling ? 31 : 28,
      },
      {
        fieldSelect: 'invoiceType',
        labelFilter: 'common:label.invoiceType',
        required: true,
        tOptions: 'selections:invoiceType',
      },
      {
        fieldSelect: 'invoiceDelivery',
        labelFilter: 'common:label.invoiceDelivery',
        required: true,
        tOptions: 'selections:invoiceDelivery',
      },
      {
        labelFilter: 'label.collectionProfile',
        fieldName: 'collectionProfile',
        fa: 'fa fa-external-link',
      },
      {
        fieldName: 'pauseBilling',
        labelFilter: 'common:label.pauseBilling',
        type: 'checkbox',
        itemClassName: 'check-item-account',
      },
      {
        fieldName: 'pauseInvoicing',
        labelFilter: 'common:label.pauseInvoicing',
        type: 'checkbox',
        itemClassName: 'check-item-account',
      },
    ],

    btnGroup: [
      {
        label: 'CANCEL',
        name: 'CANCEL',
        className: 'button button-border black x-small float-right',
      },
      {
        label: 'MODIFY',
        name: 'MODIFY',
        className: 'button button-border x-small float-right',
      },
    ],
  },
  accountServices: {
    subsciptionTable: [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'name',
        label: 'common:label.name',
      },
      {
        name: 'category',
        label: 'common:label.category',
        type: 'select',
        tOptions: 'selections:subscriptionCategory',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:subscriptionCategory')().find(val => val.value === item.category) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'creditProfileName',
        label: 'common:label.creditProfile',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
      },
      {
        name: 'status',
        label: 'common:label.status',
        type: 'select',
        tOptions: 'selections:subscriptionStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:subscriptionStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'initialTermUnit',
        label: 'common:label.initialTermUnit',
        type: 'select',
        tOptions: 'selections:initialTermUnit',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.initialTermUnit) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'initialTerm',
        label: 'common:label.initialTerm',
      },
      {
        name: 'renewalTermUnit',
        label: 'common:label.renewalTermUnit',
        type: 'select',
        tOptions: 'selections:initialTermUnit',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.renewalTermUnit) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'renewalTerm',
        label: 'common:label.renewalTerm',
      },

      {
        name: 'startDate',
        label: 'common:label.termStart',
      },
      {
        name: 'endDate',
        label: 'common:label.termEnd',
      },
      {
        name: 'trialTermUnit',
        label: 'common:label.trialTermUnit',
        type: 'select',
        tOptions: 'selections:initialTermUnit',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:initialTermUnit')().find(val => val.value === item.trialTermUnit) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'trialTerm',
        label: 'common:label.trialTerm',
      },
      {
        name: 'trialType',
        label: 'common:label.trialType',
        type: 'select',
        tOptions: 'selections:trialTypes',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:trialTypes')().find(val => val.value === item.trialType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'trialStatus',
        label: 'common:label.trialStatus',
        type: 'select',
        tOptions: 'selections:trialStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:trialStatus')().find(val => val.value === item.trialStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ],

    serviceUnitTable: [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'type',
        label: 'common:label.type',
        type: 'select',
        tOptions: 'selections:serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'subscriptionId',
        label: 'common:label.subscriptionId',
      },
      // {
      //   name: 'accountId',
      //   label: 'common:label.accountId',
      // },
      {
        name: 'status',
        label: 'common:label.status',
        type: 'select',
        tOptions: 'selections:serviceUnitStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceUnitStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'parentId',
        label: 'common:label.parentId',
      },

      {
        name: 'provisioningId',
        label: 'common:label.provisioningId',
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
      },
      {
        name: 'packageName',
        label: 'common:label.packageName',
      },
      {
        name: 'effectiveDate',
        label: 'common:label.effectiveDate',
      },
    ],
  },
  accountAssets: {
    assetsTable: [
      {
        name: 'id',
        label: 'common:label.id',
      },
      {
        name: 'status',
        label: 'common:label.status',
        type: 'select',
        tOptions: 'selections:status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:status')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'quantity',
        label: 'common:label.quantity',
      },
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'offerType',
        label: 'common:label.offerType',
        sortable: true,
      },
      {
        name: 'offerName',
        label: 'common:label.offerName',
        sortable: true,
      },
      {
        name: 'subscriptionId',
        label: 'common:label.subscriptionId',
        sortable: true,
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        type: 'select',
        tOptions: 'selections:serviceType',
        isRenderT: true,
        sortable: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'serviceUnitId',
        label: 'common:label.serviceUnitId',
        sortable: true,
      },
      {
        name: 'bundleName',
        label: 'common:label.bundleName',
        sortable: true,
      },

      {
        name: 'packageName',
        label: 'common:label.packageName',
        sortable: true,
      },
      {
        name: 'offerOverride',
        label: 'common:label.priceOverride',
        sortable: true,
      },
      {
        name: 'offerOffset',
        label: 'common:label.priceOffset',
      },
      {
        name: 'discountPercent',
        label: 'common:label.discountPercent',
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
      {
        name: 'cycleStartDate',
        label: 'common:label.cycleStart',
      },
      {
        name: 'cycleEndDate',
        label: 'common:label.cycleEnd',
      },
      {
        name: 'appliedStartDate',
        label: 'common:label.appliedStart',
      },
      {
        name: 'appliedEndDate',
        label: 'common:label.appliedEnd',
      },
    ],
  },
  accountBalances: {
    balancesTable: [
      {
        name: 'currencyId',
        label: 'common:label.currencyId',
      },
      {
        name: 'amount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.amount)}</span>;
        },
      },
      {
        name: 'creditLimit',
        label: 'common:label.creditLimit',
      },
    ],

    grantTable: [
      {
        name: 'resourceId',
        label: 'common:label.grantId',
      },
      {
        name: 'grantName',
        label: 'common:label.grantName',
      },
      {
        name: 'grantType',
        label: 'common:label.grantType',
      },
      {
        name: 'grantAmount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.grantAmount)}</span>;
        },
      },
      {
        name: 'amountUsed',
        label: 'common:label.amountUsed',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.amountUsed)}</span>;
        },
      },
      {
        name: 'remainingGrant',
        label: 'common:label.remainingGrant',
        render: (colName, item) => {
          return (
            <span>
              {formatMoneyValue(Number.parseFloat(item?.grantAmount || 0) - Number.parseFloat(item?.amountUsed || 0))}
            </span>
          );
        },
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
      },
    ],

    accumulatorsTable: [
      {
        name: 'accumulatorId',
        label: 'common:label.accumulatorId',
      },
      {
        name: 'accumulatorName',
        label: 'common:label.name',
      },
      {
        name: 'accumulateQuantity',
        label: 'common:label.accumulateQuantity',
        render: (colName, item) => {
          return <span>{item.accumulateQuantity ? 'TRUE' : 'FALSE'}</span>;
        },
      },
      {
        name: 'amount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.amount)}</span>;
        },
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
      },
    ],
  },

  accountBills: {
    pendingBillsTable: [
      {
        name: 'accountId',
        label: 'common:label.accountId',
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
      },
      {
        name: 'total',
        label: 'common:label.total',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.total)}</span>;
        },
      },
      {
        name: 'nonPayingTotal',
        label: 'common:label.nonPayingTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.nonPayingTotal)}</span>;
        },
      },
      {
        name: 'billTotal',
        label: 'common:label.billTotal',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.billTotal)}</span>;
        },
      },
      {
        name: 'status',
        label: 'common:label.status',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:billUnitStatus')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
    ],
  },

  accountTransactions: {
    billsTable: [
      // {
      //   name: 'id',
      //   label: 'common:label.id',
      // },
      {
        name: 'type',
        label: 'common:label.type',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:transactionType')().find(val => val.value === item.type) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'billUnitId',
        label: 'common:label.billUnitId',
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'itemId',
        label: 'common:label.itemId',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
      {
        name: 'netAmount',
        label: 'common:label.netAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.netAmount)}</span>;
        },
      },
      {
        name: 'source',
        label: 'common:label.source',
        sortable: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        sortable: true,
      },
    ],
  },
  ratedUsageTransactions: {
    billsTable: [
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'billUnitId',
        label: 'common:label.billUnitId',
      },
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:serviceType')().find(val => val.value === item.serviceType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
        sortable: true,
      },
      {
        name: 'itemId',
        label: 'common:label.itemId',
        sortable: true,
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
      {
        name: 'netAmount',
        label: 'common:label.netAmount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.netAmount)}</span>;
        },
      },
      {
        name: 'source',
        label: 'common:label.source',
        sortable: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        sortable: true,
      },
    ],
  },

  accountArActivities: {
    arActivitiesTable: [
      {
        name: 'endDate',
        label: 'common:label.endDate',
        sortable: true,
      },
      {
        name: 'amount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{formatMoneyValue(item.amount)}</span>;
        },
      },
      {
        name: 'type',
        label: 'common:label.type',
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'source',
        label: 'common:label.source',
        sortable: true,
      },
      {
        name: 'invoiceId',
        label: 'common:label.invoiceId',
      },
      {
        name: 'itemId',
        label: 'common:label.itemId',
        sortable: true,
      },
      {
        name: 'arCrDrType',
        label: 'common:label.arCrDrType',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:arCrDrType')().find(val => val.value === item.arCrDrType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'arTaxRule',
        label: 'common:label.arTaxRule',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:taxRule')().find(val => val.value === item.taxRule) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'reason',
        label: 'common:label.reason',
      },
      {
        name: 'invoiceCancellation',
        label: 'common:label.invoiceCancellation',
        render: (colName, item) => {
          return <span>{item?.invoiceCancellation ? 'TRUE' : item?.invoiceCancellation === false ? 'FALSE' : ''}</span>;
        },
      },
    ],
  },

  accountActivities: {
    activitiesTable: [
      {
        name: 'userId',
        label: 'User Id',
      },
      {
        name: 'entityType',
        label: 'Entity Type',
      },
      {
        name: 'source',
        label: 'Source',
      },
      {
        name: 'action',
        label: 'Action',
      },
      {
        name: 'activityDate',
        label: 'Activity Date',
        sortable: true,
      },
      {
        name: 'id',
        label: 'ID',
      },
    ],
  },

  fieldsInfoCard: [
    {
      label: 'label.currentBalance',
      name: 'currentBalance',
      bgColor: '#3ec1d3',
      isNumberFormat: true,
    },
    {
      label: 'label.currentBillTotal',
      name: 'lastBillTotal',
      bgColor: '#669900',
      isNumberFormat: true,
    },
    {
      label: 'label.lastInvoiceDate',
      name: 'invoiceDate',
      bgColor: '#2d4059',
    },
    {
      label: 'label.lastInvoiceTotal',
      name: 'invoiceTotal',
      bgColor: '#43ab92',
      isNumberFormat: true,
    },
    {
      label: 'label.lastInvoiceDueDate',
      name: 'invoiceDueDate',
      bgColor: '#31a1b0',
    },
    {
      label: 'label.lastInvoiceDue',
      name: 'invoiceDue',
      bgColor: '#456308',
      isNumberFormat: true,
    },
    {
      label: 'label.lastPaymentDate',
      name: 'lastPaymentDate',
      bgColor: '#182330',
    },
    {
      label: 'label.lastPaymentAmount',
      name: 'lastPaymentAmount',
      bgColor: '#009999',
      isNumberFormat: true,
    },
    {
      label: 'label.crARSinceLastInvoice',
      name: 'totalCreditAr',
      bgColor: '#30806d',
      isNumberFormat: true,
    },
    {
      label: 'label.drARSinceLastInvoice',
      name: 'totalDebitAr',
      bgColor: '#006600',
      isNumberFormat: true,
    },
  ],

  fieldParentRequired: ['currency'],
  // fieldContactRequired: ['firstName', 'lastName', 'email'],
  fieldContactRequired: ['firstName'],
  fieldPhoneRequired: ['type', 'number'],
  fieldAddressRequired: ['street', 'country', 'state', 'city', 'postalCode'],
  groupNamePermissions: {
    createCustomer: 'CREATE_CUSTOMER',
    searchAccount: 'SEARCH_ACCOUNTS',
    getAccount: 'GET_ACCOUNT',
    modifyCustomer: 'MODIFY_CUSTOMER',
    getContact: 'GET_CONTACT',
    modifyContact: 'MODIFY_CONTACT',
    deleteContact: 'DELETE_CONTACT',
    addContact: 'ADD_CONTACT',
    modifyPhone: 'MODIFY_PHONE',
    addPhone: 'ADD_PHONE',
    deletePhone: 'DELETE_PHONE',
    getAddress: 'GET_ADDRESS',
    modifyAddress: 'MODIFY_ADDRESS',
    addAddress: 'ADD_ADDRESS',
    deleteAddress: 'DELETE_ADDRESS',
    getPayment: 'GET_PAYMENT_PROFILE',
    modifyPayment: 'MODIFY_PAYMENT_PROFILE',
    modifyCreditCard: 'MODIFY_CREDIT_CARD',
    addCreditCard: 'ADD_CREDIT_CARD',
    deleteCreditCard: 'DELETE_CREDIT_CARD',
    getBillingProfile: 'GET_BILLING_PROFILE',
    modifyBillingProfile: 'MODIFY_BILLING_PROFILE',
    suspendStatus: 'SUSPEND_CUSTOMER',
    resumeStatus: 'RESUME_CUSTOMER',
    closeStatus: 'CLOSE_CUSTOMER',
    getSubscription: 'GET_SUBSCRIPTION',
    getServiceUnit: 'GET_SERVICE_UNIT',
    getPriceUnit: 'GET_PRICE_UNIT',
    searchBills: 'SEARCH_BILLS',
    searchInvoice: 'SEARCH_INVOICES',
    getInvoice: 'GET_INVOICE',
    searchTransactions: 'SEARCH_TRANSACTIONS',
    getTransactions: 'GET_TRANSACTION',
    getBalance: 'GET_BALANCE',
    setCreditLimt: 'SET_CREDIT_LIMIT_CONFIG',
    modifyCreditLimt: 'MODIFY_CREDIT_LIMIT_CONFIG',
    searchActivity: 'SEARCH_ACTIVITY',
    getActivity: 'GET_ACTIVITY',
    createDelayedBillingConfig: 'CREATE_DELAYED_BILLING_CONFIG',
    modifyDelayedBillingConfig: 'MODIFY_DELAYED_BILLING_CONFIG',
    getDelayedBillingConfig: 'GET_DELAYED_BILLING_CONFIG',
    sendInvoice: 'SEND_INVOICE',
    reInvoice: 'RE_INVOICE',
    regenerateInvoice: 'REGENERATE_INVOICE',
    viewInvoceTemplate: 'VIEW_INVOICE_TEMPLATE',
    createCustomAttributeConfig: 'CREATE_CUSTOM_ATTRIBUTE_CONFIG',
    modifyCustomAttributeConfig: 'MODIFY_CUSTOM_ATTRIBUTE_CONFIG',
    approveInvoice: 'APPROVE_INVOICE',
    createExternalPO: 'CREATE_EXTERNAL_PO',
    modifyExternalPO: 'MODIFY_EXTERNAL_PO',
    getExternalPO: 'GET_EXTERNAL_PO',
    modifyCustomAttributes: 'MODIFY_CUSTOM_ATTRIBUTES',
    createCustomAttributes: 'CREATE_CUSTOM_ATTRIBUTES',
    getCustomAttributes: 'GET_CUSTOM_ATTRIBUTES',
    resyncInvoice: 'RESYNC_INVOICE',
    undoManualInvoice: 'UNDO_MANUAL_INVOICE',
    getSpecialPriceRate: 'GET_SPECIAL_PRICE_RATE',
    modifySpecialPriceRate: 'MODIFY_SPECIAL_PRICE_RATE',
    createSpecialPriceRate: 'CREATE_SPECIAL_PRICE_RATE',
    getAccountExchangeRates: 'GET_ACCOUNT_EXCHANGE_RATES',
    modifyAccountExchangeRates: 'MODIFY_ACCOUNT_EXCHANGE_RATES',
    createAccountExchangeRates: 'CREATE_ACCOUNT_EXCHANGE_RATES',
    generateInvoice: 'GENERATE_INVOICE',
    createPaymentInstallment: 'CREATE_PAYMENT_INSTALLMENT',
    modifyPaymentInstallment: 'MODIFY_PAYMENT_INSTALLMENT',
    getPaymentInstallment: 'GET_PAYMENT_INSTALLMENT',
    searchLandingBill: 'SEARCH_LADING_BILL',
    VIEW_TRANSACTION_DETAILS: 'VIEW_TRANSACTION_DETAILS',
    EXTEND_INVOICE_DUE_DATE: 'EXTEND_INVOICE_DUE_DATE',
    GET_ACCOUNT_STATEMENT: 'GET_ACCOUNT_STATEMENT',
    RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
    UNDO_INVOICE_CANCEL: 'UNDO_INVOICE_CANCEL',
  },

  collapsibleMenuTransactionId: [
    {
      title: 'common:label.recurringData',
      state: 'Recurring Data',
    },
    {
      title: 'common:label.usageData',
      state: 'Usage Data',
    },
    {
      title: 'common:label.taxData',
      state: 'Tax Data',
    },
    {
      title: 'common:label.currency',
      state: 'Currency',
    },
    {
      title: 'common:label.grants',
      state: 'Grants',
    },
    {
      title: 'common:label.accumulator',
      state: 'Accumulator',
    },
  ],

  collapsibleMenuArOpsUnitById: [
    {
      title: 'common:label.AROpsData',
      state: 'AR Ops Data',
    },
    {
      title: 'common:label.taxData',
      state: 'Tax Data',
    },
    {
      title: 'common:label.currency',
      state: 'Currency',
    },
  ],

  dataFieldTransactionById: [
    {
      name: 'id',
      label: 'label.transactionId',
    },
    {
      name: 'type',
      label: 'label.transactionType',
      isSelect: true,
      tOptions: 'selections:transactionType',
    },
    {
      name: 'description',
      label: 'label.description',
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      isSelect: true,
      tOptions: 'selections:serviceType',
    },
    {
      name: 'accountId',
      label: 'label.accountId',
    },
    {
      name: 'billUnitId',
      label: 'label.billUnitId',
    },
    {
      name: 'startDate',
      label: 'label.startDate',
    },
    {
      name: 'endDate',
      label: 'label.endDate',
    },
    {
      name: 'source',
      label: 'label.source',
    },
    {
      name: 'serviceUnitId',
      label: 'label.serviceUnitId',
    },
    {
      name: 'netAmount',
      label: 'label.netAmount',
    },
    {
      name: 'accountingCode',
      label: 'label.accountingCode',
    },
  ],

  transactionSort: {
    billUnitId: {
      asc: 'billUnitId_ASC',
      desc: 'billUnitId_DESC',
    },
    createdDate: {
      asc: 'createdDate_ASC',
      desc: 'createdDate_DESC',
    },
    startDate: {
      asc: 'startDate_ASC',
      desc: 'startDate_DESC',
    },
    endDate: {
      asc: 'endDate_ASC',
      desc: 'endDate_DESC',
    },
    type: {
      asc: 'type_ASC',
      desc: 'type_DESC',
    },
    serviceType: {
      asc: 'serviceType_ASC',
      desc: 'serviceType_DESC',
    },
    source: {
      asc: 'source_ASC',
      desc: 'source_DESC',
    },
    itemId: {
      asc: 'itemId_ASC',
      desc: 'itemId_DESC',
    },
  },

  ratedUsageSort: {
    createdDate: {
      asc: 'createdDate_ASC',
      desc: 'createdDate_DESC',
    },
    startDate: {
      asc: 'startDate_ASC',
      desc: 'startDate_DESC',
    },
    endDate: {
      asc: 'endDate_ASC',
      desc: 'endDate_DESC',
    },
    type: {
      asc: 'type_ASC',
      desc: 'type_DESC',
    },
    serviceType: {
      asc: 'serviceType_ASC',
      desc: 'serviceType_DESC',
    },
    source: {
      asc: 'source_ASC',
      desc: 'source_DESC',
    },
    itemId: {
      asc: 'itemId_ASC',
      desc: 'itemId_DESC',
    },
  },

  dataFieldRecurringByTransactionId: [
    {
      name: 'type',
      label: 'label.recurringType',
      type: 'select',
      tOptions: 'selections:recurringType',
    },
    {
      name: 'cycleUnit',
      label: 'label.recurringUnit',
      type: 'select',
      tOptions: 'selections:recurringUnit',
    },
    {
      name: 'cycleDuration',
      label: 'label.recurringDuration',
    },
    {
      name: 'proration',
      label: 'label.proration',
      type: 'select',
      tOptions: 'selections:prorationType',
    },
    {
      name: 'scale',
      label: 'label.scale',
    },
    {
      name: 'cycleStart',
      label: 'label.cycleStart',
      type: 'date',
    },
    {
      name: 'cycleEnd',
      label: 'label.cycleEnd',
      type: 'date',
    },
    {
      name: 'appliedStart',
      label: 'label.appliedStart',
      type: 'date',
    },
    {
      name: 'appliedEnd',
      label: 'label.appliedEnd',
      type: 'date',
    },
  ],

  dataFieldUsageTransactionId: [
    {
      name: 'transactionId',
      label: 'label.transactionId',
    },
    {
      name: 'fileId',
      label: 'label.fileId',
    },
    {
      name: 'batchid',
      label: 'label.batchid',
    },
    {
      name: 'provisioningId',
      label: 'common:label.provisioningId',
    },
    {
      name: 'usageid',
      label: 'common:label.usageId',
    },
    {
      name: 'usageType',
      label: 'common:label.usageType',
    },
    {
      name: 'usageClass',
      label: 'common:label.usageClass',
    },
    {
      name: 'routingNumber',
      label: 'common:label.routingNumber',
    },
    {
      name: 'source',
      label: 'common:label.source',
    },
    {
      name: 'destination',
      label: 'common:label.destination',
    },
    {
      name: 'direction',
      label: 'common:label.direction',
    },
    {
      name: 'rateUnit',
      label: 'label.rateUnit',
      type: 'showLabelOption',
      tOptions: 'selections:rateUnit',
    },
    {
      name: 'scaleUnit',
      label: 'label.scaleUnit',
    },
    {
      name: 'quantity',
      label: 'label.quantity',
      isNumberFormat: true,
    },
    {
      name: 'scaledQuantity',
      label: 'label.scaledQuantity',
    },
    {
      name: 'ratingDescr',
      label: 'label.ratingDescr',
    },
    {
      name: 'timeUnit',
      label: 'label.timeUnit',
      type: 'showLabelOption',
      tOptions: 'selections:timeUnitPricing',
    },
    {
      name: 'zoneUnit',
      label: 'label.zoneUnit',
    },
    {
      name: 'impactUnit',
      label: 'label.impactUnit',
    },
    {
      name: 'holidayName',
      label: 'label.holidayName',
    },
    {
      name: 'code',
      label: 'label.code',
    },
    {
      name: 'salesChannel',
      label: 'label.salesChannel',
      type: 'showLabelOption',
      tOptions: 'selections:salesChannel',
    },
    {
      name: 'marketSegment',
      label: 'label.marketSegment',
      type: 'showLabelOption',
      tOptions: 'selections:marketSegment',
    },
    {
      name: 'type',
      label: 'label.accountType',
      type: 'showLabelOption',
      tOptions: 'selections:accountType',
    },
    {
      name: 'subType',
      label: 'label.accountSubType',
      type: 'showLabelOption',
      tOptions: 'selections:accountSubType',
    },
  ],

  tableCurrencyTransactionById: [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'balanceType',
      label: 'label.impactType',
      type: 'showLabelOption',
      tOptions: 'selections:transactionBalanceType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:transactionBalanceType')().find(val => val.value === item.balanceType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'offerType',
      label: 'label.offerType',
    },
    {
      name: 'currencyId',
      label: 'label.currency',
    },
    {
      name: 'quantity',
      label: 'label.quantity',
    },
    {
      name: 'unitPrice',
      label: 'label.unitPriceOrDiscount',
      render: (colName, item) => {
        return <span>{item.unitPrice || item.unitDiscount || ''}</span>;
      },
    },
    {
      name: 'unitpercentage',
      label: 'label.discountPercent',
    },
    {
      name: 'amount',
      label: 'label.amount',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.amount)}</span>;
      },
    },
    {
      name: 'sourceCurrencyId',
      label: 'label.sourceCurrency',
    },
    {
      name: 'sourceCurrencyAmount',
      label: 'label.sourceAmount',
    },
    {
      name: 'exchangeRate',
      label: 'label.exchangeRate',
    },
  ],

  tableTaxDataByTransactionId: [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'city',
      label: 'label.city',
    },
    {
      name: 'state',
      label: 'label.state',
    },
    {
      name: 'country',
      label: 'label.country',
    },
    {
      name: 'postalCode',
      label: 'label.postalCode',
    },
    {
      name: 'code',
      label: 'label.code',
    },
    {
      name: 'taxCode',
      label: 'label.taxCode',
    },
    {
      name: 'taxType',
      label: 'label.taxType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:taxType')().find(val => val.value === item.taxType) : '';
        return <span>{slt ? slt.label : item.taxType || ''}</span>;
      },
    },
    {
      name: 'jurisdiction',
      label: 'label.jurisdiction',
    },
    {
      name: 'taxableAmount',
      label: 'label.taxableAmount',
    },
    {
      name: 'exemptAmount',
      label: 'label.exemptAmount',
    },
    {
      name: 'percent',
      label: 'label.percent',
    },
    {
      name: 'taxAmount',
      label: 'label.taxAmount',
    },
  ],

  tableGrantsByTransactionId: [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'grantId',
      label: 'label.grantId',
    },
    {
      name: 'grantType',
      label: 'label.grantType',
    },
    {
      name: 'validStart',
      label: 'label.grantStartDate',
    },
    {
      name: 'validEnd',
      label: 'label.grantEndDate',
    },
    {
      name: 'amount',
      label: 'label.amount',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.amount)}</span>;
      },
    },
  ],

  tableAccumulatorByTransactionId: [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'accumulatorId',
      label: 'label.accumulatorId',
    },
    {
      name: 'balanceUnitIndex',
      label: 'label.balanceUnitIndex',
    },
    {
      name: 'validStart',
      label: 'label.accumulatorStartDate',
    },
    {
      name: 'validEnd',
      label: 'label.accumulatorEndDate',
    },
    {
      name: 'amount',
      label: 'label.amount',
      render: (colName, item) => {
        return <span>{formatMoneyValue(item.amount)}</span>;
      },
    },
  ],

  dataFieldArOpsUnitById: [
    {
      name: 'id',
      label: 'label.AROpsId',
    },
    {
      name: 'type',
      label: 'label.AROpsType',
      // type: 'select',
      // tOptions: 'selections:arType',
    },
    {
      name: 'description',
      label: 'label.description',
    },
    {
      name: 'serviceType',
      label: 'label.serviceType',
      type: 'select',
      tOptions: 'selections:serviceType',
    },
    {
      name: 'accountId',
      label: 'label.accountId',
    },
    {
      name: 'endDate',
      label: 'label.endDate',
    },
    {
      name: 'source',
      label: 'label.source',
      type: 'select',
      tOptions: 'selections:source',
    },
    {
      name: 'netAmount',
      label: 'label.netAmount',
    },
  ],

  dataFieldArOpsDataId: [
    {
      name: 'reason',
      label: 'label.reason',
    },
    {
      name: 'taxCode',
      label: 'label.taxCode',
    },
    {
      name: 'arCrDrType',
      label: 'label.arCrDrType',
      type: 'select',
      tOptions: 'selections:arCrDrType',
    },
    {
      name: 'taxRule',
      label: 'label.taxRule',
      type: 'select',
      tOptions: 'selections:taxRule',
    },
    {
      name: 'itemId',
      label: 'label.itemId',
    },
    {
      name: 'numberOfTransactions',
      label: 'label.numberOfTransactions',
    },
    {
      name: 'amount',
      label: 'label.amount',
    },
    {
      name: 'percent',
      label: 'label.percent',
      type: 'number',
    },
    // {
    //   name: 'appliedStart',
    //   label: 'label.appliedStart',
    //   type: 'date',
    // },
    // {
    //   name: 'appliedEnd',
    //   label: 'label.appliedEnd',
    //   type: 'date',
    // },
  ],

  tableOpsTaxData: [
    {
      name: 'index',
      label: 'label.index',
    },
    {
      name: 'city',
      label: 'label.city',
    },
    {
      name: 'state',
      label: 'label.state',
    },
    {
      name: 'country',
      label: 'label.country',
    },
    {
      name: 'postalCode',
      label: 'label.postalCode',
    },
    {
      name: 'code',
      label: 'label.code',
    },
    {
      name: 'taxCode',
      label: 'label.taxCode',
    },
    {
      name: 'taxType',
      label: 'label.taxType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:taxType')().find(val => val.value === item.taxType) : '';
        return <span>{slt ? slt.label : item.taxType || ''}</span>;
      },
    },
    {
      name: 'jurisdiction',
      label: 'label.jurisdiction',
    },
    {
      name: 'taxableAmount',
      label: 'label.taxableAmount',
    },
    {
      name: 'exemptAmount',
      label: 'label.exemptAmount',
    },
    {
      name: 'percent',
      label: 'label.percent',
    },
    {
      name: 'taxAmount',
      label: 'label.taxAmount',
    },
  ],
  tableCurrencyArOpsDataId: [
    // {
    //   name: 'index',
    //   label: 'label.index',
    // },
    {
      name: 'type',
      label: 'label.type',
    },
    // {
    //   name: 'type',
    //   label: 'label.type',
    // },
    {
      name: 'currencyId',
      label: 'label.currency',
    },
    {
      name: 'unitPrice',
      label: 'label.unitPrice',
    },
    {
      name: 'amount',
      label: 'label.amount',
    },
  ],

  // hanlde ActivitiesPage
  activitySort: {
    activityDate: {
      asc: 'activityDate_ASC',
      desc: 'activityDate_DESC',
    },
    entityId: {
      asc: 'entityId_ASC',
      desc: 'entityId_DESC',
    },
    userId: {
      asc: 'userId_ASC',
      desc: 'userId_DESC',
    },
    accountId: {
      asc: 'accountId_ASC',
      desc: 'accountId_DESC',
    },
  },

  activityColumns: [
    {
      name: 'activityDate',
      label: 'common:label.activityDate',
      style: { minWidth: '100px' },
      sortable: true,
    },
    {
      name: 'userId',
      label: 'common:label.userId',
      sortable: true,
    },
    {
      name: 'entityId',
      label: 'common:label.objectId',
      sortable: true,
    },
    {
      name: 'entityType',
      label: 'common:label.entityType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:entityType')().find(val => val.value === item.entityType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'source',
      label: 'common:label.source',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:source')().find(val => val.value === item.source) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'action',
      label: 'common:label.action',
    },
    {
      name: 'gatewayType',
      label: 'common:label.gatewayType',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:gatewayType')().find(val => val.value === item.gatewayType) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'merchantName',
      label: 'common:label.merchantName',
    },
    {
      name: 'apiName',
      label: 'common:label.apiName',
    },
  ],

  dataFieldActivity: [
    {
      name: 'id',
      label: 'label.id',
    },

    {
      name: 'accountId',
      label: 'label.accountId',
    },
    {
      name: 'userId',
      label: 'label.userId',
    },
    {
      name: 'entityType',
      label: 'label.entityType',
      isSelect: true,
      tOptions: 'selections:entityType',
    },
    {
      name: 'entityId',
      label: 'label.entityId',
    },
    {
      name: 'action',
      label: 'label.action',
    },
    {
      name: 'source',
      label: 'label.source',
      isSelect: true,
      tOptions: 'selections:source',
    },
    {
      name: 'activityDate',
      label: 'label.activityDate',
    },
    {
      name: 'request',
      label: 'label.request',
      type: 'textarea',
      wrapperClass: 'col-md-6',
      style: { minHeight: 200 },
    },
    {
      name: 'response',
      label: 'label.response',
      type: 'textarea',
      wrapperClass: 'col-md-6',
      style: { minHeight: 200 },
    },
  ],

  collapsibleMenuAccountActivities: [
    {
      title: 'customerPage:sidebarHandleAccountPage.accountInfo',
      state: 'Account Info',
      key: 'activityAccountInfo',
      entityType: 'ACCOUNT_INFO',
      dataFields: [
        {
          label: 'label.customerSegment',
          name: 'customerSegment',
          tOptions: 'selections:customerSegment',
          type: 'select',
        },
        {
          label: 'label.accountType',
          name: 'type',
          tOptions: 'selections:accountType',
          type: 'select',
        },
        {
          label: 'label.accountSubType',
          name: 'subType',
          tOptions: 'selections:accountSubType',
          type: 'select',
        },
        // {
        //   label: 'label.creditProfileIndex',
        //   name: 'creditProfileIndex',
        // },
        {
          label: 'label.salesChannel',
          name: 'salesChannel',
          tOptions: 'selections:salesChannel',
          type: 'select',
        },
        {
          label: 'label.marketSegment',
          name: 'marketSegment',
          tOptions: 'selections:marketSegment',
          type: 'select',
        },
        {
          label: 'label.sellingCompany',
          name: 'sellingCompany',
        },
        {
          label: 'label.lineOfBusiness',
          name: 'lineOfBusiness',
        },
        {
          label: 'label.legalEntity',
          name: 'legalEntity',
        },
        {
          label: 'label.currency',
          name: 'currency',
          optionSelect: utils.convertCurrency2Option(listCurrency.currencies),
        },
        {
          label: 'label.status',
          name: 'status',
          tOptions: 'selections:status',
          type: 'select',
        },
        {
          label: 'label.reason',
          name: 'reason',
          tOptions: 'selections:reason',
          type: 'select',
        },
        {
          label: 'label.effectiveDate',
          name: 'effectiveDate',
        },
        {
          label: 'label.userId',
          name: 'userId',
        },
        {
          label: 'label.clientId',
          name: 'clientId',
        },
        {
          label: 'label.salesAgentId',
          name: 'salesAgentId',
        },
        {
          label: 'label.organization',
          name: 'organization',
        },
        {
          label: 'label.region',
          name: 'region',
        },
        {
          label: 'label.collectionAgentId',
          name: 'collectionAgent',
        },
        {
          label: 'label.employeeId',
          name: 'employeeId',
        },
        {
          label: 'label.commercialName',
          name: 'commercialName',
        },
        {
          label: 'label.taxId',
          name: 'taxId',
        },
        {
          label: 'label.foreignTaxId',
          name: 'foreignTaxId',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.accountInfo',
      state: 'Account Info',
      key: 'activityAccountInfo',
      entityType: 'CUSTOMER',
      dataFields: [
        {
          label: 'label.customerSegment',
          name: 'customerSegment',
          tOptions: 'selections:customerSegment',
          type: 'select',
        },
        {
          label: 'label.accountType',
          name: 'type',
          tOptions: 'selections:accountType',
          type: 'select',
        },
        {
          label: 'label.accountSubType',
          name: 'subType',
          tOptions: 'selections:accountSubType',
          type: 'select',
        },
        // {
        //   label: 'label.creditProfileIndex',
        //   name: 'creditProfileIndex',
        // },
        {
          label: 'label.salesChannel',
          name: 'salesChannel',
          tOptions: 'selections:salesChannel',
          type: 'select',
        },
        {
          label: 'label.marketSegment',
          name: 'marketSegment',
          tOptions: 'selections:marketSegment',
          type: 'select',
        },
        {
          label: 'label.sellingCompany',
          name: 'sellingCompany',
        },
        {
          label: 'label.lineOfBusiness',
          name: 'lineOfBusiness',
        },
        {
          label: 'label.legalEntity',
          name: 'legalEntity',
        },
        {
          label: 'label.currency',
          name: 'currency',
          optionSelect: utils.convertCurrency2Option(listCurrency.currencies),
        },
        {
          label: 'label.status',
          name: 'status',
          tOptions: 'selections:status',
          type: 'select',
        },
        {
          label: 'label.reason',
          name: 'reason',
          tOptions: 'selections:reason',
          type: 'select',
        },
        {
          label: 'label.effectiveDate',
          name: 'effectiveDate',
        },
        {
          label: 'label.userId',
          name: 'userId',
        },
        {
          label: 'label.clientId',
          name: 'clientId',
        },
        {
          label: 'label.salesAgentId',
          name: 'salesAgentId',
        },
        {
          label: 'label.organization',
          name: 'organization',
        },
        {
          label: 'label.region',
          name: 'region',
        },
        {
          label: 'label.collectionAgentId',
          name: 'collectionAgent',
        },
        {
          label: 'label.employeeId',
          name: 'employeeId',
        },
        {
          label: 'label.commercialName',
          name: 'commercialName',
        },
        {
          label: 'label.taxId',
          name: 'taxId',
        },
        {
          label: 'label.foreignTaxId',
          name: 'foreignTaxId',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.contact',
      state: 'Account Contact',
      key: 'activityContacts',
      entityType: 'CONTACT',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
          isDisable: true,
        },
        {
          label: 'common:label.salutation',
          name: 'salutation',
        },
        {
          label: 'common:label.firstName',
          name: 'firstName',
        },
        {
          label: 'common:label.middleName',
          name: 'middleName',
        },
        {
          label: 'common:label.lastName',
          name: 'lastName',
        },
        {
          label: 'common:label.position',
          name: 'position',
        },
        {
          label: 'common:label.organization',
          name: 'organization',
        },
        {
          label: 'common:label.email',
          name: 'email',
        },
        {
          label: 'common:label.identity',
          name: 'identity',
          tooltipDetails: 'common:message.identityNote',
        },
        {
          label: 'common:label.identityDocument',
          name: 'identityDocument',
          tooltipDetails: 'common:message.identityDocumentNote',
        },
        {
          label: 'common:label.phone',
          name: 'phones',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.addresses',
      state: 'Account Addresses',
      key: 'activityAddresses',
      entityType: 'ADDRESS',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
          required: true,
        },
        {
          name: 'unit',
          label: 'common:label.unit',
        },
        {
          name: 'street',
          label: 'common:label.street',
          type: 'textarea',
        },
        {
          label: 'common:label.country',
          name: 'country',
          optionSelect: countryList,
        },
        {
          name: 'state',
          label: 'common:label.state',
        },
        {
          name: 'district',
          label: 'common:label.district',
        },
        {
          label: 'common:label.city',
          name: 'city',
        },
        {
          name: 'postalCode',
          label: 'common:label.postalCode',
        },
        {
          name: 'code',
          label: 'common:label.code',
          isDisable: true,
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.paymentProfile',
      state: 'Payment Profile',
      key: 'activityPaymentProfiles',
      entityType: 'PAYMENT_PROFILE',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
        },
        {
          label: 'common:label.paymentTerm',
          name: 'paymentTerm',
          tOptions: 'selections:paymentTerm',
          type: 'select',
        },
        {
          label: 'common:label.paymentMethod',
          name: 'paymentMethod',
          tOptions: 'selections:paymentMethod',
          type: 'select',
        },
        {
          label: 'common:label.cardToken',
          name: 'cardToken',
        },
        {
          label: 'common:label.cardExp',
          name: 'cardExpiry',
        },
        {
          label: 'common:label.creditCardCVV',
          name: 'last4CC',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.billingProfile',
      state: 'Billing Profile',
      key: 'activityBillingProfiles',
      entityType: 'BILLING_PROFILE',
      dataFields: [
        {
          name: 'id',
          label: 'common:label.id',
        },
        {
          name: 'parentId',
          label: 'common:label.parentId',
          isDisable: true,
        },
        {
          name: 'billingDom',
          label: 'common:label.billingDom',
          type: 'number',
        },
        {
          name: 'billingSegment',
          label: 'common:label.billingSegment',
        },
        {
          name: 'billingFrequency',
          label: 'common:label.billingFrequency',
          tOptions: 'selections:billingFrequency',
          type: 'select',
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
          tOptions: 'selections:invoiceType',
          type: 'select',
        },
        {
          name: 'invoiceDelivery',
          label: 'common:label.invoiceDelivery',
          tOptions: 'selections:invoiceDelivery',
          type: 'select',
        },
        {
          name: 'paymentProfileId',
          label: 'common:label.paymentProfileId',
        },
        {
          name: 'lastAccountingDate',
          label: 'label.lastAccountingDate',
        },
        {
          name: 'nextAccountingDate',
          label: 'label.nextAccountingDate',
        },
        {
          name: 'futureAccountingDate',
          label: 'label.futureAccountingDate',
        },
        {
          name: 'lastBillDate',
          label: 'label.lastBillDate',
        },
        {
          name: 'lastBillUnitId',
          label: 'label.lastBillId',
        },
        {
          name: 'nextBillDate',
          label: 'label.nextBillDate',
        },
        {
          name: 'nextBillUnitId',
          label: 'label.nextBillId',
        },
        {
          name: 'futureCycleOpen',
          label: 'label.futureCycleOpen',
        },
        {
          name: 'futureBillDate',
          label: 'label.futureBillDate',
        },
        {
          name: 'futureBillUnitId',
          label: 'label.futureBillId',
        },
        {
          name: 'status',
          label: 'label.status',
          tOptions: 'selections:status',
          type: 'select',
        },
        {
          name: 'reason',
          label: 'label.reason',
          tOptions: 'selections:reason',
          type: 'select',
        },
      ],
    },
    {
      title: 'customerPage:sidebarHandleAccountPage.customAttributes',
      state: 'Custom Attributes',
      key: 'activityCustomAttributes',
      entityType: 'CUSTOM_ATTRIBUTES',
      dataFields: [],
    },
  ],

  collapsibleMenuBeforeAfter: [
    {
      title: 'Before',
      // state: 'Before',
      state: 'Childrent',
      key: 'beforeData',
    },
    {
      title: 'After',
      // state: 'Aftter',
      state: 'Childrent',
      key: 'afterData',
    },
  ],

  creditNotesTable: [
    {
      label: 'label.invoiceId',
      name: 'invoiceId',
    },
    {
      name: 'notesDate',
      label: 'common:label.date',
      sortable: true,
    },
    {
      name: 'type',
      label: 'common:label.noteType',
      isRenderT: true,
      sortable: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:arNotesType')().find(val => val.value === item.type) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'total',
      label: 'common:label.total',
    },
    {
      name: 'status',
      label: 'common:label.status',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:noteStatus')().find(val => val.value === item.status) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'folioStatus',
      label: 'common:label.folioStatus',
      isRenderT: true,
      render: (colName, item, t) => {
        const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
        return <span>{slt ? slt.label : ''}</span>;
      },
    },
    {
      name: 'currency',
      label: 'common:label.currency',
    },
    {
      name: 'reasonDescription',
      label: 'common:label.reason',
    },
    {
      name: 'invoiceCancellation',
      label: 'common:label.invoiceCancellation',
      render: (colName, item) => {
        return <span>{item?.invoiceCancellation ? 'TRUE' : item?.invoiceCancellation === false ? 'FALSE' : ''}</span>;
      },
    },
    {
      name: 'ticketNumber',
      label: 'common:label.ticketNumber',
    },
  ],
};

export const arOpsFields = [
  {
    label: 'common:label.type',
    name: 'type',
    tOptions: 'selections:arType',
    disabled: true,
  },
  {
    label: 'common:label.source',
    name: 'source',
  },
  {
    label: 'common:label.CRDRType',
    name: 'arType',
  },
  {
    label: 'common:label.taxRule',
    name: 'taxRule',
    tOptions: 'selections:taxRule',
    disabled: true,
    isClearable: true,
  },
  {
    label: 'common:label.accountId',
    name: 'accountId',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.invoiceId',
    name: 'invoiceId',
  },
  {
    label: 'common:label.itemId',
    name: 'itemId',
  },
  {
    label: 'common:label.customReasonCode',
    name: 'customReason',
  },
  {
    label: 'common:label.reason',
    name: 'reason',
  },
  {
    label: 'common:label.amount',
    name: 'amount',
    type: 'money',
  },
  {
    label: 'common:label.percent',
    name: 'percent',
  },
  {
    label: 'common:label.numberOfTransactions',
    name: 'numberOfTransactions',
  },
  {
    label: 'common:label.date',
    name: 'startDate',
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

export const additionalAddressInformation = [
  { name: 'floor', label: 'label.floor' },
  { name: 'municipality', label: 'label.municipality' },
  { name: 'locality', label: 'label.locality' },
  { name: 'neighbourhood', label: 'label.neighbourhood' },
  { name: 'address1', label: 'label.address1' },
  { name: 'address2', label: 'label.address2' },
  { name: 'address3', label: 'label.address3' },
  { name: 'latitude', label: 'label.latitude' },
  { name: 'longitude', label: 'label.longitude' },
];

export const collapsibleAddress = [
  {
    title: 'label.additionalAddressInformation',
    state: 'additionalAddressInformation',
  },
];
