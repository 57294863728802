import React, { Component } from 'react';
import moment from 'moment';
import { SortableHandle } from 'react-sortable-hoc';
import Switch from 'react-switch';
import { Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation, useTranslation } from 'react-i18next';
import { ModalJobStats, ModalJobErrors, ModalJobErrorDetails } from '../../../components/Modals';
import { getJobErrors, getJobErrorDetails } from '../actions';
import { makeJobScheduleTime, makeJobErrorGroupList } from '../selectors';

const CardHandle = SortableHandle(({ job }) => {
  const { t } = useTranslation('common');
  const slt = t ? t('selections:jobTypes')().find(val => val.value === job.type) : '';
  return <h6 className="job-card-handle">{slt ? slt.label : ''}</h6>;
});

const canRetry = job => job.status === 'ERROR';
const canProcess = job => job.status === 'PENDING';

class JobCard extends Component {
  state = {
    openModalJobErrors: false,
    openMobalJobStats: false,
    openModalJobErrorDetails: false,
    errorJobs: [],
    dropdownOpen: false,
  };

  componentDidMount() {}

  toggleModalJobErrorDetails = reason => {
    const { openModalJobErrorDetails } = this.state;
    if (openModalJobErrorDetails) {
      this.setState({
        openModalJobErrors: true,
        openModalJobErrorDetails: false,
      });
    } else {
      this.setState({
        openModalJobErrors: false,
      });
      const { job, scheduleDate } = this.props;
      const { type } = job;
      this.props.getJobErrorDetails(
        {
          reason,
          type,
          scheduleDate,
        },
        jobs => {
          if (jobs && jobs.length > 0) {
            this.setState({
              errorJobs: jobs,
              openModalJobErrorDetails: true,
            });
          }
        }
      );
    }
  };

  toggleModalJobErrors = () => {
    const { openModalJobErrors } = this.state;
    const { job, scheduleDate, jobErrorGroupList } = this.props;
    const { type } = job;
    if (openModalJobErrors) {
      this.setState({
        openModalJobErrors: false,
        openMobalJobStats: true,
      });
    } else {
      this.setState({
        openMobalJobStats: false,
      });
      if (!jobErrorGroupList || !jobErrorGroupList.length) {
        this.props.getJobErrors(
          {
            type,
            scheduleDate,
          },
          () => {
            this.setState({
              openModalJobErrors: true,
            });
          }
        );
      } else {
        this.setState({
          openModalJobErrors: true,
        });
      }
    }
  };

  toggleModal = () => {
    const { openMobalJobStats } = this.state;
    this.setState({
      openMobalJobStats: !openMobalJobStats,
    });
  };

  onProcess = e => {
    e.preventDefault();
    const { job, onProcessJob } = this.props;
    onProcessJob(job);
  };

  onRetry = e => {
    // e.preventDefault();
    const { job, onRetryJob } = this.props;
    onRetryJob(job);
  };

  onChangeType = (val, e) => {
    const { job, onModifyJob } = this.props;
    onModifyJob({ index: job.index, scheduleType: job.scheduleType === 'AUTOMATIC' ? 'MANUAL' : 'AUTOMATIC' });
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const {
      job,
      jobErrorGroupList,
      lockedProcess,
      modeModifyJob,
      modeProcessJob,
      modeReprocessJob,
      modeGetJobStats,
      modeModifyJobType,
      t,
    } = this.props;
    const { openMobalJobStats, openModalJobErrors, openModalJobErrorDetails, errorJobs } = this.state;
    const { count, countSuccess, countError } = job;
    const jobErrorList = jobErrorGroupList[job.jobId] || [];

    return (
      <li className="job-card">
        <Card className="card card-statistics h-100">
          <CardBody className="card-body text-center">
            <CardHandle job={job} />
            {modeModifyJob !== 0 && (
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="info-drop">
                <DropdownToggle className="dropdown-toggle-split text-muted" color="none">
                  <i className="ti-more" />
                </DropdownToggle>
                <DropdownMenu>
                  {modeProcessJob !== 0 && (
                    <DropdownItem
                      disabled={!canProcess(job) || lockedProcess || modeModifyJob === 1 || modeProcessJob === 1}
                      onClick={this.onProcess}
                    >
                      <i className="text-primary ti-settings" />
                      {t('label.processAction')}
                    </DropdownItem>
                  )}
                  {modeReprocessJob !== 0 && (
                    <DropdownItem
                      disabled={!canRetry(job) || modeModifyJob === 1 || modeReprocessJob === 1}
                      onClick={this.onRetry}
                    >
                      <i className="text-dark ti-reload" />
                      {t('label.retry')}
                    </DropdownItem>
                  )}
                  {modeGetJobStats !== 0 && (
                    <DropdownItem
                      disabled={modeModifyJob === 1 || modeGetJobStats === 1}
                      onClick={() => this.toggleModal(job)}
                    >
                      <i className="text-success ti-bar-chart-alt" />
                      {t('label.stats')}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </ButtonDropdown>
            )}

            <div className="divider" />
            <div className="row">
              <div className="col-sm-4 mt-15">
                <b>
                  {t('label.status')}
                  :
                </b>
                <br />
                <p
                  className={`job-status job-status-${job.status ? job.status.toLocaleLowerCase() : ''}`}
                  title={t(`label.${job.status.toLocaleLowerCase()}`)}
                >
                  {t(`label.${job.status.toLocaleLowerCase()}`)}
                </p>
              </div>
              <div className="col-sm-4 mt-15">
                <b>{t('label.scheduleTime')}</b>
                <p className="text-danger">{job.scheduleTime ? moment(job.scheduleTime).format('h:mm:ss a') : ''}</p>
              </div>
              {modeModifyJob !== 0 && modeModifyJobType !== 0 && (
                <div className="col-sm-4 mt-15">
                  <b>{t('label.type')}</b>
                  <div className="checkbox checbox-switch switch-success">
                    <label className="small">
                      {t(`label.${job.scheduleType.toLocaleLowerCase()}`)}
                      <Switch
                        onChange={this.onChangeType}
                        checked={job.scheduleType === 'AUTOMATIC'}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={20}
                        width={45}
                        className="schedule-job-toggle-switcher"
                        disabled={!canProcess(job) || lockedProcess || modeModifyJob === 1 || modeModifyJobType === 1}
                      />
                    </label>
                  </div>
                </div>
              )}
              <br />
            </div>
          </CardBody>

          {openMobalJobStats && (
            <ModalJobStats
              openModal={openMobalJobStats}
              toggleModal={this.toggleModal}
              toggleModalDetail={this.toggleModalJobErrors}
              stats={{ count, countSuccess, countError }}
            />
          )}
          {openModalJobErrors && (
            <ModalJobErrors
              openModal={openModalJobErrors}
              toggleModal={this.toggleModalJobErrors}
              toggleModalDetail={this.toggleModalJobErrorDetails}
              jobErrorGroupList={jobErrorList}
              onRetry={this.onRetry}
            />
          )}
          {openModalJobErrorDetails && (
            <ModalJobErrorDetails
              openModal={openModalJobErrorDetails}
              toggleModal={this.toggleModalJobErrorDetails}
              errorJobs={errorJobs}
            />
          )}
        </Card>
        <div className="card-arrows" />
      </li>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  scheduleDate: makeJobScheduleTime() || null,
  jobErrorGroupList: makeJobErrorGroupList() || null,
});

JobCard.propTypes = {
  job: PropTypes.object,
  scheduleDate: PropTypes.string,
  jobErrorGroupList: PropTypes.object,
  getJobErrors: PropTypes.func,
  getJobErrorDetails: PropTypes.func,
  sortingItemKey: PropTypes.object,
  onProcess: PropTypes.func,
  onRetryJob: PropTypes.func,
  onModifyJob: PropTypes.func,
  modeModifyJob: PropTypes.number,
  modeProcessJob: PropTypes.number,
  modeReprocessJob: PropTypes.number,
  modeGetJobStats: PropTypes.number,
  modeModifyJobType: PropTypes.number,
};

JobCard.defaultProps = {
  modeModifyJob: 2,
  modeProcessJob: 2,
  modeReprocessJob: 2,
  modeGetJobStats: 2,
  modeModifyJobType: 2,
};

export default withTranslation('common')(
  connect(mapStateToProps, {
    getJobErrors,
    getJobErrorDetails,
  })(JobCard)
);
