import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import JobCard from './JobCard';

const SortableItem = SortableElement(props => <JobCard {...props} />);

const SortableList = SortableContainer(({ items, isSorting, ...props }) => (
  <ul id="jobList" className={`job-list-cards ${isSorting ? 'job-card-sorting' : ''}`}>
    {items.map((job, index) => (
      <SortableItem
        // eslint-disable-next-line react/no-array-index-key
        key={`item-${index}`}
        isSorting={isSorting}
        index={index}
        job={job}
        {...props}
      />
    ))}
  </ul>
));

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: props.jobs,
    };
  }

  componentDidMount() {
    this.checkRightChildren();
    window.addEventListener('resize', this.checkRightChildren);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ jobs: nextProps.jobs });
  }

  componentDidUpdate() {
    this.checkRightChildren();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkRightChildren);
  }

  checkRightChildren = () => {
    const wrapperLeft = document.querySelector('#jobList').offsetLeft;
    const maxRight = wrapperLeft + document.querySelector('#jobList').offsetWidth;
    let num = [];
    document.querySelectorAll('.job-card').forEach(e => {
      e.classList.remove('right-card');
      e.classList.remove('newline-card');
      e.lastChild.style.width = '';
      num.push(e.offsetWidth);
      // console.log(e, e.offsetWidth, wrapperLeft + e.offsetLeft + e.offsetWidth, maxRight - 350);
      if (e.offsetLeft + e.offsetWidth > maxRight - 350) {
        e.classList.add('right-card');
        const wd =
          // eslint-disable-next-line no-shadow
          num.reduce((e, r) => r + e, 0) + 50 * (num.length - 1) - (num[0] + num[num.length - 1]) / 2;
        e.lastChild.style.width = `${wd}px`;
        num = [];
      }
      if (num && num.length === 1) {
        e.classList.add('newline-card');
      }
    });
  };

  handleUpdateBeforeSortStart = ({ index }) =>
    new Promise(resolve =>
      this.setState(
        ({ jobs }) => ({
          sortingItemKey: jobs[index],
          isSorting: true,
        }),
        resolve
      )
    );

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { jobs = [] } = this.state;
    const orderedJobList = arrayMove(jobs, oldIndex, newIndex);

    this.setState({
      jobs: orderedJobList,
      isSorting: false,
      sortingItemKey: null,
    });
    this.props.onSortJobs(orderedJobList);
  };

  render() {
    const { jobs } = this.state;
    const { ...props } = this.props;
    return (
      <SortableList
        axis="xy"
        items={jobs}
        useDragHandle
        onSortEnd={this.onSortEnd}
        isSorting={this.state.isSorting}
        sortingItemKey={this.state.sortingItemKey}
        updateBeforeSortStart={this.handleUpdateBeforeSortStart}
        {...props}
      />
    );
  }
}

JobList.propTypes = {
  jobs: PropTypes.any,
  onSortJobs: PropTypes.func,
  onProcessJob: PropTypes.func,
  onRetryJob: PropTypes.func,
  onModifyJob: PropTypes.func,
  modeModifyJob: PropTypes.number,
};

export default JobList;
