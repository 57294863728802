import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import RouteName from '../App/RouteNames';
import DelayedBilling from './DelayedBilling';
import InAdvanceBilling from './InAdvanceBilling';
import PendingBillTrigger from './PendingBillTrigger';
import ClientTE from './ClientTE';
import Employee from './Employee';
import SavedManualBills from './SavedManualBills';
import TEExpenseType from './TEExpenseType';
import TEExpenseData from './TEExpenseData';
import EmployeeBonus from './EmployeeBonus';
import { Invoicing } from '../ReportManagement/BillingReports';
import { makeGetPermissionsReports, makeGetPermissionsBilling } from '../App/selectors';
import { checkPermissionForAllPages } from '../ReportManagement/CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';
import { EmployeeCostingPNL, EmployeeCostingYearlyPL } from '../ReportManagement/RevenueReports';

class BillingManagement extends PureComponent {
  render() {
    const { permissionsReports, permissionsBilling } = this.props;
    if (!isHasPermissionWithMenu({ permissions: permissionsBilling, listKeys: permissionList.billing })) return null;

    let permissions = {};
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              {/* {/* <Route exact path={RouteName.taxCodeConfig.path} component={TaxCodeConfig} /> */}
              <Route exact path={RouteName.delayedBilling.path} component={DelayedBilling} />
              <Route exact path={RouteName.inAdvanceBilling.path} component={InAdvanceBilling} />
              <Route exact path={RouteName.pendingBillTrigger.path} component={PendingBillTrigger} />
              <Route exact path={RouteName.clientBilling.path} component={ClientTE} />
              <Route exact path={RouteName.employeeBilling.path} component={Employee} />
              <Route exact path={RouteName.savedManualBills.path} component={SavedManualBills} />
              <Route exact path={RouteName.TEExpenseType.path} component={TEExpenseType} />
              <Route exact path={RouteName.TEExpenseData.path} component={TEExpenseData} />
              <Route exact path={RouteName.employeeBonus.path} component={EmployeeBonus} />
              <Route
                exact
                path={RouteName.invoicingBilling.path}
                component={props => <Invoicing {...props} permissions={permissions} isBilling />}
              />
              <Route
                exact
                path={RouteName.employeeCostingPnl.path}
                component={props => <EmployeeCostingPNL {...props} permissions={permissions} isBilling />}
              />
              <Route
                exact
                path={RouteName.employeeYearlyPl.path}
                component={props => <EmployeeCostingYearlyPL {...props} permissions={permissions} isBilling />}
              />
            </Switch>
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

const withReducer = injectReducer({ key: 'billingReducer', reducer });
const withSaga = injectSaga({ key: 'billingSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionsReports: makeGetPermissionsReports() || {},
  permissionsBilling: makeGetPermissionsBilling() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducerReport, withSagaReport, withReducer, withSaga, withConnect)(BillingManagement);
