import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { size, isArray } from 'lodash';
import listCurrency from '../../../../constantsApp/currency.json';
import SubForm from '../../../../components/form/SubForm';
import TierForm from './TierForm';
import { GenericInput, FormCollapse } from '../../../../components/common';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const tierInputs = [
  {
    label: 'label.startRange',
    wrapperClass: 'col-md-4',
    name: 'minimum',
    type: 'number',
    required: true,
  },
  {
    label: 'label.endRange',
    wrapperClass: 'col-md-4',
    name: 'maximum',
    type: 'number',
    required: true,
  },
];

const renderTier = ({
  t,
  item,
  index,
  onRemoveTier,
  onRemoveItem,
  isComplexUsage,
  onChangeTier,
  creatingMode,
  priceRemovable,
  onAddCurrency,
  handleRemovePrice,
  onAddAccumulators,
  handleRemoveGrants,
  handleRemoveAccumulators,
  optionsAccumulatorId,
  onUpdateTierItem,
  optionsGrantId,
  grantInputs,
  accumulatorInputs,
  isNotTier,
  onToggleTab,
  activeTab,
  discountingModel,
  transactionType,
}) => {
  return (
    item.tiers &&
    item.tiers
      .filter(v => size(v) > 1)
      .map((tier, idx) => {
        let title = '-';
        if (tier.minimum && tier.minimum !== '0') {
          title = `<${tier.minimum}>${title}`;
        } else {
          title = `<0>${title}`;
        }
        if (tier.maximum && tier.maximum !== '0') {
          title = `${title}<${tier.maximum}>`;
        } else {
          title = `${title}<0>`;
        }
        return (
          <div className={!isNotTier ? 'card-body' : ''} key={`tier-${index}-${idx}`}>
            <FormCollapse
              key={`tier-${idx}`}
              label="First Name"
              isActive={activeTab && `tier-${index}-${idx}` === activeTab.name && activeTab.isActive}
              title={title}
              state={`tier-${index}-${idx}`}
              onToggleTab={onToggleTab}
            >
              {!isNotTier && (
                <div className="card-header">
                  {`${t('label.addTier')}#`}
                  {idx + 1}
                  {item.tiers.length > 1 && (
                    <button type="button" className="btn-rm-card" onClick={() => onRemoveTier({ idx, index })}>
                      <i className="fa fa-trash" />
                    </button>
                  )}
                </div>
              )}
              <SubForm
                headerTitle={`#${idx + 1}`}
                inputs={
                  !isComplexUsage
                    ? tierInputs
                    : tierInputs.map(val => {
                        const { required, ...rest } = val;
                        return rest;
                      })
                }
                onRemoveItem={e => {
                  e.preventDefault();
                  return onRemoveItem(idx, item);
                }}
                item={tier}
                onChangeItem={data => onChangeTier(idx, data)}
                isRemoveable={creatingMode && item.tiers.length > 1 && priceRemovable}
                isComplexUsage={isComplexUsage}
                index={index}
              />
              <TierForm
                data={tier}
                isTier
                onAddCurrency={() => onAddCurrency({ idx, index })}
                handleRemovePrice={idxPrice => handleRemovePrice({ idxPrice, indexTier: idx, idx, index })}
                onAddAccumulators={() => onAddAccumulators({ idx, index })}
                handleRemoveGrants={idxGrant => handleRemoveGrants({ idxGrant, idx })}
                handleRemoveAccumulators={idxAccumulator => handleRemoveAccumulators({ idxAccumulator, idx })}
                optionsAccumulatorId={optionsAccumulatorId}
                onUpdateTierItem={data => onUpdateTierItem(data, idx, index)}
                optionsGrantId={optionsGrantId}
                // grantInputs={                    !isComplexUsage
                //     ? grantInputs
                //     : grantInputs.map(val => {
                //         const { required, ...rest } = val;
                //         return rest;
                //       })
                // }
                grantInputs={grantInputs}
                // accumulatorInputs={                    !isComplexUsage
                //     ? accumulatorInputs
                //     : accumulatorInputs.map(val => {
                //         const { required, ...rest } = val;
                //         return rest;
                //       })
                // }
                accumulatorInputs={accumulatorInputs}
                isComplexUsage={isComplexUsage}
                index={index}
                discountingModel={discountingModel}
                transactionType={transactionType}
              />
            </FormCollapse>
          </div>
        );
      })
  );
};

const PricesForm = ({
  headerTitle,
  item,
  index,
  onRemoveItem,
  priceRemovable,
  onChangePrice,
  creatingMode,
  onChangeTier,
  onAddCurrency,
  onAddAccumulators,
  handleRemovePrice,
  handleRemoveGrants,
  handleRemoveAccumulators,
  handleAddTier,
  optionsAccumulatorId,
  onRemoveTier,
  onUpdateTierItem,
  optionsGrantId,
  discountingModel,
  onUpdateDataPricing,
  isComplexUsage,
  onAddAccumulatorsWithOtherType,
  onAddGrantWithOtherType,
  onChangeGrantWithOtherType,
  onChangeAccumulatorsWithOtherType,
  handleRemoveAccumulatorsWithOtherType,
  handleRemoveGrantsWithOtherType,
  handleRemovCurrencyWithOtherType,
  readOnlyCurrency,
  selectField,
  transactionType,
}) => {
  const [activeTab, setActiveTab] = useState({});

  const onToggleTab = activeTabName => {
    // const { activeTab } = this.state;
    if (activeTab.name === activeTabName) {
      setActiveTab({ name: activeTabName, isActive: !activeTab.isActive });
    } else {
      setActiveTab({ name: activeTabName, isActive: true });
      // this.setState({ activeTab: { name: activeTabName, isActive: true } });
    }
  };
  // const adaptedInputs = priceInputs.map(pin => ({
  //   ...pin,
  //   disabled: !creatingMode && pin.name !== 'amount',
  // }));
  const { t } = useTranslation('common');
  if (!item.discounts && !item.tiers) return '';

  const grantInputs = [
    {
      label: 'label.grantId',
      wrapperClass: 'col-md-4',
      name: 'resourceId',
      type: 'select',
      required: true,
      options: optionsGrantId,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'grantUnit',
      type: 'select',
      required: true,
      tOptions: 'selections:grantUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'grantDuration',
      type: 'number',
      required: true,
    },
  ];

  const accumulatorInputs = [
    {
      label: 'label.accumulatorId',
      wrapperClass: 'col-md-4',
      name: 'accumulatorId',
      type: 'select',
      required: true,
      options: optionsAccumulatorId,
      isClearable: true,
      menuPortalTarget: true,
    },
    {
      label: 'label.amount',
      wrapperClass: 'col-md-4',
      name: 'amount',
      type: 'number',
      required: true,
      isNegativeValue: true,
    },
    {
      label: 'label.validityUnit',
      wrapperClass: 'col-md-4',
      name: 'unit',
      type: 'select',
      required: true,
      tOptions: 'selections:accumulatorUnit',
      menuPortalTarget: true,
    },
    {
      label: 'label.validityDuration',
      wrapperClass: 'col-md-4',
      name: 'duration',
      type: 'number',
      required: true,
    },
  ];
  if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
    return (
      <div className="card price-panel">
        <TierForm
          data={item}
          onAddCurrency={() => onAddCurrency({ index })}
          handleRemovePrice={idxPrice =>
            handleRemovePrice({ isPricingField: true, idxPrice, indexPricing: index, index })
          }
          onAddAccumulators={() => onAddAccumulators({ isPricingField: true, indexPricing: index, index })}
          handleRemoveGrants={idxGrant =>
            handleRemoveGrants({ isPricingField: true, idxGrant, indexPricing: index, index })
          }
          handleRemoveAccumulators={idxAccumulator =>
            handleRemoveAccumulators({ isPricingField: true, idxAccumulator, indexPricing: index })
          }
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          onUpdateDataPricing={data => onUpdateDataPricing({ data, index })}
          grantInputs={grantInputs}
          accumulatorInputs={accumulatorInputs}
          discountingModel={discountingModel}
          transactionType={transactionType}
        />
      </div>
    );
  }

  const isComplexWithTier = isComplexUsage && selectField.indexOf(4) > -1;

  return (
    <div>
      <div className="card price-panel">
        {discountingModel !== 'FLAT' && (
          <div className="card-header">
            {t('label.discount')}
#
            {index + 1}
          </div>
        )}
        <div className="card-body">
          {item &&
            item.discounts &&
            item.discounts.map((price, idx) => {
              const priceInputs = [
                {
                  label: 'common:label.currency',
                  wrapperClass: 'col-md-4',
                  name: 'currencyId',
                  type: 'select',
                  options: currencyOptions,
                  // disabled: (!creatingMode || index > 0) && !isComplexUsage,
                  disabled: index > 1 && !isComplexUsage,
                  readOnly: readOnlyCurrency,
                  menuPortalTarget: true,
                },
                {
                  label: 'common:label.amount',
                  wrapperClass: 'col-md-4',
                  name: 'amount',
                  type: 'number',
                  disabled: !!price.percent,
                  isNegativeValue: true,
                },
                {
                  label: 'common:label.percent',
                  wrapperClass: 'col-md-4',
                  name: 'percent',
                  type: 'number',
                  disabled: !!price.amount,
                  isNegativeValue: true,
                },
              ];
              return (
                <div className="card-body" key={`discounts-${idx}`}>
                  {discountingModel !== 'FLAT' && (
                    <div className="card-header">
                      {`${t('label.discount')}#`}
                      {index + 1}
                      {item.discounts.filter(i => size(i) > 1).length > 1 && (
                        <button
                          type="button"
                          className="btn-rm-card"
                          onClick={() => handleRemovCurrencyWithOtherType({ idx, index })}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                  )}
                  <SubForm
                    key={`${index}-${idx}-sub-form`}
                    headerTitle={`#${idx + 1}`}
                    inputs={priceInputs}
                    onRemoveItem={e => {
                      e.preventDefault();
                      return onRemoveItem(idx, item);
                    }}
                    item={price}
                    onChangeItem={data => onChangePrice(idx, data)}
                    isRemoveable={creatingMode && item.discounts.length > 1 && priceRemovable}
                    isComplexUsage={isComplexUsage}
                    index={index}
                  />
                </div>
              );
            })}
          {item &&
            item.accumulators &&
            item.accumulators.length > 0 &&
            item.accumulators.map((accumulator, idx) => {
              if (accumulator && size(accumulator) > 2) {
                return (
                  <div key={`accumulators-${idx}`}>
                    <div className="card-header">
                      {`Accumulator#${idx + 1}`}
                      {item.accumulators.filter(i => size(i) > 2).length > 0 && (
                        <button
                          type="button"
                          className="btn-rm-card"
                          onClick={() => handleRemoveAccumulatorsWithOtherType({ idx, index })}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                    <div className="row col-md-12 p-3" key={`grant-${idx}`}>
                      {accumulatorInputs.map(item => (
                        <GenericInput
                          key={`accumulators-${item.name}`}
                          {...item}
                          value={accumulator[item.name]}
                          onChange={({ name, value }) => onChangeAccumulatorsWithOtherType({ name, value, index, idx })}
                        />
                      ))}
                    </div>
                  </div>
                );
              }
              return '';
            })}
          {(!isComplexUsage || isComplexWithTier) &&
            renderTier({
              item,
              index,
              onRemoveTier,
              onRemoveItem,
              isComplexUsage,
              onChangeTier,
              creatingMode,
              priceRemovable,
              onAddCurrency,
              handleRemovePrice,
              onAddAccumulators,
              handleRemoveGrants,
              handleRemoveAccumulators,
              optionsAccumulatorId,
              onUpdateTierItem,
              optionsGrantId,
              grantInputs,
              accumulatorInputs,
              t,
              onToggleTab,
              activeTab,
              discountingModel,
              transactionType,
            })}
          <div className="row col-md-12 pl-3 pb-2">
            {item.tiers && isArray(item.tiers) && (
              <div className="p-2 m-auto">
                <button
                  type="button"
                  className="button button-border gray x-small"
                  onClick={handleAddTier}
                  disabled={index > 0 && !isComplexUsage}
                >
                  {`+ ${t('label.addTier')}`}
                </button>
              </div>
            )}
            {item.discounts && (
              <button
                type="button"
                disabled={item.discounts.length > 0}
                className="button button-border mt-3 black x-small"
                onClick={() => onAddCurrency({ index })}
              >
                {`+ ${t('label.addNewCurrency')}`}
              </button>
            )}
            {item.accumulators && (
              <button
                type="button"
                className="button button-border mt-3 black x-small"
                onClick={() => onAddAccumulatorsWithOtherType({ index })}
                disabled={item.accumulators.filter(val => size(val) > 1).length >= optionsAccumulatorId.length}
              >
                {`+ ${t('label.addNewAccumulators')}`}
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        {isComplexUsage && !isComplexWithTier && (
          <div>
            {renderTier({
              item,
              index,
              onRemoveTier,
              onRemoveItem,
              isComplexUsage,
              onChangeTier,
              creatingMode,
              priceRemovable,
              onAddCurrency,
              handleRemovePrice,
              onAddAccumulators,
              handleRemoveGrants,
              handleRemoveAccumulators,
              optionsAccumulatorId,
              onUpdateTierItem,
              optionsGrantId,
              grantInputs,
              accumulatorInputs,
              isNotTier: true,
              t,
              onToggleTab,
              activeTab,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PricesForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
  priceRemovable: PropTypes.bool,
  creatingMode: PropTypes.bool,
};

PricesForm.defaultProps = {
  item: {},
  isSubmitting: false,
  priceRemovable: true,
  creatingMode: true,
  onRemoveItem: () => {},
};
export default PricesForm;
