import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import OfferForm from './DiscountForm';
import { makeGetDiscountOfferDetail, makeErrorMessage } from '../selectors';
import { makeGetPermissionsPricingManagement, makeGetCcpTime, makeGetCcpPropertiesConfig } from '../../App/selectors';
import {
  getDiscountOfferDetail,
  modifyDiscountOffer,
  updateDiscountOfferStatus,
  getUsageContainerSpec,
  getDiscountOfferAudit,
  convertJsonToDiscountOffer,
} from '../actions';
import {
  checkPermissionModifyDiscountOffer,
  checkPermissionApproveDiscountOffer,
  checkPermissionSellableDiscountOffer,
  checkPermissionNonSellableDiscountOffer,
  checkPermissionSunsetDiscountOffer,
} from '../CheckPermission';
import { getAccumulatorsConfig, getCcpDateTimeConfig, getCcpPropertiesConfig } from '../../App/actions';

class PriceOfferEdit extends Component {
  state = {
    isSubmitting: false,
    discountOfferInfo: {},
    accumulatorList: [],
    grantList: [],
    usageContainerRecordList: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldPriceOfferInfo === props.discountOfferInfo) return null;
    return {
      discountOfferInfo: props.discountOfferInfo,
      oldPriceOfferInfo: props.discountOfferInfo,
    };
  }

  componentDidMount() {
    const {
      getAccumulatorsConfig,
      getUsageContainerSpec,
      getCcpDateTimeConfig,
      ccpTime,
      getCcpPropertiesConfig,
      ccpPropertyList,
    } = this.props;
    this.doGetDiscountOfferDetail();
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
    getAccumulatorsConfig(({ data, success }) => {
      if (success) {
        this.setState({ accumulatorList: data && data.accumulatorList ? data.accumulatorList : [] });
      }
    });

    getUsageContainerSpec(({ data, success }) => {
      if (success) {
        this.setState({ usageContainerRecordList: data && data.length ? data : [] });
      }
    });

    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success && data.ccpPropertyList && data.ccpPropertyList.length) {
          this.doInitCcpPropertyList(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitCcpPropertyList(ccpPropertyList);
    }
  }

  doGetDiscountOfferDetail = () => {
    const { getDiscountOfferDetail } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    getDiscountOfferDetail(id);
  };

  doInitCcpPropertyList = ccpPropertyList => {
    const defaultCurrency = ccpPropertyList.find(val => val.property === 'defaultCurrency');
    this.setState({
      defaultCurrency: defaultCurrency?.value ? defaultCurrency.value : null,
    });
  };

  onSave = (data, cb = null) => {
    const { discountOfferInfo, modifyDiscountOffer, updateDiscountOfferStatus } = this.props;
    const { status, customerDiscounting = [], discountingType, ...dataItem } = data;
    const newPricing = customerDiscounting ? [...customerDiscounting] : [];
    // const num = newPricing.length;
    // Array.from(new Array(10)).map((d, idx) => newPricing.push({ index: num + idx + 1 }));
    if (dataItem.recurringDiscounting) {
      const newPricing = dataItem.recurringDiscounting.map(e => {
        const newEle = { ...e };
        delete newEle.unit;
        delete newEle.frequency;
        return newEle;
      });
      dataItem.recurringDiscounting = newPricing;
    }
    const changedStatus = status !== discountOfferInfo.status;
    this.setState({ isSubmitting: true });
    if (!changedStatus) {
      modifyDiscountOffer({ ...dataItem, customerDiscounting: newPricing }, ({ success }) => {
        this.setState({ isSubmitting: false });
        if (cb) cb({ success });
        if (success) {
          this.doGetDiscountOfferDetail();
        }
      });
    } else {
      modifyDiscountOffer({ ...dataItem, customerDiscounting: newPricing }, ({ success }) => {
        if (cb) cb({ success });
        if (success) {
          updateDiscountOfferStatus({ id: discountOfferInfo.id, status }, () => {
            this.doGetDiscountOfferDetail();
            this.setState({ isSubmitting: false });
          });
        } else {
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const {
      discountOfferInfo,
      isSubmitting,
      accumulatorList,
      grantList,
      usageContainerRecordList,
      defaultCurrency,
    } = this.state;
    const {
      permissionsPricing,
      t,
      location,
      getDiscountOfferAudit,
      convertJsonToDiscountOffer,
      match: {
        params: { id },
      },
      ccpTime,
    } = this.props;
    let modeModifyDiscountOffer = 0;
    let modeApproveDiscountOffer = 0;
    let modeSellableDiscountOffer = 0;
    let modeNonSellableDiscountOffer = 0;
    let modeSunsetDiscountOffer = 0;
    if (permissionsPricing && permissionsPricing.pricingModulePermissions) {
      const listPermissionPricing = permissionsPricing.pricingModulePermissions;
      modeModifyDiscountOffer = checkPermissionModifyDiscountOffer({ listPermission: listPermissionPricing });
      modeApproveDiscountOffer = checkPermissionApproveDiscountOffer({ listPermission: listPermissionPricing });
      modeSellableDiscountOffer = checkPermissionSellableDiscountOffer({ listPermission: listPermissionPricing });
      modeNonSellableDiscountOffer = checkPermissionNonSellableDiscountOffer({ listPermission: listPermissionPricing });
      modeSunsetDiscountOffer = checkPermissionSunsetDiscountOffer({ listPermission: listPermissionPricing });
    }
    if (modeModifyDiscountOffer === 0) return '';

    const optionsAccumulatorId = accumulatorList.map(item => ({
      label: item.accumulatorid,
      value: item.accumulatorid,
    }));

    const optionsGrantId = grantList.map(item => ({
      label: item.resourceId,
      value: item.resourceId,
    }));

    const { effectiveDate, ...rest } = discountOfferInfo;

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.pricingDiscountOffer.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.pricingManagement'), url: RouteNames.pricingItem.path },
                  { name: t('pricingPage:sidebar.discountOffer'), url: RouteNames.pricingPriceOffer.path },
                  { name: discountOfferInfo && discountOfferInfo.name ? discountOfferInfo.name : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <OfferForm
          item={{ ...rest, effectiveDate: effectiveDate || ccpTime || null }}
          onSubmit={this.onSave}
          modeModifyDiscountOffer={modeModifyDiscountOffer}
          modeApproveDiscountOffer={modeApproveDiscountOffer}
          modeSellableDiscountOffer={modeSellableDiscountOffer}
          modeNonSellableDiscountOffer={modeNonSellableDiscountOffer}
          modeSunsetDiscountOffer={modeSunsetDiscountOffer}
          isSubmitting={isSubmitting}
          creatingMode={false}
          optionsAccumulatorId={optionsAccumulatorId}
          optionsGrantId={optionsGrantId}
          usageContainerRecordList={usageContainerRecordList}
          onCancel={this.doGetDiscountOfferDetail}
          getAuditData={getDiscountOfferAudit}
          id={id}
          convertAuditJsonData={convertJsonToDiscountOffer}
          title="label.discountOffer"
          defaultCurrency={defaultCurrency}
        />
      </div>
    );
  }
}

PriceOfferEdit.propTypes = {
  discountOfferInfo: PropTypes.objectOf(PropTypes.any),
  modifyDiscountOffer: PropTypes.func.isRequired,
  updateDiscountOfferStatus: PropTypes.func.isRequired,
  permissionsPricing: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  getDiscountOfferDetail: PropTypes.func.isRequired,
  getAccumulatorsConfig: PropTypes.func.isRequired,
  getDiscountOfferAudit: PropTypes.func.isRequired,
  convertJsonToDiscountOffer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

PriceOfferEdit.defaultProps = {
  permissionsPricing: {},
  discountOfferInfo: {},
  match: {},
  location: {},
};

const mapStateToProps = createStructuredSelector({
  discountOfferInfo: makeGetDiscountOfferDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsPricing: makeGetPermissionsPricingManagement() || {},
  ccpTime: makeGetCcpTime() || '',
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
});

const mapDispatchToProps = dispatch => ({
  getDiscountOfferDetail: (e, cb) => dispatch(getDiscountOfferDetail(e, cb)),
  modifyDiscountOffer: (e, cb) => dispatch(modifyDiscountOffer(e, cb)),
  updateDiscountOfferStatus: (e, cb) => dispatch(updateDiscountOfferStatus(e, cb)),
  getAccumulatorsConfig: cb => dispatch(getAccumulatorsConfig(cb)),
  getUsageContainerSpec: cb => dispatch(getUsageContainerSpec(cb)),
  getDiscountOfferAudit: (e, cb) => dispatch(getDiscountOfferAudit(e, cb)),
  convertJsonToDiscountOffer: (e, cb) => dispatch(convertJsonToDiscountOffer(e, cb)),
  getCcpDateTimeConfig: (e, cb) => dispatch(getCcpDateTimeConfig(e, cb)),
  getCcpPropertiesConfig: (e, cb) => dispatch(getCcpPropertiesConfig(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'pricingPage'])(compose(withConnect)(PriceOfferEdit));
